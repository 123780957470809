import React from "react"
import PropTypes from "prop-types"

import styles from "./Tag.module.css"

const Tag = ({ onClose, text }) => (
  <div className={styles.root}>
    <span>{text}</span>
    <button type="button" className={styles.delete} onClick={onClose}>
      <span className="icon-trash v-m" />
    </button>
  </div>
)

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

Tag.defaultProps = {
  onClose: () => {},
}

export default Tag
