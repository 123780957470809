import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  BLACKLIST_GET,
  BLACKLIST_GET_REQUEST,
  BLACKLIST_GET_SUCCESS,
  BLACKLIST_GET_FAILURE,
  BLACKLIST_CANCEL,
} from "../../constants"

import { get as getKeyword } from "../../fetch/blacklist"

function* get() {
  while (true) {
    const action = yield take(BLACKLIST_GET)
    const { user } = yield select()
    yield put({ type: BLACKLIST_GET_REQUEST })
    try {
      const { data } = yield call(() =>
        getKeyword(user, action.platform, action.keyword)
      )
      if (data) yield put({ type: BLACKLIST_GET_SUCCESS, data: data.data })
    } catch (error) {
      yield put({ type: BLACKLIST_GET_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(get)])

    yield all(tasks)

    yield take(BLACKLIST_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
