import { all, select, put, fork, call, take } from "redux-saga/effects"
import { find } from "lodash"

import {
  PRODUCT_GET,
  PRODUCT_GET_REQUEST,
  PRODUCT_GET_SUCCESS,
  PRODUCT_GET_FAILURE,
  PRODUCT_GET_TRANSLATIONS,
  PRODUCT_GET_TRANSLATIONS_REQUEST,
  PRODUCT_GET_TRANSLATIONS_SUCCESS,
  PRODUCT_GET_TRANSLATIONS_FAILURE,
  PRODUCT_GET_PRICES,
  PRODUCT_GET_PRICES_REQUEST,
  PRODUCT_GET_PRICES_SUCCESS,
  PRODUCT_GET_PRICES_FAILURE,
  PRODUCT_CHANGE_TAB,
  PRODUCT_CANCEL,
} from "../../constants"

import { get as getTranslations } from "../../fetch/product/translations"
import { get as getPrices } from "../../fetch/product/prices"

function* getItem() {
  while (true) {
    const action = yield take(PRODUCT_GET)
    const { productsList } = yield select()
    yield put({ type: PRODUCT_GET_REQUEST })
    const product = find(productsList.list, {
      ref: action.ref,
    })
    if (product) {
      yield put({ type: PRODUCT_GET_SUCCESS, data: product })
    } else {
      yield put({ type: PRODUCT_GET_FAILURE, error: "PRODUCT_NOT_FOUND" })
    }
  }
}

function* prices() {
  while (true) {
    const action = yield take(PRODUCT_GET_PRICES)
    const { user, productSummary } = yield select()
    yield put({ type: PRODUCT_GET_PRICES_REQUEST })
    try {
      const data = yield call(() =>
        getPrices(user, productSummary.data, action.platform)
      )
      if (data) yield put({ type: PRODUCT_GET_PRICES_SUCCESS, data })
    } catch (error) {
      yield put({ type: PRODUCT_GET_PRICES_FAILURE, error })
    }
  }
}

function* translations() {
  while (true) {
    const { user } = yield select()
    const action = yield take(PRODUCT_GET_TRANSLATIONS)
    yield put({ type: PRODUCT_GET_TRANSLATIONS_REQUEST })
    try {
      const { data } = yield call(() =>
        getTranslations(user.id, action.id, action.platform)
      )
      yield put({
        type: PRODUCT_GET_TRANSLATIONS_SUCCESS,
        data: data.data || data,
      })
    } catch (error) {
      yield put({ type: PRODUCT_GET_TRANSLATIONS_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getItem), fork(prices), fork(translations)])

    yield all(tasks)

    yield take([PRODUCT_CANCEL, PRODUCT_CHANGE_TAB])
    yield all(tasks.map(task => task.cancel()))
  }
}
