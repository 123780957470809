import {
  USER_GET_SETTINGS_REQUEST,
  USER_GET_SETTINGS_SUCCESS,
  USER_GET_SETTINGS_FAILURE,
  USER_UPDATE_SETTING_SUCCESS,
  USER_CLEAR_SETTINGS,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  loading: false,
  data: [],
  error: null,
}
function settingsStore(state = defaultState, action) {
  switch (action.type) {
    case USER_GET_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case USER_GET_SETTINGS_SUCCESS:
      return {
        ...state,
        data: {
          ...action.data,
        },
        loading: false,
      }
    case USER_GET_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error.error,
        loading: false,
      }
    case USER_UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.name]: action.data.value,
        },
      }
    case USER_LOGOUT_SUCCESS:
    case USER_CLEAR_SETTINGS:
      return defaultState
    default:
      return state
  }
}

export default settingsStore
