import {
  PROFILE_GET_TOOLS_ACTIVE,
  PROFILE_UPDATE_TOOLS_ACTIVE,
} from "data/constants"
import {
  getActive as getActiveToolList,
  edit,
} from "data/fetch/profile/thirdPartyTools"

export function getActive() {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = getActiveToolList(user.id)

    return dispatch({
      type: PROFILE_GET_TOOLS_ACTIVE,
      promise,
    })
  }
}

export function update(data) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = edit(user.id, {
      ...data,
      id: user.id,
    })

    return dispatch({
      type: PROFILE_UPDATE_TOOLS_ACTIVE,
      promise,
    })
  }
}

export default {
  getActive,
  update,
}
