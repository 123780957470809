const openZendeskPlugin = () => {
  const delayInterval = setInterval(() => {
    if (window.wiZendeskPlugin) {
      clearInterval(delayInterval)
      window.wiZendeskPlugin.openPopup()
    }
  }, 500)
}

export default openZendeskPlugin
