import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function get(id) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${id}/trial/items/ebay`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function prolong(id) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${id}/trial/prolong/ebay`,
    method: "put",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function start(id) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${id}/trial/start/ebay`,
    method: "put",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  get,
  prolong,
  start,
}
