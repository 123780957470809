import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"

import styles from "./DropdownContent.module.css"

export class DropdownContent extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    top: PropTypes.bool,
    right: PropTypes.bool,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
  }
  static defaultProps = {
    className: "",
    activeClassName: styles.active,
    active: false,
    top: false,
    right: false,
    onClick: noop,
  }

  render() {
    const {
      children,
      className,
      active,
      activeClassName,
      top,
      right,
      onClick,
    } = this.props
    const props = {
      className: `${styles.root} ${className} ${
        active ? activeClassName : ""
      } ${right ? styles.right : ""} ${top ? styles.top : ""}`,
    }

    return (
      <div onClick={onClick} role="presentation" {...props}>
        {children}
      </div>
    )
  }
}

export default DropdownContent
