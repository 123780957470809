import { ZENDESK_CHANNELS_GET } from "data/constants"
import ZendeskTicketAPI from "data/fetch/zendesk/ticket"

export function getChannels() {
  return dispatch => {
    const promise = ZendeskTicketAPI.getChannels()

    return dispatch({
      type: ZENDESK_CHANNELS_GET,
      promise,
    })
  }
}

export default {
  getChannels,
}
