import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function uploadFile(file) {
  const controller = new AbortController()
  const data = new FormData()
  data.append("filename", file, file.name)

  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/no-auth/uploads`,
    method: "POST",
    params: {
      filename: file.name,
    },
    data,
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function submit(data) {
  const controller = new AbortController()

  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/no-auth/tickets`,
    method: "POST",
    data,
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function createTicket(data) {
  const controller = new AbortController()

  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/zendesk/tickets`,
    method: "POST",
    data,
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getChannels() {
  const controller = new AbortController()

  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/zendesk/channels`,
    method: "GET",
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  uploadFile,
  submit,
  createTicket,
  getChannels,
}
