import React, { createContext, useContext } from "react"
import { getTranslateFunction } from "redux-i18n"

const TranslateContext = createContext({})

export function useTranslate() {
  return useContext(TranslateContext)
}

export function TranslateProvider({ children, store }) {
  const { i18nState } = store.getState()
  return (
    <TranslateContext.Provider
      value={{
        t: getTranslateFunction(i18nState.translations, i18nState.lang, "en"),
      }}
    >
      {children}
    </TranslateContext.Provider>
  )
}

export function withTranslate(Component) {
  return function TranslateComponent(props) {
    const { t } = useTranslate()
    return <Component {...props} t={t} />
  }
}
