import React from "react"
import PropTypes from "prop-types"

import { useTranslate } from "context/translateContext"
import dayjs from "shared/services/date"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

const renderDatePicker = ({
  reference,
  label,
  addon,
  meta,
  input,
  error,
  translateLabel,
  pickerProps,
  ...props
}) => {
  const { t: translate } = useTranslate()
  return (
    <div
      className={`inputContainer ${
        meta.submitFailed && (error || meta.error) ? "hasError" : ""
      }`}
      {...props}
    >
      {label && (
        <label htmlFor={reference}>
          {translateLabel ? translate(label) : label}
        </label>
      )}
      <div className="inputGroup">
        {addon && <span className="inputAddon">{addon}</span>}
        <DatePicker
          {...input}
          selected={input.value ? dayjs(input.value) : null}
          {...pickerProps}
        />
      </div>
      {meta.submitFailed && meta.error && (
        <span className="inputError">{translate(meta.error)}</span>
      )}
    </div>
  )
}

renderDatePicker.propTypes = {
  reference: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  addon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  error: PropTypes.bool,
  translateLabel: PropTypes.bool,
  pickerProps: PropTypes.object.isRequired,
}
renderDatePicker.defaultProps = {
  translateLabel: false,
  error: null,
}

export default renderDatePicker
