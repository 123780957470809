import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import SurveyActions from "data/actions/surveys/surveys"

import PostMarketCloseSurvey from "./components/PostMarketCloseSurvey"
import PostEplPlanUpgradeCancelSurvey from "./components/PostEplPlanUpgradeCancelSurvey"

const GetFeedbackSurveys = ({
  justClosedMarkets,
  showPostMarketCloseSurvey,
  showPostEplPlanUpgradeCancelSurvey,
  hideSurvey,
  userId,
}) => (
  <Fragment>
    {showPostMarketCloseSurvey && (
      <PostMarketCloseSurvey
        onClose={hideSurvey}
        justClosedMarkets={justClosedMarkets}
        userId={userId}
      />
    )}

    {showPostEplPlanUpgradeCancelSurvey && (
      <PostEplPlanUpgradeCancelSurvey onClose={hideSurvey} />
    )}
  </Fragment>
)

GetFeedbackSurveys.propTypes = {
  showPostMarketCloseSurvey: PropTypes.bool.isRequired,
  showPostEplPlanUpgradeCancelSurvey: PropTypes.bool.isRequired,
  hideSurvey: PropTypes.func.isRequired,
  justClosedMarkets: PropTypes.string,
  userId: PropTypes.number,
}

GetFeedbackSurveys.defaultProps = {
  justClosedMarkets: "",
  userId: 0,
}

const mapStateToProps = state => ({
  justClosedMarkets: state.surveys.justClosedMarkets,
  showPostMarketCloseSurvey: state.surveys.showPostMarketCloseSurvey,
  showPostEplPlanUpgradeCancelSurvey:
    state.surveys.showPostEplPlanUpgradeCancelSurvey,
  userId: state.user.id,
})

const mapDispatchToProps = dispatch => ({
  hideSurvey: () => dispatch(SurveyActions.hideSurvey()),
})

export default connect(mapStateToProps, mapDispatchToProps)(GetFeedbackSurveys)
