import { all, put, fork, call, take, select } from "redux-saga/effects"

import {
  TRANSLATION_MANAGER_BULK_DELETE,
  TRANSLATION_MANAGER_BULK_DELETE_SUCCESS,
  TRANSLATION_MANAGER_BULK_DELETE_FAILURE,
} from "data/constants"

import TranslationManagerAPI from "data/fetch/translationManager/terms"

function* bulkDeleteTerms() {
  while (true) {
    const { payload } = yield take(TRANSLATION_MANAGER_BULK_DELETE)
    const { user } = yield select()
    try {
      yield all(
        payload.terms.map(term =>
          call(TranslationManagerAPI.removeTerm, user.id, term.id)
        )
      )
      yield put({
        type: TRANSLATION_MANAGER_BULK_DELETE_SUCCESS,
        data: { terms: payload.terms },
      })
    } catch (error) {
      yield put({ type: TRANSLATION_MANAGER_BULK_DELETE_FAILURE })
    }
  }
}

export default function* main() {
  const tasks = yield all([fork(bulkDeleteTerms)])

  yield all(tasks)
}
