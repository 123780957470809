import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function start(userId, platform, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/multichannel-onboarding/${platform}`,
    method: "POST",
    data,
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getList(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/multichannel-onboarding?is_ongoing=true`,
    method: "GET",
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function cancelOnboarding(userId, onboardingId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/multichannel-onboarding/${onboardingId}`,
    method: "DELETE",
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  start,
  getList,
  cancelOnboarding,
}
