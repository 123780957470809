import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function getUserOnboarding(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/onboarding`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getCampaignReports(userId, eiasToken, params) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v3/epl/seller/${userId}/accounts/${eiasToken}/campaign-reports`,
    method: "get",
    params,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getAggregatedCampaignReport(userId, eiasToken, params) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v3/epl/seller/${userId}/accounts/${eiasToken}/aggregated-campaign-report`,
    method: "get",
    params,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getMissingCampaignReports(userId, eiasToken, params) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v3/epl/seller/${userId}/accounts/${eiasToken}/missing-reports`,
    method: "get",
    params,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getCampaignChartData(userId, eiasToken, params) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v3/epl/seller/${userId}/accounts/${eiasToken}/chart-data`,
    method: "get",
    params,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getTermsStatus(userId, eiasToken) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/accounts/${eiasToken}/terms`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getAvailableSites(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/available-sites`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function onboard(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/onboarding`,
    method: "post",
    data: {},
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function optOut(userId, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/opt-out`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getOptOutStatus(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/opt-out`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getHadPromotedItems(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/had-anything-promoted`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getAdRateStrategies(userId, campaignId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/campaign-rules/${campaignId}`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function updateAdRateStrategies(userId, campaignId, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/campaign-rules/${campaignId}`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getAdRateHistory(userId, campaignId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/campaign-rule-changes/${campaignId}?limit=100`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function upgradePlan(userId, origin) {
  const controller = new AbortController()
  const plan = origin === "wi" ? "non-sponsored" : "paying"
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/plan/upgrade/${plan}`,
    method: "post",
    data: {},
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getMarkets(userId, eiasToken) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/accounts/${eiasToken}/markets`,
    method: "get",
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getAllMarkets(userId, eiasToken) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/accounts/${eiasToken}/markets?only_accepted=false`,
    method: "get",
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function closeMarket(userId, eiasToken, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/accounts/${eiasToken}/markets/close`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function openMarket(userId, eiasToken, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/accounts/${eiasToken}/markets/open`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function pauseMarket(userId, eiasToken, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/accounts/${eiasToken}/markets/pause`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function setAdRateForMarket(userId, eiasToken, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/accounts/${eiasToken}/defaults`,
    method: "patch",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function recordChurnReason(userId, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/churn-data`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function excludeItemsByFile(userId, file) {
  const controller = new AbortController()
  const data = new FormData()
  data.append("filename", file.uploads[0], file.uploads[0].path)
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/uploads/${userId}/epl-exclude-items`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getItemsList(userId, params) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/items/list`,
    method: "get",
    params,
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getItemsStatus(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/items/status`,
    method: "get",
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function resetAllExcludedItems(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/items/list`,
    method: "delete",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function doActionOnSelectedItems(userId, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/items/list`,
    method: "patch",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getAccounts(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/epl/seller/${userId}/accounts`,
    method: "get",
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function startTrial(userId, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/plan/start-trial`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function startStandard(userId, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/plan/start-standard`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getDashboardReportData(userId, eiasToken, currency) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/epl/seller/${userId}/accounts/${eiasToken}/dashboard-report?currency=${currency}`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  getUserOnboarding,
  getCampaignReports,
  getMissingCampaignReports,
  getAggregatedCampaignReport,
  getCampaignChartData,
  getAvailableSites,
  getTermsStatus,
  getOptOutStatus,
  getHadPromotedItems,
  getAdRateStrategies,
  getAdRateHistory,
  getMarkets,
  getAllMarkets,
  closeMarket,
  openMarket,
  pauseMarket,
  setAdRateForMarket,
  optOut,
  onboard,
  updateAdRateStrategies,
  upgradePlan,
  recordChurnReason,
  excludeItemsByFile,
  getItemsList,
  getItemsStatus,
  resetAllExcludedItems,
  doActionOnSelectedItems,
  getAccounts,
  startTrial,
  startStandard,
  getDashboardReportData,
}
