import { sortBy, uniqWith, isEqual } from "lodash"

import {
  ALERTS_ADD,
  ACCOUNTS_GET_SUCCESS,
  PROFILE_GET_FINANCIAL_DETAILS_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

import { getAccountsIssues, getPaymentIssues } from "../../utils"

const defaultState = {
  list: [],
}

const extendList = (list, newItems) => {
  // Extend existing issue list
  let newList = [...list, ...newItems]
  // Remove duplicate objects
  newList = uniqWith(newList, isEqual)
  // Sort by priority
  newList = sortBy(newList, "priority")
  return newList
}

function alertsStore(state = defaultState, action) {
  switch (action.type) {
    case ALERTS_ADD:
      return {
        ...state,
        list: extendList(state.list, [action.alert]),
      }
    case ACCOUNTS_GET_SUCCESS:
      return {
        ...state,
        list: extendList(state.list, getAccountsIssues(action.data)),
      }
    case PROFILE_GET_FINANCIAL_DETAILS_SUCCESS:
      return {
        ...state,
        list: extendList(state.list, getPaymentIssues(action.data)),
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default alertsStore
