/* eslint-disable no-case-declarations */

import { some, get, has, each, cloneDeep, isNumber, isEmpty } from "lodash"

import { getSitesList } from "shared/services/amazon"
import { isCurrencySupported } from "shared/services/currencies"

import {
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  USER_GET_STATES_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_GET_PLANS_SUCCESS,
  ACCOUNT_UPDATE_BUSINESS_TYPE_SUCCESS,
  CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_SUCCESS,
  EPL_ADD_USER_STATE,
  USER_MULTICHANNEL_ONBOARDING_SET_CHOSEN_PLATFORM,
  USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_COMPLETED,
} from "data/constants"

const defaultState = {
  isAdmin: false,
  isESB: false,
  isPaying: false,
  isPassive: false,
  isTrial: false,
  isEbayTrial: false,
  isPlatformless: false,
  isAmazonTokenLinked: false,
  isShippingEnabled: false,
  isEbayUK: false,
  isSubscriber: false,
  platforms: {},
  accounts: [],
  platformStates: [],
  loading: false,
  error: null,
  chosenOnboardingPlatform: "",
}

function userStore(state = defaultState, action) {
  switch (action.type) {
    case USER_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case USER_GET_SUCCESS:
      return {
        ...state,
        ...action.req.data,
        isCurrencySupported: isCurrencySupported(action.req.data.currency),
        currency: action.req.data.currency || "EUR",
        isAdmin: isAdmin(action.req.data),
        isESB: isESB(action.req.data),
        isPaying: isPaying(action.req.data),
        isPassive: isPassive(action.req.data),
        isPreTrial: isPreTrial(action.req.data),
        isPreTrialESB: isPreTrialESB(action.req.data),
        // TODO: This is part of Quick & dirty solution for Auto Opt-in sellers
        isAutoOptIn: isAutoOptIn(action.req.data),
        isTrial: isTrial(action.req.data),
        isEbayTrial: isEbayTrial(action.req.data),
        isPlatformless: isPlatformless(action.req.data),
        isAmazonTokenLinked: isAmazonTokenLinked(action.req.data),
        isESBTrial: isESBTrial(action.req.data),
        isReturningAmazonUser: isReturningAmazonUser(action.req.data),
        isDeleted: isDeleted(action.req.data),
        isShippingEnabled: isShippingEnabled(action.req.data),
        isEbayUK: isEbayUK(action.req.data),
        platforms: extendPlatformsInformation(action.req.data.platforms),
        loading: false,
      }
    case USER_GET_STATES_SUCCESS:
      return {
        ...state,
        platformStates: action.payload,
      }
    case EPL_ADD_USER_STATE:
      return {
        ...state,
        platformStates: [action.data, ...state.platformStates],
      }
    case CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_SUCCESS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          cdiscount: {
            state: "T",
          },
        },
      }
    case USER_GET_PLANS_SUCCESS:
      return {
        ...state,
        isSubscriber: isSubscriber(action.payload),
        plans: action.payload,
      }
    case ACCOUNT_UPDATE_BUSINESS_TYPE_SUCCESS:
      const [account, ...tail] = state.platforms.ebay.accounts
      const updatedAccount = {
        ...account,
        businessType: action.req.business_type,
      }

      return {
        ...state,
        platforms: {
          ...state.platforms,
          ebay: {
            ...state.platforms.accounts,
            accounts: [updatedAccount, ...tail],
          },
        },
      }
    case USER_MULTICHANNEL_ONBOARDING_SET_CHOSEN_PLATFORM:
      return {
        ...state,
        chosenOnboardingPlatform: action.platform,
      }
    case USER_MULTICHANNEL_ONBOARDING_COMPLETED:
    case USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_SUCCESS:
      return {
        ...state,
        chosenOnboardingPlatform: "",
      }
    case USER_GET_FAILURE:
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        error: action.error.error,
        loading: false,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default userStore

export function isAdmin(user) {
  return has(user, "adminUserId") && isNumber(user.adminUserId)
}

export function isDeleted(user) {
  return some(user.platforms, platform => platform.state === "D")
}

export function isSubscriber(userPlans) {
  return !isEmpty(userPlans)
}

export function isPaying(user) {
  return some(user.platforms, platform => platform.state === "P")
}

export function isPassive(user) {
  return some(user.platforms, platform => platform.state === "V")
}

export function isShippingEnabled(user) {
  return (
    has(user.platforms, "ebay") &&
    some(user.platforms, platform => platform.is_shipping_for_mp_enabled)
  )
}

export function isEbayUK(user) {
  return has(user.platforms, "ebay") && user.country === "GB"
}

export function isESB(user) {
  return (
    has(user.platforms, "ebay") &&
    some(user.platforms, platform => platform.programme === "ESB")
  )
}

// TODO: This is part of Quick & dirty solution for Auto Opt-in sellers
export function isAutoOptIn(user) {
  return (
    has(user.platforms, "ebay") &&
    some(user.platforms, platform => platform.programme === "AUTO_OPT_IN")
  )
}

export function isPreTrial(user) {
  const platform = get(user, "platforms.ebay", {})
  if (!["T", "V"].includes(platform.state)) {
    return false
  }
  return has(platform, "trialLaunched") && platform.trialLaunched === null
}

export function isPreTrialESB(user) {
  return isPreTrial(user) && isESB(user)
}

export function isEbayTrial(user) {
  const platform = get(user, "platforms.ebay", {})
  // Check if ebay is trial
  if (
    platform.state === "T" &&
    has(platform, "trialLaunched") &&
    platform.trialLaunched !== null
  ) {
    return true
  }
  return false
}

export function isTrial(user) {
  let trial = false
  each(user.platforms, (platform, key) => {
    if (
      key === "ebay" &&
      platform.state === "T" &&
      has(platform, "trialLaunched") &&
      platform.trialLaunched !== null
    ) {
      trial = true
    } else if (key !== "ebay" && platform.state === "T") {
      trial = true
    } else {
      trial = false
    }
  })
  return trial
}

export function isPlatformless(user) {
  const platforms = extendPlatformsInformation(user.platforms)
  return isEmpty(platforms) && isEmpty(user.accounts)
}

function isESBTrial(user) {
  return isESB(user) && isPassive(user)
}

function isAmazonTokenLinked(user) {
  return has(user.platforms, "amazon") && user.platforms.amazon.state !== "D"
}

function isReturningAmazonUser(user) {
  return (
    has(user.platforms, "amazon") &&
    (user.platforms.amazon.state === "N" || user.platforms.amazon.state === "I")
  )
}

function extendPlatformsInformation(platforms) {
  const isAmazon =
    has(platforms, "amazon") &&
    !["D", "N", "I"].includes(platforms.amazon.state)

  const isEbay =
    has(platforms, "ebay") &&
    platforms.ebay.accounts.length > 0 &&
    !["D", "I"].includes(platforms.ebay.state) &&
    // TODO: ebay Auto opt-in hack. Should not have platform until become paying
    !some(platforms, platform => platform.programme === "AUTO_OPT_IN")

  const isCdiscount =
    has(platforms, "cdiscount") && platforms.cdiscount.state !== "D"

  const userPlatforms = cloneDeep(platforms)
  if (isAmazon) {
    userPlatforms.amazon = { ...userPlatforms.amazon, ...createMockAmazon() }
  }
  if (!isAmazon) {
    delete userPlatforms.amazon
  }
  if (!isEbay) {
    delete userPlatforms.ebay
  }
  if (!isCdiscount) {
    delete userPlatforms.cdiscount
  }
  return userPlatforms
}

function createMockAmazon() {
  // TODO: These sites should come from API
  return { sites: getSitesList() }
}
