import {
  USER_MULTICHANNEL_ONBOARDING_START,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET,
  USER_MULTICHANNEL_ONBOARDING_CANCEL_POST,
  USER_MULTICHANNEL_ONBOARDING_SET_STEP_NAME,
  USER_MULTICHANNEL_ONBOARDING_CATCH_REDIRECT_BACK,
  USER_MULTICHANNEL_ONBOARDING_RECALCULATE_ONGOING_ONBOARDINGS,
} from "data/constants"
import MultichannelOnboardingAPI from "data/fetch/user/multichannelOnboarding"

export function startOnboarding(platform) {
  return (dispatch, getState) => {
    const { user, signUp } = getState()
    let payload = {
      redirect_back_url: window.location.origin,
      user_id: user.id,
      campaign: signUp.context.utm_campaign || "",
    }

    if (["amazon", "ebay"].includes(platform)) {
      payload = {
        ...payload,
        redirect_back_url: `${window.location.origin}?onboarding=${platform}`,
        redirect_back_failure_url: `${
          window.location.origin
        }/?onboarding=${platform}`,
      }
    }

    const promise = MultichannelOnboardingAPI.start(user.id, platform, payload)

    return dispatch({
      type: USER_MULTICHANNEL_ONBOARDING_START,
      promise,
    })
  }
}

export function getOngoingOnboardings() {
  return (dispatch, getState) => {
    const { user } = getState()
    const userId = user.id

    const promise = MultichannelOnboardingAPI.get(userId)

    return dispatch({
      type: USER_MULTICHANNEL_ONBOARDING_LIST_GET,
      promise,
    })
  }
}

export function cancelCurrentOnboarding() {
  return (dispatch, getState) => {
    const { multichannelOnboarding } = getState()
    const userId = multichannelOnboarding.current.user_id
    const onboardingId = multichannelOnboarding.current.id
    const promise = MultichannelOnboardingAPI.cancelOnboarding(
      userId,
      onboardingId
    )

    return dispatch({
      type: USER_MULTICHANNEL_ONBOARDING_CANCEL_POST,
      promise,
    })
  }
}

export function setCurrentOnboardingStep(stepName) {
  return dispatch =>
    dispatch({
      type: USER_MULTICHANNEL_ONBOARDING_SET_STEP_NAME,
      stepName,
    })
}

export function dispatchTokenStateConnection(platform, reason) {
  return dispatch =>
    dispatch({
      type: USER_MULTICHANNEL_ONBOARDING_CATCH_REDIRECT_BACK,
      platform,
      reason,
    })
}

export function recalculateOnboardings(platform) {
  return dispatch =>
    dispatch({
      type: USER_MULTICHANNEL_ONBOARDING_RECALCULATE_ONGOING_ONBOARDINGS,
      platform,
    })
}

export default {
  startOnboarding,
  getOngoingOnboardings,
  cancelCurrentOnboarding,
  setCurrentOnboardingStep,
  dispatchTokenStateConnection,
  recalculateOnboardings,
}
