import { filter } from "lodash"
import {
  CONVERSION_CHARTS_GET_LIST_REQUEST,
  CONVERSION_CHARTS_GET_LIST_SUCCESS,
  CONVERSION_CHARTS_GET_LIST_FAILURE,
  CONVERSION_CHART_DELETE_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  count: 0,
  list: [],
  state: "INITIAL",
  error: null,
}

function conversionChartsStore(state = defaultState, action) {
  switch (action.type) {
    case CONVERSION_CHARTS_GET_LIST_REQUEST:
      return {
        ...state,
        count: 0,
        list: [],
        error: null,
        state: "LOADING",
      }
    case CONVERSION_CHARTS_GET_LIST_SUCCESS:
      return {
        ...state,
        count: action.data.pagination.total_count,
        list: action.data.charts,
        state: "LOADED",
      }
    case CONVERSION_CHARTS_GET_LIST_FAILURE:
      return {
        ...state,
        count: 0,
        list: [],
        error: action.error,
        state: "FAILED",
      }
    case CONVERSION_CHART_DELETE_SUCCESS:
      return {
        ...state,
        count: state.count - 1,
        list: state.list.reduce((result, brand) => {
          const charts = filter(
            brand.charts,
            chart => chart.id !== action.data.chart.id
          )
          if (charts.length > 0) {
            result.push({
              ...brand,
              charts,
            })
          }
          return result
        }, []),
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default conversionChartsStore
