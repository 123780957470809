import { get } from "lodash"
import {
  EPL_CAMPAIGN_REPORTS_GET_SUCCESS,
  EPL_CAMPAIGN_REPORTS_GET_FAILURE,
  EPL_AGGREGATED_CAMPAIGN_REPORT_GET_SUCCESS,
  EPL_AGGREGATED_CAMPAIGN_REPORT_GET_FAILURE,
  EPL_CAMPAIGN_CHART_DATA_GET_SUCCESS,
  EPL_CAMPAIGN_CHART_DATA_GET_FAILURE,
  EPL_SAVE_CAMPAIGN_CHART_OPTION,
  EPL_REPORTS_GET,
  EPL_REPORTS_GET_SUCCESS,
  EPL_REPORTS_GET_FAILURE,
  LOADING_STATES,
  EPL_ADRATE_STRATEGY_POST_SUCCESS,
  EPL_MISSING_REPORTS_GET_REQUEST,
  EPL_MISSING_REPORTS_GET_SUCCESS,
  EPL_MISSING_REPORTS_GET_FAILURE,
  GENERAL_SET_ACTIVE_ACCOUNT,
} from "data/constants"

const defaultState = {
  status: LOADING_STATES.INITIAL,
  error: null,
  campaigns: [],
  missingReports: {
    status: LOADING_STATES.INITIAL,
    reports: [],
    error: null,
  },
  chartData: [],
  chartOption: "total_sales",
}

const eplStore = (state = defaultState, action) => {
  switch (action.type) {
    case EPL_CAMPAIGN_REPORTS_GET_SUCCESS:
      return {
        ...state,
        campaigns: get(action.req, "reports", []),
      }
    case EPL_CAMPAIGN_REPORTS_GET_FAILURE:
    case EPL_AGGREGATED_CAMPAIGN_REPORT_GET_FAILURE:
    case EPL_CAMPAIGN_CHART_DATA_GET_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case EPL_ADRATE_STRATEGY_POST_SUCCESS:
      return {
        ...state,
        campaigns: updateCampaignAdRate(state, action),
      }
    case EPL_AGGREGATED_CAMPAIGN_REPORT_GET_SUCCESS:
      return {
        ...state,
        aggregatedReport: action.req,
      }
    case EPL_CAMPAIGN_CHART_DATA_GET_SUCCESS:
      return {
        ...state,
        chartData: action.req.datapoints,
      }
    case EPL_SAVE_CAMPAIGN_CHART_OPTION:
      return {
        ...state,
        chartOption: action.params,
      }
    case EPL_REPORTS_GET:
      return {
        ...state,
        error: null,
        status: LOADING_STATES.LOADING,
      }
    case EPL_REPORTS_GET_SUCCESS:
      return {
        ...state,
        status: LOADING_STATES.LOADED,
      }
    case EPL_REPORTS_GET_FAILURE:
      return {
        ...state,
        status: LOADING_STATES.FAILED,
        missingReports: {
          ...state.missingReports,
          status: LOADING_STATES.LOADED,
          reports: [],
        },
      }
    case EPL_MISSING_REPORTS_GET_REQUEST:
      return {
        ...state,
        missingReports: {
          ...state.missingReports,
          status: LOADING_STATES.LOADING,
        },
      }
    case EPL_MISSING_REPORTS_GET_SUCCESS:
      return {
        ...state,
        missingReports: {
          ...state.missingReports,
          status: LOADING_STATES.LOADED,
          reports: action.req.reports,
        },
      }
    case EPL_MISSING_REPORTS_GET_FAILURE:
      return {
        ...state,
        missingReports: {
          ...state.missingReports,
          status: LOADING_STATES.FAILED,
          reports: [],
          error: action.req.error,
        },
      }
    case GENERAL_SET_ACTIVE_ACCOUNT:
      return defaultState
    default:
      return state
  }
}

const updateCampaignAdRate = (state, action) =>
  state.campaigns.map(campaign => {
    if (campaign.campaign_id === action.campaignId) {
      const { data } = action
      let adRate
      if (data.rate) {
        adRate = {
          rate: data.rate,
        }
      } else {
        adRate = {
          min_rate: data.min_rate,
          max_rate: data.max_rate,
        }
      }

      return {
        ...campaign,
        rate: adRate,
      }
    }
    return campaign
  })

export default eplStore
