import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

// import mockPlans from "./mockPlans"

export function getAll(user) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${user.id}/current_payment_links`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

// TODO: rename once we get rid of the old plans endpoint above
export function fetchNewPlans(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/subscriptions/plans`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()
  return promise
}

export function fetchSubscribedPlans(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/users/${userId}/subscriptions/plans/current`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()
  return promise
}

export function getIds() {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/no-auth/rate_plans`,
    method: "get",
  }
  const promise = fetch(options)
    .then(response => response.data)
    .then(response => response.data.map(data => data.data.attributes))
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  getAll,
  fetchNewPlans,
  fetchSubscribedPlans,
  getIds,
}
