import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function getTokenUrl(context, data) {
  const controller = new AbortController()
  const params = {
    keyset: data.keyset,
    type: data.type === "R" ? "regular" : "oauth",
    site: data.site,
  }
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/no-auth/tokens`,
    method: "GET",
    params,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  getTokenUrl,
}
