import {
  LOADING_STATES,
  UPSELL_GET_LIMITS_REQUEST,
  UPSELL_GET_LIMITS_SUCCESS,
  UPSELL_GET_LIMITS_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  state: LOADING_STATES.INITIAL,
  limits: {},
}

function upsellStore(state = defaultState, action) {
  switch (action.type) {
    case UPSELL_GET_LIMITS_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      }
    case UPSELL_GET_LIMITS_SUCCESS:
      return {
        ...state,
        limits: {
          ...action.req,
        },
        state: LOADING_STATES.LOADED,
      }
    case UPSELL_GET_LIMITS_FAILURE:
      return {
        ...state,
        error: action.error.error,
        state: LOADING_STATES.FAILED,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default upsellStore
