import { each, cloneDeep } from "lodash"

const toggleRepricerItem = (list, sites, value) => {
  const translations = cloneDeep(list)
  each(sites, code => {
    translations[code] = {
      ...translations[code],
      repricerBlocked: value,
    }
  })
  return translations
}

export const block = (list, sites) => toggleRepricerItem(list, sites, true)
export const unblock = (list, sites) => toggleRepricerItem(list, sites, false)

export default {
  block,
  unblock,
}
