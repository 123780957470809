import React, { Component } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import Permissions from "react-redux-permissions"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty } from "lodash"

import { withTranslate } from "context/translateContext"

import history from "core/history"

import { GENERAL_HIDE_MODAL } from "data/constants"

import ModalHeader from "shared/components/Modal/ModalHeader"
import ModalContent from "shared/components/Modal/ModalContent"
import ZendeskArticles from "shared/components/Zendesk/Articles"

import styles from "./HelpCenterModal.module.css"

class HelpCenterModal extends Component {
  static propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      query: "",
    }

    this.updateQuery = this.updateQuery.bind(this)
  }

  componentDidMount() {
    const { closeModal } = this.props
    this.unlisten = history.listen(() => {
      closeModal()
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  updateQuery(e) {
    this.setState({
      query: e.target.value,
    })
  }

  render() {
    const { onRequestClose } = this.props
    const { query } = this.state
    const { t: translate } = this.props

    return (
      <Modal
        isOpen
        onRequestClose={onRequestClose}
        contentLabel="Product Information"
        className="aside"
        overlayClassName="asideOverlay"
        ariaHideApp={false}
      >
        <div className={styles.root}>
          <ModalHeader onRequestClose={onRequestClose}>
            <h2 className="w400 m-b-0">{translate("Quick help")}</h2>
            <a href="https://webinterpret.zendesk.com/" target="_blank">
              {translate("Go to help center")}
            </a>
          </ModalHeader>
          <ModalContent>
            <div className={styles.search}>
              <input
                placeholder={translate("How can we help?")}
                onChange={this.updateQuery}
              />
            </div>
            {!isEmpty(query) && (
              <div className="p-t-20 p-b-20">
                <ZendeskArticles query={query} limit={10} viewMore />
              </div>
            )}
            <div className={styles.menu}>
              <ul className="l-unstyled">
                <li className="separator" />
                <li>
                  <a href="https://webinterpret.zendesk.com/" target="_blank">
                    {translate("Help center")}
                  </a>
                </li>
                <Permissions allowed={["china"]}>
                  <li>
                    <Link to="/submit-request">
                      {translate("Report a problem")}
                    </Link>
                  </li>
                </Permissions>
                <li className="separator" />
              </ul>
            </div>
          </ModalContent>
        </div>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  closeModal: () =>
    dispatch({
      type: GENERAL_HIDE_MODAL,
      name: "help",
    }),
})

export default connect(null, mapDispatchToProps)(withTranslate(HelpCenterModal))
