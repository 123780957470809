import { isNull, isNil, findKey, forEach, get, reduce } from "lodash"
import dayjs from "shared/services/date"

export const MAP_DURATION_TO_NAME = {
  M: "month",
  Y: "year",
  A: "transaction",
}

export const OLD_PLANS = [
  "Starter - Monthly",
  "Silver - Monthly",
  "Gold - Monthly",
  "Platinum - Monthly",
  "Silver - Yearly",
  "Gold - Yearly",
  "Platinum - Yearly",
]

export const NEW_PLANS_PRICES = {
  PAY_AS_YOU_GO: {
    EUR: 0.99,
    GBP: 0.79,
    USD: 1.29,
  },
  MONTHLY: {
    EUR: 49.99,
    GBP: 41.99,
    USD: 49.99,
  },
  YEARLY: {
    EUR: 549.89,
    GBP: 461.89,
    USD: 549.89,
  },
}

const sites = {
  AMAZON: ["UK", "DE", "ES", "FR", "IT"],
  FREE: ["UK", "DE", "ES", "FR", "IT", "US", "AU"],
  PAYG: [
    "UK",
    "DE",
    "ES",
    "FR",
    "IT",
    "US",
    "AU",
    "CA",
    "BE",
    "IE",
    "AT",
    "HK",
    "SG",
  ],
  PRO: [
    "UK",
    "DE",
    "ES",
    "FR",
    "IT",
    "US",
    "AU",
    "CA",
    "BE",
    "IE",
    "AT",
    "HK",
    "SG",
  ],
}

export const getPlanPrice = (planType, data) => {
  if (planType === "PAY_AS_YOU_GO" || data.duration === "A") return 0
  return (data.price && data.price.net_amount) || data.amount
}

export const getTransactionFee = (planType, data) => {
  if (planType !== "PAY_AS_YOU_GO" && data.duration !== "A") return 0
  return NEW_PLANS_PRICES.PAY_AS_YOU_GO[data.price.currency]
}

export function isSpecialOfferAvailable(plan) {
  return !isNull(plan)
}

export function isSpecialPlanCurrent(plans) {
  if (!isSpecialOfferAvailable(plans.SPECIAL) || !plans.CURRENT) return false

  return plans.SPECIAL.id === plans.CURRENT.id
}

export const isUserEarlyBird = user => {
  if (get(user, "special_offers.early_birds.value", false)) {
    const today = dayjs()
    if (dayjs(user.special_offers.early_birds.expiry_date).isAfter(today)) {
      return true
    }
  }
  return false
}

export function determineCurrentPlan(data, ids) {
  const { CURRENT, ...realPlans } = data
  const FREE = {
    isCurrent: true,
    display_name: "Free",
    amount: 0,
    transaction: 0,
    displayPrice: 0,
  }

  if (isNull(CURRENT)) {
    return {
      FREE,
      ...realPlans,
    }
  }

  if (isSpecialOfferAvailable(data.SPECIAL) && isSpecialPlanCurrent(data)) {
    return {
      ...realPlans,
      SPECIAL: {
        ...realPlans.SPECIAL,
        isCurrent: true,
      },
    }
  }

  if (
    get(CURRENT, "id") !== get(realPlans, "SPECIAL.id") &&
    !ids.some(item => item.id === get(CURRENT, "id"))
  ) {
    return {
      LEGACY: {
        ...CURRENT,
        isCurrent: true,
      },
      ...realPlans,
    }
  }

  const clone = {}
  forEach(realPlans, (plan, name) => {
    if (CURRENT.id === get(plan, "id")) {
      const updatedPlan = {
        ...plan,
        isCurrent: true,
      }
      clone[name] = updatedPlan
      return true
    }
    clone[name] = plan
    return true
  })

  return clone
}

export function reducePlanList(plans) {
  const userSelectedPlanName = findKey(plans, { isCurrent: true })
  return reduce(
    plans,
    (result, value, key) => {
      if (isNull(value)) return result
      const current = { [key]: value }

      if (!isNil(plans.SPECIAL)) {
        if (
          key === "SPECIAL" ||
          userSelectedPlanName === key ||
          (key === "YEARLY" && userSelectedPlanName === "MONTHLY") ||
          (key === "MONTHLY" && userSelectedPlanName === "YEARLY")
        ) {
          return {
            ...result,
            ...current,
          }
        }

        return result
      }

      return {
        ...current,
        ...result,
      }
    },
    {}
  )
}

export function addSitesListToPlans(plans, currency) {
  return reduce(
    plans,
    (result, value, key) => {
      switch (key) {
        case "LEGACY":
          return {
            ...result,
            [key]: {
              ...value,
              currency,
            },
          }
        case "FREE":
          return {
            ...result,
            [key]: {
              ...value,
              currency,
              displayPrice: getPlanPrice(key, value),
              transaction: getTransactionFee(key, value),
              success_fee: "0%",
              ebaySites: sites.FREE,
              amazonSites: sites.AMAZON,
            },
          }
        case "PAY_AS_YOU_GO":
          return {
            ...result,
            [key]: {
              ...value,
              currency,
              displayPrice: getPlanPrice(key, value),
              transaction: getTransactionFee(key, value),
              success_fee: "5%",
              ebaySites: sites.PAYG,
              amazonSites: sites.AMAZON,
            },
          }
        case "SPECIAL":
          return {
            ...result,
            [key]: {
              ...value,
              currency,
              displayPrice: getPlanPrice(key, value),
              transaction: getTransactionFee(key, value),
              success_fee: isNil(value.monthly_bundled_gmv) ? "5%" : null,
              ebaySites: value.duration === "A" ? sites.PAYG : sites.PRO,
              amazonSites: sites.AMAZON,
            },
          }
        default:
          return {
            ...result,
            [key]: {
              ...value,
              currency,
              displayPrice: getPlanPrice(key, value),
              transaction: getTransactionFee(key, value),
              success_fee: "5%",
              ebaySites: sites.PRO,
              amazonSites: sites.AMAZON,
            },
          }
      }
    },
    {}
  )
}

export function mergePROPlans(plans, isEarlyBird) {
  if (!get(plans, "MONTHLY", null) || !get(plans, "YEARLY", null)) return plans
  const { MONTHLY, YEARLY, ...rest } = plans
  return {
    ...rest,
    PRO: {
      MONTHLY: {
        ...MONTHLY,
        isEarlyBird,
        type: "MONTHLY",
        display_name: isEarlyBird ? "EARLY BIRDS PRO" : "PRO MONTHLY",
      },
      YEARLY: {
        ...YEARLY,
        type: "YEARLY",
        display_name: "PRO YEARLY",
      },
    },
  }
}

export default {
  MAP_DURATION_TO_NAME,
  OLD_PLANS,
  NEW_PLANS_PRICES,
  isUserEarlyBird,
  getPlanPrice,
  getTransactionFee,
  reducePlanList,
  addSitesListToPlans,
  isSpecialPlanCurrent,
  determineCurrentPlan,
  isSpecialOfferAvailable,
  mergePROPlans,
}
