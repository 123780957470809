import {
  TRANSLATION_MANAGER_TERM_GET_REQUEST,
  TRANSLATION_MANAGER_TERM_GET_SUCCESS,
  TRANSLATION_MANAGER_TERM_GET_FAILURE,
  TRANSLATION_MANAGER_TERM_UPDATE_SUCCESS,
  TRANSLATION_MANAGER_TERM_UPDATE_FAILURE,
  TRANSLATION_MANAGER_TERM_DELETE_FAILURE,
  TRANSLATION_MANAGER_TERM_CLEAR,
  USER_LOGOUT_SUCCESS,
  LOADING_STATES,
} from "data/constants"

const defaultState = {
  data: {},
  state: LOADING_STATES.INITIAL,
  error: null,
}

function sellerTermStore(state = defaultState, action) {
  switch (action.type) {
    case TRANSLATION_MANAGER_TERM_GET_REQUEST:
      return {
        ...state,
        data: {},
        error: null,
        state: LOADING_STATES.LOADING,
      }
    case TRANSLATION_MANAGER_TERM_GET_SUCCESS:
      return {
        ...state,
        data: { ...action.data },
        state: LOADING_STATES.LOADED,
      }
    case TRANSLATION_MANAGER_TERM_GET_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        state: LOADING_STATES.FAILED,
      }
    case TRANSLATION_MANAGER_TERM_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.data,
      }
    case TRANSLATION_MANAGER_TERM_UPDATE_FAILURE:
    case TRANSLATION_MANAGER_TERM_DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case TRANSLATION_MANAGER_TERM_CLEAR:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default sellerTermStore
