import { all, fork, take } from "redux-saga/effects"
import { get } from "lodash"
import Cookies from "js-cookie"

import { USER_MULTICHANNEL_ONBOARDING_START_SUCCESS } from "data/constants"
import { cdiscountWelcomeMessageCookieName } from "shared/services/multichannelOnboarding"

function* setWelcomeMessageCookie() {
  while (true) {
    const { req: data } = yield take([
      USER_MULTICHANNEL_ONBOARDING_START_SUCCESS,
    ])

    const isCookieSet = !!Cookies.get(cdiscountWelcomeMessageCookieName)
    if (get(data, "channel", "") === "cdiscount" && !isCookieSet) {
      Cookies.set(cdiscountWelcomeMessageCookieName, true, {
        path: "/",
        expires: 365,
      })
    }
  }
}

export default function* main() {
  const tasks = yield all([fork(setWelcomeMessageCookie)])

  yield all(tasks)
}
