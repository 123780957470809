import {
  USER_GET_REQUEST,
  USER_GET_FAILURE,
  GENERAL_INITIAL_DATA_READY,
  GENERAL_INITIAL_DATA_FAILED,
  GENERAL_SET_ACTIVE_PLATFORM,
  GENERAL_SET_ACTIVE_ACCOUNT,
  USER_LOGOUT_SUCCESS,
  GENERAL_SHOW_OVERLAY,
  GENERAL_HIDE_OVERLAY,
  GENERAL_SHOW_MODAL,
  GENERAL_HIDE_MODAL,
} from "../constants"

const defaultState = {
  general: false,
  errorLoadingInitialData: false,
  stateReady: false,
  activePlatform: null,
  activeAccount: null,
  overlay: false,
  overlayMessage: null,
  modals: {
    auth: false,
    tos: false,
    help: false,
  },
}

function generalStore(state = defaultState, action) {
  switch (action.type) {
    case USER_GET_REQUEST:
      return {
        ...state,
        stateReady: false,
        loading: true,
      }
    case USER_GET_FAILURE:
      return {
        ...state,
        stateReady: false,
        loading: false,
      }
    case GENERAL_INITIAL_DATA_READY:
      return {
        ...state,
        stateReady: action.value,
        loading: false,
      }
    case GENERAL_INITIAL_DATA_FAILED:
      return {
        ...state,
        errorLoadingInitialData: true,
      }
    case GENERAL_SET_ACTIVE_PLATFORM:
      return {
        ...state,
        activePlatform: action.platform,
      }
    case GENERAL_SET_ACTIVE_ACCOUNT:
      return {
        ...state,
        activeAccount: action.account,
      }
    case GENERAL_SHOW_OVERLAY:
      return {
        ...state,
        overlay: true,
        overlayMessage: action.message,
      }
    case GENERAL_HIDE_OVERLAY:
      return {
        ...state,
        overlay: false,
        overlayMessage: null,
      }
    case GENERAL_SHOW_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.name]: true,
        },
      }
    case GENERAL_HIDE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.name]: false,
        },
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default generalStore
