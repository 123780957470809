/* eslint-disable no-restricted-globals */
import { put, takeLatest, takeEvery } from "redux-saga/effects"
import * as Sentry from "@sentry/react"

import history from "core/history"

import {
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  GENERAL_HIDE_MODAL,
} from "data/constants"
import { get as getUser } from "data/actions/user/auth"

function* loginSuccess() {
  yield put({
    type: GENERAL_HIDE_MODAL,
    name: "auth",
  })
  yield put(getUser())
}

function* logout() {
  // Clear Sentry context
  Sentry.configureScope(scope => scope.clear())
  // Redirect to start page
  history.push("/")
}

export default function* main() {
  yield takeLatest(USER_LOGIN_SUCCESS, loginSuccess)
  yield takeEvery(USER_LOGOUT_SUCCESS, logout)
}
