import { map, flatMapDepth, isUndefined } from "lodash"

/**
 * onChange from Redux Form Field has to be called explicity.
 */
export function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : "")
  }
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
export function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map(value => value.value))
  }
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function flattenOptions(options) {
  return flatMapDepth(
    map(options, option =>
      !isUndefined(option.options) ? flattenOptions(option.options) : option
    )
  )
}
export function transformValue(value, options, multi) {
  if (multi && typeof value === "string") return []
  const flatOptions = flattenOptions(options)
  const filteredOptions = flatOptions.filter(option =>
    multi ? value.indexOf(option.value) !== -1 : option.value === value
  )

  return multi ? filteredOptions : filteredOptions[0]
}

export default {
  singleChangeHandler,
  multiChangeHandler,
  transformValue,
}
