import { ELIGIBILITY_STATUS } from "data/constants"

export const getActiveAccount = ({ activeAccount, accounts = [] }) =>
  accounts.find(account => account.account_id === activeAccount)

export const isUserEligibleForEbayMarketing = accounts => {
  if (
    accounts?.some(
      account => account.eligibility_status === ELIGIBILITY_STATUS.ELIGIBLE
    )
  )
    return ELIGIBILITY_STATUS.ELIGIBLE
  if (
    accounts?.some(
      account => account.eligibility_status === ELIGIBILITY_STATUS.NOT_PROCESSED
    )
  )
    return ELIGIBILITY_STATUS.NOT_PROCESSED
  return ELIGIBILITY_STATUS.NOT_ELIGIBLE
}
