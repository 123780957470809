export class DataStore {
  static getAll(storeName) {
    const data =
      "localStorage" in window && window.localStorage.getItem(storeName)
    let parsedData
    try {
      parsedData = JSON.parse(data)
    } catch (e) {
      return data
    }
    return parsedData
  }
  static set(storeName, data) {
    const userStoredData = this.getAll(storeName)
    const newData = { ...userStoredData, ...data }
    window.localStorage.setItem(storeName, JSON.stringify(newData))
  }
  static remove(storeName, dataToRemove) {
    const data = this.getAll(storeName)
    delete data[dataToRemove]
    window.localStorage.setItem(storeName, JSON.stringify(data))
  }
}

export default {
  DataStore,
}
