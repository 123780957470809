import {
  ENTITLEMENTS_CUSTOM_GET_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

const defaultState = {
  bulk_requesting_enabled: false,
  channel_chat: false,
  channel_ticket_form: false,
  channel_talk: false,
  channel_help_center: false,
  can_onboard_to_epl: false,
  can_onboard_to_epl_domestic: false,
  can_use_epl_trial: false,
  can_use_epl_trial_domestic: false,
  can_use_shipping: false,
  can_onboard_to_returns_domestic: false,
  can_onboard_to_returns_international_active: false,
}

const entitlementsStore = (state = defaultState, action) => {
  switch (action.type) {
    case ENTITLEMENTS_CUSTOM_GET_SUCCESS:
      return {
        ...state,
        bulk_requesting_enabled: action.data.bulk_requesting_enabled,
        channel_chat: action.data.channel_chat,
        channel_ticket_form: action.data.channel_ticket_form,
        channel_talk: action.data.channel_talk,
        channel_help_center: action.data.channel_help_center,
        can_onboard_to_epl: action.data.can_onboard_to_epl,
        can_onboard_to_epl_domestic: action.data.can_onboard_to_epl_domestic,
        can_use_epl_trial: action.data.can_use_epl_trial,
        can_use_epl_trial_domestic: action.data.can_use_epl_trial_domestic,
        can_manage_epl_ad_rate_strategies:
          action.data.can_manage_epl_ad_rate_strategies,
        can_manage_epl_domestic_ad_rate_strategies:
          action.data.can_manage_epl_domestic_ad_rate_strategies,
        can_use_shipping: action.data.can_use_shipping,
        can_onboard_to_returns_domestic:
          action.data.can_onboard_to_returns_domestic,
        can_onboard_to_returns_international_active:
          action.data.can_onboard_to_returns_international_active,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default entitlementsStore
