import {
  SHOW_POST_MARKET_CLOSE_SURVEY,
  SHOW_POST_EPL_PLAN_UPGRADE_CANCEL_SURVEY,
  HIDE_SURVEY,
  TRIGGER_SURVEY,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

const defaultStore = {
  showPostMarketCloseSurvey: false,
  showPostEplPlanUpgradeCancelSurvey: false,
}

const surveyStore = (state = defaultStore, action) => {
  switch (action.type) {
    case TRIGGER_SURVEY:
      return {
        ...state,
        ...triggerSurvey(action),
      }
    case HIDE_SURVEY:
    case USER_LOGOUT_SUCCESS:
      return {
        ...defaultStore,
      }
    default:
      return state
  }
}

const triggerSurvey = action => {
  const surveySettings = {}
  switch (action.data.surveyActionType) {
    case SHOW_POST_MARKET_CLOSE_SURVEY:
      surveySettings.showPostMarketCloseSurvey = true
      surveySettings.justClosedMarkets = action.data.justClosedMarkets
      break
    case SHOW_POST_EPL_PLAN_UPGRADE_CANCEL_SURVEY:
      surveySettings.showPostEplPlanUpgradeCancelSurvey = true
      break
    default:
  }
  return surveySettings
}

export default surveyStore
