import {
  ZENDESK_ARTICLES_GET_LIST_REQUEST,
  ZENDESK_ARTICLES_GET_LIST_SUCCESS,
  ZENDESK_ARTICLES_GET_LIST_FAILURE,
} from "../../constants"

const defaultState = {
  count: 0,
  list: [],
  loading: false,
  error: null,
}

function zendeskArticlesStore(state = defaultState, action) {
  switch (action.type) {
    case ZENDESK_ARTICLES_GET_LIST_REQUEST:
      return {
        ...state,
        count: 0,
        list: [],
        error: null,
        loading: true,
      }
    case ZENDESK_ARTICLES_GET_LIST_SUCCESS:
      return {
        ...state,
        count: action.data.count,
        list: action.data.results,
        loading: false,
      }
    case ZENDESK_ARTICLES_GET_LIST_FAILURE:
      return {
        ...state,
        count: 0,
        list: [],
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export default zendeskArticlesStore
