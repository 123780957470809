import { get } from "lodash"
import {
  LOADING_STATES,
  USER_LOGOUT_SUCCESS,
  CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_REQUEST,
  CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_SUCCESS,
  CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_FAILURE,
  CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_REQUEST,
  CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_SUCCESS,
  CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_FAILURE,
  CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_REQUEST,
  CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_SUCCESS,
  CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_FAILURE,
  CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_REQUEST,
  CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_SUCCESS,
  CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_FAILURE,
  CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_REQUEST,
  CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_SUCCESS,
  CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_FAILURE,
  CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_REQUEST,
  CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_SUCCESS,
  CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_FAILURE,
} from "data/constants"

const defaultState = {
  error: null,
  state: LOADING_STATES.INITIAL,
  credentialsLoadingState: LOADING_STATES.INITIAL,
  username: null,
  handling_time: 1,
  shipping_options: [],
  price_adjustment: {},
}

function cdiscountOnboardingStore(state = defaultState, action) {
  switch (action.type) {
    case CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_REQUEST:
    case CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_REQUEST:
      return {
        ...state,
        credentialsLoadingState: LOADING_STATES.LOADING,
      }
    case CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_REQUEST:
    case CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_REQUEST:
    case CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_REQUEST:
    case CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      }
    case CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_SUCCESS:
      return {
        ...state,
        credentialsLoadingState: LOADING_STATES.LOADED,
        status: "VALID",
      }
    case CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_SUCCESS:
    case CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
      }
    case CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_SUCCESS:
      return {
        ...state,
        credentialsLoadingState: LOADING_STATES.LOADED,
        status: action.req.status,
        username: action.req.username,
      }
    case CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        handling_time: action.req.handling_time,
        shipping_options: action.req.options,
      }
    case CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        price_adjustment: action.req,
      }
    case CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_FAILURE:
      return {
        ...state,
        error: get(action.error, "error.description.message", "Error"),
        credentialsLoadingState: LOADING_STATES.FAILED,
      }
    case CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_FAILURE:
    case CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_FAILURE:
    case CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_FAILURE:
    case CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_FAILURE:
      return {
        ...state,
        error: get(action.error, "error.description.message", "Error"),
        state: LOADING_STATES.FAILED,
      }
    case CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_FAILURE:
      return {
        ...state,
        status: getCdiscountErrorStatus(
          get(action.error, "error.description.message", "")
        ),
        credentialsLoadingState: LOADING_STATES.FAILED,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

const getCdiscountErrorStatus = errorMessage =>
  errorMessage.startsWith("Merchant not found.")
    ? "disconnected"
    : "internal-error"

export default cdiscountOnboardingStore
