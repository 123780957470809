import { all, put, fork, call, take } from "redux-saga/effects"

import {
  ZENDESK_ARTICLES_GET_LIST,
  ZENDESK_ARTICLES_GET_LIST_REQUEST,
  ZENDESK_ARTICLES_GET_LIST_SUCCESS,
  ZENDESK_ARTICLES_GET_LIST_FAILURE,
  ZENDESK_ARTICLES_CANCEL,
} from "data/constants"
import { getAll } from "data/fetch/zendesk/articles"

import { replaceUrls } from "shared/services/zendesk/articles"

function* getList() {
  while (true) {
    const action = yield take(ZENDESK_ARTICLES_GET_LIST)
    yield put({ type: ZENDESK_ARTICLES_GET_LIST_REQUEST })
    try {
      const { data } = yield call(() => getAll(action.params))
      if (data) {
        data.results = replaceUrls(data.results)
        yield put({ type: ZENDESK_ARTICLES_GET_LIST_SUCCESS, data })
      }
    } catch (error) {
      yield put({ type: ZENDESK_ARTICLES_GET_LIST_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getList)])

    yield all(tasks)

    yield take(ZENDESK_ARTICLES_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
