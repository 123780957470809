import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  CONVERSION_CHART_GET,
  CONVERSION_CHARTS_GET_LIST,
  CONVERSION_CHARTS_GET_LIST_REQUEST,
  CONVERSION_CHARTS_GET_LIST_SUCCESS,
  CONVERSION_CHARTS_GET_LIST_FAILURE,
  CONVERSION_CHARTS_GET_BRAND_LIST,
  CONVERSION_CHARTS_GET_BRAND_LIST_REQUEST,
  CONVERSION_CHARTS_GET_BRAND_LIST_SUCCESS,
  CONVERSION_CHARTS_GET_BRAND_LIST_FAILURE,
  CONVERSION_CHARTS_GET_CATEGORY_LIST,
  CONVERSION_CHARTS_GET_CATEGORY_LIST_REQUEST,
  CONVERSION_CHARTS_GET_CATEGORY_LIST_SUCCESS,
  CONVERSION_CHARTS_GET_CATEGORY_LIST_FAILURE,
  CONVERSION_CHARTS_CANCEL,
  CONVERSION_CHART_CANCEL,
} from "../../constants"

import {
  getAll,
  getBrands,
  getCategories,
} from "../../fetch/conversionCharts/list"

import getSingle from "./single"

function* getBrandsList() {
  yield take([
    CONVERSION_CHARTS_GET_BRAND_LIST,
    CONVERSION_CHARTS_GET_LIST,
    CONVERSION_CHART_GET,
  ])
  const { user } = yield select()
  yield put({ type: CONVERSION_CHARTS_GET_BRAND_LIST_REQUEST })
  try {
    const { data } = yield call(() => getBrands(user.id))
    if (data) {
      yield put({ type: CONVERSION_CHARTS_GET_BRAND_LIST_SUCCESS, data })
    }
  } catch (error) {
    yield put({ type: CONVERSION_CHARTS_GET_BRAND_LIST_FAILURE, error })
  }
}

function* getCategoriesList() {
  yield take([
    CONVERSION_CHARTS_GET_CATEGORY_LIST,
    CONVERSION_CHARTS_GET_LIST,
    CONVERSION_CHART_GET,
  ])
  const { user } = yield select()
  yield put({ type: CONVERSION_CHARTS_GET_CATEGORY_LIST_REQUEST })
  try {
    const { data } = yield call(() => getCategories(user.id))
    if (data) {
      yield put({ type: CONVERSION_CHARTS_GET_CATEGORY_LIST_SUCCESS, data })
    }
  } catch (error) {
    yield put({ type: CONVERSION_CHARTS_GET_CATEGORY_LIST_FAILURE, error })
  }
}

function* getList() {
  while (true) {
    const action = yield take([CONVERSION_CHARTS_GET_LIST])
    const { user } = yield select()
    yield put({ type: CONVERSION_CHARTS_GET_LIST_REQUEST })
    try {
      let { params } = action
      if (action.custom) {
        params = {
          seller_id: user.id,
          ...params,
        }
      }
      const { data } = yield call(() => getAll(user.id, params))
      if (data) yield put({ type: CONVERSION_CHARTS_GET_LIST_SUCCESS, data })
    } catch (error) {
      yield put({ type: CONVERSION_CHARTS_GET_LIST_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([
      fork(getBrandsList),
      fork(getCategoriesList),
      fork(getSingle),
      fork(getList),
    ])

    yield all(tasks)

    yield take([CONVERSION_CHARTS_CANCEL, CONVERSION_CHART_CANCEL])
    yield all(tasks.map(task => task.cancel()))
  }
}
