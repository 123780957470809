import React, { useState } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { noop } from "lodash"
import { connect } from "react-redux"

import CONFIG from "core/config"
import { post as postTOS } from "data/actions/user/termsOfService"
import { getService } from "shared/services/termsOfService"

import ModalHeader from "shared/components/Modal/ModalHeader"
import ModalContent from "shared/components/Modal/ModalContent"
import ModalFooter from "shared/components/Modal/ModalFooter"
import Button from "shared/components/Button"
import InlinePDF from "shared/components/InlinePDF"

import { useTranslate } from "context/translateContext"
import styles from "./TermsOfServiceModal.module.css"

const TermsOfServiceModal = props => {
  const service = getService("wi")
  const { confirm, onRequestClose, lang } = props
  const { t: translate } = useTranslate()
  const [file] = useState(
    service.files.current[lang] || service.files.current.en
  )
  const [confirmed, setConfirmed] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const toggleCheckbox = () => setConfirmed(!confirmed)

  const accept = () => {
    setSubmitting(true)

    confirm("wi", {
      file_uri: file,
      is_accepted: true,
      version: service.version,
    })
      .then(data => {
        onRequestClose(data)
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  return (
    <Modal
      isOpen
      onRequestClose={() => noop()}
      contentLabel="Terms of service"
      className={[styles.tos, "popup"].join(" ")}
      overlayClassName="popupOverlay"
      ariaHideApp={false}
    >
      <ModalHeader onRequestClose={() => noop()} canClose={false}>
        <h1>{translate("Terms of service")}</h1>
      </ModalHeader>
      <ModalContent>
        <div className={styles.pdf}>
          <p>{translate("Dear Customer")},</p>
          <p>
            {translate(
              'The following Terms of Service ("Terms") govern your access to and use of the services and Webinterpret\'s websites and applications (the "Services"), and any information, text, multimedia, notifications, SMS text messages, graphics, or other materials synchronized, uploaded, downloaded or appearing on the Services (collectively referred to as "Content"). Your access to and use of the Services is conditioned on your acceptance of and compliance with these Terms. By accessing or using the Services you agree to be bound by these Terms.'
            )}
          </p>
          <InlinePDF lang={lang} width={800} />
        </div>
        <div className="checkbox m-t-30">
          <input
            id="tos-accept"
            type="checkbox"
            checked={confirmed}
            onChange={toggleCheckbox}
          />
          <label htmlFor="tos-accept">
            {translate("I have read and agree to the terms above")}
          </label>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="row">
          <div className="col-xs-6">
            <Button href={CONFIG.mainhost} size="large" block>
              {translate("I do not accept")}
            </Button>
          </div>
          <div className="col-xs-6">
            <Button
              loading={submitting}
              disabled={!confirmed}
              variation="primary"
              size="large"
              onClick={accept}
              block
            >
              {translate("I accept")}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

TermsOfServiceModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
}

TermsOfServiceModal.contextTypes = {
  t: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  lang: state.i18nState.lang,
})

const mapDispatchToProps = dispatch => ({
  confirm: (service, data) => dispatch(postTOS(service, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfServiceModal)
