import {
  LOADING_STATES,
  EPL_DASHBOARD_REPORT_DATA_LOAD_REQUEST,
  EPL_DASHBOARD_REPORT_DATA_LOAD_SUCCESS,
  EPL_DASHBOARD_REPORT_DATA_LOAD_FAILURE,
  EPL_DASHBOARD_REPORT_DATA_GET_REQUEST,
  EPL_DASHBOARD_REPORT_DATA_GET_SUCCESS,
  EPL_DASHBOARD_REPORT_DATA_GET_FAILURE,
} from "data/constants"

const defaultState = {
  data: {
    wi: {},
    customer: {},
  },
}

const eplDashboardReportStore = (state = defaultState, action) => {
  switch (action.type) {
    case EPL_DASHBOARD_REPORT_DATA_LOAD_REQUEST:
      return {
        ...state,
        status: LOADING_STATES.LOADING,
      }
    case EPL_DASHBOARD_REPORT_DATA_LOAD_SUCCESS:
      return {
        ...state,
        status: LOADING_STATES.LOADED,
      }
    case EPL_DASHBOARD_REPORT_DATA_LOAD_FAILURE:
      return {
        ...state,
        status: LOADING_STATES.FAILED,
      }
    case EPL_DASHBOARD_REPORT_DATA_GET_REQUEST:
    case EPL_DASHBOARD_REPORT_DATA_GET_FAILURE:
      return {
        ...state,
        data: {
          wi: {},
          customer: {},
        },
      }
    case EPL_DASHBOARD_REPORT_DATA_GET_SUCCESS:
      return {
        ...state,
        data: {
          lastUpdate: action.req.last_update,
          wi: action.req.origins.wi,
          customer: action.req.origins.customer,
        },
      }
    default:
      return state
  }
}

export default eplDashboardReportStore
