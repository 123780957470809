import {
  ACCOUNTS_GET_REQUEST,
  ACCOUNTS_GET_SUCCESS,
  ACCOUNTS_GET_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

const defaultState = {
  amazon: [],
  ebay: [],
  state: "INITIAL",
  error: null,
}

function accountsStore(state = defaultState, action) {
  switch (action.type) {
    case ACCOUNTS_GET_REQUEST:
      return {
        ...state,
        error: null,
        state: "LOADING",
      }
    case ACCOUNTS_GET_SUCCESS:
      return {
        ...state,
        amazon: action.data.amazon,
        ebay: action.data.ebay,
        state: "LOADED",
      }
    case ACCOUNTS_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        state: "FAILED",
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default accountsStore
