import { pick } from "lodash"

import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function addTerm(userID, term) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/users/${userID}/terms`,
    method: "POST",
    data: term,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function updateTerm(userID, term) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/users/${userID}/terms/${term.id}`,
    method: "PUT",
    data: pick(term, ["src_text", "dst_text", "src_lang", "dst_lang"]),
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function removeTerm(userID, termID) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v2/users/${userID}/terms/${termID}`,
    method: "DELETE",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  addTerm,
  updateTerm,
  removeTerm,
}
