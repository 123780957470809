const REQUIREMENT_LIST = {
  C: {
    information:
      "In order for your incoming sales from International listings to be visible and manageable from your ChannelAdvisor interface, we need to synchronize your account using your ChannelAdvisor Seller Profile ID.",
    label: "Profile ID:",
    placeholder: "Insert profile ID",
    help:
      "https://webinterpret.zendesk.com/hc/en-us/articles/202770816-Getting-started-using-WebInterpret-with-ChannelAdvisor",
    name: "tool_external_reference",
  },
}

export const getToolRequirement = code =>
  REQUIREMENT_LIST[code.toUpperCase()] || null

export default {
  getToolRequirement,
}
