import React from "react"
import { show } from "react-notification-system-redux"
import { getTranslateFunction } from "redux-i18n"

import {
  // GENERAL
  COPY,
  // INVOICES
  INVOICE_GET_FAILURE,
  // PASSWORD RESET
  PASSWORD_RESET_SUBMIT_EMAIL_SUCCESS,
  PASSWORD_RESET_SUBMIT_EMAIL_FAILURE,
  PASSWORD_RESET_SUBMIT_PASSWORD_FAILURE,
  PASSWORD_RESET_SUBMIT_PASSWORD_SUCCESS,
  // PRODUCT LIST
  PRODUCTS_UPDATE_FREE_SHIPPING_SUCCESS,
  PRODUCTS_UPDATE_COUNTRY_USAGE_SUCCESS,
  PRODUCTS_UPDATE_ITEM_CONDITION_SUCCESS,
  PRODUCTS_UPDATE_DEDUCT_VAT_SUCCESS,
  PRODUCTS_UPDATE_SETTINGS_FAILURE,
  PRODUCTS_UPDATE_BLOCK_SUCCESS,
  PRODUCTS_UPDATE_BLOCK_FAILURE,
  PRODUCTS_UPDATE_UNBLOCK_SUCCESS,
  PRODUCTS_UPDATE_UNBLOCK_FAILURE,
  PRODUCTS_UPDATE_BLOCK_REPRICER_SUCCESS,
  PRODUCTS_UPDATE_BLOCK_REPRICER_FAILURE,
  PRODUCTS_UPDATE_UNBLOCK_REPRICER_SUCCESS,
  PRODUCTS_UPDATE_UNBLOCK_REPRICER_FAILURE,
  PRODUCTS_SET_SHIPPING_SUCCESS,
  PRODUCTS_SET_SHIPPING_FAILURE,
  PRODUCTS_CLEAR_SHIPPING_SUCCESS,
  PRODUCTS_CLEAR_SHIPPING_FAILURE,
  // SINGLE PRODUCT
  PRODUCT_UPDATE_PRICES_SUCCESS,
  PRODUCT_UPDATE_PRICES_FAILURE,
  PRODUCT_UPDATE_TRANSLATIONS_SUCCESS,
  PRODUCT_UPDATE_TRANSLATIONS_FAILURE,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILURE,
  // SHIPPING PROFILES
  SHIPPING_PROFILE_CREATE_SUCCESS,
  SHIPPING_PROFILE_CREATE_FAILURE,
  SHIPPING_DEFAULT_PROFILE_CREATE_SUCCESS,
  SHIPPING_DEFAULT_PROFILE_CREATE_FAILURE,
  SHIPPING_PROFILE_EDIT_SUCCESS,
  SHIPPING_PROFILE_EDIT_FAILURE,
  SHIPPING_PROFILE_DELETE_SUCCESS,
  SHIPPING_PROFILE_DELETE_FAILURE,
  SHIPPING_DEFAULT_PROFILE_DELETE_SUCCESS,
  SHIPPING_DEFAULT_PROFILE_DELETE_FAILURE,
  SHIPPING_PROFILE_RATE_EDIT_FAILURE,
  SHIPPING_PROFILE_RATES_SET_DEFAULT,
  SHIPPING_PROFILE_RATES_SET_CUSTOM,
  SHIPPING_PROFILE_RATES_SET_EXCLUDED,
  // CONVERSION CHARTS
  CONVERSION_CHART_ADD_SUCCESS,
  CONVERSION_CHART_ADD_FAILURE,
  CONVERSION_CHART_DELETE_SUCCESS,
  CONVERSION_CHART_DELETE_FAILURE,
  CONVERSION_CHART_EDIT_SUCCESS,
  CONVERSION_CHART_EDIT_FAILURE,
  // PRICE ADJUSTMENT
  PRICE_RULE_EDIT_SUCCESS,
  PRICE_RULE_EDIT_FAILURE,
  PRICE_RULE_DELETE_SUCCESS,
  PRICE_RULE_DELETE_FAILURE,
  // REPRICER
  REPRICER_UPDATE_SETTINGS_SUCCESS,
  REPRICER_UPDATE_SETTINGS_FAILURE,
  // BLACKLIST
  BLACKLIST_KEYWORDS_ADDED,
  BLACKLIST_KEYWORD_EDIT_SUCCESS,
  BLACKLIST_KEYWORD_EDIT_FAILURE,
  BLACKLIST_KEYWORD_DELETE_SUCCESS,
  BLACKLIST_KEYWORD_DELETE_FAILURE,
  // TRANSLATION MANAGER
  TRANSLATION_MANAGER_TERMS_ADDED,
  TRANSLATION_MANAGER_TRANSLATIONS_ADDED,
  TRANSLATION_MANAGER_TERM_DELETE_SUCCESS,
  TRANSLATION_MANAGER_BULK_DELETE_SUCCESS,
  TRANSLATION_MANAGER_TERM_DELETE_FAILURE,
  // PROFILE
  PROFILE_UPDATE_PASSWORD_SUCCESS,
  PROFILE_UPDATE_PASSWORD_FAILURE,
  PROFILE_UPDATE_CONTACT_SUCCESS,
  PROFILE_UPDATE_CONTACT_FAILURE,
  PROFILE_UPDATE_FINANCIAL_DETAILS_SUCCESS,
  PROFILE_UPDATE_FINANCIAL_DETAILS_FAILURE,
  PROFILE_UPDATE_TOOLS_ACTIVE_SUCCESS,
  PROFILE_UPDATE_TOOLS_ACTIVE_FAILURE,
  // ACCOUNT
  ACCOUNT_CONFIRM_TOKEN_SUCCESS,
  ACCOUNT_CONFIRM_TOKEN_FAILURE,
  ACCOUNT_ADD_FLOW_SUCCESS,
  ACCOUNT_ADD_FLOW_FAILURE,
  ACCOUNT_UPDATE_FLOW_SUCCESS,
  ACCOUNT_UPDATE_FLOW_FAILURE,
  // SETTINGS
  USER_UPDATE_SETTING_SUCCESS,
  USER_UPDATE_SETTING_FAILURE,
  USER_ONBOARDING_OPT_OUT_FAILURE,
  ACCOUNT_UPDATE_BUSINESS_TYPE_FAILURE,
  // PLANS
  USER_PLAN_CHANGE_SUCCESS,
  USER_PLAN_CHANGE_FAILURE,
  // ZENDESK
  ZENDESK_SUBMIT_TICKET_FAILURE,
  // ZUORA
  ZUORA_FEEDBACK_FAILURE,
  ZUORA_ERROR,
  // TRIAL START
  TRIAL_PROLONG_SUCCESS,
  TRIAL_PROLONG_FAILURE,
  TRIAL_START_SUCCESS,
  TRIAL_START_FAILURE,
  // EPL
  EPL_ONBOARDING_POST_FAILURE,
  EPL_OPT_OUT_POST_FAILURE,
  EPL_ADRATE_STRATEGY_POST_FAILURE,
  EPL_MARKETS_CLOSE_POST_FAILURE,
  EPL_MARKETS_PAUSE_POST_FAILURE,
  EPL_MARKETS_OPEN_POST_FAILURE,
  EPL_MARKETS_RESUME_POST_FAILURE,
  EPL_SET_AD_RATE_FOR_MARKET_PATCH_FAILURE,
  EPL_CREATE_ZENDESK_CHURN_TICKET_POST_FAILURE,
  EPL_ADRATE_HISTORY_GET_FAILURE,
  EPL_ITEMS_EXCLUDED_RESET_ALL_FAILURE,
  EPL_ITEMS_EXCLUDED_RESET_SELECTED_FAILURE,
  EPL_ITEMS_EXCLUDE_BY_FILE_POST_FAILURE,
  EPL_ITEMS_EXCLUDE_SELECTED_FAILURE,
  EPL_START_TRIAL_POST_FAILURE,
  EPL_START_BOTH_TRIALS_FAILURE,
  EPL_START_STANDARD_POST_FAILURE,
  EPL_START_BOTH_STANDARDS_FAILURE,
  // USER SIGN UP
  USER_SIGNUP_EMAIL_TAKEN_NOTIFY,
  USER_SIGNUP_FAILURE_NOTIFY,
  // MULTICHANNEL ONBOARDING
  USER_MULTICHANNEL_ONBOARDING_START_FAILURE,
  USER_MULTICHANNEL_ONBOARDING_TOKEN_CONNECTED_SUCCESS_NOTIFICATION,
  AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_FAILURE,
  AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_FAILURE,
  AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_FAILURE,
  USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_FAILURE,
  EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_FAILURE,
  EBAY_ONBOARDING_SUBMIT_3PTOOL_FAILURE,
  EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_FAILURE,
  EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_FAILURE,
  EBAY_ONBOARDING_POST_CHECKOUT_FAILURE,
  EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_FAILURE,

  // CHECKOUT
  CHECKOUT_PAYMENT_STATUS_FAILURE,
} from "data/constants"

export default function notificationMiddleware({ dispatch, getState }) {
  return next => action => {
    let options
    let level
    const { i18nState, user } = getState()
    const translate = getTranslateFunction(
      i18nState.translations,
      i18nState.lang,
      "en"
    )
    const { isPlatformless } = user
    next(action)

    switch (action.type) {
      case COPY:
        options = {
          autoDismiss: 1,
          dismissible: false,
          title: translate("Copied!"),
          message: translate("Contents were copied to your clipboard."),
          position: "bc",
        }
        level = "info"
        break
      case PRODUCTS_UPDATE_FREE_SHIPPING_SUCCESS:
        options = {
          title: translate("Free shipping settings updated"),
          message: translate("The changes may take up to 3 business days."),
          position: "tr",
        }
        level = "success"
        break
      case PASSWORD_RESET_SUBMIT_EMAIL_SUCCESS:
        options = {
          title: translate("Password requested"),
          message: translate(
            "You have successfully requested a new password, please check your email for further instructions."
          ),
          position: "tr",
        }
        level = "success"
        break
      case PASSWORD_RESET_SUBMIT_PASSWORD_SUCCESS:
        options = {
          title: translate("Password reset requested"),
          message: translate("You have successfully created a new password."),
          position: "tr",
        }
        level = "success"
        break
      case USER_SIGNUP_EMAIL_TAKEN_NOTIFY:
        options = {
          autoDismiss: 0,
          title: translate("It seems that you already have an account."),
          message: `${translate(
            "Please sign in to your Webinterpret account or contact Customer Care at "
          )}<a href="mailto:support@webinterpret.com" target="_blank">support@webinterpret.com</a>`,
          position: "tc",
        }
        level = "info"
        break
      case USER_MULTICHANNEL_ONBOARDING_TOKEN_CONNECTED_SUCCESS_NOTIFICATION: {
        const onboardingPlatform = {
          ebay: "eBay",
          amazon: "Amazon",
        }

        options = {
          dismissible: false,
          message: `<div class='flex-container f-d-row c-green'><div class='m-r-10 fs-20'><?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path stroke="#27ae60" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/></svg></div><div>${translate(
            "Your {platform} account has been successfully connected!",
            {
              platform: onboardingPlatform[action.payload.platform],
            },
            "Do not translate {platform}"
          )}
            </div></div>`,
          position: "tr",
        }
        level = "success"
        break
      }
      case PRODUCTS_UPDATE_COUNTRY_USAGE_SUCCESS:
        options = {
          title: translate("Country usage updated"),
          message: translate("The changes may take up to 3 business days."),
          position: "tr",
        }
        level = "success"
        break
      case PRODUCTS_UPDATE_ITEM_CONDITION_SUCCESS:
        options = {
          title: translate("Items condition updated"),
          message: translate("The changes may take up to 3 business days."),
          position: "tr",
        }
        level = "success"
        break
      case PRODUCTS_UPDATE_DEDUCT_VAT_SUCCESS:
        options = {
          title: translate("VAT settings updated"),
          message: translate(
            "The changes may take up to 3 business days. Contact our support to reverse this operation."
          ),
          position: "tr",
        }
        level = "success"
        break
      case PRODUCTS_UPDATE_BLOCK_SUCCESS:
        options = {
          title: translate("Products blocked"),
          message: translate(
            [
              "The product will be blocked on the selected markets",
              "The products will be blocked on the selected markets",
              "n",
            ],
            {
              n: action.data.items.length,
            }
          ),
          position: "tr",
        }
        level = "warning"
        break
      case PRODUCTS_UPDATE_UNBLOCK_SUCCESS:
        options = {
          title: translate("Products unblocked"),
          message: translate(
            [
              "The product will be unblocked on the selected markets",
              "The products will be unblocked on the selected markets",
              "n",
            ],
            {
              n: action.data.items.length,
            }
          ),
          position: "tr",
        }
        level = "success"
        break
      case PRODUCTS_UPDATE_BLOCK_REPRICER_SUCCESS:
        options = {
          title: translate("Automatic repricing disabled"),
          message: translate(
            [
              "The product will not be automatically repriced on the selected markets",
              "The products will not be automatically repriced on the selected markets",
              "n",
            ],
            {
              n: action.data.items.length,
            }
          ),
          position: "tr",
        }
        level = "warning"
        break
      case PRODUCTS_UPDATE_UNBLOCK_REPRICER_SUCCESS:
        options = {
          title: translate("Automatic repricing enabled"),
          message: translate(
            [
              "The product will be automatically repriced on the selected markets",
              "The products will be automatically repriced on the selected markets",
              "n",
            ],
            {
              n: action.data.items.length,
            }
          ),
          position: "tr",
        }
        level = "success"
        break
      case PRODUCTS_SET_SHIPPING_SUCCESS:
        options = {
          title: translate("Shipping settings updated"),
          message: translate(
            [
              "The product shipping profile was successfully modified.",
              "The products shipping profiles were successfully modified.",
              "n",
            ],
            {
              n: action.data.items.length,
            }
          ),
          position: "tr",
        }
        level = "success"
        break
      case PRODUCTS_CLEAR_SHIPPING_SUCCESS:
        options = {
          title: translate("Shipping settings updated"),
          message: translate(
            [
              "The product shipping profile was successfully removed.",
              "The products shipping profiles were successfully removed.",
              "n",
            ],
            {
              n: action.data.items.length,
            }
          ),
          position: "tr",
        }
        level = "success"
        break
      // SINGLE PRODUCT
      case PRODUCT_UPDATE_SUCCESS:
        options = {
          title: translate("Product updated"),
          message: translate("Product was successfully modified."),
          position: "tr",
        }
        level = "success"
        break
      case PRODUCT_UPDATE_TRANSLATIONS_SUCCESS:
        options = {
          title: translate("Product translations updated"),
          message: translate("Translations were successfully modified."),
          position: "tr",
        }
        level = "success"
        break
      case PRODUCT_UPDATE_PRICES_SUCCESS:
        options = {
          title: translate("Product prices updated"),
          message: translate(
            "The product prices will be updated on the selected markets."
          ),
          position: "tr",
        }
        level = "success"
        break
      // SHIPPING PROFILES
      case SHIPPING_DEFAULT_PROFILE_CREATE_SUCCESS:
      case SHIPPING_PROFILE_CREATE_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Shipping profile was successfully created."),
          position: "tr",
        }
        level = "success"
        break
      case SHIPPING_PROFILE_EDIT_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Shipping profile was successfully modified."),
          position: "tr",
        }
        level = "success"
        break
      case SHIPPING_DEFAULT_PROFILE_DELETE_SUCCESS:
      case SHIPPING_PROFILE_DELETE_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Shipping profile was successfully removed."),
          position: "tr",
        }
        level = "success"
        break
      case SHIPPING_PROFILE_RATES_SET_DEFAULT:
        options = {
          title: translate("Default settings enabled"),
          message: translate(
            "Country was successfully modified and will inherit it's settings from it's region."
          ),
          position: "tr",
        }
        level = "success"
        break
      case SHIPPING_PROFILE_RATES_SET_CUSTOM:
        options = {
          title: translate("Custom setting created"),
          message: translate(
            "Settings for this country was successfully created."
          ),
          position: "tr",
        }
        level = "success"
        break
      case SHIPPING_PROFILE_RATES_SET_EXCLUDED:
        options = {
          title: translate("Country excluded successfully"),
          message: translate(
            "International shipping will be turned off for this country."
          ),
          position: "tr",
        }
        level = "success"
        break
      // CONVERSION CHARTS
      case CONVERSION_CHART_ADD_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Chart was successfully created."),
          position: "tr",
        }
        level = "success"
        break
      case CONVERSION_CHART_EDIT_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Chart was successfully modified."),
          position: "tr",
        }
        level = "success"
        break
      case CONVERSION_CHART_DELETE_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Chart was successfully removed."),
          position: "tr",
        }
        level = "success"
        break
      case CONVERSION_CHART_EDIT_FAILURE:
      case CONVERSION_CHART_ADD_FAILURE:
        if (action.error.error.title === "Chart conflict") {
          options = {
            title: translate("There is a conflict!"),
            message: translate(
              "You already have a custom chart for this brand and category."
            ),
            position: "tr",
          }
        } else {
          options = {
            title: translate("Oops!"),
            message: translate(
              "Looks like something went wrong on our side. Please, try again."
            ),
            position: "tr",
          }
        }
        level = "error"
        break
      // PRICE ADJUSTMENT
      case PRICE_RULE_EDIT_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Pricing rule was successfully modified."),
          position: "tr",
        }
        level = "success"
        break
      case PRICE_RULE_DELETE_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Pricing rule was successfully removed."),
          position: "tr",
        }
        level = "success"
        break
      // REPRICER
      case REPRICER_UPDATE_SETTINGS_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Repricing settings were successfully modified."),
          position: "tr",
        }
        level = "success"
        break
      // BLACKLIST
      case BLACKLIST_KEYWORDS_ADDED:
        options = {
          title: translate("Success!"),
          message: translate(
            [
              "Keyword {terms} was successfully blacklisted.",
              "Keywords {terms} were successfully blacklisted.",
              "n",
            ],
            {
              n: action.keywords.length,
              terms: <strong>{action.keywords.join(", ")}</strong>,
            }
          ),
          position: "tr",
        }
        level = "success"
        break
      case BLACKLIST_KEYWORD_EDIT_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Keyword {term} was successfully modified.", {
            term: <strong>{action.data.keyword}</strong>,
          }),
          position: "tr",
        }
        level = "success"
        break
      case BLACKLIST_KEYWORD_DELETE_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Keyword was successfully removed."),
          position: "tr",
        }
        level = "success"
        break
      // TRANSLATION MANAGER
      case TRANSLATION_MANAGER_TERMS_ADDED:
        options = {
          title: translate("Success!"),
          message: translate(
            [
              "Term {terms} was successfully added.",
              "Terms {terms} were successfully added.",
              "n",
            ],
            {
              n: action.terms.length,
              terms: <strong>{action.terms.join(", ")}</strong>,
            }
          ),
          position: "tr",
        }
        level = "success"
        break
      case TRANSLATION_MANAGER_TRANSLATIONS_ADDED:
        options = {
          title: translate("Success!"),
          message: translate(
            [
              "Translation {translations} was successfully added for term {term}.",
              "Translations {translations} were successfully added for term {term}.",
              "n",
            ],
            {
              n: action.payload.translations.length,
              translations: (
                <strong>
                  {action.payload.translations
                    .map(translation => translation.dst_text)
                    .join(", ")}
                </strong>
              ),
              term: <strong>{action.payload.translations[0].src_text}</strong>,
            },
            "Do not translate {translations} and {terms}"
          ),
          position: "tr",
        }
        level = "success"
        break
      case TRANSLATION_MANAGER_TERM_DELETE_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Term {term} was successfully removed.", {
            term: <strong>{action.data.term.src_text}</strong>,
          }),
          position: "tr",
        }
        level = "success"
        break
      case TRANSLATION_MANAGER_BULK_DELETE_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("The selected terms were successfully removed."),
          position: "tr",
        }
        level = "success"
        break
      // PLANS
      case USER_PLAN_CHANGE_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate(
            "Request to change plan was successfully created and will be processed."
          ),
          position: "tr",
        }
        level = "success"
        break
      case USER_PLAN_CHANGE_FAILURE:
        options = {
          title: translate("Oops!"),
          message: translate(
            action.error.description ||
              "Looks like something went wrong on our side. Please, try again."
          ),
          position: "tr",
        }
        level = "error"
        break
      // PROFILE
      case PROFILE_UPDATE_PASSWORD_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Password was successfully changed."),
          position: "tr",
        }
        level = "success"
        break
      case PROFILE_UPDATE_CONTACT_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Contact details were successfully modified."),
          position: "tr",
        }
        level = "success"
        break
      case PROFILE_UPDATE_FINANCIAL_DETAILS_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Financial details were successfully modified."),
          position: "tr",
        }
        level = "success"
        break
      case PROFILE_UPDATE_FINANCIAL_DETAILS_FAILURE:
        if (action.error.status !== "ERROR") {
          options = {
            title: translate("Oops!"),
            message: translate(
              "Looks like something went wrong on our side. Please, try again."
            ),
            position: "tr",
          }
          level = "error"
        }
        break
      case PROFILE_UPDATE_TOOLS_ACTIVE_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Third-party tool was successfully saved."),
          position: "tr",
        }
        level = "success"
        break
      // ACCOUNT
      case ACCOUNT_CONFIRM_TOKEN_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Your new token is now valid."),
          position: "tr",
        }
        level = "success"
        break
      case ACCOUNT_ADD_FLOW_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Flow was successfully created."),
          position: "tr",
        }
        level = "success"
        break
      case ACCOUNT_UPDATE_FLOW_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate("Flow was successfully updated."),
          position: "tr",
        }
        level = "success"
        break
      // SETTINGS
      case USER_UPDATE_SETTING_SUCCESS:
        if (!isPlatformless) {
          options = {
            title: translate("Success!"),
            message: translate("Setting was successfully updated."),
            position: "tr",
          }
          level = "success"
        }
        break
      case ZUORA_ERROR:
        options = {
          title: translate("Oops!"),
          message: translate(action.message),
          position: "tr",
        }
        level = "error"
        break
      // TRIAL START
      case TRIAL_PROLONG_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate(
            "Translating and listing was successfully postponed."
          ),
          position: "tr",
        }
        level = "success"
        break
      case TRIAL_START_SUCCESS:
        options = {
          title: translate("Success!"),
          message: translate(
            "We will start translating and listing your items in international markets."
          ),
          position: "tr",
        }
        level = "success"
        break
      // EPL
      case EPL_ADRATE_STRATEGY_POST_FAILURE:
        options = {
          title: translate("Oops!"),
          message: translate(
            "Unfortunately, we are unable to change ad rates right now. Please try again later."
          ),
          position: "tr",
        }
        level = "error"
        break
      // FAILURES
      case PRODUCTS_UPDATE_SETTINGS_FAILURE:
      case PRODUCTS_UPDATE_BLOCK_FAILURE:
      case PRODUCTS_UPDATE_UNBLOCK_FAILURE:
      case PRODUCTS_UPDATE_BLOCK_REPRICER_FAILURE:
      case PRODUCTS_UPDATE_UNBLOCK_REPRICER_FAILURE:
      case PRODUCTS_SET_SHIPPING_FAILURE:
      case PRODUCTS_CLEAR_SHIPPING_FAILURE:
      case PRODUCT_UPDATE_PRICES_FAILURE:
      case PRODUCT_UPDATE_TRANSLATIONS_FAILURE:
      case PRODUCT_UPDATE_FAILURE:
      case SHIPPING_PROFILE_CREATE_FAILURE:
      case SHIPPING_DEFAULT_PROFILE_CREATE_FAILURE:
      case SHIPPING_PROFILE_EDIT_FAILURE:
      case SHIPPING_PROFILE_DELETE_FAILURE:
      case SHIPPING_DEFAULT_PROFILE_DELETE_FAILURE:
      case SHIPPING_PROFILE_RATE_EDIT_FAILURE:
      case CONVERSION_CHART_DELETE_FAILURE:
      case PRICE_RULE_EDIT_FAILURE:
      case PRICE_RULE_DELETE_FAILURE:
      case REPRICER_UPDATE_SETTINGS_FAILURE:
      case BLACKLIST_KEYWORD_EDIT_FAILURE:
      case BLACKLIST_KEYWORD_DELETE_FAILURE:
      case TRANSLATION_MANAGER_TERM_DELETE_FAILURE:
      case PROFILE_UPDATE_PASSWORD_FAILURE:
      case PROFILE_UPDATE_CONTACT_FAILURE:
      case PROFILE_UPDATE_TOOLS_ACTIVE_FAILURE:
      case USER_UPDATE_SETTING_FAILURE:
      case USER_ONBOARDING_OPT_OUT_FAILURE:
      case ACCOUNT_CONFIRM_TOKEN_FAILURE:
      case ACCOUNT_ADD_FLOW_FAILURE:
      case ACCOUNT_UPDATE_FLOW_FAILURE:
      case ACCOUNT_UPDATE_BUSINESS_TYPE_FAILURE:
      case ZENDESK_SUBMIT_TICKET_FAILURE:
      case ZUORA_FEEDBACK_FAILURE:
      case TRIAL_PROLONG_FAILURE:
      case TRIAL_START_FAILURE:
      case PASSWORD_RESET_SUBMIT_EMAIL_FAILURE:
      case PASSWORD_RESET_SUBMIT_PASSWORD_FAILURE:
      case EPL_ONBOARDING_POST_FAILURE:
      case EPL_OPT_OUT_POST_FAILURE:
      case USER_MULTICHANNEL_ONBOARDING_START_FAILURE:
      case EPL_MARKETS_CLOSE_POST_FAILURE:
      case EPL_MARKETS_PAUSE_POST_FAILURE:
      case EPL_MARKETS_OPEN_POST_FAILURE:
      case EPL_MARKETS_RESUME_POST_FAILURE:
      case EPL_SET_AD_RATE_FOR_MARKET_PATCH_FAILURE:
      case EPL_CREATE_ZENDESK_CHURN_TICKET_POST_FAILURE:
      case EPL_ADRATE_HISTORY_GET_FAILURE:
      case EPL_ITEMS_EXCLUDED_RESET_SELECTED_FAILURE:
      case EPL_ITEMS_EXCLUDE_SELECTED_FAILURE:
      case EPL_ITEMS_EXCLUDE_BY_FILE_POST_FAILURE:
      case EPL_ITEMS_EXCLUDED_RESET_ALL_FAILURE:
      case USER_SIGNUP_FAILURE_NOTIFY:
      case EPL_START_TRIAL_POST_FAILURE:
      case EPL_START_BOTH_TRIALS_FAILURE:
      case EPL_START_STANDARD_POST_FAILURE:
      case EPL_START_BOTH_STANDARDS_FAILURE:
      case AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_FAILURE:
      case AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_FAILURE:
      case AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_FAILURE:
      case USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_FAILURE:
      case EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_FAILURE:
      case EBAY_ONBOARDING_SUBMIT_3PTOOL_FAILURE:
      case EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_FAILURE:
      case EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_FAILURE:
      case EBAY_ONBOARDING_POST_CHECKOUT_FAILURE:
      case EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_FAILURE:
      case INVOICE_GET_FAILURE:
        options = {
          title: translate("Oops!"),
          message: translate(
            "Looks like something went wrong on our side. Please, try again."
          ),
          position: "tr",
        }
        level = "error"
        break
      case CHECKOUT_PAYMENT_STATUS_FAILURE:
        if (action.payload.toLowerCase() !== "declined") {
          options = {
            title: translate("Oops!"),
            message: translate(
              "Looks like something went wrong on our side. Please, try again."
            ),
            position: "tr",
          }
          level = "error"
        }
        break
      default:
        options = null
    }

    if (options) {
      dispatch(show(options, level))
    }
  }
}
