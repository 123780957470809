import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { find } from "lodash"
import Cookies from "js-cookie"

import { GENERAL_SET_LANGUAGE } from "data/constants"
import { getLanguagesList, changeDayjsLocale } from "shared/services/languages"

import Dropdown from "../Dropdown"
import DropdownTrigger from "../Dropdown/DropdownTrigger"
import DropdownContent from "../Dropdown/DropdownContent"

import de from "./images/de.svg"
import en from "./images/en.svg"
import es from "./images/es.svg"
import fr from "./images/fr.svg"
import it from "./images/it.svg"
import zh from "./images/zh.svg"

import styles from "./LanguagePicker.module.css"

const LANG_FLAGS = {
  de,
  en,
  es,
  fr,
  it,
  zh,
}

class LanguagePicker extends PureComponent {
  static propTypes = {
    currLang: PropTypes.string.isRequired,
    set: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props)

    this.setLanguage = this.setLanguage.bind(this)
    this.LANGS = getLanguagesList()
  }

  setLanguage(lang) {
    const { set } = this.props
    changeDayjsLocale(lang)
    set(lang)
    Cookies.set("lang", lang, { domain: "webinterpret.com" })
  }

  getFlag(lang) {
    const flag = LANG_FLAGS[lang.value.toLowerCase()]

    return flag && <img src={flag} className={styles.flag} alt={lang.name} />
  }

  render() {
    const { currLang = "en" } = this.props

    const curr = find(this.LANGS, {
      value: currLang,
    })
    return (
      <Dropdown top right>
        <DropdownTrigger>
          <span>
            {this.getFlag(curr)}
            {curr.name} <span className="icon-arrow-down fs-12" />
          </span>
        </DropdownTrigger>
        <DropdownContent>
          <ul className="l-unstyled">
            {this.LANGS.map(language => (
              <li key={language.value}>
                <button
                  onClick={() => this.setLanguage(language.value)}
                  type="button"
                >
                  {this.getFlag(language)}
                  {language.name}
                </button>
              </li>
            ))}
          </ul>
        </DropdownContent>
      </Dropdown>
    )
  }
}

const mapStateToProps = state => ({
  currLang: state.i18nState.lang,
})

const mapDispatchToProps = dispatch => ({
  set: language =>
    dispatch({
      type: GENERAL_SET_LANGUAGE,
      language,
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker)
