import { useEffect, useState } from "react"

import { getServicesEligibility } from "data/fetch/services"
import { fetchSubscribedPlans } from "data/fetch/plans/list"
import getLegacyPlanName from "views/User/views/PlansNew/services/getLegacyPlanName"

const useServices = userId => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const getServices = async () => {
      try {
        const { data: servicesEligibility } = await getServicesEligibility(
          userId
        )
        const { data: currentSubscriptions } = await fetchSubscribedPlans(
          userId
        )
        const legacyPlan = currentSubscriptions.find(sub =>
          getLegacyPlanName(sub.title.toLowerCase())
        )

        setData({ servicesEligibility, isLegacyPlan: !!legacyPlan })
      } catch (error) {
        setData({})
      } finally {
        setLoading(false)
      }
    }

    getServices()
  }, [])

  return {
    data,
    loading,
  }
}

export default useServices
