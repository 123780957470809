import { isUndefined, isEmpty, each, cloneDeep, pull, uniq } from "lodash"

export function block(list, sites) {
  if (isEmpty(list)) return list
  const translations = cloneDeep(list)
  each(sites, code => {
    if (!isUndefined(translations[code])) {
      const blacklistingReason = translations[code].blacklistingReason || []
      translations[code] = {
        ...translations[code],
        isBlacklisted: true,
        blacklistingReason: uniq([...blacklistingReason, "M"]),
      }
    }
  })
  return translations
}

export function unblock(list, sites) {
  if (isEmpty(list)) return list
  const translations = cloneDeep(list)
  each(sites, code => {
    if (!isUndefined(translations[code])) {
      const blacklistingReason = translations[code].blacklistingReason || []
      translations[code] = {
        ...translations[code],
        // If only reason was M then it's not blacklisted anymore.
        // If it had more than one reason then it is still blacklisted
        isBlacklisted: blacklistingReason.length > 1,
        blacklistingReason: pull(blacklistingReason, "M"),
      }
    }
  })
  return translations
}

export default {
  block,
  unblock,
}
