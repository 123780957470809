import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import styles from "./Button.module.css"

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  variation: PropTypes.string,
  /* native HTML button attribute */
  type: PropTypes.string,
  buttonType: PropTypes.oneOf(["border", "solid"]),
  size: PropTypes.string,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
}

const defaultProps = {
  className: null,
  loading: false,
  variation: "default",
  buttonType: "solid",
  type: "button",
  size: null,
  block: false,
  to: null,
  href: null,
}

function Button(props) {
  const {
    to,
    href,
    size,
    variation,
    type,
    buttonType,
    block,
    loading,
    className,
    children,
    ...rest
  } = props
  const classes = [styles.root, styles[variation], styles[buttonType]]

  if (size) {
    classes.push(styles[size])
  }
  if (loading) {
    classes.push(styles.loading)
  }
  if (block) {
    classes.push(styles.block)
  }
  if (className) {
    classes.push(className)
  }

  if (to) {
    return (
      <Link to={to} {...rest} className={classes.join(" ")}>
        {props.children}
      </Link>
    )
  }

  if (href) {
    return (
      <a href={href} {...rest} className={classes.join(" ")}>
        {children}
      </a>
    )
  }
  /* eslint-disable react/button-has-type */
  return (
    <button type={type} {...rest} className={classes.join(" ")}>
      {children}
    </button>
  )
  /* eslint-enable react/button-has-type */
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
