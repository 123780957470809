import React from "react"
import { Link } from "react-router-dom"

import { useTranslate } from "context/translateContext"
import WiMotto from "shared/views/WiMotto"

import EbayLogin from "./components/EbayLogin"
import LoginForm from "./components/LoginForm"

import styles from "./Auth.module.css"

const Auth = () => {
  const { t: translate } = useTranslate()
  return (
    <div className={styles.loginForm}>
      <div className="m-b-40">
        <WiMotto />
      </div>
      <EbayLogin />
      <div className="limiter m-v-54">
        <span className="bg-white">{translate("or")}</span>
      </div>
      <LoginForm />
      <div className="textCenter m-t-20 fs-16">
        {translate("Don't have an account?")}{" "}
        <Link to="/sign-up">{translate("Sign up now")}</Link>
      </div>
    </div>
  )
}

export default Auth
