import {
  USER_LOGOUT_SUCCESS,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_SIGNUP_SET_CONTEXT,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_RESOLVED,
} from "../../constants"

const defaultState = {
  isLoading: false,
  context: {},
  showLoader: false,
}

function signUpStore(state = defaultState, action) {
  switch (action.type) {
    case USER_SIGNUP_SET_CONTEXT:
      return {
        ...state,
        context: action.context,
      }
    case USER_SIGNUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case USER_SIGNUP_SUCCESS:
      return {
        ...state,
        showLoader: true,
      }
    case USER_SIGNUP_RESOLVED:
      return {
        ...state,
        showLoader: false,
      }
    case USER_LOGIN_SUCCESS:
    case USER_SIGNUP_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default signUpStore
