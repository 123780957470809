import { get } from "lodash"

export const cdiscountWelcomeMessageCookieName =
  "wi-cdiscount-onboarding-started"

export const STEP_LIST = {
  AMAZON: ["ConfirmSourceSite", "SelectTargetSites", "SelectProductsType"],
  EBAY: [
    "SetShippingOptions",
    "ThirdPartyTool",
    "PayPalMultiCurrency",
    "ConfigureSites",
  ],
  EBAYAOI: ["EditAccountInfo"],
}

export const GA_CATEGORIES = {
  AMAZON: "Amazon Onboarding",
  EBAY: "Ebay Onboarding",
}
export const GA_ACTIONS = {
  AMAZON: {
    REDIRECT: "Amazon redirect loader",
    CONFIRMSOURCESITE: "Amazon Source Site",
    SELECTTARGETSITES: "Amazon Target Sites",
    SELECTPRODUCTSTYPE: "Amazon Fulfillment Method",
    OTHER: "Amazon other error alert",
    DUPLICATED: "Amazon duplicated error alert",
  },
  EBAY: {
    REDIRECT: "Ebay redirect loader",
    SETSHIPPINGOPTIONS: "Ebay Shipping Options",
    THIRDPARTYTOOL: "Ebay Third Party Tool",
    PAYPALMULTICURRENCY: "Ebay PayPal Multi Currency",
    CONFIGURESITES: "Ebay configure sites",
    THANKYOU: "Thank you message",
  },
}

export const getAnalyticsData = (
  platform,
  currentStepName,
  actionLabel = "",
  shownAction = false
) => {
  const platformName = platform && platform.toUpperCase()
  const stepName = currentStepName && currentStepName.toUpperCase()
  const analyticsData = {}
  if (!get(GA_ACTIONS[platformName], stepName)) {
    return {}
  }

  if (shownAction) {
    analyticsData["data-gtm"] = "shown-ui"
  }
  analyticsData.category = GA_CATEGORIES[platformName]
  analyticsData.action = GA_ACTIONS[platformName][stepName]
  if (actionLabel !== "") {
    analyticsData.actionlabel = actionLabel
  } else {
    analyticsData.actionlabel = shownAction
      ? "element_shown"
      : "element_clicked"
  }

  return analyticsData
}

export const getCurrentStepNumber = (currentStepName, stepList) =>
  stepList.indexOf(currentStepName) + 2

export const getTotalStepsAmount = stepList => stepList.length + 1

export const getPreviousStepName = (currentStepName, stepList) => {
  if (stepList.indexOf(currentStepName) === 0) return ""
  return stepList[stepList.indexOf(currentStepName) - 1]
}

export const useStepsProps = (currentStepName, platform, isAutoOptIn) => {
  const stepsList = isAutoOptIn
    ? STEP_LIST.EBAYAOI
    : STEP_LIST[platform.toUpperCase()]
  const properties = {
    currentStep: getCurrentStepNumber(currentStepName, stepsList),
    previousStepName: getPreviousStepName(currentStepName, stepsList),
    backLink: "",
    platform,
    currentStepName,
  }

  if (!isAutoOptIn) {
    properties.totalSteps = getTotalStepsAmount(stepsList)
  }

  return properties
}

export default {
  STEP_LIST,
  cdiscountWelcomeMessageCookieName,
  getCurrentStepNumber,
  getTotalStepsAmount,
  getPreviousStepName,
  getAnalyticsData,
  useStepsProps,
}
