import { filter, reject, some, find, cloneDeep, sortBy } from "lodash"

import {
  TRANSLATION_MANAGER_GET_LIST_REQUEST,
  TRANSLATION_MANAGER_GET_LIST_SUCCESS,
  TRANSLATION_MANAGER_GET_LIST_FAILURE,
  TRANSLATION_MANAGER_TERM_UPDATE_SUCCESS,
  TRANSLATION_MANAGER_TERM_DELETE_SUCCESS,
  TRANSLATION_MANAGER_BULK_DELETE,
  TRANSLATION_MANAGER_BULK_DELETE_SUCCESS,
  TRANSLATION_MANAGER_BULK_DELETE_FAILURE,
  TRANSLATION_MANAGER_TRANSLATIONS_ADDED,
  TRANSLATION_MANAGER_SELECT_TERM_GROUP,
  TRANSLATION_MANAGER_SELECT_ALL,
  TRANSLATION_MANAGER_SELECT_TERM_TO_DELETE,
  TRANSLATION_MANAGER_CLEAR_TERM_TO_DELETE,
  USER_LOGOUT_SUCCESS,
  LOADING_STATES,
} from "data/constants"

const defaultState = {
  count: 0,
  list: [],
  termToDelete: null,
  state: LOADING_STATES.INITIAL,
  bulkState: LOADING_STATES.INITIAL,
  error: null,
}

function translationManagerStore(state = defaultState, action) {
  switch (action.type) {
    case TRANSLATION_MANAGER_GET_LIST_REQUEST:
      return {
        ...state,
        count: 0,
        list: [],
        error: null,
        state: LOADING_STATES.LOADING,
      }
    case TRANSLATION_MANAGER_GET_LIST_SUCCESS:
      return {
        ...state,
        count: action.data.length,
        list: sortBy(
          action.data.map(termGroup => ({
            ...termGroup,
            checked: false,
          })),
          "src_text"
        ),
        state: LOADING_STATES.LOADED,
      }
    case TRANSLATION_MANAGER_GET_LIST_FAILURE:
      return {
        ...state,
        count: 0,
        list: [],
        error: action.error,
        state: LOADING_STATES.FAILED,
      }
    case TRANSLATION_MANAGER_TERM_UPDATE_SUCCESS:
      return {
        ...state,
        list: updateTermInList(state.list, action.data),
      }
    case TRANSLATION_MANAGER_SELECT_TERM_GROUP:
      return {
        ...state,
        list: state.list.map(termGroup =>
          termGroup.src_text === action.payload.termText
            ? {
              ...termGroup,
              checked: !termGroup.checked,
            }
            : termGroup
        ),
      }
    case TRANSLATION_MANAGER_SELECT_ALL:
      return {
        ...state,
        list: state.list.map(termGroup => ({
          ...termGroup,
          checked: action.payload.check,
        })),
      }
    case TRANSLATION_MANAGER_SELECT_TERM_TO_DELETE:
      return {
        ...state,
        termToDelete: action.payload.term,
      }
    case TRANSLATION_MANAGER_CLEAR_TERM_TO_DELETE:
      return {
        ...state,
        termToDelete: null,
      }
    case TRANSLATION_MANAGER_TERM_DELETE_SUCCESS:
      return {
        ...state,
        termToDelete: null,
        list: state.list.map(termGroup => ({
          ...termGroup,
          terms: filter(
            termGroup.terms,
            term => term.id !== action.data.term.id
          ),
        })),
      }
    case TRANSLATION_MANAGER_BULK_DELETE:
      return {
        ...state,
        bulkState: LOADING_STATES.LOADING,
      }
    case TRANSLATION_MANAGER_BULK_DELETE_SUCCESS:
      return {
        ...state,
        bulkState: LOADING_STATES.LOADED,
        list: reject(state.list, termGroup => {
          const { terms } = action.data
          const containsOneOfTheTerms = some(
            terms,
            term => term.src_text === termGroup.src_text
          )
          return containsOneOfTheTerms
        }),
      }
    case TRANSLATION_MANAGER_BULK_DELETE_FAILURE:
      return {
        ...state,
        bulkState: LOADING_STATES.FAILED,
      }
    case TRANSLATION_MANAGER_TRANSLATIONS_ADDED:
      return {
        ...state,
        list: addTermsToList(state.list, action.payload.translations),
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

function addTermsToList(termList, newTerms) {
  const srcText = newTerms[0].src_text
  const updatedTermList = cloneDeep(termList)
  const existingTermGroup = find(updatedTermList, { src_text: srcText })

  if (!existingTermGroup) {
    updatedTermList.push({
      src_text: srcText,
      terms: newTerms,
      checked: false,
    })
  } else {
    existingTermGroup.terms = existingTermGroup.terms.concat(newTerms)
  }

  return sortBy(updatedTermList, "src_text")
}

function updateTermInList(termList, updatedTerm) {
  const updatedTermList = cloneDeep(termList)

  for (let index = 0; index < updatedTermList.length; index += 1) {
    const termGroup = updatedTermList[index]
    const term = find(termGroup.terms, { id: updatedTerm.id })
    if (term) {
      termGroup.terms = reject(termGroup.terms, term)
      break
    }
  }

  return addTermsToList(updatedTermList, [updatedTerm])
}

export default translationManagerStore
