import {
  SHIPPING_PROFILES_GET_SERVICES_REQUEST,
  SHIPPING_PROFILES_GET_SERVICES_SUCCESS,
  SHIPPING_PROFILES_GET_SERVICES_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  list: [],
  loading: false,
  error: null,
}

function shippingProfilesServicesStore(state = defaultState, action) {
  switch (action.type) {
    case SHIPPING_PROFILES_GET_SERVICES_REQUEST:
      return {
        ...state,
        list: [],
        error: null,
        loading: true,
      }
    case SHIPPING_PROFILES_GET_SERVICES_SUCCESS:
      return {
        ...state,
        list: action.data,
        loading: false,
      }
    case SHIPPING_PROFILES_GET_SERVICES_FAILURE:
      return {
        ...state,
        list: [],
        error: action.error,
        loading: false,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default shippingProfilesServicesStore
