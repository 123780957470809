import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  USER_GET_SUCCESS,
  PROFILE_GET_FINANCIAL_DETAILS,
  PROFILE_GET_FINANCIAL_DETAILS_REQUEST,
  PROFILE_GET_FINANCIAL_DETAILS_SUCCESS,
  PROFILE_GET_FINANCIAL_DETAILS_FAILURE,
  PROFILE_GET_FINANCIAL_DETAILS_CANCEL,
  EBAY_ONBOARDING_BACK_TO_CHECKOUT,
} from "../../constants"

import { get } from "../../fetch/profile/financialDetails"

function* getFinancialDetails() {
  while (true) {
    yield take([
      PROFILE_GET_FINANCIAL_DETAILS,
      USER_GET_SUCCESS,
      EBAY_ONBOARDING_BACK_TO_CHECKOUT,
    ])
    const { user } = yield select()
    yield put({ type: PROFILE_GET_FINANCIAL_DETAILS_REQUEST })
    try {
      const { data } = yield call(() => get(user.id))
      if (data) yield put({ type: PROFILE_GET_FINANCIAL_DETAILS_SUCCESS, data })
    } catch (error) {
      yield put({ type: PROFILE_GET_FINANCIAL_DETAILS_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getFinancialDetails)])

    yield all(tasks)

    yield take(PROFILE_GET_FINANCIAL_DETAILS_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
