/* eslint-disable import/prefer-default-export */
import {
  GENERAL_SET_ACTIVE_PLATFORM,
  GENERAL_SET_ACTIVE_ACCOUNT,
} from "../../constants"

export function setActivePlatform(platform) {
  return dispatch =>
    dispatch({
      type: GENERAL_SET_ACTIVE_PLATFORM,
      platform,
    })
}

export function setActiveAccount(account) {
  return dispatch =>
    dispatch({
      type: GENERAL_SET_ACTIVE_ACCOUNT,
      account,
    })
}

export default {
  setActivePlatform,
  setActiveAccount,
}
