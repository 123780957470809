import { createSelector } from "reselect"

const getActiveAccount = state => state.general.activeAccount
const getAllUserAccount = state => state.epl.accounts

export const activeEbayAccount = createSelector(
  getActiveAccount,
  getAllUserAccount,
  (activeAccount, accounts) =>
    accounts?.find(account => account.account_id === activeAccount)
)
