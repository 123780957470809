import { USER_GET, USER_LOGIN, USER_LOGOUT } from "../../constants"
import {
  get as getUser,
  login as loginUser,
  logout as logoutUser,
} from "../../fetch/user/auth"

export function get() {
  return dispatch => {
    const promise = getUser()

    return dispatch({
      type: USER_GET,
      promise,
    })
  }
}

export function login(data) {
  return dispatch => {
    const promise = loginUser(data)

    return dispatch({
      type: USER_LOGIN,
      promise,
    })
  }
}

export function logout() {
  return dispatch => {
    const promise = logoutUser()

    return dispatch({
      type: USER_LOGOUT,
      promise,
    })
  }
}

export default {
  get,
  login,
  logout,
}
