import { find } from "lodash"

const EBAY_SITES_LIST = [
  {
    country: "DE",
    region: "Europe",
    site: "DE",
  },
  {
    country: "ES",
    region: "Europe",
    site: "ES",
  },
  {
    country: "IT",
    region: "Europe",
    site: "IT",
  },
  {
    country: "UK",
    region: "Europe",
    site: "UK",
  },
  {
    country: "FR",
    region: "Europe",
    site: "FR",
  },
  {
    country: "AU",
    region: "Australia",
    site: "AU",
  },
  {
    country: "US",
    region: "North America",
    site: "US",
  },
  {
    country: "CA",
    region: "North America",
    site: "CA",
  },
  {
    country: "IE",
    region: "Europe",
    site: "IE",
  },
  {
    country: "AT",
    region: "Europe",
    site: "AT",
  },
  {
    country: "BEFR",
    region: "Europe",
    site: "BEFR",
  },
]

export function getSitesList() {
  return EBAY_SITES_LIST
}

export function getSiteKeyList() {
  return EBAY_SITES_LIST.map(site => site.site)
}

export function getCountryBySite(site) {
  const siteData = find(EBAY_SITES_LIST, { site })
  return siteData && siteData.country
}

export default {
  getSitesList,
  getSiteKeyList,
  getCountryBySite,
}
