/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isObject } from "lodash"
import Permissions, { add } from "react-redux-permissions"
import { withTranslate } from "context/translateContext"

import { GENERAL_SHOW_MODAL } from "data/constants"

import Dropdown from "../Dropdown"
import DropdownTrigger from "../Dropdown/DropdownTrigger"
import DropdownContent from "../Dropdown/DropdownContent"
import Button from "../Button"

class HelpDropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    top: PropTypes.bool,
    right: PropTypes.bool,
    openHelpCenterModal: PropTypes.func.isRequired,
    enableChat: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: "",
    top: false,
    right: false,
  }

  constructor(props) {
    super(props)

    this.setChatVisibility = this.setChatVisibility.bind(this)
  }

  componentDidMount() {
    try {
      window.watch("LC_API", this.setChatVisibility)
    } catch (err) {
      /* eslint-disable-next-line */
      console.log(err)
    }
  }

  componentWillUnmount() {
    try {
      window.unwatch("LC_API")
    } catch (err) {
      /* eslint-disable-next-line */
      console.log(err)
    }
  }

  setChatVisibility(id, oldval, newval) {
    const { enableChat } = this.props
    if (isObject(newval)) {
      enableChat()
    }
    return newval
  }

  render() {
    const { className, top, right, openHelpCenterModal } = this.props
    const { t: translate } = this.props

    return (
      <Dropdown className={[className].join(" ")} top={top} right={right}>
        <DropdownTrigger>
          <Button variation="primary" size="small">
            {translate("Need Help?")}
          </Button>
        </DropdownTrigger>
        <DropdownContent>
          <ul className="l-unstyled">
            <li>
              <a onClick={openHelpCenterModal} role="button" tabIndex="0">
                {translate("Quick help")}
              </a>
            </li>
            <li>
              <Link to="/submit-request">{translate("Submit request")}</Link>
            </li>
            <Permissions allowed={["livechat", "china"]}>
              <li>
                <a
                  role="button"
                  tabIndex={0}
                  onClick={() => window.LC_API.show_full_view()}
                >
                  <strong>{translate("Chat now")}</strong>
                </a>
              </li>
            </Permissions>
          </ul>
        </DropdownContent>
      </Dropdown>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  enableChat: () => dispatch(add("livechat")),
  openHelpCenterModal: () =>
    dispatch({
      type: GENERAL_SHOW_MODAL,
      name: "help",
    }),
})

export default connect(null, mapDispatchToProps)(withTranslate(HelpDropdown))
