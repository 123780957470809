import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function getAll(user, platform, params) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${user.id}/sales/${platform}`,
    method: "GET",
    params,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  getAll,
}
