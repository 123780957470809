import { each, find, filter } from "lodash"
import {
  PRODUCT_GET_REQUEST,
  PRODUCT_GET_SUCCESS,
  PRODUCT_GET_FAILURE,
  PRODUCT_GET_PRICES_REQUEST,
  PRODUCT_GET_PRICES_SUCCESS,
  PRODUCT_GET_PRICES_FAILURE,
  PRODUCT_UPDATE_PRICES_SUCCESS,
  PRODUCT_CLEAR,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  data: {},
  state: "INITIAL",
  error: null,
}

const getTranslationList = product => {
  const list = []
  each(product.translations, (ti, key) => {
    list.push({
      ...ti,
      site: key,
    })
  })
  return list
}

const mapPricesToState = data => {
  const translations = getTranslationList(data)
  const list = filter(
    translations,
    t => t.site !== data.site && (t.state === "L" || t.isListed)
  )
  const prices = list.map(ti => ({
    site: ti.site,
    price: ti.price || ti.startPrice,
    currency: ti.currency || ti.priceCurrency || ti.startPriceCurrency,
    // OTHER DATA
    identifier: ti.sku || ti.itemId,
    manualPriceEnabled: ti.manualPriceEnabled,
    isFBA: ti.isFBA,
    url: ti.url,
  }))
  return prices
}

function productPricesStore(state = defaultState, action) {
  switch (action.type) {
    case PRODUCT_GET_REQUEST:
      return {
        ...state,
        data: {},
        error: null,
        state: "LOADING",
      }
    case PRODUCT_GET_PRICES_REQUEST:
      return {
        ...state,
        error: null,
        state: "LOADING",
      }
    case PRODUCT_GET_SUCCESS:
      return {
        ...state,
        data: mapPricesToState(action.data),
      }
    case PRODUCT_GET_PRICES_SUCCESS:
    case PRODUCT_UPDATE_PRICES_SUCCESS:
      return {
        ...state,
        data: state.data.map(value => {
          const changedItem = find(action.data, { site: value.site })
          if (changedItem) {
            return {
              ...value,
              price: changedItem.algorithm.price,
              manualPriceEnabled:
                changedItem.algorithm.type === "ALGORITHM_MANUAL",
            }
          }
          return value
        }),
        state: "LOADED",
      }
    case PRODUCT_GET_FAILURE:
    case PRODUCT_GET_PRICES_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        state: "FAILED",
      }
    case PRODUCT_CLEAR:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default productPricesStore
