import { includes, map } from "lodash"

export const getTabFilter = tab => {
  const MAP = {
    listed: {
      status: "listedAbroad",
    },
    "in-translation": {
      status: "inTranslation",
    },
    "no-shipping": {
      eligibility: "noShipping",
    },
    blocked: {
      eligibility: "blacklisted",
    },
  }
  return MAP[tab]
}

const bulkRequestIssuesMap = ["OK", "item already requested on this site"]

export const mapBulkItemResultIssues = data => ({
  ...data,
  items: map(data.items, item => {
    let issues = 0
    const results = map(item.results, result => {
      let error = false
      if (includes(bulkRequestIssuesMap, result.result)) {
        issues += issues
        error = true
      }
      return {
        ...result,
        error,
      }
    })
    return {
      ...item,
      results,
      issues,
    }
  }),
})

export default {
  getTabFilter,
  mapBulkItemResultIssues,
}
