import { all, put, fork, call, take, delay } from "redux-saga/effects"

import {
  ZUORA_FEEDBACK,
  ZUORA_FEEDBACK_REQUEST,
  ZUORA_FEEDBACK_SUCCESS,
  ZUORA_FEEDBACK_FAILURE,
  CHECKOUT_PAYMENT_STATUS,
  CHECKOUT_PAYMENT_STATUS_REQUEST,
  CHECKOUT_PAYMENT_STATUS_SUCCESS,
  CHECKOUT_PAYMENT_STATUS_FAILURE,
} from "data/constants"

import history from "core/history"
import { underscoreToDash } from "shared/helpers/strings"
import { PaymentTimeout, PaymentFailed } from "shared/errors"

import { postZuoraFeedback, getPaymentStatus } from "data/fetch/plans/checkout"

function* paymentSuccess() {
  while (true) {
    const action = yield take(ZUORA_FEEDBACK)
    yield put({ type: ZUORA_FEEDBACK_REQUEST })
    try {
      const { data } = yield call(() => postZuoraFeedback(action.data))
      if (data.id) {
        yield put({ type: ZUORA_FEEDBACK_SUCCESS })
        const method = underscoreToDash(
          action.data.payment_method_type
        ).toLowerCase()
        history.push(`/checkout/pending/${method}?checkout_id=${data.id}`)
      }
    } catch (error) {
      yield put({ type: ZUORA_FEEDBACK_FAILURE, error })
    }
  }
}

function* retryFetch(request, attempts, delayTime) {
  for (let i = 0; i < attempts; i += 1) {
    try {
      const { data } = yield call(request)

      if (data.status.toLowerCase() !== "pending") {
        return data
      }

      if (i < attempts - 1) {
        yield delay(delayTime)
      }
    } catch (error) {
      if (error.response.status === 404) throw new PaymentFailed("NOT FOUND")
      if (error.response.status > 499) {
        yield delay(delayTime)
      } else {
        throw new PaymentFailed("OTHER ERROR")
      }
    }
  }

  throw new PaymentTimeout("TIMED OUT")
}

function* checkoutPendingRetry() {
  while (true) {
    const action = yield take(CHECKOUT_PAYMENT_STATUS)
    yield put({ type: CHECKOUT_PAYMENT_STATUS_REQUEST })
    try {
      const getPaymentStatusCall = () =>
        getPaymentStatus(action.payload.checkoutId)
      const { status, error_code: errorCode } = yield retryFetch(
        getPaymentStatusCall,
        6,
        5000
      )

      if (status.toLowerCase() === "failure") {
        throw new PaymentFailed(errorCode)
      }

      yield put({ type: CHECKOUT_PAYMENT_STATUS_SUCCESS })
      history.push("/checkout/success")
    } catch (error) {
      yield put({
        type: CHECKOUT_PAYMENT_STATUS_FAILURE,
        payload: error.message,
      })
      history.push("/checkout/error")
    }
  }
}

export default function* main() {
  yield all([fork(paymentSuccess), fork(checkoutPendingRetry)])
}
