import {
  PRICE_RULE_GET_REQUEST,
  PRICE_RULE_GET_SUCCESS,
  PRICE_RULE_GET_FAILURE,
  PRICE_RULE_EDIT_SUCCESS,
  PRICE_RULE_CLEAR,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

import { arePricesEqual, areAllEnabled } from "../../utils"

const defaultState = {
  data: {},
  state: "INITIAL",
  error: null,
}

function priceAdjustmentSingleRuleStore(state = defaultState, action) {
  switch (action.type) {
    case PRICE_RULE_GET_REQUEST:
      return {
        ...state,
        data: {},
        error: null,
        state: "LOADING",
      }
    case PRICE_RULE_GET_SUCCESS:
      return {
        ...state,
        data: action.data,
        state: "LOADED",
      }
    case PRICE_RULE_GET_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        state: "FAILED",
      }
    case PRICE_RULE_EDIT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          rules: action.data.rules,
          pricesEqual: arePricesEqual(action.data.rules),
          areAllEnabled: areAllEnabled(action.data.rules),
          neat_prices: action.data.sites[0].neat_prices,
        },
      }
    case PRICE_RULE_CLEAR:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default priceAdjustmentSingleRuleStore
