import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  TRANSLATION_MANAGER_GET_LIST,
  TRANSLATION_MANAGER_GET_LIST_REQUEST,
  TRANSLATION_MANAGER_GET_LIST_SUCCESS,
  TRANSLATION_MANAGER_GET_LIST_FAILURE,
  TRANSLATION_MANAGER_CANCEL,
} from "../../constants"

import { getAll } from "../../fetch/translationManager/list"

function* getList() {
  while (true) {
    const { payload } = yield take([TRANSLATION_MANAGER_GET_LIST])
    const { user } = yield select()
    yield put({ type: TRANSLATION_MANAGER_GET_LIST_REQUEST })
    try {
      const { data } = yield call(() => getAll(user.id, payload.params))
      if (data) yield put({ type: TRANSLATION_MANAGER_GET_LIST_SUCCESS, data })
    } catch (error) {
      yield put({ type: TRANSLATION_MANAGER_GET_LIST_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getList)])

    yield all(tasks)

    yield take(TRANSLATION_MANAGER_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
