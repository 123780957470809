import { isNil } from "lodash"
import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

// EBAY

export function getEbay(user, platform, params) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${user.id}/accounts/tokens`,
    method: "GET",
    params,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getValidatedAccount(user, eiastoken) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${user.id}/accounts`,
    method: "GET",
    params: {
      eiastoken,
    },
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function activateAccount(user, accountId, tokenId) {
  const controller = new AbortController()
  const params = {}
  if (!isNil(accountId)) {
    params.account_id = accountId
  }
  if (!isNil(tokenId)) {
    params.token_id = tokenId
  }
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${user.id}/accounts/ebay`,
    method: "POST",
    params,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  getEbay,
  getValidatedAccount,
  activateAccount,
}
