const usersForAdoption = [
  8633,
  16904,
  2655695,
  2397018,
  11795,
  11590,
  143132,
  15177,
  4466,
  20816,
  2109290,
  21050,
  142093,
  504008,
  10728,
  23839,
  15754,
  501730,
  2073658,
  11443,
  9032,
  160909,
  93057,
  963385,
  12279,
  160788,
  753550,
  16582,
  2780816,
  3865,
  2396184,
  16332,
  2791543,
  2304610,
  2655731,
  10247,
  7668,
  11981,
  13049,
  3034,
  22732,
  4351,
  2792884,
  10129,
  2612615,
  2600551,
  12473,
  164896,
  1035933,
  286340,
  142166,
  501399,
  2654479,
  1035970,
  11563,
  12765,
  2663765,
  142065,
  29965,
  1287260,
  155164,
  42731,
  2789162,
  2628709,
  12401,
  753024,
  2055144,
  2055880,
  21399,
  2660059,
  2781760,
  2147278,
  495234,
  11336,
  14849,
  14483,
  17294,
  511692,
  286460,
  2604263,
  567360,
  12789,
  501798,
  498324,
  2781248,
  12283,
  7572,
  14130,
  16877,
  23127,
  13946,
  16626,
]

export const isEligibleForAmazonAdoption = userId =>
  usersForAdoption.includes(userId)
