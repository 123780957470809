import React, { Component } from "react"
import PropTypes from "prop-types"
import { matchRoutes } from "react-router-dom"
import { connect } from "react-redux"
import { keys } from "lodash"

import withRouter from "shared/helpers/withRouter"
import { Navigate } from "shared/components/Navigate"
import { validAccounts } from "../data/selectors/user"

class FallbackComponent extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    activePlatform: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    accounts: PropTypes.shape({
      all: PropTypes.arrayOf(PropTypes.number).isRequired,
      amazon: PropTypes.arrayOf(PropTypes.number).isRequired,
      ebay: PropTypes.arrayOf(PropTypes.number).isRequired,
      cdiscount: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
  }
  constructor(props) {
    super(props)

    this.fallbackRoutes = {
      "/dashboard": "/{platform}/{account}/dashboard",
      "/dashboard/:platform": "/{platform}/{account}/dashboard",
      "/products": "/{platform}/{account}/products/{tab}",
      "/products/:platform/:tab": "/{platform}/{account}/products/{tab}",
      "/orders": "/{platform}/{account}/orders",
      "/orders/:platform": "/{platform}/{account}/orders",
      "/tools/conversion-charts":
        "/{all}/{account}/tools/conversion-charts/default",
      "/tools/translation-manager":
        "/{all}/{account}/tools/translation-manager",
      "/tools/blacklist": "/{platform}/{account}/tools/blacklist",
      "/tools/blacklist/:platform": "/{platform}/{account}/tools/blacklist",
      "/tools/price-adjustment":
        "/{platform}/{account}/tools/price-adjustment",
      "/tools/price-adjustment/:platform":
        "/{platform}/{account}/tools/price-adjustment",
      "/tools/shipping-manager": "/ebay/{account}/tools/shipping-manager",
      "/tools/shipping-manager/profile": "/ebay/{account}/tools/shipping-manager",
      "/tools/bulk-request": "/ebay/{account}/tools/bulk-request",
      "/tools/repricer": "/amazon/{account}/tools/repricer",
      "/profile/accounts": "/user/accounts",
      "/profile": "/user",
      "/plans": "/user/plans",
      "/ebay-promoted-listings": "/ebay/{account}/marketing",
      "/ebay-promoted-listings-settings": "/ebay/{account}/marketing/settings",
      "/cdiscount-dashboard": "/cdiscount/{account}/dashboard",
      "/cdiscount-onboarding": "/cdiscount/{account}/onboarding",
      "/amazon-onboarding": "/amazon/{account}/onboarding",
      "/ebay-onboarding": "/ebay/{account}/onboarding",
    }
  }

  getRedirectPath() {
    const { location, searchParams } = this.props
    const paths = Object.keys(this.fallbackRoutes).map(key => ({ path: key }))
    const matchedRoutes = matchRoutes(paths, location)
    if (matchedRoutes.length === 0) return null
    let newPath = this.fallbackRoutes[matchedRoutes[0].route.path] || "/"
    newPath = this.replacePlatformInPath(newPath)
    newPath = this.replaceAccountInPath(newPath)
    newPath = this.replaceTabInPath(newPath)
    return `${newPath}?${searchParams.toString()}`
  }

  replacePlatformInPath(path) {
    const { params, activePlatform, platforms } = this.props
    let newPath
    if (params.platform && platforms.indexOf(params.platform) > -1) {
      newPath = path.replace("{platform}", params.platform)
      newPath = newPath.replace("{all}", params.platform)
    } else {
      newPath = path.replace("{platform}", activePlatform || platforms[0])
      newPath = newPath.replace("{all}", "all")
    }

    return newPath
  }

  replaceAccountInPath(path) {
    const { accounts, userId } = this.props
    let account = userId

    if (path.indexOf("ebay") > -1) {
      account = accounts.ebay[0] // eslint-disable-line
    }

    return path.replace("{account}", account)
  }

  replaceTabInPath(path) {
    const { params } = this.props
    const tab = params.tab || "all"
    return path.replace("{tab}", tab)
  }

  render() {
    const path = this.getRedirectPath()
    if (path) {
      return <Navigate to={path} replace />
    }
    return null
  }
}

const mapStateToProps = state => ({
  userId: state.user.id,
  activePlatform: state.general.activePlatform,
  platforms: keys(state.user.platforms),
  accounts: validAccounts(state),
})

export default connect(mapStateToProps)(withRouter(FallbackComponent))
