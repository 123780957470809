import { isNull } from "lodash"

import {
  LOADING_STATES,
  PLANS_GET_REQUEST,
  PLANS_GET_SUCCESS,
  PLANS_GET_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

const defaultState = {
  state: LOADING_STATES.INITIAL,
  currency: "EUR",
  ids: [],
  packages: {},
  displayPlanList: {},
  error: null,
  isSpecialOfferAvailable: false,
}

function plansStore(state = defaultState, action) {
  switch (action.type) {
    case PLANS_GET_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
        error: null,
      }
    case PLANS_GET_SUCCESS:
      return {
        ...state,
        currentPlanID: action.data.currentPlanID,
        state: LOADING_STATES.LOADED,
        currency: action.data.currency,
        ids: action.data.ids,
        packages: action.data.packages,
        displayPlanList: action.displayPlanList,
        isSpecialOfferAvailable: isSpecialOfferAvailable(action.data.packages),
      }
    case PLANS_GET_FAILURE:
      return {
        ...state,
        error: action.error.error,
        state: LOADING_STATES.FAILED,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export function isSpecialOfferAvailable(packages) {
  return !isNull(packages.SPECIAL)
}

export default plansStore
