import React, { Component } from "react"
import PropTypes from "prop-types"
import { Routes, Route, Outlet } from "react-router-dom"
import { connect } from "react-redux"
import Loadable from "react-loadable"
import Notifications from "react-notification-system-redux"
import ReactTooltip from "react-tooltip"
import { setLanguage } from "redux-i18n"
import Cookies from "js-cookie"
import { get } from "lodash"

import withRouter from "shared/helpers/withRouter"
import { getUserLanguages } from "shared/services/languages"
import GlobalLoader from "shared/components/Loader/Global"
import Loader from "shared/components/Loader/Local"
import AlertBanner from "shared/components/AppAlertBanner"
import Header from "shared/components/Header"
import CheckoutHeader from "shared/components/Header/Checkout"
import SignUpHeader from "shared/components/Header/SignUp"
import Footer from "shared/components/Footer"
import Throw from "shared/components/Throw"
import GlobalModal from "shared/views/GlobalModal"

import CONFIG from "core/config"

import { get as fetchUser } from "data/actions/user/auth"

import { Navigate } from "shared/components/Navigate"
import Zendesk from "shared/services/zendesk/Zendesk"
import Meta from "./Meta"
import ErrorBoundary from "./ErrorBoundary"
import Auth from "./Auth"
import PlatformAccountRoot from "./Root"
import FallbackComponent from "./FallbackComponent"
import TokenConnectionHandler from "./NewAccount/Amazon/components/TokenConnectionHandler"
import Services from "./Services"

import GetFeedbackSurveys from "./GetFeedbackSurveys/GetFeedbackSurveys"
import styles from "./App.module.css"

import "../shared/styles/Base.css"

const isChineseSite = CONFIG.location === "cn"

const NewEbayEsbAccount = Loadable({
  loader: () => import("./NewAccount/eBayESB"),
  loading: () => <Loader />,
})
const Checkout = Loadable({
  loader: () => import("./Checkout"),
  loading: () => <Loader />,
})
const Payment = Loadable({
  loader: () => import("./Payment"),
  loading: () => <Loader />,
})
const TransactionPendingView = Loadable({
  loader: () => import("./Checkout/views/Confirmation/views/LoaderView"),
  loading: () => <Loader />,
})
const User = Loadable({
  loader: () => import("./User"),
  loading: () => <Loader />,
})
const Token = Loadable({
  loader: () => import("./Token"),
  loading: () => <Loader />,
})
const Help = isChineseSite
  ? Loadable({
      loader: () => import("./Help"),
      loading: () => <Loader />,
    })
  : null
const SignUp = Loadable({
  loader: () => import("./SignUp"),
  loading: () => <Loader />,
})
const EmailForm = Loadable({
  loader: () => import("./PasswordReset/views/EmailForm"),
  loading: () => <Loader />,
})
const NewPasswordForm = Loadable({
  loader: () => import("./PasswordReset/views/NewPasswordForm"),
  loading: () => <Loader />,
})
const TermsAndConditions = Loadable({
  loader: () => import("./TermsAndConditions"),
  loading: () => <Loader />,
})

const NewAccount = Loadable({
  loader: () => import("./NewAccount"),
  loading: () => <Loader />,
})

class App extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    shouldStartEbayOnboarding: PropTypes.bool.isRequired,
    general: PropTypes.object.isRequired,
    setInitialLang: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    hasOngoingOnboarding: PropTypes.bool.isRequired,
    searchParams: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.fallbackRoutes = [
      "/dashboard",
      "/dashboard/:platform",
      "/products",
      "/products/:platform/:tab",
      "/orders",
      "/orders/:platform",
      "/tools/conversion-charts",
      "/tools/translation-manager",
      "/tools/blacklist",
      "/tools/blacklist/:platform",
      "/tools/price-adjustment",
      "/tools/price-adjustment/:platform",
      "/tools/shipping-manager",
      "/tools/shipping-manager/:profile",
      "/tools/bulk-request",
      "/tools/repricer",
      "/profile/accounts",
      "/profile",
      "/plans",
      "/ebay-promoted-listings",
      "/ebay-promoted-listings-settings",
      "/cdiscount-dashboard",
      "/cdiscount-onboarding",
      "/amazon-onboarding",
      "/ebay-onboarding",
    ]
  }

  componentDidMount() {
    const { navigate, setInitialLang, getUser } = this.props
    const initialLang = getUserLanguages()
    setInitialLang(initialLang)
    getUser().catch(() => {})

    // Check if we come from some previous page before login
    const redirectTo = Cookies.get("redirectTo")
    if (redirectTo) {
      Cookies.remove("redirectTo")
      navigate(redirectTo, { replace: true })
    }
  }

  notSignedInRoutes() {
    const { general } = this.props
    return general.loading ? (
      <GlobalLoader />
    ) : (
      <div>
        <Routes>
          <Route path="/" element={<Auth />}>
            <Route
              path="submit-request/*"
              element={isChineseSite ? <Help /> : <Zendesk />}
            />
          </Route>

          <Route
            path="/terms-and-conditions/*"
            element={<TermsAndConditions />}
          />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/password-reset/submit-email" element={<EmailForm />} />
          <Route
            path="/password-reset/submit-password"
            element={<NewPasswordForm />}
          />
          <Route path="*" element={<Auth />} />
        </Routes>
      </div>
    )
  }

  signedInRoutes() {
    const {
      general,
      shouldStartEbayOnboarding,
      user,
      wiShippingEnabled,
      wiReturnsEnabled,
      isShippingEligible,
      isReturnsEligible,
      location,
      searchParams,
    } = this.props
    return (
      <div>
        <Routes location={location}>
          <Route
            path="/"
            element={
              <>
                <Navigate
                  to={
                    shouldStartEbayOnboarding
                      ? "/onboarding"
                      : `/${general.activePlatform}/${general.activeAccount}/dashboard`
                  }
                />
                <Outlet />
              </>
            }
          >
            <Route
              path="/submit-request/*"
              element={isChineseSite ? <Help /> : <Zendesk />}
            />
          </Route>
          {shouldStartEbayOnboarding && (
            <Route path="/onboarding/*" element={<NewEbayEsbAccount />} />
          )}
          <Route
            path="/shipping"
            element={
              <Services
                userId={user.id}
                wiShippingEnabled={wiShippingEnabled}
                wiReturnsEnabled={wiReturnsEnabled}
                isReturnsEligible={isReturnsEligible}
                isShippingEligible={isShippingEligible}
              />
            }
          />
          <Route
            path="/returns"
            element={
              <Services
                userId={user.id}
                wiShippingEnabled={wiShippingEnabled}
                wiReturnsEnabled={wiReturnsEnabled}
                isReturnsEligible={isReturnsEligible}
                isShippingEligible={isShippingEligible}
              />
            }
          />

          <Route path="/user/*" element={<User />} />
          <Route
            path="/terms-and-conditions/*"
            element={<TermsAndConditions />}
          />
          <Route
            path="/checkout/pending"
            element={<TransactionPendingView />}
          />
          <Route path="/checkout/*" element={<Checkout />} />
          <Route path="/payment/:type" element={<Payment />} />

          <Route path="/token/*" element={<Token />} />
          <Route
            path="/profile/accounts/new-token"
            element={<Navigate to={`/token${searchParams.toString()}`} />}
          />
          <Route
            path="/:platform/:account/*"
            element={<PlatformAccountRoot />}
          />
          {this.fallbackRoutes.map(route => (
            <Route key={route} path={route} element={<FallbackComponent />} />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    )
  }

  renderCommon() {
    const { general } = this.props

    return general.stateReady ? this.signedInRoutes() : this.notSignedInRoutes()
  }

  renderOnboarding() {
    const { general, location } = this.props
    return general.loading ? (
      <GlobalLoader />
    ) : (
      <Routes location={location}>
        <Route
          path="/"
          element={
            <>
              <Navigate to="start" />
              <Outlet />
            </>
          }
        >
          <Route
            path="/submit-request/*"
            element={isChineseSite ? <Help /> : <Zendesk />}
          />
        </Route>
        <Route path="/start" element={<NewAccount />} />
        <Route path="/user" element={<User />} />
        <Route
          path="/terms-and-conditions/*"
          element={<TermsAndConditions />}
        />
        <Route path="*" element={<Navigate to="/start" replace />} />
      </Routes>
    )
  }

  render() {
    const { general, user, notifications, hasOngoingOnboarding } = this.props

    return (
      <ErrorBoundary>
        <Throw shouldThrow={general.errorLoadingInitialData} />
        <Meta />
        <AlertBanner />
        <TokenConnectionHandler />
        <Routes>
          <Route path="/checkout/:plan/*" element={<CheckoutHeader />} />
          <Route path="/sign-up" element={<SignUpHeader />} />
          <Route path="/start" element={<SignUpHeader />} />
          <Route
            path="/*"
            element={
              <Header
                stateReady={general.stateReady}
                name={user.name}
                email={user.email}
              />
            }
          />
        </Routes>
        <div className={styles.root}>
          {user.isPlatformless &&
          !user.isDeleted &&
          !user.chosenOnboardingPlatform &&
          !hasOngoingOnboarding
            ? this.renderOnboarding()
            : this.renderCommon()}
          <GetFeedbackSurveys />
        </div>
        <GlobalModal />
        <Footer />
        <Notifications notifications={notifications} allowHTML />
        <ReactTooltip effect="solid" />
        {general.overlay && (
          <div className={styles.overlay}>
            <div className={styles.overlayContent}>
              {general.overlayMessage}
            </div>
          </div>
        )}
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications,
  general: state.general,
  user: state.user,
  hasOngoingOnboarding: get(
    state.multichannelOnboarding,
    "current.is_ongoing",
    false
  ),
  shouldStartEbayOnboarding: state.ebayOnboarding.shouldBeOnboarded,
  wiShippingEnabled: state.settings.data["wi-shipping-enabled"],
  wiReturnsEnabled: state.settings.data["wi-returns-enabled"],
  isReturnsEligible:
    state.entitlements.can_onboard_to_returns_domestic ||
    state.entitlements.can_onboard_to_returns_international_active,
  isShippingEligible: state.entitlements.can_use_shipping,
})

const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(fetchUser()),
  setInitialLang: lang => dispatch(setLanguage(lang)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
