import { find, each, toString } from "lodash"

import {
  PRODUCTS_GET_LIST_REQUEST,
  PRODUCTS_GET_LIST_SUCCESS,
  PRODUCTS_GET_LIST_FAILURE,
  PRODUCTS_SELECT_ITEM,
  PRODUCTS_SELECT_ALL,
  PRODUCTS_SELECT_ALL_QUERY,
  PRODUCTS_UPDATE_BLOCK_SUCCESS,
  PRODUCTS_UPDATE_UNBLOCK_SUCCESS,
  PRODUCTS_SET_SHIPPING_SUCCESS,
  PRODUCTS_CLEAR_SHIPPING_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

import { block, unblock } from "../../utils"

const defaultState = {
  allFromQuerySelected: false,
  count: 0,
  list: [],
  state: "INITIAL",
  error: null,
}

function productsStore(state = defaultState, action) {
  switch (action.type) {
    case PRODUCTS_GET_LIST_REQUEST:
      return {
        ...state,
        count: 0,
        list: [],
        error: null,
        state: "LOADING",
      }
    case PRODUCTS_GET_LIST_SUCCESS:
      return {
        ...state,
        count: action.data.count,
        list: extendProductInformation(action.data.items),
        state: "LOADED",
      }
    case PRODUCTS_GET_LIST_FAILURE:
      return {
        ...state,
        count: 0,
        list: [],
        error: action.error,
        state: "FAILED",
      }
    case PRODUCTS_SELECT_ITEM:
      return {
        ...state,
        allFromQuerySelected: false,
        list: state.list.map(item =>
          item.ref === action.ref
            ? {
              ...item,
              checked: !item.checked,
            }
            : item
        ),
      }
    case PRODUCTS_SELECT_ALL:
      return {
        ...state,
        allFromQuerySelected: false,
        list: state.list.map(item => ({
          ...item,
          checked: action.check,
        })),
      }
    case PRODUCTS_SELECT_ALL_QUERY:
      return {
        ...state,
        allFromQuerySelected: action.check,
      }
    // BULK ACTIONS
    case PRODUCTS_UPDATE_BLOCK_SUCCESS:
      return {
        ...state,
        allFromQuerySelected: false,
        list: state.list.map(item => {
          const blockedItem = find(action.data.items, {
            id: item.id,
          })

          if (blockedItem) {
            return {
              ...item,
              checked: false,
              translations: block(item.translations, blockedItem.sites),
            }
          }

          return {
            ...item,
            checked: false,
          }
        }),
      }
    case PRODUCTS_UPDATE_UNBLOCK_SUCCESS:
      return {
        ...state,
        allFromQuerySelected: false,
        list: state.list.map(item => {
          const unblockedItem = find(action.data.items, {
            id: item.id,
          })

          if (unblockedItem) {
            return {
              ...item,
              checked: false,
              translations: unblock(item.translations, unblockedItem.sites),
            }
          }

          return {
            ...item,
            checked: false,
          }
        }),
      }
    case PRODUCTS_SET_SHIPPING_SUCCESS:
    case PRODUCTS_CLEAR_SHIPPING_SUCCESS:
      return {
        ...state,
        allFromQuerySelected: false,
        list: state.list.map(item => {
          const wasUpdated =
            action.data.items.indexOf(item.itemId || item.sku) > -1

          if (wasUpdated) {
            return {
              ...item,
              checked: false,
              shipping_profile: action.data.profile,
            }
          }

          return {
            ...item,
            checked: false,
          }
        }),
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default productsStore

function extendProductInformation(products) {
  return products.map(item => ({
    ...item,
    ref: toString(item.itemId) || `${item.asin}-${item.sku}`, // for amazon make asin as id for easier operations
    checked: false,
    listedIn: getItemsListed(item.translations),
  }))
}

function getItemsListed(tis) {
  const result = []
  each(tis, (value, key) => {
    if (value.state === "L" || value.isListed) {
      result.push(key)
    }
  })
  return result
}
