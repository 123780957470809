export function isPhoneNumber(number) {
  return /^\+[1-9]{1}[0-9]{6,14}$/.test(number)
}
export function isEmail(string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(string)
}
export function emailInText(string) {
  const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/
  return regex.test(string)
}

export function isPasswordStrongEnough(string) {
  const regex = /((?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).{6,30})/
  return regex.test(string)
}

export default {
  isPhoneNumber,
  isEmail,
  emailInText,
  isPasswordStrongEnough,
}
