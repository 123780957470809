import { omitBy, isNil } from "lodash"
import {
  EPL_CAMPAIGN_REPORTS_GET,
  EPL_TOS_GET,
  EPL_SITES_GET,
  EPL_ONBOARDING_GET,
  EPL_ONBOARDING_POST,
  EPL_OPT_OUT_POST,
  EPL_OPT_OUT_GET,
  EPL_LOAD,
  EPL_AGGREGATED_CAMPAIGN_REPORT_GET,
  EPL_CAMPAIGN_CHART_DATA_GET,
  EPL_HAD_PROMOTED_ITEMS_GET,
  EPL_ADRATE_STRATEGY_GET,
  EPL_ADRATE_STRATEGY_POST,
  EPL_ADRATE_HISTORY_GET,
  EPL_PLAN_INTERNATIONAL_UPGRADE_POST,
  EPL_PLAN_DOMESTIC_UPGRADE_POST,
  EPL_TRIGGER_MODAL,
  EPL_ADD_USER_STATE,
  EPL_MARKETS_CLOSE_POST,
  EPL_MARKETS_OPEN_POST,
  EPL_MARKETS_PAUSE_POST,
  EPL_MARKETS_RESUME_POST,
  EPL_SET_AD_RATE_FOR_MARKET_PATCH,
  EPL_MARKETS_GET,
  EPL_ALL_MARKETS_GET,
  EPL_CHURN_REASON_POST,
  EPL_MISSING_REPORTS_GET,
  EPL_ITEMS_LOAD,
  EPL_ITEMS_EXCLUDE_BY_FILE_POST,
  EPL_ITEMS_LIST_GET,
  EPL_ITEMS_STATUS_GET,
  EPL_ITEMS_EXCLUDED_RESET_ALL,
  EPL_ITEMS_EXCLUDED_RESET_SELECTED,
  EPL_ITEMS_EXCLUDE_SELECTED,
  EPL_ACCOUNTS_GET,
  EPL_START_TRIAL_POST,
  EPL_START_INTERNATIONAL_TRIAL_POST,
  EPL_START_DOMESTIC_TRIAL_POST,
  EPL_DASHBOARD_REPORT_DATA_GET,
  EPL_START_DOMESTIC_STANDARD_POST,
  EPL_START_INTERNATIONAL_STANDARD_POST,
} from "data/constants"

import { activeEbayAccount } from "data/selectors/epl/activeAccount"
import API from "data/fetch/epl/epl"

export function getCampaignReports(params) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.getCampaignReports(user.id, eiasToken, params)

    return dispatch({
      type: EPL_CAMPAIGN_REPORTS_GET,
      promise,
    })
  }
}

export function getAggregatedCampaignReport(params) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.getAggregatedCampaignReport(user.id, eiasToken, params)

    return dispatch({
      type: EPL_AGGREGATED_CAMPAIGN_REPORT_GET,
      promise,
    })
  }
}

export function getMissingCampaignReports(params) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.getMissingCampaignReports(user.id, eiasToken, params)

    return dispatch({
      type: EPL_MISSING_REPORTS_GET,
      promise,
    })
  }
}

export function getCampaignChartData(params) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.getCampaignChartData(user.id, eiasToken, params)

    return dispatch({
      type: EPL_CAMPAIGN_CHART_DATA_GET,
      promise,
    })
  }
}

export function getTermsStatus() {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.getTermsStatus(user.id, eiasToken)

    return dispatch({
      type: EPL_TOS_GET,
      promise,
    })
  }
}

export function getAvailableSites() {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.getAvailableSites(user.id)

    return dispatch({
      type: EPL_SITES_GET,
      promise,
    })
  }
}

export function getUserOnboarding() {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.getUserOnboarding(user.id)

    return dispatch({
      type: EPL_ONBOARDING_GET,
      promise,
    })
  }
}

export function onboard() {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.onboard(user.id)

    return dispatch({
      type: EPL_ONBOARDING_POST,
      promise,
    })
  }
}

export function optOut(origin) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.optOut(user.id, origin)

    return dispatch({
      type: EPL_OPT_OUT_POST,
      promise,
    })
  }
}

export function getOptOutStatus() {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.getOptOutStatus(user.id)

    return dispatch({
      type: EPL_OPT_OUT_GET,
      promise,
    })
  }
}

export function getHadPromotedItems() {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.getHadPromotedItems(user.id)

    return dispatch({
      type: EPL_HAD_PROMOTED_ITEMS_GET,
      promise,
    })
  }
}

export function getAdRateStrategies() {
  return (dispatch, getState) => {
    const { user, eplCampaign } = getState()
    const promise = API.getAdRateStrategies(
      user.id,
      eplCampaign.data.campaign_id
    )

    return dispatch({
      type: EPL_ADRATE_STRATEGY_GET,
      promise,
    })
  }
}

export function getAdRateHistory() {
  return (dispatch, getState) => {
    const { user, eplCampaign } = getState()
    const promise = API.getAdRateHistory(user.id, eplCampaign.data.campaign_id)

    return dispatch({
      type: EPL_ADRATE_HISTORY_GET,
      promise,
    })
  }
}

export function updateAdRateStrategies(data) {
  return (dispatch, getState) => {
    const { user, eplCampaign } = getState()
    const promise = API.updateAdRateStrategies(
      user.id,
      eplCampaign.data.campaign_id,
      data
    )

    return dispatch({
      type: EPL_ADRATE_STRATEGY_POST,
      promise,
      campaignId: eplCampaign.data.campaign_id,
      campaignName: eplCampaign.data.campaign_name,
      data,
    })
  }
}

export function upgradePlan(origin) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.upgradePlan(user.id, origin)
    const type =
      origin === "wi"
        ? EPL_PLAN_INTERNATIONAL_UPGRADE_POST
        : EPL_PLAN_DOMESTIC_UPGRADE_POST
    return dispatch({
      type,
      promise,
    })
  }
}

export function getMarkets() {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.getMarkets(user.id, eiasToken)

    return dispatch({
      type: EPL_MARKETS_GET,
      promise,
    })
  }
}

export function getAllMarkets() {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.getAllMarkets(user.id, eiasToken)

    return dispatch({
      type: EPL_ALL_MARKETS_GET,
      promise,
    })
  }
}

export function closeMarket(data) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.closeMarket(user.id, eiasToken, data)

    return dispatch({
      type: EPL_MARKETS_CLOSE_POST,
      data,
      promise,
    })
  }
}

export function openMarket(data) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.openMarket(user.id, eiasToken, data)

    return dispatch({
      type: EPL_MARKETS_OPEN_POST,
      data,
      promise,
    })
  }
}

export function pauseMarket(data) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.pauseMarket(user.id, eiasToken, data)

    return dispatch({
      type: EPL_MARKETS_PAUSE_POST,
      data,
      promise,
    })
  }
}

export function resumeMarket(data) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.openMarket(user.id, eiasToken, data)

    return dispatch({
      type: EPL_MARKETS_RESUME_POST,
      data,
      promise,
    })
  }
}

export function setAdRateForMarket(data) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.setAdRateForMarket(user.id, eiasToken, data)

    return dispatch({
      type: EPL_SET_AD_RATE_FOR_MARKET_PATCH,
      data,
      promise,
    })
  }
}

export function loadEplData() {
  return dispatch =>
    dispatch({
      type: EPL_LOAD,
    })
}

export function addEPLUserState(data) {
  return dispatch =>
    dispatch({
      type: EPL_ADD_USER_STATE,
      data,
    })
}

export function triggerModal(modalActionType, modalData) {
  return dispatch =>
    dispatch({
      type: EPL_TRIGGER_MODAL,
      data: {
        modalActionType,
        modalData,
      },
    })
}

export function recordChurnReason(data) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.recordChurnReason(user.id, data)

    return dispatch({
      type: EPL_CHURN_REASON_POST,
      data,
      promise,
    })
  }
}

export function loadEPLItemsData() {
  return dispatch =>
    dispatch({
      type: EPL_ITEMS_LOAD,
    })
}

export function excludeItemsByFile(data) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.excludeItemsByFile(user.id, data)

    return dispatch({
      type: EPL_ITEMS_EXCLUDE_BY_FILE_POST,
      data,
      promise,
    })
  }
}

export function loadItemsList(params) {
  return (dispatch, getState) => {
    const { user, eplExcludingItems } = getState()
    const notNullParams = omitBy(params || eplExcludingItems.queryParams, isNil)
    const properParams = {
      ...notNullParams,
      limit: notNullParams.limit || 10,
    }
    const promise = API.getItemsList(user.id, properParams)

    return dispatch({
      type: EPL_ITEMS_LIST_GET,
      promise,
    })
  }
}

export function loadItemsStatus() {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.getItemsStatus(user.id)

    return dispatch({
      type: EPL_ITEMS_STATUS_GET,
      promise,
    })
  }
}
export function resetAllExcludedItems() {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.resetAllExcludedItems(user.id)

    return dispatch({
      type: EPL_ITEMS_EXCLUDED_RESET_ALL,
      promise,
    })
  }
}

export function resetSelectedExcludedItems(data, params) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.doActionOnSelectedItems(user.id, data)
    return dispatch({
      type: EPL_ITEMS_EXCLUDED_RESET_SELECTED,
      data,
      params,
      promise,
    })
  }
}

export function excludeSelectedItems(data, params) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.doActionOnSelectedItems(user.id, data)
    return dispatch({
      type: EPL_ITEMS_EXCLUDE_SELECTED,
      data,
      params,
      promise,
    })
  }
}

export function getAccounts() {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.getAccounts(user.id)

    return dispatch({
      type: EPL_ACCOUNTS_GET,
      promise,
    })
  }
}

export function startTrial(data) {
  return (dispatch, getState) => {
    const { user, general } = getState()
    const promise = API.startTrial(user.id, data)

    return dispatch({
      type: EPL_START_TRIAL_POST,
      promise,
      activeAccount: general.activeAccount,
    })
  }
}

export function startDomesticTrial(data) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.startTrial(user.id, data)
    return dispatch({
      type: EPL_START_DOMESTIC_TRIAL_POST,
      promise,
    })
  }
}

export function startInternationalTrial(data) {
  return (dispatch, getState) => {
    const { user, general } = getState()
    const promise = API.startTrial(user.id, data)
    return dispatch({
      type: EPL_START_INTERNATIONAL_TRIAL_POST,
      promise,
      activeAccount: general.activeAccount,
    })
  }
}

export function getDashboardReportData() {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state
    const eiasToken = activeEbayAccount(state).eias_token
    const promise = API.getDashboardReportData(
      user.id,
      eiasToken,
      user.currency
    )
    return dispatch({
      type: EPL_DASHBOARD_REPORT_DATA_GET,
      promise,
    })
  }
}

export function startStandard(data) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.startStandard(user.id, data)

    return dispatch({
      type: EPL_START_TRIAL_POST,
      promise,
    })
  }
}

export function startDomesticStandard(data) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.startStandard(user.id, data)
    return dispatch({
      type: EPL_START_DOMESTIC_STANDARD_POST,
      promise,
    })
  }
}

export function startInternationalStandard(data) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = API.startStandard(user.id, data)
    return dispatch({
      type: EPL_START_INTERNATIONAL_STANDARD_POST,
      promise,
    })
  }
}

export default {
  getCampaignReports,
  getAggregatedCampaignReport,
  getTermsStatus,
  getAvailableSites,
  getUserOnboarding,
  getOptOutStatus,
  getHadPromotedItems,
  getAdRateStrategies,
  getAdRateHistory,
  getMarkets,
  getAllMarkets,
  closeMarket,
  openMarket,
  pauseMarket,
  resumeMarket,
  setAdRateForMarket,
  optOut,
  onboard,
  loadEplData,
  updateAdRateStrategies,
  upgradePlan,
  triggerModal,
  addEPLUserState,
  recordChurnReason,
  excludeItemsByFile,
  loadEPLItemsData,
  loadItemsList,
  loadItemsStatus,
  resetAllExcludedItems,
  resetSelectedExcludedItems,
  excludeSelectedItems,
  getAccounts,
  startTrial,
  startDomesticTrial,
  startInternationalTrial,
  getDashboardReportData,
  startStandard,
  startDomesticStandard,
  startInternationalStandard,
}
