import React from "react"
import Cookies from "js-cookie"

import CONFIG from "core/config"
import { useTranslate } from "context/translateContext"

import styles from "./EbayLogin.module.css"

const EbayLogin = () => {
  const { t: translate } = useTranslate()

  const login = event => {
    event.preventDefault()
    Cookies.set("ebay_login", "true")
    window.location.href = `${CONFIG.mother}/ebay/oauth`
    return false
  }

  return (
    <a
      className={styles.root}
      onClick={login}
      href={`${CONFIG.mother}/ebay/oauth`}
    >
      {translate("Login with eBay")}
    </a>
  )
}

export default EbayLogin
