import { filter, findIndex } from "lodash"
import {
  BLACKLIST_GET_LIST_REQUEST,
  BLACKLIST_GET_LIST_SUCCESS,
  BLACKLIST_GET_LIST_FAILURE,
  BLACKLIST_KEYWORD_ADD_SUCCESS,
  BLACKLIST_KEYWORD_EDIT_SUCCESS,
  BLACKLIST_KEYWORD_DELETE_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  count: 0,
  list: [],
  state: "INITIAL",
  error: null,
}

const updateKeyword = (data, existingKeywords) => {
  const keywords = [...existingKeywords]
  const idx = findIndex(keywords, { keyword: data.keyword })
  keywords[idx] = data
  return keywords
}

function shippingProfilesListStore(state = defaultState, action) {
  switch (action.type) {
    case BLACKLIST_GET_LIST_REQUEST:
      return {
        ...state,
        count: 0,
        list: [],
        error: null,
        state: "LOADING",
      }
    case BLACKLIST_GET_LIST_SUCCESS:
      return {
        ...state,
        count: action.data.keywords.length,
        list: action.data.keywords,
        state: "LOADED",
      }
    case BLACKLIST_GET_LIST_FAILURE:
      return {
        ...state,
        count: 0,
        list: [],
        error: action.error,
        state: "FAILED",
      }
    case BLACKLIST_KEYWORD_ADD_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.req.data.keywords],
      }
    case BLACKLIST_KEYWORD_EDIT_SUCCESS:
      return {
        ...state,
        list: updateKeyword(action.data, state.list),
      }
    case BLACKLIST_KEYWORD_DELETE_SUCCESS:
      return {
        ...state,
        list: filter(
          state.list,
          keyword => keyword.keyword !== action.data.keyword
        ),
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default shippingProfilesListStore
