import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  ORDERS_GET_LIST,
  PRODUCT_GET_ORDERS,
  ORDERS_GET_LIST_REQUEST,
  ORDERS_GET_LIST_SUCCESS,
  ORDERS_GET_LIST_FAILURE,
  ORDERS_CANCEL,
} from "../../constants"

import { getAll } from "../../fetch/orders/list"

function* getList() {
  while (true) {
    const action = yield take([ORDERS_GET_LIST, PRODUCT_GET_ORDERS])
    const { user } = yield select()
    yield put({ type: ORDERS_GET_LIST_REQUEST })
    try {
      const { data } = yield call(() =>
        getAll(user, action.platform, action.params)
      )
      if (data) { yield put({ type: ORDERS_GET_LIST_SUCCESS, data: data.data || data }) }
    } catch (error) {
      yield put({ type: ORDERS_GET_LIST_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getList)])

    yield all(tasks)

    yield take(ORDERS_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
