import {
  LOADING_STATES,
  ACCOUNT_UPDATE_BUSINESS_TYPE_REQUEST,
  ACCOUNT_UPDATE_BUSINESS_TYPE_SUCCESS,
  ACCOUNT_UPDATE_BUSINESS_TYPE_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

const defaultState = {
  error: null,
  state: LOADING_STATES.INITIAL,
}

function businessTypeStore(state = defaultState, action) {
  switch (action.type) {
    case ACCOUNT_UPDATE_BUSINESS_TYPE_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      }
    case ACCOUNT_UPDATE_BUSINESS_TYPE_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
      }
    case ACCOUNT_UPDATE_BUSINESS_TYPE_FAILURE:
      return {
        ...state,
        error: action.error,
        state: LOADING_STATES.FAILED,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default businessTypeStore
