import React from "react"
import Helmet from "react-helmet"

import CONFIG from "core/config"
import helmetConfig from "./helmconfig"

// Remove stylesheets because we do not extract them into a css file
// in development mode
if (CONFIG.env !== "production") {
  helmetConfig.link = helmetConfig.link.filter(
    l => l.href !== "/assets/main.css"
  )
}

const Meta = () => (
  <Helmet
    htmlAttributes={{ lang: "en", amp: undefined }}
    titleTemplate="%s"
    meta={helmetConfig.meta}
    link={helmetConfig.link}
    script={[
      {
        type: "application/ld+json",
        innerHTML: `{
          "@context" : "http://schema.org",
          "@type" : "Organization",
          "name" : "Webinterpret",
          "url" : ${CONFIG.mainhost},
        }`,
      },
    ]}
  />
)

export default Meta
