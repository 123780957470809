import { each } from "lodash"

const MAX_TOKENS_COUNT = 2
const PRIORITIES = {
  REQUIRES_ATTENTION: 2,
  BROKEN: 1,
}

export const getTokenWithStatus = token => {
  let status = "ACTIVE"
  if (token.state !== "A") {
    status = token.type === "R" ? "BROKEN" : "REQUIRES_ATTENTION"
  }
  return {
    ...token,
    status,
  }
}

export const getIssuePriority = issue => PRIORITIES[issue]

export const getAccountsIssues = accounts => {
  const list = []
  accounts.ebay.forEach(({ account_id: accountId, username, tokens }) => {
    let issue = null
    each(tokens, token => {
      if (token.status !== "ACTIVE") {
        if (!issue || PRIORITIES[issue] > PRIORITIES[token.status]) {
          issue = token.status
        }
      }
    })

    if (tokens.some(tkn => tkn.status === "ACTIVE") && issue === "BROKEN") {
      // If we have a broken issue, but some token is ACTIVE
      issue = "REQUIRES_ATTENTION_2"
    }

    // Set issues if we have less than [maxTokensCount] tokens
    if (tokens.length < MAX_TOKENS_COUNT) {
      // If we have less than maximum,
      // it means that user should add additional token to help sync
      if (issue !== "BROKEN") {
        issue = "REQUIRES_ATTENTION"
      }
      // If we have no tokens at all,
      // it means that tokens are broken and we need to add them
      if (tokens.length === 0) {
        issue = "BROKEN"
      }
    }

    if (issue) {
      list.push({
        accountId,
        username,
        type: `ACCOUNT_${issue}`,
        priority: getIssuePriority(issue),
      })
    }
  })

  return list
}

export default {
  getTokenWithStatus,
  getAccountsIssues,
}
