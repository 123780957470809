import { createSelector } from "reselect"
import { get, map, isEmpty, without, difference, uniq, toNumber } from "lodash"

import CONFIG from "core/config"

import { getSiteKeyList, getCountryBySite } from "shared/services/ebay"
import { isUserEarlyBird } from "shared/services/plans"
import { getLanguageByCountry } from "shared/services/languages"

const getUser = state => state.user

const getActiveAccount = state => state.general.activeAccount

const getUserLangs = state =>
  uniq(state.user.langs.map(lang => lang.split("-")[0].toUpperCase()))

const getActivePlatform = state => state.general.activePlatform

const getUserPlatforms = state => state.user.platforms

const getUserPlans = state => state.user.plans

const getMarketUsage = state =>
  state.productSettings.countryUsage && state.productSettings.countryUsage.value

const getAccountSite = state =>
  state.user.platforms[state.general.activePlatform].accounts[0].site

export const getEbaySite = state =>
  state.user.platforms.ebay && state.user.platforms.ebay.accounts[0].site

export const getCountry = state => state.user.country

export const getEbayState = state =>
  state.user.platforms.ebay && state.user.platforms.ebay.state

export const getUserId = state => toNumber(state.user.id)

export const getUserCountry = state => state.user.country

export const getUserEmail = state => state.user.email

export const getUserZuoraAccountId = state => state.user.zuoraAccountId

const getUILanguage = state => state.i18nState.lang

const getUserOnboardings = state =>
  state.multichannelOnboarding.ongoingOnboardings

const getEbayAccountIds = state => {
  const authAccounts =
    state.user.platforms && state.user.platforms.ebay
      ? state.user.platforms.ebay.accounts.map(acc => acc.id)
      : []
  const accountsList =
    state.accountsList && state.accountsList.ebay
      ? state.accountsList.ebay.map(acc => acc.account_id)
      : []
  return uniq([...authAccounts, ...accountsList])
}

export const getProgramme = state =>
  state.user.platforms[state.general.activePlatform].programme

export const validAccounts = createSelector(
  getUserId,
  getEbayAccountIds,
  (userId, ebayIds) => ({
    all: [userId],
    ebay: isEmpty(ebayIds) ? [userId] : ebayIds,
    amazon: [userId],
    cdiscount: [userId],
  })
)

export const platformSites = createSelector(
  getActivePlatform,
  getUserPlatforms,
  (active, platforms) =>
    (platforms[active] && map(platforms[active].sites, "site")) || []
)

export const isEarlyBird = createSelector(getUser, isUserEarlyBird)

export const getUserMarkets = createSelector(
  getAccountSite,
  getMarketUsage,
  (accountSite, activeMarkets) => {
    const totalList = without(getSiteKeyList(), accountSite)

    const markets = activeMarkets ? activeMarkets.map(o => o.site) : []

    const disabledMarkets = difference(totalList, markets)

    return {
      enabled: markets,
      disabled: disabledMarkets,
    }
  }
)

export const getMarketEntitlements = createSelector(
  getUser,
  getActivePlatform,
  getEbaySite,
  getActiveAccount,
  getEbayAccountIds,
  (user, activePlatform, domesticSite, activeEbayAccount, ebayAccountIDs) => {
    const { siteEntitlements } = user

    if (activePlatform !== "ebay" || isEmpty(siteEntitlements)) return null

    const ebayID = activeEbayAccount || get(ebayAccountIDs, "0")

    const isEbayIDValid = (ebayAccountIDs || []).includes(ebayID)

    if (!isEbayIDValid) return null

    let availableMarkets = siteEntitlements[ebayID]
      .filter(
        siteEntitlement =>
          siteEntitlement.account_site_entitlements.site_available
      )
      .map(({ site }) => site)

    availableMarkets = without(availableMarkets, domesticSite)

    const unavailableMarkets = siteEntitlements[ebayID]
      .filter(
        siteEntitlement =>
          !siteEntitlement.account_site_entitlements.site_available
      )
      .map(({ site }) => site)

    return {
      enabled: availableMarkets,
      disabled: unavailableMarkets,
    }
  }
)

export const getEntitledSitesTotal = createSelector(
  getMarketEntitlements,
  marketEntitlements => {
    if (!marketEntitlements) return 0
    return marketEntitlements.enabled.length
  }
)

export const getCurrentPlanPrice = createSelector(getUserPlans, plans => {
  if (!plans || plans.length !== 1) return {}
  return plans[0].price || {}
})

export const getUserBaseLanguage = createSelector(
  getUser,
  getUILanguage,
  (user, UILanguage) => {
    const language = isEmpty(user.country)
      ? UILanguage
      : getLanguageByCountry(user.country)
    return language.split("-")[0].toUpperCase()
  }
)

export const getEbayLanguage = createSelector(getEbaySite, site => {
  const country = getCountryBySite(site)
  const language = getLanguageByCountry(country)
  return language
})

export const getAdminPortalURL = state => {
  const adminURL = state.user.adminUrl
    ? `//${state.user.adminUrl}`
    : CONFIG.portal
  return `${adminURL}/admin/become_admin_user_back_again`
}

export const getCurrentEPLPlatformState = (state, origin) => {
  const { platformStates } = state.user
  const platform = origin === "customer" ? "epl-domestic" : "epl"
  const eplStates = (platformStates || []).filter(
    platformState => platformState.user_parameters.platform === platform
  )

  if (!eplStates.length) {
    return {}
  }

  const currentStateLetter = eplStates[0].user_parameters.current_state
  const matchingStates = eplStates.filter(
    eplState => eplState.state === currentStateLetter
  )
  const currentState = matchingStates.sort((firstState, secondState) => {
    const firstStateDate = new Date(firstState.start)
    const secondStateDate = new Date(secondState.start)
    return secondStateDate - firstStateDate
  })[0]

  return currentState || {}
}

export const getOngoingOnboardingPlatforms = createSelector(
  getUserOnboardings,
  userOnboardings =>
    userOnboardings.reduce(
      (platforms, onboarding) =>
        onboarding.is_ongoing ? [...platforms, onboarding.channel] : platforms,
      []
    )
)

export const getIsUserEligibleToStartOnboarding = createSelector(
  getUser,
  getUserOnboardings,
  (user, userOnboardings) =>
    user.isPaying ||
    ((user.isPlatformless || user.isReturningAmazonUser) &&
      isEmpty(userOnboardings))
)

export default {
  validAccounts,
  platformSites,
  isEarlyBird,
  getUserMarkets,
  getUserId,
  getUserZuoraAccountId,
  getUserEmail,
  getUserCountry,
  getCurrentPlanPrice,
  getUserBaseLanguage,
  getEbayLanguage,
  getMarketEntitlements,
  getEntitledSitesTotal,
  getEbaySite,
  getEbayState,
  getCountry,
  getUserLangs,
  getAdminPortalURL,
  getCurrentEPLPlatformState,
  getOngoingOnboardingPlatforms,
  getIsUserEligibleToStartOnboarding,
}
