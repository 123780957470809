const LEGACY_PLANS_PREFIXES = [
  "Basic",
  "Enterprise",
  "Gold",
  "Pay",
  "Platinum",
  "Premium",
  "ProSeller",
  "Silver",
  "Pro",
]

export default function getLegacyPlanName(planName) {
  return LEGACY_PLANS_PREFIXES.find(prefix =>
    planName.startsWith(prefix.toLowerCase())
  )
}
