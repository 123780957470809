import {
  PROFILE_GET_FINANCIAL_DETAILS_REQUEST,
  PROFILE_GET_FINANCIAL_DETAILS_SUCCESS,
  PROFILE_GET_FINANCIAL_DETAILS_FAILURE,
  PROFILE_UPDATE_FINANCIAL_DETAILS_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

import { isNorthAmericaCountry } from "shared/services/countries"
import { getProvinceCodeByName } from "shared/services/provinces"

const defaultState = {
  data: null,
  loading: false,
  error: null,
}

function profileFinancialDetailsStore(state = defaultState, action) {
  switch (action.type) {
    case PROFILE_GET_FINANCIAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PROFILE_GET_FINANCIAL_DETAILS_SUCCESS:
    case PROFILE_UPDATE_FINANCIAL_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...action.data,
          address: {
            ...action.data.address,
            ...setStateOrProvince(
              action.data.address.country,
              action.data.address.state_or_province
            ),
          },
        },
        loading: false,
      }
    case PROFILE_GET_FINANCIAL_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error.error,
        loading: false,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

const setStateOrProvince = (country, stateOrProvince) =>
  isNorthAmericaCountry(country)
    ? { state: getProvinceCodeByName(country, stateOrProvince) }
    : { province: stateOrProvince }

export default profileFinancialDetailsStore
