import { all, put, fork, take, takeLatest, call } from "redux-saga/effects"

import {
  PROFILE_GET_TOOLS,
  PROFILE_GET_TOOLS_REQUEST,
  PROFILE_GET_TOOLS_SUCCESS,
  PROFILE_GET_TOOLS_FAILURE,
  PROFILE_UPDATE_TOOLS_ACTIVE_SUCCESS,
  TRIAL_START_GET,
} from "data/constants"

import history from "core/history"

import { getActive } from "data/actions/profile/thirdPartyTools"
import { getList } from "data/fetch/profile/thirdPartyTools"

import { getToolRequirement } from "shared/services/thirdPartyTools"

function* getToolList() {
  while (true) {
    yield take([PROFILE_GET_TOOLS])
    yield put({ type: PROFILE_GET_TOOLS_REQUEST })
    try {
      const { data } = yield call(() => getList())

      const tools = data.map(tool => ({
        ...tool,
        requirement: getToolRequirement(tool.tool_code),
      }))
      const toolOptions = tools.map(tool => ({ label: tool.tool_name, value: tool.tool_code }))

      if (data) yield put({ type: PROFILE_GET_TOOLS_SUCCESS, data: { tools, toolOptions } })
    } catch (error) {
      yield put({ type: PROFILE_GET_TOOLS_FAILURE, error })
    }
  }
}

function* updateTool() {
  while (true) {
    const action = yield take([PROFILE_UPDATE_TOOLS_ACTIVE_SUCCESS])

    const redirectionMap = {
      C: "/user/profile/third-party-tools/channeladvisor",
    }
    const destination = redirectionMap[action.req.tool_code]

    if (destination) {
      history.push(destination)
    }
  }
}

function* getCurrentTool() {
  yield put(getActive())
}

export default function* main() {
  const tasks = yield all([
    fork(getToolList),
    fork(updateTool),
    takeLatest([PROFILE_GET_TOOLS_SUCCESS, TRIAL_START_GET], getCurrentTool),
  ])

  yield all(tasks)
}
