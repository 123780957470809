import { all, put, select, call, fork, take } from "redux-saga/effects"
import { find } from "lodash"

import {
  SHIPPING_PROFILE_GET,
  SHIPPING_PROFILE_GET_REQUEST,
  SHIPPING_PROFILE_GET_SUCCESS,
  SHIPPING_PROFILE_GET_FAILURE,
  PRODUCTS_GET_SHIPPING,
  SHIPPING_PROFILE_CANCEL,
} from "../../constants"

import { get as getProfile } from "../../fetch/shippingProfiles/single"
import { get as getRates } from "../../fetch/shippingProfiles/rates"

function* get() {
  while (true) {
    const action = yield take([SHIPPING_PROFILE_GET])
    const { user } = yield select()
    yield put({ type: SHIPPING_PROFILE_GET_REQUEST })
    try {
      const { data } = yield call(() => getProfile(user.id, action.id))
      const { data: rates } = yield call(() => getRates(user.id, action.id))
      if (data) {
        yield put({
          type: SHIPPING_PROFILE_GET_SUCCESS,
          data: {
            ...data[0],
            regions: data[0].regions.map(r => ({
              ...r,
              ...find(rates, { code: r.code }),
            })),
          },
        })
        // GET PROFILE ITEMS
        yield put({
          type: PRODUCTS_GET_SHIPPING,
          items: [data[0].profile.profile_id],
        })
      }
    } catch (error) {
      yield put({ type: SHIPPING_PROFILE_GET_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(get)])

    yield all(tasks)

    yield take(SHIPPING_PROFILE_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
