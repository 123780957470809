export default {
  mainhost: "//www.webinterpret.com",
  portal: "//wi-mother-portal.marketplaces.int.testenv.wi-k8s.com",
  portalAdminEbay: "//wi-mother-admin.marketplaces.int.testenv.wi-k8s.com",
  portalAdminAmazon: "//wi-amazon-portal.marketplaces.int.testenv.wi-k8s.com",
  host: "//wi-facade-testenv-k8s.devwebinterpret.com",
  gatewayHost: "//feature-gateway.devwebinterpret.com",
  shippingHost: "//wi-shipping.testenv.devwebinterpret.com",
  mother: "//wi-mother-public.marketplaces.ext.testenv.wi-k8s.com",

  env: "testenv",
  release: "1",

  zendeskHost: "//webinterpret.zendesk.com",
}
