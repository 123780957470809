import {
  SHIPPING_PROFILES_GET_LIST_REQUEST,
  SHIPPING_PROFILES_GET_LIST_SUCCESS,
  SHIPPING_PROFILES_GET_LIST_FAILURE,
  SHIPPING_PROFILE_CREATE_SUCCESS,
  SHIPPING_PROFILE_DELETE_SUCCESS,
  PRODUCTS_GET_SHIPPING_SUCCESS,
  SHIPPING_PROFILES_CANCEL,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  count: 0,
  list: [],
  state: "INITIAL",
  error: null,
}

function shippingProfilesStore(state = defaultState, action) {
  switch (action.type) {
    case SHIPPING_PROFILES_GET_LIST_REQUEST:
      return {
        ...state,
        count: 0,
        list: [],
        error: null,
        state: "LOADING",
      }
    case SHIPPING_PROFILES_GET_LIST_SUCCESS:
      return {
        ...state,
        count: action.custom.length,
        list: action.custom.map(p => ({
          ...p,
          items: [],
        })),
        state: "LOADED",
      }
    case SHIPPING_PROFILES_GET_LIST_FAILURE:
      return {
        ...state,
        count: 0,
        list: [],
        error: action.error,
        state: "FAILED",
      }
    case SHIPPING_PROFILE_CREATE_SUCCESS:
      return {
        ...state,
        list: [
          ...state.list,
          {
            ...action.req,
            items: [], // A new profile as no items assigned at the start. We might make this a saga insted.
          },
        ],
      }
    case SHIPPING_PROFILE_DELETE_SUCCESS:
      return {
        ...state,
        count: state.count - 1,
        list: state.list.filter(
          p => p.profile.profile_id !== action.data.profile.profile_id
        ),
      }
    case PRODUCTS_GET_SHIPPING_SUCCESS:
      return {
        ...state,
        list: state.list.map(p => ({
          ...p,
          items: action.data[p.profile.profile_id],
        })),
      }
    case SHIPPING_PROFILES_CANCEL:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default shippingProfilesStore
