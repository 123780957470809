import { get } from "lodash"
import {
  USER_LOGOUT_SUCCESS,
  EPL_ONBOARDING_GET_SUCCESS,
  EPL_ONBOARDING_POST_SUCCESS,
  LOADING_STATES,
  EPL_LOAD,
  EPL_LOAD_SUCCESS,
  EPL_LOAD_FAILURE,
  EPL_OPT_OUT_POST_SUCCESS,
  EPL_OPT_OUT_GET_SUCCESS,
  EPL_HAD_PROMOTED_ITEMS_GET_SUCCESS,
  EPL_ACCOUNTS_GET_SUCCESS,
  EPL_ACCOUNTS_GET_FAILURE,
  EPL_START_TRIAL_POST_SUCCESS,
  EPL_START_STANDARD_POST_SUCCESS,
  EPL_DATA_FOR_ACCOUNTS_VIEW,
  EPL_DATA_FOR_ACCOUNTS_VIEW_SUCCESS,
  EPL_DATA_FOR_ACCOUNTS_VIEW_FAILURE,
  ENTITLEMENTS_CUSTOM_GET_SUCCESS,
  ELIGIBILITY_STATUS,
  EPL_CHECK_ELIGIBILITY_SUCCESS,
  EPL_CHECK_ELIGIBILITY_FAILURE,
  EPL_ONBOARDING_SHOW_PLANS,
  GENERAL_SET_ACTIVE_ACCOUNT,
} from "data/constants"
import { isUserEligibleForEbayMarketing } from "shared/helpers/eligibility"

const originMap = {
  WI: "wi",
  CUSTOMER: "customer",
}

const NOT_OPTING_OUT = "not_opting_out"

const defaultState = {
  isSellerOnboarded: false,
  canSellerSignUp: false,
  eligibilityStatus: ELIGIBILITY_STATUS.NOT_PROCESSED,
  loadingState: LOADING_STATES.INITIAL,
  error: null,
  hadPromotedItems: false,
  optOutStatusDomestic: NOT_OPTING_OUT,
  optOutStatusInternational: NOT_OPTING_OUT,
}

const eplStore = (state = defaultState, action) => {
  switch (action.type) {
    case EPL_ONBOARDING_POST_SUCCESS:
      return {
        ...state,
        isSellerOnboarded: true,
        domesticCustomerStatus: getCustomerStatusByOrigin(
          action.req?.statuses || [],
          originMap.CUSTOMER
        ),
        internationalCustomerStatus: getCustomerStatusByOrigin(
          action.req?.statuses || [],
          originMap.WI
        ),
      }
    case EPL_ONBOARDING_GET_SUCCESS:
      return {
        ...state,
        ...updateOnOnboardingGet(action),
      }
    case EPL_OPT_OUT_POST_SUCCESS:
      return {
        ...state,
        optOutStatusDomestic: "pending",
        optOutStatusInternational: "pending",
      }
    case EPL_OPT_OUT_GET_SUCCESS:
      return {
        ...state,
        trialEndDomestic: action.req.customer.trial_end,
        optOutStatusDomestic: action.req.customer.optOutStatus,
        trialEndInternational: action.req.wi.trial_end,
        optOutStatusInternational: action.req.wi.optOutStatus,
      }
    case EPL_LOAD:
    case GENERAL_SET_ACTIVE_ACCOUNT:
      return {
        ...state,
        loadingState: LOADING_STATES.LOADING,
      }
    case EPL_LOAD_SUCCESS:
      return {
        ...state,
        loadingState: LOADING_STATES.LOADED,
      }
    case EPL_HAD_PROMOTED_ITEMS_GET_SUCCESS:
      return {
        ...state,
        hadPromotedItems: action.req.had_promotion,
      }
    case EPL_LOAD_FAILURE:
      return {
        ...state,
        loadingState: LOADING_STATES.FAILED,
      }
    case EPL_ACCOUNTS_GET_SUCCESS:
      return {
        ...state,
        eligibilityStatus:
          isUserEligibleForEbayMarketing(action?.req?.accounts) ||
          ELIGIBILITY_STATUS.NOT_ELIGIBLE,
        accounts: get(action.req, "accounts", []),
      }
    case EPL_ACCOUNTS_GET_FAILURE:
      return {
        ...state,
        eligibilityStatus: ELIGIBILITY_STATUS.NOT_PROCESSED,
      }
    case EPL_START_TRIAL_POST_SUCCESS:
    case EPL_START_STANDARD_POST_SUCCESS:
      return {
        ...state,
        accounts: [
          {
            ...state.accounts[
              state.accounts.findIndex(
                account => account.id === action.activeAccount
              )
            ],
            show_upsell_banner: {
              wi: false,
              customer: false,
            },
          },
        ],
      }
    case ENTITLEMENTS_CUSTOM_GET_SUCCESS:
      return {
        ...state,
        canSellerSignUp:
          action.data.can_onboard_to_epl ||
          action.data.can_onboard_to_epl_domestic ||
          action.data.can_use_epl_trial ||
          action.data.can_use_epl_trial_domestic,
      }
    case EPL_CHECK_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        eligibilityStatus:
          isUserEligibleForEbayMarketing(action?.req?.accounts) ||
          ELIGIBILITY_STATUS.NOT_ELIGIBLE,
        accounts: get(action.data, "accounts", []),
      }

    case EPL_CHECK_ELIGIBILITY_FAILURE:
      return {
        ...state,
        eligibilityStatus: "error",
      }

    case EPL_ONBOARDING_SHOW_PLANS:
      return {
        ...state,
        isSellerReadyToSeePlans: true,
      }
    case EPL_DATA_FOR_ACCOUNTS_VIEW:
      return {
        ...state,
        eligibilityListStatus: LOADING_STATES.LOADING,
      }
    case EPL_DATA_FOR_ACCOUNTS_VIEW_SUCCESS:
    case EPL_DATA_FOR_ACCOUNTS_VIEW_FAILURE:
      return {
        ...state,
        eligibilityListStatus: LOADING_STATES.LOADED,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

const updateOnOnboardingGet = action => {
  const domesticCustomerStatus = getCustomerStatusByOrigin(
    action.req?.statuses || [],
    originMap.CUSTOMER
  )
  const internationalCustomerStatus = getCustomerStatusByOrigin(
    action.req?.statuses || [],
    originMap.WI
  )
  return {
    domesticCustomerStatus,
    internationalCustomerStatus,
    isSellerOnboarded:
      domesticCustomerStatus !== undefined ||
      internationalCustomerStatus !== undefined,
  }
}

const getCustomerStatusByOrigin = (statuses, origin) =>
  statuses.find(el => el.origin === origin)?.status

export default eplStore
