import { all, select, put, fork, take } from "redux-saga/effects"
import { find, difference, keys } from "lodash"

import {
  ACCOUNT_GET,
  ACCOUNT_GET_REQUEST,
  ACCOUNT_GET_SUCCESS,
  ACCOUNT_GET_FAILURE,
  ACCOUNT_CANCEL,
} from "../../constants"

function* getAccount() {
  while (true) {
    const action = yield take(ACCOUNT_GET)
    const { accountsList, user } = yield select()
    yield put({ type: ACCOUNT_GET_REQUEST })
    const account = find(accountsList.ebay, {
      account_id: parseInt(action.id, 10),
    })
    if (!account) {
      yield put({ type: ACCOUNT_GET_FAILURE, error: "ACCOUNT_NOT_FOUND" })
    } else {
      const platformSites = user.platforms.ebay
        ? user.platforms.ebay.sites.map(s => s.site)
        : []
      const notActiveMarkets = difference(
        platformSites,
        keys(account.listed_in)
      )
      yield put({
        type: ACCOUNT_GET_SUCCESS,
        data: {
          ...account,
          not_listed: notActiveMarkets,
        },
      })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getAccount)])

    yield all(tasks)

    yield take([ACCOUNT_CANCEL])
    yield all(tasks.map(task => task.cancel()))
  }
}
