import {
  LOADING_STATES,
  PROFILE_GET_CONTACT_REQUEST,
  PROFILE_GET_CONTACT_SUCCESS,
  PROFILE_GET_CONTACT_FAILURE,
  PROFILE_UPDATE_CONTACT_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  data: null,
  state: LOADING_STATES.INITIAL,
  error: null,
}

function profileLoginPasswordStore(state = defaultState, action) {
  switch (action.type) {
    case PROFILE_GET_CONTACT_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      }
    case PROFILE_GET_CONTACT_SUCCESS:
      return {
        ...state,
        data: action.data,
        state: LOADING_STATES.LOADED,
      }
    case PROFILE_UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        data: action.data,
      }
    case PROFILE_GET_CONTACT_FAILURE:
      return {
        ...state,
        error: action.error,
        state: LOADING_STATES.FAILED,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default profileLoginPasswordStore
