import { find, filter, isNull } from "lodash"
import {
  PRODUCT_GET_TRANSLATIONS_REQUEST,
  PRODUCT_GET_TRANSLATIONS_SUCCESS,
  PRODUCT_GET_TRANSLATIONS_FAILURE,
  PRODUCT_UPDATE_TRANSLATIONS_SUCCESS,
  PRODUCT_CLEAR,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  list: [],
  state: "INITIAL",
  error: null,
}

function productSummaryStore(state = defaultState, action) {
  switch (action.type) {
    case PRODUCT_GET_TRANSLATIONS_REQUEST:
      return {
        ...state,
        list: [],
        error: null,
        state: "LOADING",
      }
    case PRODUCT_GET_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        list: filter(
          action.data,
          translation => !isNull(translation.automatic_title_translation)
        ),
        state: "LOADED",
      }
    case PRODUCT_GET_TRANSLATIONS_FAILURE:
      return {
        ...state,
        list: [],
        error: action.error,
        state: "FAILED",
      }
    case PRODUCT_UPDATE_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        list: state.list.map(translation => {
          const newTrans = find(action.data, { site: translation.site })
          if (!isNull(newTrans.translation)) {
            return {
              ...translation,
              origin: "manual",
              manual_title_translation: {
                by_customer: true,
                translation: newTrans.translation,
              },
            }
          }
          return {
            ...translation,
            origin: "automatic",
            manual_title_translation: null,
          }
        }),
      }
    case PRODUCT_CLEAR:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default productSummaryStore
