import { find, chain, each, pull } from "lodash"
import qs from "query-string"
import Cookies from "js-cookie"
import dayjs from "shared/services/date"
import "dayjs/locale/de"
import "dayjs/locale/fr"
import "dayjs/locale/es"
import "dayjs/locale/it"
import "dayjs/locale/zh-cn"

const browserLanguagePropertyKeys = [
  "languages",
  "language",
  "browserLanguage",
  "userLanguage",
  "systemLanguage",
]

const LANGS_LIST = [
  { value: "en", name: "English" },
  { value: "de", name: "Deutsch" },
  { value: "fr", name: "Français" },
  { value: "it", name: "Italiano" },
  { value: "zh", name: "中文" },
  { value: "es", name: "Español" },
]

const availableLanguages = LANGS_LIST.map(l => l.value)

const languages = [
  { name: "Abkhaz", code: "ab" },
  { name: "Afar", code: "aa" },
  { name: "Afrikaans", code: "af" },
  { name: "Akan", code: "ak" },
  { name: "Albanian", code: "sq" },
  { name: "Amharic", code: "am" },
  { name: "Arabic", code: "ar" },
  { name: "Aragonese", code: "an" },
  { name: "Armenian", code: "hy" },
  { name: "Assamese", code: "as" },
  { name: "Avaric", code: "av" },
  { name: "Avestan", code: "ae" },
  { name: "Aymara", code: "ay" },
  { name: "Azerbaijani", code: "az" },
  { name: "Bambara", code: "bm" },
  { name: "Bashkir", code: "ba" },
  { name: "Basque", code: "eu" },
  { name: "Belarusian", code: "be" },
  { name: "Bengali", code: "bn" },
  { name: "Bihari", code: "bh" },
  { name: "Bislama", code: "bi" },
  { name: "Bosnian", code: "bs" },
  { name: "Breton", code: "br" },
  { name: "Bulgarian", code: "bg" },
  { name: "Burmese", code: "my" },
  { name: "Catalan; Valencian", code: "ca" },
  { name: "Chamorro", code: "ch" },
  { name: "Chechen", code: "ce" },
  { name: "Chichewa; Chewa; Nyanja", code: "ny" },
  { name: "Chinese", code: "zh" },
  { name: "Chuvash", code: "cv" },
  { name: "Cornish", code: "kw" },
  { name: "Corsican", code: "co" },
  { name: "Cree", code: "cr" },
  { name: "Croatian", code: "hr" },
  { name: "Czech", code: "cs" },
  { name: "Danish", code: "da" },
  { name: "Divehi; Dhivehi; Maldivian;", code: "dv" },
  { name: "Dutch", code: "nl" },
  { name: "English", code: "en" },
  { name: "Esperanto", code: "eo" },
  { name: "Estonian", code: "et" },
  { name: "Ewe", code: "ee" },
  { name: "Faroese", code: "fo" },
  { name: "Fijian", code: "fj" },
  { name: "Finnish", code: "fi" },
  { name: "French", code: "fr" },
  { name: "Fula; Fulah; Pulaar; Pular", code: "ff" },
  { name: "Galician", code: "gl" },
  { name: "Georgian", code: "ka" },
  { name: "German", code: "de" },
  { name: "Greek, Modern", code: "el" },
  { name: "Guaraní", code: "gn" },
  { name: "Gujarati", code: "gu" },
  { name: "Haitian; Haitian Creole", code: "ht" },
  { name: "Hausa", code: "ha" },
  { name: "Hebrew (modern)", code: "he" },
  { name: "Herero", code: "hz" },
  { name: "Hindi", code: "hi" },
  { name: "Hiri Motu", code: "ho" },
  { name: "Hungarian", code: "hu" },
  { name: "Interlingua", code: "ia" },
  { name: "Indonesian", code: "id" },
  { name: "Interlingue", code: "ie" },
  { name: "Irish", code: "ga" },
  { name: "Igbo", code: "ig" },
  { name: "Inupiaq", code: "ik" },
  { name: "Ido", code: "io" },
  { name: "Icelandic", code: "is" },
  { name: "Italian", code: "it" },
  { name: "Inuktitut", code: "iu" },
  { name: "Japanese", code: "ja" },
  { name: "Javanese", code: "jv" },
  { name: "Kalaallisut, Greenlandic", code: "kl" },
  { name: "Kannada", code: "kn" },
  { name: "Kanuri", code: "kr" },
  { name: "Kashmiri", code: "ks" },
  { name: "Kazakh", code: "kk" },
  { name: "Khmer", code: "km" },
  { name: "Kikuyu, Gikuyu", code: "ki" },
  { name: "Kinyarwanda", code: "rw" },
  { name: "Kirghiz, Kyrgyz", code: "ky" },
  { name: "Komi", code: "kv" },
  { name: "Kongo", code: "kg" },
  { name: "Korean", code: "ko" },
  { name: "Kurdish", code: "ku" },
  { name: "Kwanyama, Kuanyama", code: "kj" },
  { name: "Latin", code: "la" },
  { name: "Luxembourgish, Letzeburgesch", code: "lb" },
  { name: "Luganda", code: "lg" },
  { name: "Limburgish, Limburgan, Limburger", code: "li" },
  { name: "Lingala", code: "ln" },
  { name: "Lao", code: "lo" },
  { name: "Lithuanian", code: "lt" },
  { name: "Luba-Katanga", code: "lu" },
  { name: "Latvian", code: "lv" },
  { name: "Manx", code: "gv" },
  { name: "Macedonian", code: "mk" },
  { name: "Malagasy", code: "mg" },
  { name: "Malay", code: "ms‎" },
  { name: "Malayalam", code: "ml" },
  { name: "Maltese", code: "mt" },
  { name: "Māori", code: "mi" },
  { name: "Marathi (Marāṭhī)", code: "mr" },
  { name: "Marshallese", code: "mh" },
  { name: "Mongolian", code: "mn" },
  { name: "Nauru", code: "na" },
  { name: "Navajo, Navaho", code: "nv" },
  { name: "Norwegian Bokmål", code: "nb" },
  { name: "North Ndebele", code: "nd" },
  { name: "Nepali", code: "ne" },
  { name: "Ndonga", code: "ng" },
  { name: "Norwegian Nynorsk", code: "nn" },
  { name: "Norwegian", code: "no" },
  { name: "Nuosu", code: "ii" },
  { name: "South Ndebele", code: "nr" },
  { name: "Occitan", code: "oc" },
  { name: "Ojibwe, Ojibwa", code: "oj" },
  {
    name:
      "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    code: "cu",
  },
  { name: "Oromo", code: "om" },
  { name: "Oriya", code: "or" },
  { name: "Ossetian, Ossetic", code: "os" },
  { name: "Panjabi, Punjabi", code: "pa‎" },
  { name: "Pāli", code: "pi" },
  { name: "Persian", code: "fa" },
  { name: "Polish", code: "pl" },
  { name: "Pashto, Pushto", code: "ps" },
  { name: "Portuguese", code: "pt" },
  { name: "Quechua", code: "qu" },
  { name: "Romansh", code: "rm" },
  { name: "Kirundi", code: "rn" },
  { name: "Romanian, Moldavian, Moldovan", code: "ro" },
  { name: "Russian", code: "ru" },
  { name: "Sanskrit (Saṁskṛta)", code: "sa" },
  { name: "Sardinian", code: "sc" },
  { name: "Sindhi", code: "sd" },
  { name: "Northern Sami", code: "se" },
  { name: "Samoan", code: "sm" },
  { name: "Sango", code: "sg" },
  { name: "Serbian", code: "sr" },
  { name: "Scottish Gaelic; Gaelic", code: "gd" },
  { name: "Shona", code: "sn" },
  { name: "Sinhala, Sinhalese", code: "si" },
  { name: "Slovak", code: "sk" },
  { name: "Slovene", code: "sl" },
  { name: "Somali", code: "so" },
  { name: "Southern Sotho", code: "st" },
  { name: "Spanish; Castilian", code: "es" },
  { name: "Sundanese", code: "su" },
  { name: "Swahili", code: "sw" },
  { name: "Swati", code: "ss" },
  { name: "Swedish", code: "sv" },
  { name: "Tamil", code: "ta" },
  { name: "Telugu", code: "te" },
  { name: "Tajik", code: "tg" },
  { name: "Thai", code: "th" },
  { name: "Tigrinya", code: "ti" },
  { name: "Tibetan Standard, Tibetan, Central", code: "bo" },
  { name: "Turkmen", code: "tk" },
  { name: "Tagalog", code: "tl" },
  { name: "Tswana", code: "tn" },
  { name: "Tonga (Tonga Islands)", code: "to" },
  { name: "Turkish", code: "tr" },
  { name: "Tsonga", code: "ts" },
  { name: "Tatar", code: "tt" },
  { name: "Twi", code: "tw" },
  { name: "Tahitian", code: "ty" },
  { name: "Uighur, Uyghur", code: "ug‎" },
  { name: "Ukrainian", code: "uk" },
  { name: "Urdu", code: "ur" },
  { name: "Uzbek", code: "uz‎" },
  { name: "Venda", code: "ve" },
  { name: "Vietnamese", code: "vi" },
  { name: "Volapük", code: "vo" },
  { name: "Walloon", code: "wa" },
  { name: "Welsh", code: "cy" },
  { name: "Wolof", code: "wo" },
  { name: "Western Frisian", code: "fy" },
  { name: "Xhosa", code: "xh" },
  { name: "Yiddish", code: "yi" },
  { name: "Yoruba", code: "yo" },
  { name: "Zhuang, Chuang", code: "za" },
]

export function getLanguagesList() {
  return LANGS_LIST
}

export function changeDayjsLocale(locale) {
  const mapLocale = {
    en: "en",
    fr: "fr",
    es: "es",
    de: "de",
    zh: "zh-cn",
  }
  dayjs.locale(mapLocale[locale] || locale)
}

export function detectedNavigatorLocale() {
  return chain(window.navigator)
    .pick(browserLanguagePropertyKeys) // Get only language properties
    .values() // Get values of the properties
    .flatten() // flatten all arrays
    .compact() // Remove undefined values
    .value()
}

export function getUserLanguages(additionalLanguages) {
  const langs = []
  // Get params from url
  const query = "" || qs.parse(location.search) // eslint-disable-line
  // Get cookies
  const cookie = null || Cookies.get("lang")
  // Add to list lang argument in query
  if (query.lang) langs.push(query.lang)
  // Add to list lang value in cookie
  if (cookie) langs.push(cookie)
  // Add to list of additional languages if provided.
  // Should have less priority than query params or cookies
  if (additionalLanguages) langs.push(...additionalLanguages)
  // Add to list navigator languages and default language
  langs.push(...detectedNavigatorLocale(), "en")
  // loop the array to check if we have pairs like en-EN and return en
  each(langs, (lang, key) => {
    if (lang.length > 2 && lang[2] === "-") {
      langs[key] = lang.substring(0, 2)
    }
  })
  // loop the array and pull from it any language
  // code that is not present in supported languages
  each([...langs], language => {
    if (availableLanguages.indexOf(language) === -1) {
      pull(langs, language)
    }
  })
  // use the first valid language
  return langs[0]
}

export const getLanguageNameByCode = code => {
  const language = find(languages, { code })
  return language ? language.name : null
}

export const getLanguageFlagByCode = code => {
  let flag = null
  try {
    flag = require(`../images/flags/${code}.svg`).default
  } catch (e) {
    console.log(`No flag for ${getLanguageNameByCode(code.toLowerCase())}`) // eslint-disable-line
  }
  return flag
}

export const getLanguageByCountry = countryCode => {
  const map = {
    AU: "EN",
    AT: "DE",
    BE: "FR",
    BEFR: "FR",
    CA: "CA",
    DE: "DE",
    ES: "ES",
    FR: "FR",
    GB: "EN",
    IE: "EN",
    IT: "IT",
    UK: "EN",
    US: "EN",
  }

  return map[countryCode] || "EN"
}

export default {
  getLanguagesList,
  changeDayjsLocale,
  getUserLanguages,
  getLanguageNameByCode,
  getLanguageFlagByCode,
  getLanguageByCountry,
}
