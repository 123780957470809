import {
  SHIPPING_PROFILES_GET_DEFAULT_REQUEST,
  SHIPPING_PROFILES_GET_DEFAULT_SUCCESS,
  SHIPPING_PROFILES_GET_DEFAULT_FAILURE,
  SHIPPING_DEFAULT_PROFILE_CREATE_SUCCESS,
  SHIPPING_DEFAULT_PROFILE_DELETE_SUCCESS,
  SHIPPING_PROFILES_CANCEL,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  data: {},
  state: "INITIAL",
  wasCreated: false,
  isSet: true,
  error: null,
}

function shippingProfilesDefaultStore(state = defaultState, action) {
  switch (action.type) {
    case SHIPPING_PROFILES_GET_DEFAULT_REQUEST:
      return {
        ...state,
        data: {},
        state: "LOADING",
      }
    case SHIPPING_PROFILES_GET_DEFAULT_SUCCESS:
      return {
        ...state,
        data: action.data,
        state: "LOADED",
      }
    case SHIPPING_PROFILES_GET_DEFAULT_FAILURE:
      return {
        ...state,
        data: {},
        isSet: false,
        error: action.error,
        state: "FAILED",
      }
    case SHIPPING_DEFAULT_PROFILE_CREATE_SUCCESS:
      return {
        ...state,
        data: action.req,
        isSet: true,
        wasCreated: true,
        state: "LOADED",
        error: null,
      }
    case SHIPPING_DEFAULT_PROFILE_DELETE_SUCCESS:
      return {
        ...state,
        data: null,
        isSet: false,
      }
    case SHIPPING_PROFILES_CANCEL:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default shippingProfilesDefaultStore
