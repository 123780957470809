export const setProperTokenUrl = (url, action) => {
  const amp = "%26" // `&` encoded especially for ebay
  const additionalParam = `context%3D${action}`
  if (url.indexOf("/love/authorize") > -1) {
    // oauth token
    const stateParamKey = "state="
    const splitted = url.split(stateParamKey)
    return `${splitted[0]}${stateParamKey}${additionalParam}${amp}${
      splitted[1]
    }`
  }
  if (url.indexOf("/ws/eBayISAPI") > -1) {
    // regular token
    return `${url}${amp}${additionalParam}`
  }
  return url
}

export default {
  setProperTokenUrl,
}
