import { all, select, put, fork, call, take } from "redux-saga/effects"
import { isNil } from "lodash"
import Cookies from "js-cookie"

import { setProperTokenUrl } from "shared/services/tokens"

import {
  ACCOUNT_CONNECT_TOKEN,
  ACCOUNT_REFRESH_TOKEN,
  ACCOUNT_CONFIRM_TOKEN_SUCCESS,
  ACCOUNT_CONFIRM_TOKEN_FAILURE,
  ACCOUNT_CANCEL,
} from "data/constants"

import { getTokenUrl } from "data/fetch/accounts/tokens"
import { getValidatedAccount, activateAccount } from "data/fetch/accounts/list"

function* refresh() {
  while (true) {
    const action = yield take(ACCOUNT_REFRESH_TOKEN)
    Cookies.set("tokenToRefresh-accountId", action.token.account_id, {
      path: "/",
    })
    if (action.token.refresh_url) {
      window.location = action.token.refresh_url
    } else {
      const { data } = yield call(() => getTokenUrl("refresh", action.token))
      window.location = setProperTokenUrl(data.url, "refresh")
    }
  }
}

function* connect() {
  while (true) {
    const action = yield take(ACCOUNT_CONNECT_TOKEN)
    try {
      const { data } = yield call(() => getTokenUrl("add", action.token))
      window.location = setProperTokenUrl(data.url, "add")
    } catch (error) {
      yield put({ type: ACCOUNT_CONFIRM_TOKEN_FAILURE, error })
    }
  }
}

// TODO: Not working
/* eslint-disable */
function* validateAccount(eiastoken, tokenId) {
  const { user } = yield select()
  try {
    const { data } = yield call(() => getValidatedAccount(user, eiastoken))
    if (data.is_active && !isNil(data.account_id)) {
      // this user already has this account
      yield put({
        type: ACCOUNT_CONFIRM_TOKEN_FAILURE,
        message: "This account has already been added.",
      })
    } else if (isNil(data.account_id)) {
      // this account is not assigned to any user
      // adding not yet assigned account
      yield call(() => activateAccount(user, null, tokenId))
      yield put({
        type: ACCOUNT_CONFIRM_TOKEN_SUCCESS,
        message: "Your account is now registered.",
      })
    } else if (!data.is_active && !isNil(data.account_id)) {
      // this user already has this account
      // activating existing account
      yield call(() => activateAccount(user, data.account_id, null))
      yield put({
        type: ACCOUNT_CONFIRM_TOKEN_SUCCESS,
        message: "Your account is now registered.",
      })
    } else {
      yield put({ type: ACCOUNT_CONFIRM_TOKEN_FAILURE })
    }
  } catch (error) {
    if (error.status === 400) {
      // Invalid User
      yield put({
        type: ACCOUNT_CONFIRM_TOKEN_FAILURE,
        message:
          "Account is already assigned to another user. Please contact our support.",
      })
    }

    if (error.status === 404) {
      // no account for eiastoken
      yield call(() => activateAccount(user, null, tokenId))
      yield put({
        type: ACCOUNT_CONFIRM_TOKEN_SUCCESS,
        message: "Your account is now registered.",
      })
    }
  }
}
/* eslint-enable */

export default function* main() {
  while (true) {
    const tasks = yield all([fork(connect), fork(refresh)])

    yield all(tasks)

    yield take([ACCOUNT_CANCEL])
    yield all(tasks.map(task => task.cancel()))
  }
}
