/* eslint-disable import/prefer-default-export */

// GENERAL
export const GENERAL_INITIAL_DATA_READY = "GENERAL_INITIAL_DATA_READY"
export const GENERAL_INITIAL_DATA_FAILED = "GENERAL_INITIAL_DATA_FAILED"
export const GENERAL_SET_ACTIVE_PLATFORM = "GENERAL_SET_ACTIVE_PLATFORM"
export const GENERAL_SET_ACTIVE_ACCOUNT = "GENERAL_SET_ACTIVE_ACCOUNT"
export const GENERAL_SET_LANGUAGE = "GENERAL_SET_LANGUAGE"
export const GENERAL_SHOW_OVERLAY = "GENERAL_SHOW_OVERLAY"
export const GENERAL_HIDE_OVERLAY = "GENERAL_HIDE_OVERLAY"
export const GENERAL_SHOW_MODAL = "GENERAL_SHOW_MODAL"
export const GENERAL_HIDE_MODAL = "GENERAL_HIDE_MODAL"

export const COPY = "COPY"

// AUTH
export const USER_GET = "USER_GET"
export const USER_GET_REQUEST = "USER_GET_REQUEST"
export const USER_GET_SUCCESS = "USER_GET_SUCCESS"
export const USER_GET_FAILURE = "USER_GET_FAILURE"

export const USER_LOGIN = "USER_LOGIN"
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE"

export const USER_LOGOUT = "USER_LOGOUT"
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST"
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS"
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE"

// SIGN UP
export const USER_SIGNUP = "USER_SIGNUP"
export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST"
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS"
export const USER_SIGNUP_FAILURE = "USER_SIGNUP_FAILURE"
export const USER_SIGNUP_RESOLVED = "USER_SIGNUP_RESOLVED"
export const USER_SIGNUP_FAILURE_NOTIFY = "USER_SIGNUP_FAILURE_NOTIFY"
export const USER_SIGNUP_EMAIL_TAKEN_NOTIFY = "USER_SIGNUP_EMAIL_TAKEN_NOTIFY"
export const NOTIFY_SIGNUP_ERROR = "NOTIFY_SIGNUP_ERROR"
export const USER_SIGNUP_SET_CONTEXT = "USER_SIGNUP_SET_CONTEXT"

export const PASSWORD_RESET_SUBMIT_EMAIL = "PASSWORD_RESET_SUBMIT_EMAIL"
export const PASSWORD_RESET_SUBMIT_EMAIL_REQUEST =
  "PASSWORD_RESET_SUBMIT_EMAIL_REQUEST"
export const PASSWORD_RESET_SUBMIT_EMAIL_SUCCESS =
  "PASSWORD_RESET_SUBMIT_EMAIL_SUCCESS"
export const PASSWORD_RESET_SUBMIT_EMAIL_FAILURE =
  "PASSWORD_RESET_SUBMIT_EMAIL_FAILURE"

export const PASSWORD_RESET_SUBMIT_PASSWORD = "PASSWORD_RESET_SUBMIT_PASSWORD"
export const PASSWORD_RESET_SUBMIT_PASSWORD_REQUEST =
  "PASSWORD_RESET_SUBMIT_PASSWORD_REQUEST"
export const PASSWORD_RESET_SUBMIT_PASSWORD_SUCCESS =
  "PASSWORD_RESET_SUBMIT_PASSWORD_SUCCESS"
export const PASSWORD_RESET_SUBMIT_PASSWORD_FAILURE =
  "PASSWORD_RESET_SUBMIT_PASSWORD_FAILURE"

export const USER_ONBOARDING_OPT_OUT = "USER_ONBOARDING_OPT_OUT"
export const USER_ONBOARDING_OPT_OUT_REQUEST = "USER_ONBOARDING_OPT_OUT_REQUEST"
export const USER_ONBOARDING_OPT_OUT_SUCCESS = "USER_ONBOARDING_OPT_OUT_SUCCESS"
export const USER_ONBOARDING_OPT_OUT_FAILURE = "USER_ONBOARDING_OPT_OUT_FAILURE"

export const USER_ONBOARDING_VISITED = "USER_ONBOARDING_VISITED"

// TERMS OF SERVICE
export const TERMS_OF_SERVICE_GET = "TERMS_OF_SERVICE_GET"
export const TERMS_OF_SERVICE_GET_REQUEST = "TERMS_OF_SERVICE_GET_REQUEST"
export const TERMS_OF_SERVICE_GET_SUCCESS = "TERMS_OF_SERVICE_GET_SUCCESS"
export const TERMS_OF_SERVICE_GET_FAILURE = "TERMS_OF_SERVICE_GET_FAILURE"

export const TERMS_OF_SERVICE_POST = "TERMS_OF_SERVICE_POST"
export const TERMS_OF_SERVICE_POST_REQUEST = "TERMS_OF_SERVICE_POST_REQUEST"
export const TERMS_OF_SERVICE_POST_SUCCESS = "TERMS_OF_SERVICE_POST_SUCCESS"
export const TERMS_OF_SERVICE_POST_FAILURE = "TERMS_OF_SERVICE_POST_FAILURE"

export const TERMS_OF_SERVICE_PUT = "TERMS_OF_SERVICE_PUT"
export const TERMS_OF_SERVICE_PUT_REQUEST = "TERMS_OF_SERVICE_PUT_REQUEST"
export const TERMS_OF_SERVICE_PUT_SUCCESS = "TERMS_OF_SERVICE_PUT_SUCCESS"
export const TERMS_OF_SERVICE_PUT_FAILURE = "TERMS_OF_SERVICE_PUT_FAILURE"

// SETTINGS
export const USER_GET_SETTINGS = "USER_GET_SETTINGS"
export const USER_GET_SETTINGS_REQUEST = "USER_GET_SETTINGS_REQUEST"
export const USER_GET_SETTINGS_SUCCESS = "USER_GET_SETTINGS_SUCCESS"
export const USER_GET_SETTINGS_FAILURE = "USER_GET_SETTINGS_FAILURE"

export const USER_UPDATE_SETTING = "USER_UPDATE_SETTING"
export const USER_UPDATE_SETTING_REQUEST = "USER_UPDATE_SETTING_REQUEST"
export const USER_UPDATE_SETTING_SUCCESS = "USER_UPDATE_SETTING_SUCCESS"
export const USER_UPDATE_SETTING_FAILURE = "USER_UPDATE_SETTING_FAILURE"

export const USER_CLEAR_SETTINGS = "USER_CLEAR_SETTINGS"

export const USER_SET_PERMISSIONS_SUCCESS = "USER_SET_PERMISSIONS_SUCCESS"

// PLANS
export const USER_GET_PLANS = "USER_GET_PLANS"
export const USER_GET_PLANS_REQUEST = "USER_GET_PLANS_REQUEST"
export const USER_GET_PLANS_SUCCESS = "USER_GET_PLANS_SUCCESS"
export const USER_GET_PLANS_FAILURE = "USER_GET_PLANS_FAILURE"
export const USER_GET_PLANS_RESOLVED = "USER_GET_PLANS_RESOLVED"

export const USER_PLAN_CHANGE = "USER_PLAN_CHANGE"
export const USER_PLAN_CHANGE_REQUEST = "USER_PLAN_CHANGE_REQUEST"
export const USER_PLAN_CHANGE_SUCCESS = "USER_PLAN_CHANGE_SUCCESS"
export const USER_PLAN_CHANGE_FAILURE = "USER_PLAN_CHANGE_FAILURE"

// USER STATES
export const USER_GET_STATES = "USER_GET_STATES"
export const USER_GET_STATES_REQUEST = "USER_GET_STATES_REQUEST"
export const USER_GET_STATES_SUCCESS = "USER_GET_STATES_SUCCESS"
export const USER_GET_STATES_FAILURE = "USER_GET_STATES_FAILURE"

// ALERTS
export const ALERTS_ADD = "ALERTS_ADD"
export const ALERTS_REMOVE = "ALERTS_REMOVE"

// DASHBOARD
export const DASHBOARD_GET = "DASHBOARD_GET"
export const DASHBOARD_CANCEL = "DASHBOARD_CANCEL"

export const DASHBOARD_GET_INVENTORY = "DASHBOARD_GET_INVENTORY"
export const DASHBOARD_GET_INVENTORY_REQUEST = "DASHBOARD_GET_INVENTORY_REQUEST"
export const DASHBOARD_GET_INVENTORY_SUCCESS = "DASHBOARD_GET_INVENTORY_SUCCESS"
export const DASHBOARD_GET_INVENTORY_FAILURE = "DASHBOARD_GET_INVENTORY_FAILURE"

export const DASHBOARD_GET_SALES_WEEKLY = "DASHBOARD_GET_SALES_WEEKLY"
export const DASHBOARD_GET_SALES_WEEKLY_REQUEST =
  "DASHBOARD_GET_SALES_WEEKLY_REQUEST"
export const DASHBOARD_GET_SALES_WEEKLY_SUCCESS =
  "DASHBOARD_GET_SALES_WEEKLY_SUCCESS"
export const DASHBOARD_GET_SALES_WEEKLY_FAILURE =
  "DASHBOARD_GET_SALES_WEEKLY_FAILURE"

export const DASHBOARD_GET_SALES_MONTHLY = "DASHBOARD_GET_SALES_MONTHLY"
export const DASHBOARD_GET_SALES_MONTHLY_REQUEST =
  "DASHBOARD_GET_SALES_MONTHLY_REQUEST"
export const DASHBOARD_GET_SALES_MONTHLY_SUCCESS =
  "DASHBOARD_GET_SALES_MONTHLY_SUCCESS"
export const DASHBOARD_GET_SALES_MONTHLY_FAILURE =
  "DASHBOARD_GET_SALES_MONTHLY_FAILURE"

// MULTICHANNEL ONBOARDING
export const USER_MULTICHANNEL_ONBOARDING_SET_CHOSEN_PLATFORM =
  "USER_MULTICHANNEL_ONBOARDING_SET_CHOSEN_PLATFORM"

export const USER_MULTICHANNEL_ONBOARDING_COMPLETED =
  "USER_MULTICHANNEL_ONBOARDING_COMPLETED"
export const USER_MULTICHANNEL_ONBOARDING_CANCELED =
  "USER_MULTICHANNEL_ONBOARDING_CANCELED"

export const USER_MULTICHANNEL_ONBOARDING_START =
  "USER_MULTICHANNEL_ONBOARDING_START"
export const USER_MULTICHANNEL_ONBOARDING_START_REQUEST =
  "USER_MULTICHANNEL_ONBOARDING_START_REQUEST"
export const USER_MULTICHANNEL_ONBOARDING_START_SUCCESS =
  "USER_MULTICHANNEL_ONBOARDING_START_SUCCESS"
export const USER_MULTICHANNEL_ONBOARDING_START_FAILURE =
  "USER_MULTICHANNEL_ONBOARDING_START_FAILURE"

export const USER_MULTICHANNEL_ONBOARDING_CANCEL_POST =
  "USER_MULTICHANNEL_ONBOARDING_CANCEL_POST"
export const USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_REQUEST =
  "USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_REQUEST"
export const USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_SUCCESS =
  "USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_SUCCESS"
export const USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_FAILURE =
  "USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_FAILURE"

export const USER_MULTICHANNEL_ONBOARDING_LIST_GET =
  "USER_MULTICHANNEL_ONBOARDING_LIST_GET"
export const USER_MULTICHANNEL_ONBOARDING_LIST_GET_REQUEST =
  "USER_MULTICHANNEL_ONBOARDING_LIST_GET_REQUEST"
export const USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS =
  "USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS"
export const USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE =
  "USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE"
export const USER_MULTICHANNEL_ONBOARDING_SET_STEP_NAME =
  "USER_MULTICHANNEL_ONBOARDING_SET_STEP_NAME"

export const USER_MULTICHANNEL_ONBOARDING_CATCH_REDIRECT_BACK =
  "USER_MULTICHANNEL_ONBOARDING_CATCH_REDIRECT_BACK"
export const USER_MULTICHANNEL_ONBOARDING_TOKEN_CONNECTED_SUCCESS_NOTIFICATION =
  "USER_MULTICHANNEL_ONBOARDING_TOKEN_CONNECTED_SUCCESS_NOTIFICATION"

export const USER_MULTICHANNEL_ONBOARDING_ALERT_TOKEN_ALREADY_REGISTERED =
  "USER_MULTICHANNEL_ONBOARDING_ALERT_TOKEN_ALREADY_REGISTERED"
export const USER_MULTICHANNEL_ONBOARDING_COMMON_ERROR_NOTIFICATION =
  "USER_MULTICHANNEL_ONBOARDING_COMMON_ERROR_NOTIFICATION"

export const USER_MULTICHANNEL_ONBOARDING_RECALCULATE_ONGOING_ONBOARDINGS =
  "USER_MULTICHANNEL_ONBOARDING_RECALCULATE_ONGOING_ONBOARDINGS"

// CDISCOUNT ONBOARDING
export const CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS =
  "CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS"
export const CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_REQUEST =
  "CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_REQUEST"
export const CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_SUCCESS =
  "CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_SUCCESS"
export const CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_FAILURE =
  "CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_FAILURE"

export const CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS =
  "CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS"
export const CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_REQUEST =
  "CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_REQUEST"
export const CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_SUCCESS =
  "CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_SUCCESS"
export const CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_FAILURE =
  "CDISCOUNT_ONBOARDING_GET_API_CREDENTIALS_STATUS_FAILURE"

export const CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING =
  "CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING"
export const CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_REQUEST =
  "CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_REQUEST"
export const CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_SUCCESS =
  "CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_SUCCESS"
export const CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_FAILURE =
  "CDISCOUNT_ONBOARDING_SUBMIT_SHIPPING_AND_HANDLING_FAILURE"

export const CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING =
  "CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING"
export const CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_REQUEST =
  "CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_REQUEST"
export const CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_SUCCESS =
  "CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_SUCCESS"
export const CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_FAILURE =
  "CDISCOUNT_ONBOARDING_GET_SHIPPING_AND_HANDLING_FAILURE"

export const CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT =
  "CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT"
export const CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_REQUEST =
  "CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_REQUEST"
export const CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_SUCCESS =
  "CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_SUCCESS"
export const CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_FAILURE =
  "CDISCOUNT_ONBOARDING_SUBMIT_PRICE_ADJUSTMENT_FAILURE"

export const CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT =
  "CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT"
export const CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_REQUEST =
  "CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_REQUEST"
export const CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_SUCCESS =
  "CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_SUCCESS"
export const CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_FAILURE =
  "CDISCOUNT_ONBOARDING_GET_PRICE_ADJUSTMENT_FAILURE"

// AMAZON ONBOARDING
export const AMAZON_ONBOARDING_SUBMIT_TARGET_SITES =
  "AMAZON_ONBOARDING_SUBMIT_TARGET_SITES"
export const AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_REQUEST =
  "AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_REQUEST"
export const AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_SUCCESS =
  "AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_SUCCESS"
export const AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_FAILURE =
  "AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_FAILURE"

export const AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE =
  "AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE"
export const AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_REQUEST =
  "AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_REQUEST"
export const AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_SUCCESS =
  "AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_SUCCESS"
export const AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_FAILURE =
  "AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_FAILURE"

export const AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE =
  "AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE"
export const AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_REQUEST =
  "AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_REQUEST"
export const AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_SUCCESS =
  "AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_SUCCESS"
export const AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_FAILURE =
  "AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_FAILURE"

// EBAY ONBOARDING
export const EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS =
  "EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS"
export const EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_REQUEST =
  "EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_REQUEST"
export const EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_SUCCESS =
  "EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_SUCCESS"
export const EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_FAILURE =
  "EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_FAILURE"
export const EBAY_ONBOARDING_SUBMIT_3PTOOL = "EBAY_ONBOARDING_SUBMIT_3PTOOL"
export const EBAY_ONBOARDING_SUBMIT_3PTOOL_REQUEST =
  "EBAY_ONBOARDING_SUBMIT_3PTOOL_REQUEST"
export const EBAY_ONBOARDING_SUBMIT_3PTOOL_SUCCESS =
  "EBAY_ONBOARDING_SUBMIT_3PTOOL_SUCCESS"
export const EBAY_ONBOARDING_SUBMIT_3PTOOL_FAILURE =
  "EBAY_ONBOARDING_SUBMIT_3PTOOL_FAILURE"
export const EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY =
  "EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY"
export const EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_REQUEST =
  "EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_REQUEST"
export const EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_SUCCESS =
  "EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_SUCCESS"
export const EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_FAILURE =
  "EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_FAILURE"
export const EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES =
  "EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES"
export const EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_REQUEST =
  "EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_REQUEST"
export const EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_SUCCESS =
  "EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_SUCCESS"
export const EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_FAILURE =
  "EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_FAILURE"
export const EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO =
  "EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO"
export const EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_REQUEST =
  "EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_REQUEST"
export const EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_SUCCESS =
  "EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_SUCCESS"
export const EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_FAILURE =
  "EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_FAILURE"
export const EBAY_ONBOARDING_POST_CHECKOUT = "EBAY_ONBOARDING_POST_CHECKOUT"
export const EBAY_ONBOARDING_POST_CHECKOUT_REQUEST =
  "EBAY_ONBOARDING_POST_CHECKOUT_REQUEST"
export const EBAY_ONBOARDING_POST_CHECKOUT_SUCCESS =
  "EBAY_ONBOARDING_POST_CHECKOUT_SUCCESS"
export const EBAY_ONBOARDING_POST_CHECKOUT_FAILURE =
  "EBAY_ONBOARDING_POST_CHECKOUT_FAILURE"
export const EBAY_ONBOARDING_BACK_TO_CHECKOUT =
  "EBAY_ONBOARDING_BACK_TO_CHECKOUT"

// PRODUCTS
export const PRODUCTS_GET_SETTINGS = "PRODUCTS_GET_SETTINGS"
export const PRODUCTS_GET_SETTINGS_REQUEST = "PRODUCTS_GET_SETTINGS_REQUEST"
export const PRODUCTS_GET_SETTINGS_SUCCESS = "PRODUCTS_GET_SETTINGS_SUCCESS"
export const PRODUCTS_GET_SETTINGS_FAILURE = "PRODUCTS_GET_SETTINGS_FAILURE"

export const PRODUCTS_UPDATE_SETTINGS = "PRODUCTS_UPDATE_SETTINGS"
export const PRODUCTS_UPDATE_SETTINGS_REQUEST =
  "PRODUCTS_UPDATE_SETTINGS_REQUEST"
export const PRODUCTS_UPDATE_SETTINGS_SUCCESS =
  "PRODUCTS_UPDATE_SETTINGS_SUCCESS"
export const PRODUCTS_UPDATE_SETTINGS_FAILURE =
  "PRODUCTS_UPDATE_SETTINGS_FAILURE"

export const PRODUCTS_UPDATE_FREE_SHIPPING_SUCCESS =
  "PRODUCTS_UPDATE_FREE_SHIPPING_SUCCESS"
export const PRODUCTS_UPDATE_COUNTRY_USAGE_SUCCESS =
  "PRODUCTS_UPDATE_COUNTRY_USAGE_SUCCESS"
export const PRODUCTS_UPDATE_ITEM_CONDITION_SUCCESS =
  "PRODUCTS_UPDATE_ITEM_CONDITION_SUCCESS"
export const PRODUCTS_UPDATE_DEDUCT_VAT_SUCCESS =
  "PRODUCTS_UPDATE_DEDUCT_VAT_SUCCESS"

// PRODUCTS - LIST
export const PRODUCTS_GET_LIST = "PRODUCTS_GET_LIST"
export const PRODUCTS_GET_LIST_REQUEST = "PRODUCTS_GET_LIST_REQUEST"
export const PRODUCTS_GET_LIST_SUCCESS = "PRODUCTS_GET_LIST_SUCCESS"
export const PRODUCTS_GET_LIST_FAILURE = "PRODUCTS_GET_LIST_FAILURE"

export const PRODUCTS_CANCEL = "PRODUCTS_CANCEL"

export const PRODUCTS_SELECT_ITEM = "PRODUCTS_SELECT_ITEM"
export const PRODUCTS_SELECT_ALL = "PRODUCTS_SELECT_ALL"
export const PRODUCTS_SELECT_ALL_QUERY = "PRODUCTS_SELECT_ALL_QUERY"

export const PRODUCTS_UPDATE_BLOCK = "PRODUCTS_UPDATE_BLOCK"
export const PRODUCTS_UPDATE_BLOCK_REQUEST = "PRODUCTS_UPDATE_BLOCK_REQUEST"
export const PRODUCTS_UPDATE_BLOCK_SUCCESS = "PRODUCTS_UPDATE_BLOCK_SUCCESS"
export const PRODUCTS_UPDATE_BLOCK_FAILURE = "PRODUCTS_UPDATE_BLOCK_FAILURE"

export const PRODUCTS_UPDATE_UNBLOCK = "PRODUCTS_UPDATE_UNBLOCK"
export const PRODUCTS_UPDATE_UNBLOCK_REQUEST = "PRODUCTS_UPDATE_UNBLOCK_REQUEST"
export const PRODUCTS_UPDATE_UNBLOCK_SUCCESS = "PRODUCTS_UPDATE_UNBLOCK_SUCCESS"
export const PRODUCTS_UPDATE_UNBLOCK_FAILURE = "PRODUCTS_UPDATE_UNBLOCK_FAILURE"

export const PRODUCTS_UPDATE_BLOCK_REPRICER = "PRODUCTS_UPDATE_BLOCK_REPRICER"
export const PRODUCTS_UPDATE_BLOCK_REPRICER_REQUEST =
  "PRODUCTS_UPDATE_BLOCK_REPRICER_REQUEST"
export const PRODUCTS_UPDATE_BLOCK_REPRICER_SUCCESS =
  "PRODUCTS_UPDATE_BLOCK_REPRICER_SUCCESS"
export const PRODUCTS_UPDATE_BLOCK_REPRICER_FAILURE =
  "PRODUCTS_UPDATE_BLOCK_REPRICER_FAILURE"

export const PRODUCTS_UPDATE_UNBLOCK_REPRICER =
  "PRODUCTS_UPDATE_UNBLOCK_REPRICER"
export const PRODUCTS_UPDATE_UNBLOCK_REPRICER_REQUEST =
  "PRODUCTS_UPDATE_UNBLOCK_REPRICER_REQUEST"
export const PRODUCTS_UPDATE_UNBLOCK_REPRICER_SUCCESS =
  "PRODUCTS_UPDATE_UNBLOCK_REPRICER_SUCCESS"
export const PRODUCTS_UPDATE_UNBLOCK_REPRICER_FAILURE =
  "PRODUCTS_UPDATE_UNBLOCK_REPRICER_FAILURE"

export const PRODUCTS_GET_SHIPPING = "PRODUCTS_GET_SHIPPING"
export const PRODUCTS_GET_SHIPPING_REQUEST = "PRODUCTS_GET_SHIPPING_REQUEST"
export const PRODUCTS_GET_SHIPPING_SUCCESS = "PRODUCTS_GET_SHIPPING_SUCCESS"
export const PRODUCTS_GET_SHIPPING_FAILURE = "PRODUCTS_GET_SHIPPING_FAILURE"

export const PRODUCTS_SET_SHIPPING = "PRODUCTS_SET_SHIPPING"
export const PRODUCTS_SET_SHIPPING_REQUEST = "PRODUCTS_SET_SHIPPING_REQUEST"
export const PRODUCTS_SET_SHIPPING_SUCCESS = "PRODUCTS_SET_SHIPPING_SUCCESS"
export const PRODUCTS_SET_SHIPPING_FAILURE = "PRODUCTS_SET_SHIPPING_FAILURE"

export const PRODUCTS_CLEAR_SHIPPING = "PRODUCTS_CLEAR_SHIPPING"
export const PRODUCTS_CLEAR_SHIPPING_REQUEST = "PRODUCTS_CLEAR_SHIPPING_REQUEST"
export const PRODUCTS_CLEAR_SHIPPING_SUCCESS = "PRODUCTS_CLEAR_SHIPPING_SUCCESS"
export const PRODUCTS_CLEAR_SHIPPING_FAILURE = "PRODUCTS_CLEAR_SHIPPING_FAILURE"

// PRODUCTS - SINGLE
export const PRODUCT_GET = "PRODUCT_GET"
export const PRODUCT_GET_REQUEST = "PRODUCT_GET_REQUEST"
export const PRODUCT_GET_SUCCESS = "PRODUCT_GET_SUCCESS"
export const PRODUCT_GET_FAILURE = "PRODUCT_GET_FAILURE"

export const PRODUCT_GET_ORDERS = "PRODUCT_GET_ORDERS"
export const PRODUCT_GET_ORDERS_REQUEST = "PRODUCT_GET_ORDERS_REQUEST"
export const PRODUCT_GET_ORDERS_SUCCESS = "PRODUCT_GET_ORDERS_SUCCESS"
export const PRODUCT_GET_ORDERS_FAILURE = "PRODUCT_GET_ORDERS_FAILURE"

export const PRODUCT_GET_PRICES = "PRODUCT_GET_PRICES"
export const PRODUCT_GET_PRICES_REQUEST = "PRODUCT_GET_PRICES_REQUEST"
export const PRODUCT_GET_PRICES_SUCCESS = "PRODUCT_GET_PRICES_SUCCESS"
export const PRODUCT_GET_PRICES_FAILURE = "PRODUCT_GET_PRICES_FAILURE"

export const PRODUCT_UPDATE_PRICES = "PRODUCT_UPDATE_PRICES"
export const PRODUCT_UPDATE_PRICES_REQUEST = "PRODUCT_UPDATE_PRICES_REQUEST"
export const PRODUCT_UPDATE_PRICES_SUCCESS = "PRODUCT_UPDATE_PRICES_SUCCESS"
export const PRODUCT_UPDATE_PRICES_FAILURE = "PRODUCT_UPDATE_PRICES_FAILURE"

export const PRODUCT_GET_TRANSLATIONS = "PRODUCT_GET_TRANSLATIONS"
export const PRODUCT_GET_TRANSLATIONS_REQUEST =
  "PRODUCT_GET_TRANSLATIONS_REQUEST"
export const PRODUCT_GET_TRANSLATIONS_SUCCESS =
  "PRODUCT_GET_TRANSLATIONS_SUCCESS"
export const PRODUCT_GET_TRANSLATIONS_FAILURE =
  "PRODUCT_GET_TRANSLATIONS_FAILURE"

export const PRODUCT_UPDATE_TRANSLATIONS = "PRODUCT_UPDATE_TRANSLATIONS"
export const PRODUCT_UPDATE_TRANSLATIONS_REQUEST =
  "PRODUCT_UPDATE_TRANSLATIONS_REQUEST"
export const PRODUCT_UPDATE_TRANSLATIONS_SUCCESS =
  "PRODUCT_UPDATE_TRANSLATIONS_SUCCESS"
export const PRODUCT_UPDATE_TRANSLATIONS_FAILURE =
  "PRODUCT_UPDATE_TRANSLATIONS_FAILURE"

export const PRODUCT_CHANGE_TAB = "PRODUCT_CHANGE_TAB"
export const PRODUCT_CLEAR = "PRODUCT_CLEAR"
export const PRODUCT_CANCEL = "PRODUCT_CANCEL"

export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS"
export const PRODUCT_UPDATE_FAILURE = "PRODUCT_UPDATE_FAILURE"

// ORDERS - LIST
export const ORDERS_GET_LIST = "ORDERS_GET_LIST"
export const ORDERS_GET_LIST_REQUEST = "ORDERS_GET_LIST_REQUEST"
export const ORDERS_GET_LIST_SUCCESS = "ORDERS_GET_LIST_SUCCESS"
export const ORDERS_GET_LIST_FAILURE = "ORDERS_GET_LIST_FAILURE"

export const ORDERS_CANCEL = "ORDERS_CANCEL"
export const ORDERS_CLEAR = "ORDERS_CLEAR"

// SHIPPING PROFILES - LIST
export const SHIPPING_PROFILES_GET_LIST = "SHIPPING_PROFILES_GET_LIST"
export const SHIPPING_PROFILES_GET_LIST_REQUEST =
  "SHIPPING_PROFILES_GET_LIST_REQUEST"
export const SHIPPING_PROFILES_GET_LIST_SUCCESS =
  "SHIPPING_PROFILES_GET_LIST_SUCCESS"
export const SHIPPING_PROFILES_GET_LIST_FAILURE =
  "SHIPPING_PROFILES_GET_LIST_FAILURE"

export const SHIPPING_PROFILES_GET_DEFAULT = "SHIPPING_PROFILES_GET_DEFAULT"
export const SHIPPING_PROFILES_GET_DEFAULT_REQUEST =
  "SHIPPING_PROFILES_GET_DEFAULT_REQUEST"
export const SHIPPING_PROFILES_GET_DEFAULT_SUCCESS =
  "SHIPPING_PROFILES_GET_DEFAULT_SUCCESS"
export const SHIPPING_PROFILES_GET_DEFAULT_FAILURE =
  "SHIPPING_PROFILES_GET_DEFAULT_FAILURE"

export const SHIPPING_PROFILES_CANCEL = "SHIPPING_PROFILES_CANCEL"

// SHIPPING PROFILES - SINGLE
export const SHIPPING_PROFILE_GET = "SHIPPING_PROFILE_GET"
export const SHIPPING_PROFILE_GET_REQUEST = "SHIPPING_PROFILE_GET_REQUEST"
export const SHIPPING_PROFILE_GET_SUCCESS = "SHIPPING_PROFILE_GET_SUCCESS"
export const SHIPPING_PROFILE_GET_FAILURE = "SHIPPING_PROFILE_GET_FAILURE"

export const SHIPPING_DEFAULT_PROFILE_CREATE = "SHIPPING_DEFAULT_PROFILE_CREATE"
export const SHIPPING_DEFAULT_PROFILE_CREATE_REQUEST =
  "SHIPPING_DEFAULT_PROFILE_CREATE_REQUEST"
export const SHIPPING_DEFAULT_PROFILE_CREATE_SUCCESS =
  "SHIPPING_DEFAULT_PROFILE_CREATE_SUCCESS"
export const SHIPPING_DEFAULT_PROFILE_CREATE_FAILURE =
  "SHIPPING_DEFAULT_PROFILE_CREATE_FAILURE"

export const SHIPPING_DEFAULT_PROFILE_DELETE = "SHIPPING_DEFAULT_PROFILE_DELETE"
export const SHIPPING_DEFAULT_PROFILE_DELETE_REQUEST =
  "SHIPPING_DEFAULT_PROFILE_DELETE_REQUEST"
export const SHIPPING_DEFAULT_PROFILE_DELETE_SUCCESS =
  "SHIPPING_DEFAULT_PROFILE_DELETE_SUCCESS"
export const SHIPPING_DEFAULT_PROFILE_DELETE_FAILURE =
  "SHIPPING_DEFAULT_PROFILE_DELETE_FAILURE"

export const SHIPPING_PROFILE_CREATE = "SHIPPING_PROFILE_CREATE"
export const SHIPPING_PROFILE_CREATE_REQUEST = "SHIPPING_PROFILE_CREATE_REQUEST"
export const SHIPPING_PROFILE_CREATE_SUCCESS = "SHIPPING_PROFILE_CREATE_SUCCESS"
export const SHIPPING_PROFILE_CREATE_FAILURE = "SHIPPING_PROFILE_CREATE_FAILURE"

export const SHIPPING_PROFILE_EDIT = "SHIPPING_PROFILE_EDIT"
export const SHIPPING_PROFILE_EDIT_REQUEST = "SHIPPING_PROFILE_EDIT_REQUEST"
export const SHIPPING_PROFILE_EDIT_SUCCESS = "SHIPPING_PROFILE_EDIT_SUCCESS"
export const SHIPPING_PROFILE_EDIT_FAILURE = "SHIPPING_PROFILE_EDIT_FAILURE"

export const SHIPPING_PROFILE_DELETE = "SHIPPING_PROFILE_DELETE"
export const SHIPPING_PROFILE_DELETE_REQUEST = "SHIPPING_PROFILE_DELETE_REQUEST"
export const SHIPPING_PROFILE_DELETE_SUCCESS = "SHIPPING_PROFILE_DELETE_SUCCESS"
export const SHIPPING_PROFILE_DELETE_FAILURE = "SHIPPING_PROFILE_DELETE_FAILURE"

export const SHIPPING_PROFILES_GET_SERVICES = "SHIPPING_PROFILES_GET_SERVICES"
export const SHIPPING_PROFILES_GET_SERVICES_REQUEST =
  "SHIPPING_PROFILES_GET_SERVICES_REQUEST"
export const SHIPPING_PROFILES_GET_SERVICES_SUCCESS =
  "SHIPPING_PROFILES_GET_SERVICES_SUCCESS"
export const SHIPPING_PROFILES_GET_SERVICES_FAILURE =
  "SHIPPING_PROFILES_GET_SERVICES_FAILURE"

export const SHIPPING_PROFILE_RATES_UPDATE = "SHIPPING_PROFILE_RATES_UPDATE"
export const SHIPPING_PROFILE_RATES_SET_DEFAULT =
  "SHIPPING_PROFILE_RATES_SET_DEFAULT"
export const SHIPPING_PROFILE_RATES_SET_CUSTOM =
  "SHIPPING_PROFILE_RATES_SET_CUSTOM"
export const SHIPPING_PROFILE_RATES_SET_EXCLUDED =
  "SHIPPING_PROFILE_RATES_SET_EXCLUDED"

export const SHIPPING_PROFILE_CANCEL = "SHIPPING_PROFILE_CANCEL"

// SHIPPING PROFILES - RATES
export const SHIPPING_PROFILE_RATE_EDIT = "SHIPPING_PROFILE_RATE_EDIT"
export const SHIPPING_PROFILE_RATE_EDIT_REQUEST =
  "SHIPPING_PROFILE_RATE_EDIT_REQUEST"
export const SHIPPING_PROFILE_RATE_EDIT_SUCCESS =
  "SHIPPING_PROFILE_RATE_EDIT_SUCCESS"
export const SHIPPING_PROFILE_RATE_EDIT_FAILURE =
  "SHIPPING_PROFILE_RATE_EDIT_FAILURE"

// CONVERSION CHARTS - LIST
export const CONVERSION_CHARTS_GET_LIST = "CONVERSION_CHARTS_GET_LIST"
export const CONVERSION_CHARTS_GET_LIST_REQUEST =
  "CONVERSION_CHARTS_GET_LIST_REQUEST"
export const CONVERSION_CHARTS_GET_LIST_SUCCESS =
  "CONVERSION_CHARTS_GET_LIST_SUCCESS"
export const CONVERSION_CHARTS_GET_LIST_FAILURE =
  "CONVERSION_CHARTS_GET_LIST_FAILURE"

export const CONVERSION_CHARTS_GET_BRAND_LIST =
  "CONVERSION_CHARTS_GET_BRAND_LIST"
export const CONVERSION_CHARTS_GET_BRAND_LIST_REQUEST =
  "CONVERSION_CHARTS_GET_BRAND_LIST_REQUEST"
export const CONVERSION_CHARTS_GET_BRAND_LIST_SUCCESS =
  "CONVERSION_CHARTS_GET_BRAND_LIST_SUCCESS"
export const CONVERSION_CHARTS_GET_BRAND_LIST_FAILURE =
  "CONVERSION_CHARTS_GET_BRAND_LIST_FAILURE"

export const CONVERSION_CHARTS_GET_CATEGORY_LIST =
  "CONVERSION_CHARTS_GET_CATEGORY_LIST"
export const CONVERSION_CHARTS_GET_CATEGORY_LIST_REQUEST =
  "CONVERSION_CHARTS_GET_CATEGORY_LIST_REQUEST"
export const CONVERSION_CHARTS_GET_CATEGORY_LIST_SUCCESS =
  "CONVERSION_CHARTS_GET_CATEGORY_LIST_SUCCESS"
export const CONVERSION_CHARTS_GET_CATEGORY_LIST_FAILURE =
  "CONVERSION_CHARTS_GET_CATEGORY_LIST_FAILURE"

export const CONVERSION_CHARTS_CANCEL = "CONVERSION_CHARTS_CANCEL"

// CONVERSION CHARTS - SINGLE
export const CONVERSION_CHART_GET = "CONVERSION_CHART_GET"
export const CONVERSION_CHART_GET_REQUEST = "CONVERSION_CHART_GET_REQUEST"
export const CONVERSION_CHART_GET_SUCCESS = "CONVERSION_CHART_GET_SUCCESS"
export const CONVERSION_CHART_GET_FAILURE = "CONVERSION_CHART_GET_FAILURE"

export const CONVERSION_CHART_ADD = "CONVERSION_CHART_ADD"
export const CONVERSION_CHART_ADD_REQUEST = "CONVERSION_CHART_ADD_REQUEST"
export const CONVERSION_CHART_ADD_SUCCESS = "CONVERSION_CHART_ADD_SUCCESS"
export const CONVERSION_CHART_ADD_FAILURE = "CONVERSION_CHART_ADD_FAILURE"

export const CONVERSION_CHART_EDIT = "CONVERSION_CHART_EDIT"
export const CONVERSION_CHART_EDIT_REQUEST = "CONVERSION_CHART_EDIT_REQUEST"
export const CONVERSION_CHART_EDIT_SUCCESS = "CONVERSION_CHART_EDIT_SUCCESS"
export const CONVERSION_CHART_EDIT_FAILURE = "CONVERSION_CHART_EDIT_FAILURE"

export const CONVERSION_CHART_DELETE = "CONVERSION_CHART_DELETE"
export const CONVERSION_CHART_DELETE_REQUEST = "CONVERSION_CHART_DELETE_REQUEST"
export const CONVERSION_CHART_DELETE_SUCCESS = "CONVERSION_CHART_DELETE_SUCCESS"
export const CONVERSION_CHART_DELETE_FAILURE = "CONVERSION_CHART_DELETE_FAILURE"

export const CONVERSION_CHART_CANCEL = "CONVERSION_CHART_CANCEL"

// REPRICER
export const REPRICER_GET_SETTINGS = "REPRICER_GET_SETTINGS"
export const REPRICER_GET_SETTINGS_REQUEST = "REPRICER_GET_SETTINGS_REQUEST"
export const REPRICER_GET_SETTINGS_SUCCESS = "REPRICER_GET_SETTINGS_SUCCESS"
export const REPRICER_GET_SETTINGS_FAILURE = "REPRICER_GET_SETTINGS_FAILURE"

export const REPRICER_UPDATE_SETTINGS = "REPRICER_UPDATE_SETTINGS"
export const REPRICER_UPDATE_SETTINGS_REQUEST =
  "REPRICER_UPDATE_SETTINGS_REQUEST"
export const REPRICER_UPDATE_SETTINGS_SUCCESS =
  "REPRICER_UPDATE_SETTINGS_SUCCESS"
export const REPRICER_UPDATE_SETTINGS_FAILURE =
  "REPRICER_UPDATE_SETTINGS_FAILURE"

export const REPRICER_CANCEL = "REPRICER_CANCEL"

// BLACKLIST
export const BLACKLIST_GET_LIST = "BLACKLIST_GET_LIST"
export const BLACKLIST_GET_LIST_REQUEST = "BLACKLIST_GET_LIST_REQUEST"
export const BLACKLIST_GET_LIST_SUCCESS = "BLACKLIST_GET_LIST_SUCCESS"
export const BLACKLIST_GET_LIST_FAILURE = "BLACKLIST_GET_LIST_FAILURE"

export const BLACKLIST_GET = "BLACKLIST_GET"
export const BLACKLIST_GET_REQUEST = "BLACKLIST_GET_REQUEST"
export const BLACKLIST_GET_SUCCESS = "BLACKLIST_GET_SUCCESS"
export const BLACKLIST_GET_FAILURE = "BLACKLIST_GET_FAILURE"

export const BLACKLIST_KEYWORD_ADD = "BLACKLIST_KEYWORD_ADD"
export const BLACKLIST_KEYWORD_ADD_REQUEST = "BLACKLIST_KEYWORD_ADD_REQUEST"
export const BLACKLIST_KEYWORD_ADD_SUCCESS = "BLACKLIST_KEYWORD_ADD_SUCCESS"
export const BLACKLIST_KEYWORD_ADD_FAILURE = "BLACKLIST_KEYWORD_ADD_FAILURE"

export const BLACKLIST_KEYWORDS_ADDED = "BLACKLIST_KEYWORDS_ADDED"

export const BLACKLIST_KEYWORD_EDIT = "BLACKLIST_KEYWORD_EDIT"
export const BLACKLIST_KEYWORD_EDIT_REQUEST = "BLACKLIST_KEYWORD_EDIT_REQUEST"
export const BLACKLIST_KEYWORD_EDIT_SUCCESS = "BLACKLIST_KEYWORD_EDIT_SUCCESS"
export const BLACKLIST_KEYWORD_EDIT_FAILURE = "BLACKLIST_KEYWORD_EDIT_FAILURE"

export const BLACKLIST_KEYWORD_DELETE = "BLACKLIST_KEYWORD_DELETE"
export const BLACKLIST_KEYWORD_DELETE_REQUEST =
  "BLACKLIST_KEYWORD_DELETE_REQUEST"
export const BLACKLIST_KEYWORD_DELETE_SUCCESS =
  "BLACKLIST_KEYWORD_DELETE_SUCCESS"
export const BLACKLIST_KEYWORD_DELETE_FAILURE =
  "BLACKLIST_KEYWORD_DELETE_FAILURE"

export const BLACKLIST_CANCEL = "BLACKLIST_CANCEL"

// TRANSLATION MANAGER
export const TRANSLATION_MANAGER_GET_LIST = "TRANSLATION_MANAGER_GET_LIST"
export const TRANSLATION_MANAGER_GET_LIST_REQUEST =
  "TRANSLATION_MANAGER_GET_LIST_REQUEST"
export const TRANSLATION_MANAGER_GET_LIST_SUCCESS =
  "TRANSLATION_MANAGER_GET_LIST_SUCCESS"
export const TRANSLATION_MANAGER_GET_LIST_FAILURE =
  "TRANSLATION_MANAGER_GET_LIST_FAILURE"

export const TRANSLATION_MANAGER_TERM_ADD = "TRANSLATION_MANAGER_TERM_ADD"
export const TRANSLATION_MANAGER_TERM_ADD_REQUEST =
  "TRANSLATION_MANAGER_TERM_ADD_REQUEST"
export const TRANSLATION_MANAGER_TERM_ADD_SUCCESS =
  "TRANSLATION_MANAGER_TERM_ADD_SUCCESS"
export const TRANSLATION_MANAGER_TERM_ADD_FAILURE =
  "TRANSLATION_MANAGER_TERM_ADD_FAILURE"

export const TRANSLATION_MANAGER_TERMS_ADDED = "TRANSLATION_MANAGER_TERMS_ADDED"
export const TRANSLATION_MANAGER_TRANSLATIONS_ADDED =
  "TRANSLATION_MANAGER_TRANSLATIONS_ADDED"

export const TRANSLATION_MANAGER_TERM_DELETE = "TRANSLATION_MANAGER_TERM_DELETE"
export const TRANSLATION_MANAGER_TERM_DELETE_REQUEST =
  "TRANSLATION_MANAGER_TERM_DELETE_REQUEST"
export const TRANSLATION_MANAGER_TERM_DELETE_SUCCESS =
  "TRANSLATION_MANAGER_TERM_DELETE_SUCCESS"
export const TRANSLATION_MANAGER_TERM_DELETE_FAILURE =
  "TRANSLATION_MANAGER_TERM_DELETE_FAILURE"

export const TRANSLATION_MANAGER_BULK_DELETE = "TRANSLATION_MANAGER_BULK_DELETE"
export const TRANSLATION_MANAGER_BULK_DELETE_SUCCESS =
  "TRANSLATION_MANAGER_BULK_DELETE_SUCCESS"
export const TRANSLATION_MANAGER_BULK_DELETE_FAILURE =
  "TRANSLATION_MANAGER_BULK_DELETE_FAILURE"

export const TRANSLATION_MANAGER_SELECT_TERM_GROUP =
  "TRANSLATION_MANAGER_SELECT_TERM_GROUP"
export const TRANSLATION_MANAGER_SELECT_ALL = "TRANSLATION_MANAGER_SELECT_ALL"
export const TRANSLATION_MANAGER_SELECT_TERM_TO_DELETE =
  "TRANSLATION_MANAGER_SELECT_TERM_TO_DELETE"
export const TRANSLATION_MANAGER_CLEAR_TERM_TO_DELETE =
  "TRANSLATION_MANAGER_CLEAR_TERM_TO_DELETE"

export const TRANSLATION_MANAGER_CANCEL = "TRANSLATION_MANAGER_CANCEL"

export const TRANSLATION_MANAGER_TERM_GET = "TRANSLATION_MANAGER_TERM_GET"
export const TRANSLATION_MANAGER_TERM_GET_REQUEST =
  "TRANSLATION_MANAGER_TERM_GET_REQUEST"
export const TRANSLATION_MANAGER_TERM_GET_SUCCESS =
  "TRANSLATION_MANAGER_TERM_GET_SUCCESS"
export const TRANSLATION_MANAGER_TERM_GET_FAILURE =
  "TRANSLATION_MANAGER_TERM_GET_FAILURE"
export const TRANSLATION_MANAGER_TERM_CANCEL = "TRANSLATION_MANAGER_TERM_CANCEL"

export const TRANSLATION_MANAGER_TERM_CLEAR = "TRANSLATION_MANAGER_TERM_CLEAR"

export const TRANSLATION_MANAGER_TERM_UPDATE = "TRANSLATION_MANAGER_TERM_UPDATE"
export const TRANSLATION_MANAGER_TERM_UPDATE_REQUEST =
  "TRANSLATION_MANAGER_TERM_UPDATE_REQUEST"
export const TRANSLATION_MANAGER_TERM_UPDATE_SUCCESS =
  "TRANSLATION_MANAGER_TERM_UPDATE_SUCCESS"
export const TRANSLATION_MANAGER_TERM_UPDATE_FAILURE =
  "TRANSLATION_MANAGER_TERM_UPDATE_FAILURE"

// PRICE RULES
export const PRICE_RULES_GET = "PRICE_RULES_GET"
export const PRICE_RULES_GET_REQUEST = "PRICE_RULES_GET_REQUEST"
export const PRICE_RULES_GET_SUCCESS = "PRICE_RULES_GET_SUCCESS"
export const PRICE_RULES_GET_FAILURE = "PRICE_RULES_GET_FAILURE"

export const PRICE_RULE_GET = "PRICE_RULE_GET"
export const PRICE_RULE_GET_REQUEST = "PRICE_RULE_GET_REQUEST"
export const PRICE_RULE_GET_SUCCESS = "PRICE_RULE_GET_SUCCESS"
export const PRICE_RULE_GET_FAILURE = "PRICE_RULE_GET_FAILURE"

export const PRICE_RULE_EDIT = "PRICE_RULE_EDIT"
export const PRICE_RULE_EDIT_REQUEST = "PRICE_RULE_EDIT_REQUEST"
export const PRICE_RULE_EDIT_SUCCESS = "PRICE_RULE_EDIT_SUCCESS"
export const PRICE_RULE_EDIT_FAILURE = "PRICE_RULE_EDIT_FAILURE"

export const PRICE_RULE_DELETE = "PRICE_RULE_DELETE"
export const PRICE_RULE_DELETE_REQUEST = "PRICE_RULE_DELETE_REQUEST"
export const PRICE_RULE_DELETE_SUCCESS = "PRICE_RULE_DELETE_SUCCESS"
export const PRICE_RULE_DELETE_FAILURE = "PRICE_RULE_DELETE_FAILURE"

export const PRICE_RULES_CANCEL = "PRICE_RULES_CANCEL"
export const PRICE_RULE_CANCEL = "PRICE_RULE_CANCEL"
export const PRICE_RULE_CLEAR = "PRICE_RULE_CLEAR"

// BULK ITEM REQUEST
export const BULK_ITEM_REQUEST_ITEMS = "BULK_ITEM_REQUEST_ITEMS"
export const BULK_ITEM_REQUEST_ITEMS_REQUEST = "BULK_ITEM_REQUEST_ITEMS_REQUEST"
export const BULK_ITEM_REQUEST_ITEMS_SUCCESS = "BULK_ITEM_REQUEST_ITEMS_SUCCESS"
export const BULK_ITEM_REQUEST_ITEMS_FAILURE = "BULK_ITEM_REQUEST_ITEMS_FAILURE"

export const BULK_ITEM_REQUEST_ISSUES_GET = "BULK_ITEM_REQUEST_ISSUES_GET"
export const BULK_ITEM_REQUEST_ISSUES_GET_REQUEST =
  "BULK_ITEM_REQUEST_ISSUES_GET_REQUEST"
export const BULK_ITEM_REQUEST_ISSUES_GET_SUCCESS =
  "BULK_ITEM_REQUEST_ISSUES_GET_SUCCESS"
export const BULK_ITEM_REQUEST_ISSUES_GET_FAILURE =
  "BULK_ITEM_REQUEST_ISSUES_GET_FAILURE"

export const BULK_ITEM_CANCEL = "BULK_ITEM_CANCEL"

// PROFILE
export const PROFILE_GET_DETAILS = "PROFILE_GET_DETAILS"
export const PROFILE_GET_DETAILS_REQUEST = "PROFILE_GET_DETAILS_REQUEST"
export const PROFILE_GET_DETAILS_SUCCESS = "PROFILE_GET_DETAILS_SUCCESS"
export const PROFILE_GET_DETAILS_FAILURE = "PROFILE_GET_DETAILS_FAILURE"

export const PROFILE_UPDATE_PASSWORD = "PROFILE_UPDATE_PASSWORD"
export const PROFILE_UPDATE_PASSWORD_REQUEST = "PROFILE_UPDATE_PASSWORD_REQUEST"
export const PROFILE_UPDATE_PASSWORD_SUCCESS = "PROFILE_UPDATE_PASSWORD_SUCCESS"
export const PROFILE_UPDATE_PASSWORD_FAILURE = "PROFILE_UPDATE_PASSWORD_FAILURE"

export const PROFILE_GET_CONTACT = "PROFILE_GET_CONTACT"
export const PROFILE_GET_CONTACT_REQUEST = "PROFILE_GET_CONTACT_REQUEST"
export const PROFILE_GET_CONTACT_SUCCESS = "PROFILE_GET_CONTACT_SUCCESS"
export const PROFILE_GET_CONTACT_FAILURE = "PROFILE_GET_CONTACT_FAILURE"
export const PROFILE_GET_CONTACT_CANCEL = "PROFILE_GET_CONTACT_CANCEL"

export const PROFILE_UPDATE_CONTACT = "PROFILE_UPDATE_CONTACT"
export const PROFILE_UPDATE_CONTACT_REQUEST = "PROFILE_UPDATE_CONTACT_REQUEST"
export const PROFILE_UPDATE_CONTACT_SUCCESS = "PROFILE_UPDATE_CONTACT_SUCCESS"
export const PROFILE_UPDATE_CONTACT_FAILURE = "PROFILE_UPDATE_CONTACT_FAILURE"

export const PROFILE_GET_FINANCIAL_DETAILS = "PROFILE_GET_FINANCIAL_DETAILS"
export const PROFILE_GET_FINANCIAL_DETAILS_REQUEST =
  "PROFILE_GET_FINANCIAL_DETAILS_REQUEST"
export const PROFILE_GET_FINANCIAL_DETAILS_SUCCESS =
  "PROFILE_GET_FINANCIAL_DETAILS_SUCCESS"
export const PROFILE_GET_FINANCIAL_DETAILS_FAILURE =
  "PROFILE_GET_FINANCIAL_DETAILS_FAILURE"
export const PROFILE_GET_FINANCIAL_DETAILS_CANCEL =
  "PROFILE_GET_FINANCIAL_DETAILS_CANCEL"

export const PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS =
  "PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS"
export const PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_REQUEST =
  "PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_REQUEST"
export const PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_SUCCESS =
  "PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_SUCCESS"
export const PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_FAILURE =
  "PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_FAILURE"
export const PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_CANCEL =
  "PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_CANCEL"

export const PROFILE_UPDATE_FINANCIAL_DETAILS =
  "PROFILE_UPDATE_FINANCIAL_DETAILS"
export const PROFILE_UPDATE_FINANCIAL_DETAILS_REQUEST =
  "PROFILE_UPDATE_FINANCIAL_DETAILS_REQUEST"
export const PROFILE_UPDATE_FINANCIAL_DETAILS_SUCCESS =
  "PROFILE_UPDATE_FINANCIAL_DETAILS_SUCCESS"
export const PROFILE_UPDATE_FINANCIAL_DETAILS_FAILURE =
  "PROFILE_UPDATE_FINANCIAL_DETAILS_FAILURE"

export const PROFILE_GET_TOOLS = "PROFILE_GET_TOOLS"
export const PROFILE_GET_TOOLS_REQUEST = "PROFILE_GET_TOOLS_REQUEST"
export const PROFILE_GET_TOOLS_SUCCESS = "PROFILE_GET_TOOLS_SUCCESS"
export const PROFILE_GET_TOOLS_FAILURE = "PROFILE_GET_TOOLS_FAILURE"

export const PROFILE_GET_TOOLS_ACTIVE = "PROFILE_GET_TOOLS_ACTIVE"
export const PROFILE_GET_TOOLS_ACTIVE_REQUEST =
  "PROFILE_GET_TOOLS_ACTIVE_REQUEST"
export const PROFILE_GET_TOOLS_ACTIVE_SUCCESS =
  "PROFILE_GET_TOOLS_ACTIVE_SUCCESS"
export const PROFILE_GET_TOOLS_ACTIVE_FAILURE =
  "PROFILE_GET_TOOLS_ACTIVE_FAILURE"

export const PROFILE_UPDATE_TOOLS_ACTIVE = "PROFILE_UPDATE_TOOLS_ACTIVE"
export const PROFILE_UPDATE_TOOLS_ACTIVE_REQUEST =
  "PROFILE_UPDATE_TOOLS_ACTIVE_REQUEST"
export const PROFILE_UPDATE_TOOLS_ACTIVE_SUCCESS =
  "PROFILE_UPDATE_TOOLS_ACTIVE_SUCCESS"
export const PROFILE_UPDATE_TOOLS_ACTIVE_FAILURE =
  "PROFILE_UPDATE_TOOLS_ACTIVE_FAILURE"

export const UPSELL_GET_LIMITS = "UPSELL_GET_LIMITS"
export const UPSELL_GET_LIMITS_REQUEST = "UPSELL_GET_LIMITS_REQUEST"
export const UPSELL_GET_LIMITS_SUCCESS = "UPSELL_GET_LIMITS_SUCCESS"
export const UPSELL_GET_LIMITS_FAILURE = "UPSELL_GET_LIMITS_FAILURE"

// INVOICES
export const INVOICES_GET = "INVOICES_GET"
export const INVOICES_GET_REQUEST = "INVOICES_GET_REQUEST"
export const INVOICES_GET_SUCCESS = "INVOICES_GET_SUCCESS"
export const INVOICES_GET_FAILURE = "INVOICES_GET_FAILURE"

export const INVOICES_CANCEL = "INVOICES_CANCEL"

export const INVOICE_GET = "INVOICE_GET"
export const INVOICE_GET_REQUEST = "INVOICE_GET_REQUEST"
export const INVOICE_GET_SUCCESS = "INVOICE_GET_SUCCESS"
export const INVOICE_GET_FAILURE = "INVOICE_GET_FAILURE"

// ACCOUNTS
export const ACCOUNTS_GET = "ACCOUNTS_GET"
export const ACCOUNTS_GET_REQUEST = "ACCOUNTS_GET_REQUEST"
export const ACCOUNTS_GET_SUCCESS = "ACCOUNTS_GET_SUCCESS"
export const ACCOUNTS_GET_FAILURE = "ACCOUNTS_GET_FAILURE"

export const ACCOUNT_GET = "ACCOUNT_GET"
export const ACCOUNT_GET_REQUEST = "ACCOUNT_GET_REQUEST"
export const ACCOUNT_GET_SUCCESS = "ACCOUNT_GET_SUCCESS"
export const ACCOUNT_GET_FAILURE = "ACCOUNT_GET_FAILURE"

export const ACCOUNTS_CANCEL = "ACCOUNTS_CANCEL"
export const ACCOUNT_CANCEL = "ACCOUNT_CANCEL"

export const ACCOUNT_CONNECT_TOKEN = "ACCOUNT_CONNECT_TOKEN"
export const ACCOUNT_REFRESH_TOKEN = "ACCOUNT_REFRESH_TOKEN"

export const ACCOUNT_CONFIRM_TOKEN = "ACCOUNT_CONFIRM_TOKEN"
export const ACCOUNT_CONFIRM_TOKEN_REQUEST = "ACCOUNT_CONFIRM_TOKEN_REQUEST"
export const ACCOUNT_CONFIRM_TOKEN_SUCCESS = "ACCOUNT_CONFIRM_TOKEN_SUCCESS"
export const ACCOUNT_CONFIRM_TOKEN_FAILURE = "ACCOUNT_CONFIRM_TOKEN_FAILURE"

export const ACCOUNT_ADD_FLOW = "ACCOUNT_ADD_FLOW"
export const ACCOUNT_ADD_FLOW_REQUEST = "ACCOUNT_ADD_FLOW_REQUEST"
export const ACCOUNT_ADD_FLOW_SUCCESS = "ACCOUNT_ADD_FLOW_SUCCESS"
export const ACCOUNT_ADD_FLOW_FAILURE = "ACCOUNT_ADD_FLOW_FAILURE"

export const ACCOUNT_UPDATE_FLOW = "ACCOUNT_UPDATE_FLOW"
export const ACCOUNT_UPDATE_FLOW_REQUEST = "ACCOUNT_UPDATE_FLOW_REQUEST"
export const ACCOUNT_UPDATE_FLOW_SUCCESS = "ACCOUNT_UPDATE_FLOW_SUCCESS"
export const ACCOUNT_UPDATE_FLOW_FAILURE = "ACCOUNT_UPDATE_FLOW_FAILURE"

export const ACCOUNT_UPDATE_BUSINESS_TYPE = "ACCOUNT_UPDATE_BUSINESS_TYPE"
export const ACCOUNT_UPDATE_BUSINESS_TYPE_REQUEST =
  "ACCOUNT_UPDATE_BUSINESS_TYPE_REQUEST"
export const ACCOUNT_UPDATE_BUSINESS_TYPE_SUCCESS =
  "ACCOUNT_UPDATE_BUSINESS_TYPE_SUCCESS"
export const ACCOUNT_UPDATE_BUSINESS_TYPE_FAILURE =
  "ACCOUNT_UPDATE_BUSINESS_TYPE_FAILURE"

// TRIAL START
export const TRIAL_START_GET = "TRIAL_START_GET"
export const TRIAL_START_GET_REQUEST = "TRIAL_START_GET_REQUEST"
export const TRIAL_START_GET_SUCCESS = "TRIAL_START_GET_SUCCESS"
export const TRIAL_START_GET_FAILURE = "TRIAL_START_GET_FAILURE"

export const TRIAL_PROLONG = "TRIAL_PROLONG"
export const TRIAL_PROLONG_REQUEST = "TRIAL_PROLONG_REQUEST"
export const TRIAL_PROLONG_SUCCESS = "TRIAL_PROLONG_SUCCESS"
export const TRIAL_PROLONG_FAILURE = "TRIAL_PROLONG_FAILURE"

export const TRIAL_START = "TRIAL_START"
export const TRIAL_START_REQUEST = "TRIAL_START_REQUEST"
export const TRIAL_START_SUCCESS = "TRIAL_START_SUCCESS"
export const TRIAL_START_FAILURE = "TRIAL_START_FAILURE"

export const TRIAL_START_CANCEL = "TRIAL_START_CANCEL"

// PLANS
export const PLANS_GET = "PLANS_GET"
export const PLANS_GET_REQUEST = "PLANS_GET_REQUEST"
export const PLANS_GET_SUCCESS = "PLANS_GET_SUCCESS"
export const PLANS_GET_FAILURE = "PLANS_GET_FAILURE"

export const PLANS_CANCEL = "PLANS_CANCEL"

// CHECKOUT
export const CHECKOUT_SELECT = "CHECKOUT_SELECT"

export const CHECKOUT_PAYMENT_STATUS = "CHECKOUT_PAYMENT_STATUS"
export const CHECKOUT_PAYMENT_STATUS_REQUEST = "CHECKOUT_PAYMENT_STATUS_REQUEST"
export const CHECKOUT_PAYMENT_STATUS_SUCCESS = "CHECKOUT_PAYMENT_STATUS_SUCCESS"
export const CHECKOUT_PAYMENT_STATUS_FAILURE = "CHECKOUT_PAYMENT_STATUS_FAILURE"

// ZUORA
export const ZUORA_FEEDBACK = "ZUORA_FEEDBACK"
export const ZUORA_FEEDBACK_REQUEST = "ZUORA_FEEDBACK_REQUEST"
export const ZUORA_FEEDBACK_SUCCESS = "ZUORA_FEEDBACK_SUCCESS"
export const ZUORA_FEEDBACK_FAILURE = "ZUORA_FEEDBACK_FAILURE"

export const ZUORA_ERROR = "ZUORA_ERROR"

// ZENDESK
export const ZENDESK_ARTICLES_GET_LIST = "ZENDESK_ARTICLES_GET_LIST"
export const ZENDESK_ARTICLES_GET_LIST_REQUEST =
  "ZENDESK_ARTICLES_GET_LIST_REQUEST"
export const ZENDESK_ARTICLES_GET_LIST_SUCCESS =
  "ZENDESK_ARTICLES_GET_LIST_SUCCESS"
export const ZENDESK_ARTICLES_GET_LIST_FAILURE =
  "ZENDESK_ARTICLES_GET_LIST_FAILURE"

export const ZENDESK_ARTICLES_CANCEL = "ZENDESK_ARTICLES_CANCEL"

export const ZENDESK_UPLOAD_FILE = "ZENDESK_UPLOAD_FILE"
export const ZENDESK_UPLOAD_FILE_REQUEST = "ZENDESK_UPLOAD_FILE_REQUEST"
export const ZENDESK_UPLOAD_FILE_SUCCESS = "ZENDESK_UPLOAD_FILE_SUCCESS"
export const ZENDESK_UPLOAD_FILE_FAILURE = "ZENDESK_UPLOAD_FILE_FAILURE"

export const ZENDESK_SUBMIT_TICKET = "ZENDESK_SUBMIT_TICKET"
export const ZENDESK_SUBMIT_TICKET_REQUEST = "ZENDESK_SUBMIT_TICKET_REQUEST"
export const ZENDESK_SUBMIT_TICKET_SUCCESS = "ZENDESK_SUBMIT_TICKET_SUCCESS"
export const ZENDESK_SUBMIT_TICKET_FAILURE = "ZENDESK_SUBMIT_TICKET_FAILURE"

export const ZENDESK_CHANNELS_GET = "ZENDESK_CHANNELS_GET"
export const ZENDESK_CHANNELS_GET_REQUEST = "ZENDESK_CHANNELS_GET_REQUEST"
export const ZENDESK_CHANNELS_GET_SUCCESS = "ZENDESK_CHANNELS_GET_SUCCESS"
export const ZENDESK_CHANNELS_GET_FAILURE = "ZENDESK_CHANNELS_GET_FAILURE"

export const ZENDESK_SUBMIT_TICKET_CANCEL = "ZENDESK_SUBMIT_TICKET_CANCEL"

// EPL

export const EPL_SITES_GET = "EPL_SITES_GET"
export const EPL_SITES_GET_REQUEST = "EPL_SITES_GET_REQUEST"
export const EPL_SITES_GET_SUCCESS = "EPL_SITES_GET_SUCCESS"
export const EPL_SITES_GET_FAILURE = "EPL_SITES_GET_FAILURE"

export const EPL_ONBOARDING_GET = "EPL_ONBOARDING_GET"
export const EPL_ONBOARDING_GET_REQUEST = "EPL_ONBOARDING_GET_REQUEST"
export const EPL_ONBOARDING_GET_SUCCESS = "EPL_ONBOARDING_GET_SUCCESS"
export const EPL_ONBOARDING_GET_FAILURE = "EPL_ONBOARDING_GET_FAILURE"

export const EPL_ONBOARDING_POST = "EPL_ONBOARDING_POST"
export const EPL_ONBOARDING_POST_REQUEST = "EPL_ONBOARDING_POST_REQUEST"
export const EPL_ONBOARDING_POST_SUCCESS = "EPL_ONBOARDING_POST_SUCCESS"
export const EPL_ONBOARDING_POST_FAILURE = "EPL_ONBOARDING_POST_FAILURE"

export const EPL_CAMPAIGN_REPORTS_GET = "EPL_CAMPAIGN_REPORTS_GET"
export const EPL_CAMPAIGN_REPORTS_GET_REQUEST =
  "EPL_CAMPAIGN_REPORTS_GET_REQUEST"
export const EPL_CAMPAIGN_REPORTS_GET_SUCCESS =
  "EPL_CAMPAIGN_REPORTS_GET_SUCCESS"
export const EPL_CAMPAIGN_REPORTS_GET_FAILURE =
  "EPL_CAMPAIGN_REPORTS_GET_FAILURE"

export const EPL_AGGREGATED_CAMPAIGN_REPORT_GET =
  "EPL_AGGREGATED_CAMPAIGN_REPORT_GET"
export const EPL_AGGREGATED_CAMPAIGN_REPORT_GET_REQUEST =
  "EPL_AGGREGATED_CAMPAIGN_REPORT_GET_REQUEST"
export const EPL_AGGREGATED_CAMPAIGN_REPORT_GET_SUCCESS =
  "EPL_AGGREGATED_CAMPAIGN_REPORT_GET_SUCCESS"
export const EPL_AGGREGATED_CAMPAIGN_REPORT_GET_FAILURE =
  "EPL_AGGREGATED_CAMPAIGN_REPORT_GET_FAILURE"

export const EPL_CAMPAIGN_CHART_DATA_GET = "EPL_CAMPAIGN_CHART_DATA_GET"
export const EPL_CAMPAIGN_CHART_DATA_GET_REQUEST =
  "EPL_CAMPAIGN_CHART_DATA_GET_REQUEST"
export const EPL_CAMPAIGN_CHART_DATA_GET_SUCCESS =
  "EPL_CAMPAIGN_CHART_DATA_GET_SUCCESS"
export const EPL_CAMPAIGN_CHART_DATA_GET_FAILURE =
  "EPL_CAMPAIGN_CHART_DATA_GET_FAILURE"

export const EPL_SAVE_CAMPAIGN_CHART_OPTION = "EPL_SAVE_CAMPAIGN_CHART_OPTION"

export const EPL_REPORTS_GET = "EPL_REPORTS_GET"
export const EPL_REPORTS_GET_REQUEST = "EPL_REPORTS_GET_REQUEST"
export const EPL_REPORTS_GET_SUCCESS = "EPL_REPORTS_GET_SUCCESS"
export const EPL_REPORTS_GET_FAILURE = "EPL_REPORTS_GET_FAILURE"
export const EPL_REPORTS_GET_CANCEL = "EPL_REPORTS_GET_CANCEL"

export const EPL_MISSING_REPORTS_GET = "EPL_MISSING_REPORTS_GET"
export const EPL_MISSING_REPORTS_GET_REQUEST = "EPL_MISSING_REPORTS_GET_REQUEST"
export const EPL_MISSING_REPORTS_GET_SUCCESS = "EPL_MISSING_REPORTS_GET_SUCCESS"
export const EPL_MISSING_REPORTS_GET_FAILURE = "EPL_MISSING_REPORTS_GET_FAILURE"
export const EPL_MISSING_REPORTS_GET_CANCEL = "EPL_MISSING_REPORTS_GET_CANCEL"

export const EPL_CAMPAIGN_GET = "EPL_CAMPAIGN_GET"
export const EPL_CAMPAIGN_CANCEL = "EPL_CAMPAIGN_CANCEL"
export const EPL_CAMPAIGN_CLEAR = "EPL_CAMPAIGN_CLEAR"
export const EPL_CAMPAIGN_GET_REQUEST = "EPL_CAMPAIGN_GET_REQUEST"
export const EPL_CAMPAIGN_GET_SUCCESS = "EPL_CAMPAIGN_GET_SUCCESS"
export const EPL_CAMPAIGN_GET_FAILURE = "EPL_CAMPAIGN_GET_FAILURE"

export const EPL_ADRATE_STRATEGY_GET = "EPL_ADRATE_STRATEGY_GET"
export const EPL_ADRATE_STRATEGY_GET_REQUEST = "EPL_ADRATE_STRATEGY_GET_REQUEST"
export const EPL_ADRATE_STRATEGY_GET_SUCCESS = "EPL_ADRATE_STRATEGY_GET_SUCCESS"
export const EPL_ADRATE_STRATEGY_GET_FAILURE = "EPL_ADRATE_STRATEGY_GET_FAILURE"

export const EPL_ADRATE_STRATEGY_POST = "EPL_ADRATE_STRATEGY_POST"
export const EPL_ADRATE_STRATEGY_POST_REQUEST =
  "EPL_ADRATE_STRATEGY_POST_REQUEST"
export const EPL_ADRATE_STRATEGY_POST_SUCCESS =
  "EPL_ADRATE_STRATEGY_POST_SUCCESS"
export const EPL_ADRATE_STRATEGY_POST_FAILURE =
  "EPL_ADRATE_STRATEGY_POST_FAILURE"

export const EPL_ADRATE_HISTORY_GET = "EPL_ADRATE_HISTORY_GET"
export const EPL_ADRATE_HISTORY_GET_REQUEST = "EPL_ADRATE_HISTORY_GET_REQUEST"
export const EPL_ADRATE_HISTORY_GET_SUCCESS = "EPL_ADRATE_HISTORY_GET_SUCCESS"
export const EPL_ADRATE_HISTORY_GET_FAILURE = "EPL_ADRATE_HISTORY_GET_FAILURE"

export const EPL_TOS_GET = "EPL_TOS_GET"
export const EPL_TOS_GET_REQUEST = "EPL_TOS_GET_REQUEST"
export const EPL_TOS_GET_SUCCESS = "EPL_TOS_GET_SUCCESS"
export const EPL_TOS_GET_FAILURE = "EPL_TOS_GET_FAILURE"

export const EPL_OPT_OUT_GET = "EPL_OPT_OUT_GET"
export const EPL_OPT_OUT_GET_REQUEST = "EPL_OPT_OUT_GET_REQUEST"
export const EPL_OPT_OUT_GET_SUCCESS = "EPL_OPT_OUT_GET_SUCCESS"
export const EPL_OPT_OUT_GET_FAILURE = "EPL_OPT_OUT_GET_FAILURE"

export const EPL_OPT_OUT_POST = "EPL_OPT_OUT_POST"
export const EPL_OPT_OUT_POST_REQUEST = "EPL_OPT_OUT_POST_REQUEST"
export const EPL_OPT_OUT_POST_SUCCESS = "EPL_OPT_OUT_POST_SUCCESS"
export const EPL_OPT_OUT_POST_FAILURE = "EPL_OPT_OUT_POST_FAILURE"

export const EPL_HAD_PROMOTED_ITEMS_GET = " EPL_HAD_PROMOTED_ITEMS_GET"
export const EPL_HAD_PROMOTED_ITEMS_GET_REQUEST =
  " EPL_HAD_PROMOTED_ITEMS_GET_REQUEST"
export const EPL_HAD_PROMOTED_ITEMS_GET_SUCCESS =
  " EPL_HAD_PROMOTED_ITEMS_GET_SUCCESS"
export const EPL_HAD_PROMOTED_ITEMS_GET_FAILURE =
  " EPL_HAD_PROMOTED_ITEMS_GET_FAILURE"

export const EPL_CHURN_REASON_POST = " EPL_CHURN_REASON_POST"
export const EPL_CHURN_REASON_POST_REQUEST = " EPL_CHURN_REASON_POST_REQUEST"
export const EPL_CHURN_REASON_POST_SUCCESS = " EPL_CHURN_REASON_POST_SUCCESS"
export const EPL_CHURN_REASON_POST_FAILURE = " EPL_CHURN_REASON_POST_FAILURE"

export const EPL_LOAD = "EPL_LOAD"
export const EPL_LOAD_SUCCESS = "EPL_LOAD_SUCCESS"
export const EPL_LOAD_FAILURE = "EPL_LOAD_FAILURE"

export const EPL_NON_CRITICAL_FAILURE = "EPL_NON_CRITICAL_FAILURE"

export const EPL_UNACCEPTED_TOS_ALERT = "EPL_UNACCEPTED_TOS_ALERT"
export const EPL_UNACCEPTED_TOS_WARNING = "EPL_UNACCEPTED_TOS_WARNING"
export const EPL_END_OWN_CAMPAIGNS_ALERT = "EPL_END_OWN_CAMPAIGNS_ALERT"

export const EPL_AD_RATE_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_AD_RATE_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_PLAN_UPGRADE_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_PLAN_UPGRADE_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_MARKET_CLOSE_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_MARKET_CLOSE_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_MARKET_OPEN_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_MARKET_OPEN_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_MARKET_RESUME_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_MARKET_RESUME_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_MARKET_PAUSE_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_MARKET_PAUSE_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_CREATE_ZENDESK_CHURN_TICKET_POST_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_CREATE_ZENDESK_CHURN_TICKET_POST_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_OPT_OUT_POST_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_OPT_OUT_POST_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_EXCLUDE_ITEMS_BY_FILE_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_EXCLUDE_ITEMS_BY_FILE_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_ITEMS_STATUS_GET_WITH_RESULTS_NOTIFICATION_CLEAR =
  "EPL_ITEMS_STATUS_GET_WITH_RESULTS_NOTIFICATION_CLEAR"
export const EPL_START_POST_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_START_POST_SUCCESS_NOTIFICATION_CLEAR"
export const EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS_NOTIFICATION_CLEAR =
  "EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS_NOTIFICATION_CLEAR"

export const EPL_PLAN_INTERNATIONAL_UPGRADE_POST =
  "EPL_PLAN_INTERNATIONAL_UPGRADE_POST"
export const EPL_PLAN_INTERNATIONAL_UPGRADE_POST_REQUEST =
  "EPL_PLAN_INTERNATIONAL_UPGRADE_POST_REQUEST"
export const EPL_PLAN_INTERNATIONAL_UPGRADE_POST_SUCCESS =
  "EPL_PLAN_INTERNATIONAL_UPGRADE_POST_SUCCESS"
export const EPL_PLAN_INTERNATIONAL_UPGRADE_POST_FAILURE =
  "EPL_PLAN_INTERNATIONAL_UPGRADE_POST_FAILURE"

export const EPL_PLAN_DOMESTIC_UPGRADE_POST = "EPL_PLAN_DOMESTIC_UPGRADE_POST"
export const EPL_PLAN_DOMESTIC_UPGRADE_POST_REQUEST =
  "EPL_PLAN_DOMESTIC_UPGRADE_POST_REQUEST"
export const EPL_PLAN_DOMESTIC_UPGRADE_POST_SUCCESS =
  "EPL_PLAN_DOMESTIC_UPGRADE_POST_SUCCESS"
export const EPL_PLAN_DOMESTIC_UPGRADE_POST_FAILURE =
  "EPL_PLAN_DOMESTIC_UPGRADE_POST_FAILURE"

export const EPL_MARKETS_GET = "EPL_MARKETS_GET"
export const EPL_MARKETS_GET_REQUEST = "EPL_MARKETS_GET_REQUEST"
export const EPL_MARKETS_GET_SUCCESS = "EPL_MARKETS_GET_SUCCESS"
export const EPL_MARKETS_GET_FAILURE = "EPL_MARKETS_GET_FAILURE"

export const EPL_ALL_MARKETS_GET = "EPL_ALL_MARKETS_GET"
export const EPL_ALL_MARKETS_GET_REQUEST = "EPL_ALL_MARKETS_GET_REQUEST"
export const EPL_ALL_MARKETS_GET_SUCCESS = "EPL_ALL_MARKETS_GET_SUCCESS"
export const EPL_ALL_MARKETS_GET_FAILURE = "EPL_ALL_MARKETS_GET_FAILURE"

export const EPL_MARKETS_CLOSE_POST = "EPL_MARKETS_CLOSE_POST"
export const EPL_MARKETS_CLOSE_POST_REQUEST = "EPL_MARKETS_CLOSE_POST_REQUEST"
export const EPL_MARKETS_CLOSE_POST_SUCCESS = "EPL_MARKETS_CLOSE_POST_SUCCESS"
export const EPL_MARKETS_CLOSE_POST_FAILURE = "EPL_MARKETS_CLOSE_POST_FAILURE"

export const EPL_MARKETS_OPEN_POST = "EPL_MARKETS_OPEN_POST"
export const EPL_MARKETS_OPEN_POST_REQUEST = "EPL_MARKETS_OPEN_POST_REQUEST"
export const EPL_MARKETS_OPEN_POST_SUCCESS = "EPL_MARKETS_OPEN_POST_SUCCESS"
export const EPL_MARKETS_OPEN_POST_FAILURE = "EPL_MARKETS_OPEN_POST_FAILURE"

export const EPL_MARKETS_PAUSE_POST = "EPL_MARKETS_PAUSE_POST"
export const EPL_MARKETS_PAUSE_POST_REQUEST = "EPL_MARKETS_PAUSE_POST_REQUEST"
export const EPL_MARKETS_PAUSE_POST_SUCCESS = "EPL_MARKETS_PAUSE_POST_SUCCESS"
export const EPL_MARKETS_PAUSE_POST_FAILURE = "EPL_MARKETS_PAUSE_POST_FAILURE"

export const EPL_MARKETS_RESUME_POST = "EPL_MARKETS_RESUME_POST"
export const EPL_MARKETS_RESUME_POST_REQUEST = "EPL_MARKETS_RESUME_POST_REQUEST"
export const EPL_MARKETS_RESUME_POST_SUCCESS = "EPL_MARKETS_RESUME_POST_SUCCESS"
export const EPL_MARKETS_RESUME_POST_FAILURE = "EPL_MARKETS_RESUME_POST_FAILURE"

export const EPL_SET_AD_RATE_FOR_MARKET_PATCH =
  "EPL_SET_AD_RATE_FOR_MARKET_PATCH"
export const EPL_SET_AD_RATE_FOR_MARKET_PATCH_REQUEST =
  "EPL_SET_AD_RATE_FOR_MARKET_PATCH_REQUEST"
export const EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS =
  "EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS"
export const EPL_SET_AD_RATE_FOR_MARKET_PATCH_FAILURE =
  "EPL_SET_AD_RATE_FOR_MARKET_PATCH_FAILURE"

export const EPL_TRIGGER_MODAL = "EPL_TRIGGER_MODAL"

export const EPL_PLAN_UPGRADE_MODAL_SHOW = "EPL_PLAN_UPGRADE_MODAL_SHOW"
export const EPL_PLAN_UPGRADE_MODAL_HIDE = "EPL_PLAN_UPGRADE_MODAL_HIDE"

export const EPL_RESUBSCRIBE_MODAL_SHOW = "EPL_RESUBSCRIBE_MODAL_SHOW"
export const EPL_RESUBSCRIBE_MODAL_HIDE = "EPL_RESUBSCRIBE_MODAL_HIDE"

export const EPL_PLAN_OPT_OUT_MODAL_SHOW = "EPL_PLAN_OPT_OUT_MODAL_SHOW"
export const EPL_PLAN_OPT_OUT_MODAL_HIDE = "EPL_PLAN_OPT_OUT_MODAL_HIDE"

export const EPL_MARKET_OPEN_MODAL_SHOW = "EPL_MARKET_OPEN_MODAL_SHOW"
export const EPL_MARKET_OPEN_MODAL_HIDE = "EPL_MARKET_OPEN_MODAL_HIDE"

export const EPL_MARKET_CLOSE_MODAL_SHOW = "EPL_MARKET_CLOSE_MODAL_SHOW"
export const EPL_MARKET_CLOSE_MODAL_HIDE = "EPL_MARKET_CLOSE_MODAL_HIDE"

export const EPL_MARKET_RESUME_MODAL_SHOW = "EPL_MARKET_RESUME_MODAL_SHOW"
export const EPL_MARKET_RESUME_MODAL_HIDE = "EPL_MARKET_RESUME_MODAL_HIDE"

export const EPL_MARKET_PAUSE_MODAL_SHOW = "EPL_MARKET_PAUSE_MODAL_SHOW"
export const EPL_MARKET_PAUSE_MODAL_HIDE = "EPL_MARKET_PAUSE_MODAL_HIDE"

export const EPL_EXCLUDE_ITEMS_BY_FILE_MODAL_SHOW =
  "EPL_EXCLUDE_ITEMS_BY_FILE_MODAL_SHOW"
export const EPL_EXCLUDE_ITEMS_BY_FILE_MODAL_HIDE =
  "EPL_EXCLUDE_ITEMS_BY_FILE_MODAL_HIDE"

export const EPL_ITEMS_EXCLUDED_RESET_ALL_MODAL_SHOW =
  "EPL_ITEMS_EXCLUDED_RESET_ALL_MODAL_SHOW"
export const EPL_ITEMS_EXCLUDED_RESET_ALL_MODAL_HIDE =
  "EPL_ITEMS_EXCLUDED_RESET_ALL_MODAL_HIDE"

export const EPL_UPSELL_MODAL_SHOW = "EPL_UPSELL_MODAL_SHOW"
export const EPL_UPSELL_MODAL_HIDE = "EPL_UPSELL_MODAL_HIDE"

export const EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_SHOW =
  "EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_SHOW"
export const EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_HIDE =
  "EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_HIDE"

export const EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_SHOW =
  "EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_SHOW"
export const EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_HIDE =
  "EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_HIDE"

export const EPL_ADD_USER_STATE = "EPL_ADD_USER_STATE"

export const EPL_CREATE_ZENDESK_CHURN_TICKET_POST =
  "EPL_CREATE_ZENDESK_CHURN_TICKET_POST"
export const EPL_CREATE_ZENDESK_CHURN_TICKET_POST_REQUEST =
  "EPL_CREATE_ZENDESK_CHURN_TICKET_POST_REQUEST"
export const EPL_CREATE_ZENDESK_CHURN_TICKET_POST_SUCCESS =
  "EPL_CREATE_ZENDESK_CHURN_TICKET_POST_SUCCESS"
export const EPL_CREATE_ZENDESK_CHURN_TICKET_POST_FAILURE =
  "EPL_CREATE_ZENDESK_CHURN_TICKET_POST_FAILURE"

export const EPL_ITEMS_LOAD = "EPL_ITEMS_LOAD"
export const EPL_ITEMS_LOAD_SUCCESS = "EPL_ITEMS_LOAD_SUCCESS"
export const EPL_ITEMS_LOAD_FAILURE = "EPL_ITEMS_LOAD_FAILURE"

export const EPL_ITEMS_EXCLUDE_BY_FILE_POST = "EPL_ITEMS_EXCLUDE_BY_FILE_POST"
export const EPL_ITEMS_EXCLUDE_BY_FILE_POST_REQUEST =
  "EPL_ITEMS_EXCLUDE_BY_FILE_POST_REQUEST"
export const EPL_ITEMS_EXCLUDE_BY_FILE_POST_SUCCESS =
  "EPL_ITEMS_EXCLUDE_BY_FILE_POST_SUCCESS"
export const EPL_ITEMS_EXCLUDE_BY_FILE_POST_FAILURE =
  "EPL_ITEMS_EXCLUDE_BY_FILE_POST_FAILURE"

export const EPL_ITEMS_LIST_GET = "EPL_ITEMS_LIST_GET"
export const EPL_ITEMS_LIST_GET_REQUEST = "EPL_ITEMS_LIST_GET_REQUEST"
export const EPL_ITEMS_LIST_GET_SUCCESS = "EPL_ITEMS_LIST_GET_SUCCESS"
export const EPL_ITEMS_LIST_GET_FAILURE = "EPL_ITEMS_LIST_GET_FAILURE"

export const EPL_ITEMS_STATUS_GET = "EPL_ITEMS_STATUS_GET"
export const EPL_ITEMS_STATUS_GET_REQUEST = "EPL_ITEMS_STATUS_GET_REQUEST"
export const EPL_ITEMS_STATUS_GET_SUCCESS = "EPL_ITEMS_STATUS_GET_SUCCESS"
export const EPL_ITEMS_STATUS_GET_FAILURE = "EPL_ITEMS_STATUS_GET_FAILURE"
export const EPL_ITEMS_STATUS_GET_WITH_RESULTS =
  "EPL_ITEMS_STATUS_GET_WITH_RESULTS"

export const EPL_ITEMS_EXCLUDED_RESET_ALL = "EPL_ITEMS_EXCLUDED_RESET_ALL"
export const EPL_ITEMS_EXCLUDED_RESET_ALL_REQUEST =
  "EPL_ITEMS_EXCLUDED_RESET_ALL_REQUEST"
export const EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS =
  "EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS"
export const EPL_ITEMS_EXCLUDED_RESET_ALL_FAILURE =
  "EPL_ITEMS_EXCLUDED_RESET_ALL_FAILURE"

export const EPL_ITEMS_EXCLUDED_RESET_SELECTED =
  "EPL_ITEMS_EXCLUDED_RESET_SELECTED"
export const EPL_ITEMS_EXCLUDED_RESET_SELECTED_REQUEST =
  "EPL_ITEMS_EXCLUDED_RESET_SELECTED_REQUEST"
export const EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS =
  "EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS"
export const EPL_ITEMS_EXCLUDED_RESET_SELECTED_FAILURE =
  "EPL_ITEMS_EXCLUDED_RESET_SELECTED_FAILURE"

export const EPL_ITEMS_EXCLUDE_SELECTED = "EPL_ITEMS_EXCLUDE_SELECTED"
export const EPL_ITEMS_EXCLUDE_SELECTED_REQUEST =
  "EPL_ITEMS_EXCLUDE_SELECTED_REQUEST"
export const EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS =
  "EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS"
export const EPL_ITEMS_EXCLUDE_SELECTED_FAILURE =
  "EPL_ITEMS_EXCLUDE_SELECTED_FAILURE"

export const EPL_EXCLUDE_ITEMS_LIST_GET = "EPL_EXCLUDE_ITEMS_LIST_GET"
export const EPL_EXCLUDE_ITEMS_LIST_GET_REQUEST =
  "EPL_EXCLUDE_ITEMS_LIST_GET_REQUEST"
export const EPL_EXCLUDE_ITEMS_LIST_GET_SUCCESS =
  "EPL_EXCLUDE_ITEMS_LIST_GET_SUCCESS"
export const EPL_EXCLUDE_ITEMS_LIST_GET_FAILURE =
  "EPL_EXCLUDE_ITEMS_LIST_GET_FAILURE"

export const EPL_EXCLUDE_ITEMS_STATUS_GET = "EPL_EXCLUDE_ITEMS_STATUS_GET"
export const EPL_EXCLUDE_ITEMS_STATUS_GET_REQUEST =
  "EPL_EXCLUDE_ITEMS_STATUS_GET_REQUEST"
export const EPL_EXCLUDE_ITEMS_STATUS_GET_SUCCESS =
  "EPL_EXCLUDE_ITEMS_STATUS_GET_SUCCESS"
export const EPL_EXCLUDE_ITEMS_STATUS_GET_FAILURE =
  "EPL_EXCLUDE_ITEMS_STATUS_GET_FAILURE"

export const EPL_ACCOUNTS_GET = "EPL_ACCOUNTS_GET"
export const EPL_ACCOUNTS_GET_REQUEST = "EPL_ACCOUNTS_GET_REQUEST"
export const EPL_ACCOUNTS_GET_SUCCESS = "EPL_ACCOUNTS_GET_SUCCESS"
export const EPL_ACCOUNTS_GET_FAILURE = "EPL_ACCOUNTS_GET_FAILURE"

export const EPL_START_BOTH_TRIALS = "EPL_START_BOTH_TRIALS"
export const EPL_START_BOTH_TRIALS_REQUEST = "EPL_START_BOTH_TRIALS_REQUEST"
export const EPL_START_BOTH_TRIALS_SUCCESS = "EPL_START_BOTH_TRIALS_SUCCESS"
export const EPL_START_BOTH_TRIALS_FAILURE = "EPL_START_BOTH_TRIALS_FAILURE"

export const EPL_START_TRIAL_POST = "EPL_START_TRIAL_POST"
export const EPL_START_TRIAL_POST_REQUEST = "EPL_START_TRIAL_POST_REQUEST"
export const EPL_START_TRIAL_POST_SUCCESS = "EPL_START_TRIAL_POST_SUCCESS"
export const EPL_START_TRIAL_POST_FAILURE = "EPL_START_TRIAL_POST_FAILURE"

export const EPL_START_INTERNATIONAL_TRIAL_POST =
  "EPL_START_INTERNATIONAL_TRIAL_POST"
export const EPL_START_INTERNATIONAL_TRIAL_POST_REQUEST =
  "EPL_START_INTERNATIONAL_TRIAL_POST_REQUEST"
export const EPL_START_INTERNATIONAL_TRIAL_POST_SUCCESS =
  "EPL_START_INTERNATIONAL_TRIAL_POST_SUCCESS"
export const EPL_START_INTERNATIONAL_TRIAL_POST_FAILURE =
  "EPL_START_INTERNATIONAL_TRIAL_POST_FAILURE"

export const EPL_START_DOMESTIC_TRIAL_POST = "EPL_START_DOMESTIC_TRIAL_POST"
export const EPL_START_DOMESTIC_TRIAL_POST_REQUEST =
  "EPL_START_DOMESTIC_TRIAL_POST_REQUEST"
export const EPL_START_DOMESTIC_TRIAL_POST_SUCCESS =
  "EPL_START_DOMESTIC_TRIAL_POST_SUCCESS"
export const EPL_START_DOMESTIC_TRIAL_POST_FAILURE =
  "EPL_START_DOMESTIC_TRIAL_POST_FAILURE"

export const EPL_START_BOTH_STANDARDS = "EPL_START_BOTH_STANDARDS"
export const EPL_START_BOTH_STANDARDS_REQUEST =
  "EPL_START_BOTH_STANDARDS_REQUEST"
export const EPL_START_BOTH_STANDARDS_SUCCESS =
  "EPL_START_BOTH_STANDARDS_SUCCESS"
export const EPL_START_BOTH_STANDARDS_FAILURE =
  "EPL_START_BOTH_STANDARDS_FAILURE"

export const EPL_START_STANDARD_POST = "EPL_START_STANDARD_POST"
export const EPL_START_STANDARD_POST_REQUEST = "EPL_START_STANDARD_POST_REQUEST"
export const EPL_START_STANDARD_POST_SUCCESS = "EPL_START_STANDARD_POST_SUCCESS"
export const EPL_START_STANDARD_POST_FAILURE = "EPL_START_STANDARD_POST_FAILURE"

export const EPL_START_INTERNATIONAL_STANDARD_POST =
  "EPL_START_INTERNATIONAL_STANDARD_POST"
export const EPL_START_INTERNATIONAL_STANDARD_POST_REQUEST =
  "EPL_START_INTERNATIONAL_STANDARD_POST_REQUEST"
export const EPL_START_INTERNATIONAL_STANDARD_POST_SUCCESS =
  "EPL_START_INTERNATIONAL_STANDARD_POST_SUCCESS"
export const EPL_START_INTERNATIONAL_STANDARD_POST_FAILURE =
  "EPL_START_INTERNATIONAL_STANDARD_POST_FAILURE"

export const EPL_START_DOMESTIC_STANDARD_POST =
  "EPL_START_DOMESTIC_STANDARD_POST"
export const EPL_START_DOMESTIC_STANDARD_POST_REQUEST =
  "EPL_START_DOMESTIC_STANDARD_POST_REQUEST"
export const EPL_START_DOMESTIC_STANDARD_POST_SUCCESS =
  "EPL_START_DOMESTIC_STANDARD_POST_SUCCESS"
export const EPL_START_DOMESTIC_STANDARD_POST_FAILURE =
  "EPL_START_DOMESTIC_STANDARD_POST_FAILURE"

export const EPL_DASHBOARD_REPORT_DATA_LOAD = "EPL_DASHBOARD_REPORT_DATA_LOAD"
export const EPL_DASHBOARD_REPORT_DATA_LOAD_REQUEST =
  "EPL_DASHBOARD_REPORT_DATA_LOAD_REQUEST"
export const EPL_DASHBOARD_REPORT_DATA_LOAD_SUCCESS =
  "EPL_DASHBOARD_REPORT_DATA_LOAD_SUCCESS"
export const EPL_DASHBOARD_REPORT_DATA_LOAD_FAILURE =
  "EPL_DASHBOARD_REPORT_DATA_LOAD_FAILURE"

export const EPL_DASHBOARD_REPORT_DATA_GET = "EPL_DASHBOARD_REPORT_DATA_GET"
export const EPL_DASHBOARD_REPORT_DATA_GET_REQUEST =
  "EPL_DASHBOARD_REPORT_DATA_GET_REQUEST"
export const EPL_DASHBOARD_REPORT_DATA_GET_SUCCESS =
  "EPL_DASHBOARD_REPORT_DATA_GET_SUCCESS"
export const EPL_DASHBOARD_REPORT_DATA_GET_FAILURE =
  "EPL_DASHBOARD_REPORT_DATA_GET_FAILURE"

export const EPL_CHECK_ELIGIBILITY = "EPL_CHECK_ELIGIBILITY"
export const EPL_CHECK_ELIGIBILITY_SUCCESS = "EPL_CHECK_ELIGIBILITY_SUCCESS"
export const EPL_CHECK_ELIGIBILITY_FAILURE = "EPL_CHECK_ELIGIBILITY_FAILURE"

export const EPL_REPEAT_CHECK_ELIGIBILITY = "EPL_REPEAT_CHECK_ELIGIBILITY"

export const EPL_ONBOARDING_SHOW_PLANS = "EPL_ONBOARDING_SHOW_PLANS"

export const EPL_DATA_FOR_ACCOUNTS_VIEW = "EPL_DATA_FOR_ACCOUNTS_VIEW"
export const EPL_DATA_FOR_ACCOUNTS_VIEW_SUCCESS =
  "EPL_DATA_FOR_ACCOUNTS_VIEW_SUCCESS"
export const EPL_DATA_FOR_ACCOUNTS_VIEW_FAILURE =
  "EPL_DATA_FOR_ACCOUNTS_VIEW_FAILURE"

export const EPL_LOCAL_DATA = {
  EPL_LOCAL_STORAGE: "EPL_LOCAL_STORAGE",
  SHOW_MODAL_ALL_BLOCKED: "SHOW_MODAL_ALL_BLOCKED",
  SHOW_MODAL_SOME_BLOCKED: "SHOW_MODAL_SOME_BLOCKED",
  DONT_SHOW: "DONT_SHOW",
  ALL: "all",
  SOME: "some",
  ELIGIBILITY_CHECK: "eligibilityCheck",
  SHOULD_SEE_EPL_RESULTS: "shouldSeeEPLResult",
  VISITED_EPL_TOS: "visitedEplTOS",
  AGGREGATED_REPORT: "aggregatedReport",
  SHOW_END_OWN_CAMPAIGNS_ALERT: "endOwnCampaigns",
}

// ENTITLEMENTS

export const ENTITLEMENTS_CUSTOM_GET = "ENTITLEMENTS_CUSTOM_GET"
export const ENTITLEMENTS_CUSTOM_GET_REQUEST = "ENTITLEMENTS_CUSTOM_GET_REQUEST"
export const ENTITLEMENTS_CUSTOM_GET_SUCCESS = "ENTITLEMENTS_CUSTOM_GET_SUCCESS"
export const ENTITLEMENTS_CUSTOM_GET_FAILURE = "ENTITLEMENTS_CUSTOM_GET_FAILURE"

// SURVEYS
export const TRIGGER_SURVEY = "TRIGGER_SURVEY"
export const SHOW_POST_MARKET_CLOSE_SURVEY = "SHOW_POST_MARKET_CLOSE_SURVEY"
export const SHOW_POST_EPL_PLAN_UPGRADE_CANCEL_SURVEY =
  "SHOW_POST_EPL_PLAN_UPGRADE_CANCEL_SURVEY"
export const HIDE_SURVEY = "HIDE_SURVEY"

export const LOADING_STATES = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  FAILED: "FAILED",
}

export const OFFER_TYPE = {
  wi: "wi",
  all: "all",
  customer: "customer",
}

export const OFFER_TITLE = {
  wi: "International items",
  all: "All items",
  customer: "Your items",
}

export const AD_RATE_STRATEGIES = {
  DEFAULT: "default",
  FLAT: "flat",
  RANGE: "range",
}

export const ELIGIBILITY_STATUS = {
  NOT_PROCESSED: "not_processed",
  ELIGIBLE: "eligible",
  NOT_ELIGIBLE: "not_eligible",
}
