import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  TRIAL_START_GET,
  SHIPPING_PROFILES_GET_DEFAULT,
  SHIPPING_PROFILES_GET_DEFAULT_REQUEST,
  SHIPPING_PROFILES_GET_DEFAULT_SUCCESS,
  SHIPPING_PROFILES_GET_DEFAULT_FAILURE,
  SHIPPING_PROFILES_GET_LIST,
  SHIPPING_PROFILES_GET_LIST_REQUEST,
  SHIPPING_PROFILES_GET_LIST_SUCCESS,
  SHIPPING_PROFILES_GET_LIST_FAILURE,
  PRODUCTS_GET_SHIPPING,
  SHIPPING_PROFILES_CANCEL,
} from "../../constants"

import {
  getDefault as getDefaultProfile,
  getAll,
} from "../../fetch/shippingProfiles/list"

function* getDefault() {
  while (true) {
    const action = yield take([TRIAL_START_GET, SHIPPING_PROFILES_GET_DEFAULT])
    const { user } = yield select()
    yield put({ type: SHIPPING_PROFILES_GET_DEFAULT_REQUEST })
    try {
      const { data } = yield call(() =>
        getDefaultProfile(user.id, action.params)
      )
      if (data) {
        yield put({
          type: SHIPPING_PROFILES_GET_DEFAULT_SUCCESS,
          data: data[0],
        })
      }
    } catch (error) {
      yield put({ type: SHIPPING_PROFILES_GET_DEFAULT_FAILURE, error })
    }
  }
}

function* getList() {
  while (true) {
    const action = yield take([TRIAL_START_GET, SHIPPING_PROFILES_GET_LIST])
    const { user } = yield select()
    yield put({ type: SHIPPING_PROFILES_GET_LIST_REQUEST })
    try {
      const { data } = yield call(() => getAll(user.id, action.params))

      if (data) {
        yield put({
          type: SHIPPING_PROFILES_GET_LIST_SUCCESS,
          custom: data.map(p => ({
            ...p,
            items: [],
          })),
        })
        // GET PROFILE ITEMS
        yield put({
          type: PRODUCTS_GET_SHIPPING,
          items: data.map(p => p.profile.profile_id),
        })
      }
    } catch (error) {
      yield put({ type: SHIPPING_PROFILES_GET_LIST_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getDefault), fork(getList)])

    yield all(tasks)

    yield take(SHIPPING_PROFILES_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
