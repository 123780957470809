import { all, select, put, fork, take } from "redux-saga/effects"

import {
  PRODUCTS_GET_SHIPPING,
  PRODUCTS_GET_SHIPPING_REQUEST,
  PRODUCTS_GET_SHIPPING_SUCCESS,
  PRODUCTS_GET_SHIPPING_FAILURE,
  SHIPPING_PROFILES_CANCEL,
} from "../../constants"

import { get as getItems } from "../../fetch/shippingProfiles/products"

function* getProfileItems() {
  while (true) {
    const action = yield take([PRODUCTS_GET_SHIPPING])
    const { general, user } = yield select()
    yield put({ type: PRODUCTS_GET_SHIPPING_REQUEST })
    try {
      const data = {}
      yield all(
        action.items.map(async profileId => {
          const res = await getItems(
            user.id,
            general.activePlatform.toUpperCase(),
            profileId
          )
          data[profileId] = res.data.item_ids
        })
      )

      yield put({ type: PRODUCTS_GET_SHIPPING_SUCCESS, data })
    } catch (error) {
      yield put({ type: PRODUCTS_GET_SHIPPING_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getProfileItems)])

    yield all(tasks)

    yield take(SHIPPING_PROFILES_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
