import {
  all,
  putResolve,
  put,
  race,
  fork,
  take,
  takeLatest,
  call,
  select,
  delay,
} from "redux-saga/effects"
import { add } from "react-redux-permissions"
import {
  EPL_PLAN_INTERNATIONAL_UPGRADE_POST_SUCCESS,
  EPL_PLAN_DOMESTIC_UPGRADE_POST_SUCCESS,
  EPL_OPT_OUT_POST_SUCCESS,
  EPL_START_TRIAL_POST_SUCCESS,
  EPL_START_BOTH_TRIALS,
  EPL_START_BOTH_TRIALS_SUCCESS,
  EPL_START_BOTH_TRIALS_FAILURE,
  EPL_START_STANDARD_POST_SUCCESS,
  EPL_START_BOTH_STANDARDS,
  EPL_START_BOTH_STANDARDS_SUCCESS,
  EPL_START_BOTH_STANDARDS_FAILURE,
  EPL_CHECK_ELIGIBILITY,
  EPL_CHECK_ELIGIBILITY_SUCCESS,
  EPL_CHECK_ELIGIBILITY_FAILURE,
  EPL_REPEAT_CHECK_ELIGIBILITY,
  ELIGIBILITY_STATUS,
} from "data/constants"

import {
  getUserOnboarding,
  addEPLUserState,
  startDomesticTrial,
  startInternationalTrial,
  getOptOutStatus,
  onboard,
  startDomesticStandard,
  startInternationalStandard,
} from "data/actions/epl/epl"

import { getAccounts as checkStatus } from "data/fetch/epl/epl"

import { createEPLUserState } from "shared/services/user/states"

import { isUserEligibleForEbayMarketing } from "shared/helpers/eligibility"

const period = 1000
const maxAttempts = 6

function* handleInternationalTrialUpgrade() {
  yield put(addEPLUserState(createEPLUserState("N", "epl")))
  yield put(add("eplCanManageAdRates"))
}

function* handleDomesticTrialUpgrade() {
  yield put(addEPLUserState(createEPLUserState("P", "epl-domestic")))
  yield put(add("eplCanManageDomesticAdRates"))
}

function* repeatCheckEligibility(action) {
  const { attempts } = action.data
  if (maxAttempts > attempts) {
    yield delay(period)
    const { user } = yield select()
    const { data } = yield call(() => checkStatus(user.id))
    const eligibilityStatus = isUserEligibleForEbayMarketing(data.accounts)
    if (eligibilityStatus === ELIGIBILITY_STATUS.NOT_PROCESSED) {
      yield put({
        type: EPL_REPEAT_CHECK_ELIGIBILITY,
        data: { attempts: attempts + 1 },
      })
    } else {
      yield put({ type: EPL_CHECK_ELIGIBILITY_SUCCESS, data: { ...data } })
    }
  } else {
    yield put({ type: EPL_CHECK_ELIGIBILITY_FAILURE })
  }
}

function* checkEligibility() {
  yield putResolve(onboard())

  const { user } = yield select()
  const { data } = yield call(() => checkStatus(user.id))
  const eligibilityStatus = isUserEligibleForEbayMarketing(data.accounts)
  if (eligibilityStatus === ELIGIBILITY_STATUS.NOT_PROCESSED) {
    yield put({ type: EPL_REPEAT_CHECK_ELIGIBILITY, data: { attempts: 0 } })
  } else {
    yield put({ type: EPL_CHECK_ELIGIBILITY_SUCCESS, data: { ...data } })
  }
}

function* startBothTrials(action) {
  try {
    const { payload } = action
    yield all([
      putResolve(startDomesticTrial(payload.customer)),
      putResolve(startInternationalTrial(payload.wi)),
    ])
    yield put({ type: EPL_START_BOTH_TRIALS_SUCCESS })
  } catch (error) {
    yield put({ type: EPL_START_BOTH_TRIALS_FAILURE })
  }
}

function* startBothStandards(action) {
  try {
    const { payload } = action
    yield all([
      putResolve(startDomesticStandard(payload.customer)),
      putResolve(startInternationalStandard(payload.wi)),
    ])
    yield put({ type: EPL_START_BOTH_STANDARDS_SUCCESS })
  } catch (error) {
    yield put({ type: EPL_START_BOTH_STANDARDS_FAILURE })
  }
}

function* onOnboardingStatusUpdate() {
  // TODO: Add a loader to the plans view when the transition is happening
  while (true) {
    yield race([
      take([EPL_PLAN_INTERNATIONAL_UPGRADE_POST_SUCCESS]),
      take([EPL_PLAN_DOMESTIC_UPGRADE_POST_SUCCESS]),
      take([EPL_START_TRIAL_POST_SUCCESS]),
      take([EPL_START_STANDARD_POST_SUCCESS]),
      take([EPL_OPT_OUT_POST_SUCCESS]),
      take([EPL_START_BOTH_TRIALS_SUCCESS]),
      take([EPL_START_BOTH_STANDARDS_SUCCESS]),
    ])

    yield putResolve(getUserOnboarding())
    yield putResolve(getOptOutStatus())
  }
}

export default function* main() {
  const tasks = yield all([fork(onOnboardingStatusUpdate)])

  yield all(tasks)

  yield takeLatest(
    EPL_PLAN_INTERNATIONAL_UPGRADE_POST_SUCCESS,
    handleInternationalTrialUpgrade
  )
  yield takeLatest(
    EPL_PLAN_DOMESTIC_UPGRADE_POST_SUCCESS,
    handleDomesticTrialUpgrade
  )
  yield takeLatest(EPL_START_BOTH_TRIALS, startBothTrials)
  yield takeLatest(EPL_START_BOTH_STANDARDS, startBothStandards)
  yield takeLatest(EPL_CHECK_ELIGIBILITY, checkEligibility)
  yield takeLatest(EPL_REPEAT_CHECK_ELIGIBILITY, repeatCheckEligibility)
}
