import React from "react"
import Helmet from "react-helmet"

import { useTranslate } from "context/translateContext"
import Container from "shared/components/Container"
import Panel from "shared/components/Panel"
import Button from "shared/components/Button"

const DashboardDisabled = () => {
  const { t: translate } = useTranslate()
  return (
    <Container className="clearfix">
      <Helmet title={translate("Dashboard disabled")} />
      <Panel className={["textCenter", "m-t-30", "m-b-30"].join(" ")}>
        <div className="m-t-50 m-b-20">
          <h1 className="w300 m-b-30">
            {translate("Welcome to Webinterpret")}
          </h1>
          <div className={["fs-24", "m-b-30", "c-gray"].join(" ")}>
            <span className="icon-graph" />
          </div>
          <p className="m-b-30">
            {translate(
              "A full overview of your international sales performance and access to additional markets is available on our paid plans."
            )}
            <br />
            {translate(
              "Upgrade your plan to get access to this and more features."
            )}
          </p>
          <Button
            className="m-b-10"
            variation="primary"
            size="wide"
            to="/user/plans"
          >
            {translate("Upgrade")}
          </Button>
          <br />
          <Button variation="link" to="/user/plans">
            {translate("View plans")}
          </Button>
        </div>
      </Panel>
    </Container>
  )
}

export default DashboardDisabled
