import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import { useTranslate } from "context/translateContext"
import { capitalizeFirstLetter } from "shared/helpers/strings"
import Container from "shared/components/Container"
import Loader from "shared/components/Loader/Local"

import { useLocation } from "react-router-dom"
import ServiceUpsell from "../../shared/components/Service/ServiceUpsell/ServiceUpsell"
import useServices from "./hooks/useServices"
import {
  getServiceEligibility,
  SERVICE_TYPE,
} from "./helpers/serviceEligibility"

const Services = props => {
  const { t: translate } = useTranslate()
  const {
    userId,
    wiShippingEnabled,
    wiReturnsEnabled,
    isShippingEligible,
    isReturnsEligible,
  } = props
  const { data, loading } = useServices(userId)
  const location = useLocation()
  const service = location.pathname.replace("/", "")
  const serviceName = capitalizeFirstLetter(service)
  return (
    <Container fullWidth className="p-t-10 p-b-20">
      <Container>
        <Helmet title={translate(serviceName)} />
        {loading ? (
          <div className="m-t-50 m-b-50">
            <Loader floating={false} />
          </div>
        ) : (
          <ServiceUpsell
            service={service}
            serviceEligibility={getServiceEligibility({
              serviceName: SERVICE_TYPE[service.toUpperCase()],
              services: data.servicesEligibility.services,
            })}
            wiShippingEnabled={wiShippingEnabled}
            wiReturnsEnabled={wiReturnsEnabled}
            isShippingEligible={isShippingEligible}
            isReturnsEligible={isReturnsEligible}
          />
        )}
      </Container>
    </Container>
  )
}

Services.propTypes = {
  userId: PropTypes.number.isRequired,
  wiShippingEnabled: PropTypes.bool.isRequired,
  wiReturnsEnabled: PropTypes.bool.isRequired,
  isShippingEligible: PropTypes.bool.isRequired,
  isReturnsEligible: PropTypes.bool.isRequired,
}

export default Services
