import React, { useEffect } from "react"

import openZendeskPlugin from "./plugin"

const Zendesk = () => {
  useEffect(() => {
    openZendeskPlugin()
  }, [])
  return <div />
}

export default Zendesk
