import { isUndefined } from "lodash"

const TOS = {
  wi: {
    files: {
      current: {
        en: "/assets/docs/webinterpret-terms-and-conditions_v.6_en-260722.pdf",
        it: "/assets/docs/webinterpret-terms-and-conditions_v.6_it-260722.pdf",
        fr: "/assets/docs/webinterpret-terms-and-conditions_v.6_fr-260722.pdf",
        de: "/assets/docs/webinterpret-terms-and-conditions_v.6_de-260722.pdf",
      },
      previous: {
        en:
          "/assets/docs/webinterpret-terms-and-conditions_v.5.2_en-290422.pdf",
        it:
          "/assets/docs/webinterpret-terms-and-conditions_v.5.2_it-290422.pdf",
        fr:
          "/assets/docs/webinterpret-terms-and-conditions_v.5.2_fr-290422.pdf",
        de:
          "/assets/docs/webinterpret-terms-and-conditions_v.5.2_de-290422.pdf",
      },
    },
    version: 1,
  },
  shipping: {
    files: {
      current: {
        en: "/assets/docs/shipping-terms-and-conditions-210728-en.pdf",
      },
      previous: {
        en: "/assets/docs/shipping-terms-and-conditions-210728-en.pdf",
      },
    },
  },
}

export const getService = type => TOS[type] || null

export const getServiceByLang = (type, lang, version = "current") =>
  TOS[type].files[version][lang] || TOS[type].files.current.en

export const isTOSNotAccepted = (data, service) =>
  service.version > data.version ||
  isUndefined(data.is_accepted) ||
  data.is_accepted === false

export default {
  getService,
  getServiceByLang,
  isTOSNotAccepted,
}
