import React, { PureComponent } from "react"
import PropTypes from "prop-types"

import styles from "./ModalHeader.module.css"

class ModalHeader extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    error: PropTypes.bool,
    onRequestClose: PropTypes.func,
    canClose: PropTypes.bool,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
  }
  static defaultProps = {
    children: null,
    error: false,
    onRequestClose: () => {},
    canClose: true,
    className: "",
    containerClassName: "",
  }

  render() {
    const {
      children,
      onRequestClose,
      error,
      canClose,
      className,
      containerClassName,
    } = this.props
    return (
      <header
        className={[styles.root, className, error ? styles.error : ""].join(
          " "
        )}
      >
        <div
          className={[
            styles.header,
            containerClassName,
            children ? "" : styles.empty,
          ].join(" ")}
        >
          {canClose && (
            <button
              type="button"
              className={styles.close}
              onClick={onRequestClose}
            >
              <span className="icon-close hidden-xs" />
              <span className="icon-arrow-left visible-xs" />
            </button>
          )}
          {children && <div className={styles.content}>{children}</div>}
        </div>
      </header>
    )
  }
}

export default ModalHeader
