import {
  all,
  delay,
  select,
  put,
  fork,
  call,
  take,
  takeEvery,
} from "redux-saga/effects"
import { isUndefined } from "lodash"

import {
  BULK_ITEM_REQUEST_ITEMS_SUCCESS,
  BULK_ITEM_REQUEST_ISSUES_GET,
  BULK_ITEM_REQUEST_ISSUES_GET_REQUEST,
  BULK_ITEM_REQUEST_ISSUES_GET_SUCCESS,
  BULK_ITEM_REQUEST_ISSUES_GET_FAILURE,
  BULK_ITEM_CANCEL,
} from "../../constants"

import history from "../../../core/history"
import { getIssues } from "../../fetch/products/bulk"

function* getStatus() {
  const { general, user } = yield select()
  yield put({ type: BULK_ITEM_REQUEST_ISSUES_GET_REQUEST })
  try {
    const { data } = yield call(() =>
      getIssues(user.id, general.activePlatform)
    )
    if (data.status === "ERROR") throw new Error(data.message)
    if (
      !isUndefined(data.total_items) &&
      !isUndefined(data.processed_items) &&
      data.total_items === data.processed_items
    ) {
      yield put({ type: BULK_ITEM_REQUEST_ISSUES_GET_SUCCESS, data })
    } else {
      yield delay(10000)
      yield put({ type: BULK_ITEM_REQUEST_ISSUES_GET })
    }
  } catch (error) {
    yield put({ type: BULK_ITEM_REQUEST_ISSUES_GET_FAILURE, error })
  }
}

function* requestItems() {
  while (true) {
    const action = yield take(BULK_ITEM_REQUEST_ITEMS_SUCCESS)

    if (action.req.status !== "ERROR") {
      const { general } = yield select()
      history.push(
        `/${general.activePlatform}/${
          general.activeAccount
        }/tools/bulk-request/success`
      )
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([
      fork(requestItems),
      takeEvery(BULK_ITEM_REQUEST_ISSUES_GET, getStatus),
    ])

    yield all(tasks)

    yield take([
      BULK_ITEM_CANCEL,
      BULK_ITEM_REQUEST_ISSUES_GET_SUCCESS,
      BULK_ITEM_REQUEST_ISSUES_GET_FAILURE,
    ])
    yield all(tasks.map(task => task.cancel()))
  }
}
