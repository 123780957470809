import { all, select, call, put, take, fork } from "redux-saga/effects"
import { get } from "lodash"

import {
  USER_GET_SUCCESS,
  USER_GET_STATES_SUCCESS,
  USER_GET_STATES_FAILURE,
  USER_GET_STATES_REQUEST,
} from "data/constants"
import UserStatesAPI from "data/fetch/user/platformStates"

function* getPlatformStates() {
  while (true) {
    yield take([USER_GET_SUCCESS])

    const { user } = yield select()

    yield put({ type: USER_GET_STATES_REQUEST })

    try {
      const { data } = yield call(() =>
        UserStatesAPI.getPlatformStates(user.id)
      )
      yield put({
        type: USER_GET_STATES_SUCCESS,
        payload: get(data, "result", []),
      })
    } catch (error) {
      yield put({ type: USER_GET_STATES_FAILURE, error })
    }
  }
}

export default function* main() {
  const tasks = yield all([fork(getPlatformStates)])

  yield all(tasks)
}
