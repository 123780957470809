import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import "print-js"

import { useTranslate } from "context/translateContext"

import ModalHeader from "shared/components/Modal/ModalHeader"
import ModalContent from "shared/components/Modal/ModalContent"
import ModalFooter from "shared/components/Modal/ModalFooter"
import Button from "shared/components/Button"

import styles from "./Article.module.css"

const Article = props => {
  const [open, setOpen] = useState(false)
  const { t: translate } = useTranslate()

  const { article } = props

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const renderModal = () =>
    open ? (
      <Modal
        isOpen
        onRequestClose={onClose}
        contentLabel="Zendesk Article"
        className="popup"
        overlayClassName="popupOverlay"
        ariaHideApp={false}
      >
        <ModalHeader onRequestClose={onClose}>
          <button
            className="fs-12 m-b-15"
            onClick={() => {
              window.printJS({
                printable: "zendesk-article",
                type: "html",
                header: article.title,
              })
            }}
            type="button"
          >
            <span className="c-motive icon-printer" />{" "}
            {translate("Print article")}
          </button>
          <h1 className="w500">{article.title}</h1>
        </ModalHeader>
        <ModalContent>
          <div
            id="zendesk-article"
            className={styles.article}
            dangerouslySetInnerHTML={{ __html: article.body }}
          />
        </ModalContent>
        <ModalFooter>
          <Button
            href={article.html_url}
            variation="primary"
            size="large"
            target="_blank"
            block
            category="Zendesk"
            action="open_article_helpcenter"
            actionlabel={article.title}
          >
            {translate("Open in Help Center")}
          </Button>
        </ModalFooter>
      </Modal>
    ) : null

  return (
    <Fragment>
      <article
        role="presentation"
        onClick={onOpen}
        className={styles.root}
        category="Zendesk"
        action="open_article"
        actionlabel={article.title}
      >
        <h1 className="fs-16 m-b-5">{article.title}</h1>
        <p
          className="m-b-0"
          dangerouslySetInnerHTML={{ __html: article.snippet }}
        />
      </article>
      {renderModal()}
    </Fragment>
  )
}

Article.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    snippet: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    html_url: PropTypes.string.isRequired,
  }).isRequired,
}

Article.contextTypes = {
  t: PropTypes.func.isRequired,
}

export default Article
