import { isNull } from "lodash"

export const getPaymentIssues = details => {
  const alerts = []
  // If there are no details then payments were not setup yet, so it's pointless to show any error
  if (
    !details.credit_card_details &&
    !details.direct_debit_details &&
    !details.paypal_details
  ) { return alerts }
  // When payment was never processed all attributes come as null.
  // We should return no errors in such case.
  if (
    isNull(details.last_payment_details.was_successful) &&
    isNull(details.last_payment_details.date) &&
    isNull(details.last_payment_details.is_processing)
  ) { return alerts }

  if (details.credit_card_details) {
    // last payment failed, no credit card issues
    if (
      !details.last_payment_details.was_successful &&
      !details.credit_card_details.card_expired &&
      !details.credit_card_details.card_will_expire_soon
    ) {
      alerts.push({
        type: "PAYMENT_FAILED_1",
        priority: 2,
      })
    }

    // last payment failed, credit card will expire soon
    if (
      !details.last_payment_details.was_successful &&
      details.credit_card_details.card_will_expire_soon
    ) {
      alerts.push({
        type: "PAYMENT_FAILED_2",
        priority: 1,
      })
    }

    // last payment failed, credit card has expired
    if (
      !details.last_payment_details.was_successful &&
      details.credit_card_details.card_expired
    ) {
      alerts.push({
        type: "PAYMENT_FAILED_3",
        priority: 1,
      })
    }

    // last payment was successful, credit card will expire soon
    if (
      details.last_payment_details.was_successful &&
      details.credit_card_details.card_will_expire_soon
    ) {
      alerts.push({
        type: "PAYMENT_EXPIRE_SOON",
        priority: 2,
      })
    }

    // last payment was successful, credit card has expired
    if (
      details.last_payment_details.was_successful &&
      details.credit_card_details.card_expired
    ) {
      alerts.push({
        type: "PAYMENT_EXPIRED",
        priority: 2,
      })
    }
  } else if (!details.last_payment_details.was_successful) {
    // last payment failed
    alerts.push({
      type: "PAYMENT_FAILED_1",
      priority: 1,
    })
  }

  return alerts
}

export default {
  getPaymentIssues,
}
