import configData from "./config"

const defaultHost = "app.webinterpret.com"

export class ConfigFactory {
  constructor(hostname = defaultHost, config = configData) {
    this.domains = {
      "app.webinterpret.com": {
        key: "default",
        location: "com",
      },
      "app.webinterpret.cn": {
        key: "china",
        location: "cn",
        legal: "粤ICP备17155305号-1",
      },
    }
    this.hostname = hostname
    this.key = this.getKey()
    this.location = this.getLocation()
    this.legal = this.getLegal()
    this.portalAdminEbay = "//wi-mother-admin.webinterpret.com"
    this.portalAdminAmazon =
      "http://wi-amazon-admin.devwebinterpret.com"

    Object.assign(this, this.replaceAttributes(config))
  }

  getKey() {
    const domain = this.domains[this.hostname] || this.domains[defaultHost]
    return domain.key
  }

  getLocation() {
    const domain = this.domains[this.hostname] || this.domains[defaultHost]
    return domain.location
  }

  getLegal() {
    const domain = this.domains[this.hostname] || this.domains[defaultHost]
    return domain.legal
  }

  replaceAttributes(data) {
    const keys = Object.keys(data)
    const config = {}
    keys.forEach(key => {
      config[key] = data[key].replace("{location}", this.location)
    })

    return config
  }
}

const Config = new ConfigFactory(window.location.hostname)

export default Config
