import { get } from "lodash"
import {
  EPL_TOS_GET_SUCCESS,
  EPL_SITES_GET_SUCCESS,
  EPL_MARKETS_GET_SUCCESS,
  EPL_MARKETS_GET_FAILURE,
  EPL_ALL_MARKETS_GET_SUCCESS,
  EPL_ALL_MARKETS_GET_FAILURE,
  EPL_MARKETS_CLOSE_POST_SUCCESS,
  EPL_MARKETS_OPEN_POST_SUCCESS,
  EPL_MARKETS_PAUSE_POST_SUCCESS,
  EPL_MARKETS_RESUME_POST_SUCCESS,
  LOADING_STATES,
  EPL_MARKETS_GET_REQUEST,
} from "data/constants"

const originMap = {
  WI: "wi",
  CUSTOMER: "customer",
}

const defaultState = {
  status: LOADING_STATES.INITIAL,
  termsStatuses: [],
  markets: [],
  sites: [],
}

const eplMarketsStore = (state = defaultState, action) => {
  switch (action.type) {
    case EPL_TOS_GET_SUCCESS:
      return {
        ...state,
        sites: addTermsInformationToSites(
          state.sites,
          get(action.req, "sites", [])
        ),
        termsStatuses: get(action.req, "sites", []),
      }
    case EPL_SITES_GET_SUCCESS:
      return {
        ...state,
        sites: action.req.sites,
      }
    case EPL_MARKETS_GET_REQUEST:
      return {
        ...state,
        status: LOADING_STATES.LOADING,
      }
    case EPL_MARKETS_GET_SUCCESS:
    case EPL_MARKETS_GET_FAILURE:
      return {
        ...state,
        sites: addMarketInformationToSites(
          state.sites,
          get(action.req, "markets", [])
        ),
        markets: get(action.req, "markets", []),
        status: LOADING_STATES.LOADED,
      }
    case EPL_ALL_MARKETS_GET_SUCCESS:
    case EPL_ALL_MARKETS_GET_FAILURE:
      return {
        ...state,
        sites: addMarketInformationToSites(
          state.sites,
          get(action.req, "markets", [])
        ),
        allMarkets: get(action.req, "markets", []),
      }
    case EPL_MARKETS_CLOSE_POST_SUCCESS:
      return {
        ...state,
        ...updateMarketStatus(state, action.data, "closed"),
      }
    case EPL_MARKETS_PAUSE_POST_SUCCESS:
      return {
        ...state,
        ...updateMarketStatus(state, action.data, "paused"),
      }
    case EPL_MARKETS_OPEN_POST_SUCCESS:
    case EPL_MARKETS_RESUME_POST_SUCCESS:
      return {
        ...state,
        ...updateMarketStatus(state, action.data, "open"),
      }

    default:
      return state
  }
}

const addTermsInformationToSites = (sites, termsStatuses) =>
  sites.map(site => {
    const status = termsStatuses.find(
      termsStatus => termsStatus.site === site.siteId
    )
    return {
      ...site,
      termsAccepted: (status || {}).terms_accepted,
    }
  })

const addMarketInformationToSites = (sites, markets) =>
  sites.map(site => {
    const siteMarket = markets.find(market => market.site === site.siteId)
    return {
      ...site,
      marketIsDomestic: (siteMarket || {}).origin === originMap.CUSTOMER,
      marketDomesticStatus: (siteMarket || {}).status_origin_customer,
      marketInternationalStatus: (siteMarket || {}).status,
      marketDomesticAdRate: (siteMarket || {}).ad_rule_origin_customer || {},
      marketInternationalAdRate: (siteMarket || {}).ad_rule_origin_wi || {},
      eligibility: (siteMarket || {}).eligibility || {},
    }
  })

const updateMarketStatus = (state, data, newStatus) => {
  const updatedMarkets = state.markets.map(market => {
    if (data.site === market.site) {
      if (data.origin === originMap.CUSTOMER) {
        return { ...market, status_origin_customer: newStatus }
      }
      return { ...market, status: newStatus }
    }
    return market
  })

  return {
    markets: updatedMarkets,
    sites: addMarketInformationToSites(state.sites, updatedMarkets),
  }
}

export default eplMarketsStore
