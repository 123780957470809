import ebayUpsellImage from "shared/images/upsell/ebay.png"
import amazonUpsellImage from "shared/images/upsell/amazon.png"
import cdiscountUpsellImage from "shared/images/upsell/cdiscount.png"
import shippingUpsellImage from "shared/images/upsell/shipping.png"
import returnsUpsellImage from "shared/images/upsell/returns.png"
import returnsDemoImage from "shared/images/upsell/returnsDemo.png"

/**
 * Mock translate function in order to be able to extract strings to POT file
 */
const translate = string => string

const platformMap = {
  amazon: {
    title: translate("Launch your Amazon store in 4 new markets"),
    description: translate(
      "Open your store's borders to 4 new markets and double your international sales. Webinterpret creates a local shopping experience to international buyers by building localized versions of your online store."
    ),
    secondDescriptionLine: translate(
      "Let's connect your Amazon account and get it ready for international selling."
    ),
    image: amazonUpsellImage,
  },
  amazonContinueOnboarding: {
    title: translate("Welcome back! Finalise your onboarding now"),
    description: translate(
      "You did not finalise your account set up to launch your Amazon store internationally with us. Just a few more steps and you will be ready to grow your international sales with our Plug & Play solution."
    ),
    secondDescriptionLine: translate(
      "You just need to connect and configure your Amazon account."
    ),
    image: amazonUpsellImage,
  },
  ebay: {
    title: translate("Increase your international sales by 20%"),
    description: translate(
      "Our simple Plug & Play solution creates a local shopping experience to international buyers by building localized versions of your eBay store."
    ),
    secondDescriptionLine: translate(
      "In just a few clicks we'll launch your eBay store in up to 12 new markets. Let's connect your eBay store and get it ready for international selling."
    ),
    image: ebayUpsellImage,
  },
  ebayContinueOnboarding: {
    title: translate("Welcome back! Finalise your onboarding now"),
    description: translate(
      "You did not finalise your account set up to launch your eBay store internationally with us. Just a few more steps and you will be ready to grow your international sales with our Plug & Play solution."
    ),
    secondDescriptionLine: translate(
      "You just need to connect and configure your eBay account."
    ),
    image: ebayUpsellImage,
  },
  cdiscount: {
    title: translate(
      "Start selling to French buyers with Cdiscount and get ready for online sales growth"
    ),
    description: translate(
      "Webinterpret creates a local shopping experience by translating, localizing and listing your inventory on Cdiscount, the second largest marketplace in France."
    ),
    secondDescriptionLine: translate(
      "Connect your Amazon account with Cdiscount to start."
    ),
    image: cdiscountUpsellImage,
  },
  cdiscountContinueOnboarding: {
    title: translate("Welcome back! Finalise your onboarding now"),
    description: translate(
      "You did not finalise your account set up to launch your Cdiscount store internationally with us. Just a few more steps and you will be ready to grow your international sales with our Plug & Play solution."
    ),
    secondDescriptionLine: translate(
      "You just need to connect and configure your Cdiscount account."
    ),
    image: cdiscountUpsellImage,
  },
  shipping: {
    title: translate("Webinterpret shipping service available now!"),
    description: translate(
      "This feature is available on our paid plan. Upgrade your plan to get access to this and more features."
    ),
    image: shippingUpsellImage,
    buttonLink: "/user/plans",
    isInsideLink: true,
    buttonText: translate("Upgrade your plan"),
    option: "shipping",
  },
  shippingComingSoon: {
    title: translate("Webinterpret shipping coming soon!"),
    description: translate(
      "We are working hard to support Webinterpret Shipping from all countries that our sellers ship from. Please check the list of countries where Shipping is currently available"
    ),
    image: shippingUpsellImage,
    buttonLink:
      "https://support.webinterpret.com/hc/en-us/articles/7055399365266",
    isInsideLink: false,
    buttonText: translate("Check the list"),
    option: "shippingComingSoon",
  },
  returns: {
    title: translate("Webinterpret returns service available now!"),
    description: translate(
      "This feature is available on our paid plan. Upgrade your plan to get access to this and more features."
    ),
    image: returnsUpsellImage,
    buttonLink: "/user/plans",
    isInsideLink: true,
    buttonText: translate("Upgrade your plan"),
    option: "returns",
  },
  returnsComingSoon: {
    title: translate("Webinterpret returns coming soon!"),
    description: translate(
      "Coming soon! We are working hard to support Webinterpret Returns to all countries that our sellers ship from. Please check the list of countries where Returns are currently available"
    ),
    image: returnsUpsellImage,
    buttonLink:
      "https://support.webinterpret.com/hc/en-us/articles/6238431471122-Eligible-Countries-For-WebInterpret-Returns",
    isInsideLink: false,
    buttonText: translate("Check the list"),
    option: "returnsComingSoon",
  },
  returnsDemo: {
    title: translate("Webinterpret Returns available now!"),
    description: translate(
      "Webinterpret Returns is a new addition to our range of services."
    ),
    image: returnsDemoImage,
    buttonLink: "https://pages.webinterpret.com/returns-demo.html",
    isInsideLink: false,
    buttonText: translate("Request a demo"),
    option: "returnsDemo",
  },
}

const getPlatformContent = (platform, upsellOption = "") => {
  let option = platform

  if (upsellOption === "resume") {
    option = `${platform}ContinueOnboarding`
  }

  if (upsellOption === "comingSoon") {
    option = `${platform}ComingSoon`
  }

  if (upsellOption === "demo") {
    option = `${platform}Demo`
  }

  return platformMap[option] || {}
}

export default getPlatformContent
