import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS,
  PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_SUCCESS,
  PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_FAILURE,
  PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_CANCEL,
  PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_REQUEST,
} from "../../constants"

import { get } from "../../fetch/profile/creditCardConfigDetails"

function* getCreditCardConfigDetails() {
  while (true) {
    yield take([PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS])
    const { user } = yield select()
    yield put({ type: PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_REQUEST })
    try {
      const { data } = yield call(() => get(user.id))
      if (data)
        yield put({
          type: PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_SUCCESS,
          data,
        })
    } catch (error) {
      yield put({ type: PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getCreditCardConfigDetails)])

    yield all(tasks)

    yield take(PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
