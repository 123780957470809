import React from "react"
import PropTypes from "prop-types"

import { useTranslate } from "context/translateContext"
import GetFeedbackModal from "shared/components/GetFeedbackModal"

const SURVEY_DELAY_MS = 1000
const PostMarketCloseSurvey = ({ onClose, justClosedMarkets, userId }) => {
  const { t: translate } = useTranslate()
  return (
    <GetFeedbackModal
      src={`${translate(
        "https://www.getfeedback.com/e/amkAmqYW"
      )}?user=${userId}&closedMarkets=${justClosedMarkets}`}
      showAfterMS={SURVEY_DELAY_MS}
      data-gtm="shown-ui"
      category="ebay"
      action="post_market_close_survey_shown"
      actionlabel="survey"
      onClose={onClose}
    />
  )
}

PostMarketCloseSurvey.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default PostMarketCloseSurvey
