import { select, call, put, takeLatest } from "redux-saga/effects"

import {
  USER_GET_SUCCESS,
  TERMS_OF_SERVICE_POST_SUCCESS,
  TERMS_OF_SERVICE_PUT_SUCCESS,
  GENERAL_SHOW_MODAL,
  GENERAL_HIDE_MODAL,
} from "data/constants"
import { get as getTOS } from "data/fetch/user/termsOfService"
import { getService, isTOSNotAccepted } from "shared/services/termsOfService"
import logger from "shared/services/logger"

function* initTermsOfService() {
  const { user } = yield select()
  const serviceName = "wi"

  try {
    const { data } = yield call(() => getTOS(user.id, serviceName))
    const service = getService(serviceName)
    const isTOSAccepted = !isTOSNotAccepted(data, service)

    logger("TOS verifying", user.id, "TOS:acceptance", "info")

    if (isTOSAccepted || user.isAdmin) return

    logger("TOS not accepted", user.id, "TOS:acceptance:Open Modal", "info")

    // Open Modal
    yield put({
      type: GENERAL_SHOW_MODAL,
      name: "tos",
    })
  } catch (err) {
    if (user.isAdmin) return

    logger(
      "TOS acceptance status check failed",
      user.id,
      "TOS:acceptance:Open Modal",
      "error",
      {
        ...err,
        userData: { ...user },
        envData: {
          userAgent: navigator?.userAgent,
          isBrowserOnline: navigator.onLine,
        },
      }
    )

    // Open Modal
    yield put({
      type: GENERAL_SHOW_MODAL,
      name: "tos",
    })
  }
}

function* closeTermsOfService() {
  yield put({
    type: GENERAL_HIDE_MODAL,
    name: "tos",
  })
}

export default function* main() {
  yield takeLatest(USER_GET_SUCCESS, initTermsOfService)
  yield takeLatest(TERMS_OF_SERVICE_POST_SUCCESS, closeTermsOfService)
  yield takeLatest(TERMS_OF_SERVICE_PUT_SUCCESS, closeTermsOfService)
}
