import { all, select, put, fork, take } from "redux-saga/effects"
import { find, flatten } from "lodash"

import {
  TRANSLATION_MANAGER_TERM_GET,
  TRANSLATION_MANAGER_TERM_GET_REQUEST,
  TRANSLATION_MANAGER_TERM_GET_SUCCESS,
  TRANSLATION_MANAGER_TERM_GET_FAILURE,
  TRANSLATION_MANAGER_TERM_CANCEL,
} from "data/constants"

function* getItem() {
  while (true) {
    const action = yield take(TRANSLATION_MANAGER_TERM_GET)
    const { translationManager } = yield select()

    yield put({ type: TRANSLATION_MANAGER_TERM_GET_REQUEST })

    const terms = flatten(
      translationManager.list.map(termGroup => termGroup.terms)
    )
    const sellerTerm = find(terms, term => term.id === action.termID)

    if (sellerTerm) {
      yield put({
        type: TRANSLATION_MANAGER_TERM_GET_SUCCESS,
        data: sellerTerm,
      })
    } else {
      yield put({
        type: TRANSLATION_MANAGER_TERM_GET_FAILURE,
        error: "TRANSLATION_MANAGER_TERM_NOT_FOUND",
      })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getItem)])

    yield all(tasks)

    yield take(TRANSLATION_MANAGER_TERM_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
