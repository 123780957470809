import { call, delay, put, take, takeLatest, race } from "redux-saga/effects"
import {
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAILURE,
  USER_SIGNUP_RESOLVED,
  USER_LOGIN_SUCCESS,
  USER_SIGNUP_EMAIL_TAKEN_NOTIFY,
  USER_SIGNUP_FAILURE_NOTIFY,
} from "data/constants"
import { login } from "data/fetch/user/auth"

import history from "core/history"

function* signUp() {
  const [signUpSuccess, signUpFailure] = yield race([
    take(USER_SIGNUP_SUCCESS),
    take(USER_SIGNUP_FAILURE),
  ])

  if (signUpSuccess) {
    const {
      credentials: { email: username, password },
    } = signUpSuccess

    try {
      yield call(() => login({ username, password }))
      yield delay(3000)
      yield put({ type: USER_SIGNUP_RESOLVED })
      yield put({ type: USER_LOGIN_SUCCESS })
    } catch (loginError) {
      yield put({ type: USER_SIGNUP_FAILURE_NOTIFY })
      history.push("/")
    }
  } else if (signUpFailure.httpStatus === 409) {
    yield put({ type: USER_SIGNUP_EMAIL_TAKEN_NOTIFY })
    history.push("/")
  } else {
    yield put({ type: USER_SIGNUP_FAILURE_NOTIFY })
  }
}

export default function* main() {
  yield takeLatest(USER_SIGNUP_REQUEST, signUp)
}
