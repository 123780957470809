import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  TRIAL_START_GET,
  USER_GET_SUCCESS,
  PROFILE_GET_CONTACT,
  PROFILE_GET_CONTACT_REQUEST,
  PROFILE_GET_CONTACT_SUCCESS,
  PROFILE_GET_CONTACT_FAILURE,
  PROFILE_GET_CONTACT_CANCEL,
} from "../../constants"

import { get } from "../../fetch/profile/contactDetails"

function* getContactDetails() {
  while (true) {
    yield take([PROFILE_GET_CONTACT, USER_GET_SUCCESS, TRIAL_START_GET])
    const { user } = yield select()
    yield put({ type: PROFILE_GET_CONTACT_REQUEST })
    try {
      const { data } = yield call(() => get(user.id))
      if (data) yield put({ type: PROFILE_GET_CONTACT_SUCCESS, data })
    } catch (error) {
      yield put({ type: PROFILE_GET_CONTACT_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getContactDetails)])

    yield all(tasks)

    yield take(PROFILE_GET_CONTACT_CANCEL)
    yield tasks.map(task => task.cancel())
  }
}
