import React from "react"
import Helmet from "react-helmet"

import Container from "shared/components/Container"
import Panel from "shared/components/Panel"
import Auth from "shared/components/Auth"

import { useTranslate } from "context/translateContext"

import { Outlet } from "react-router-dom"
import styles from "./Auth.module.css"

const AuthView = () => {
  const { t: translate } = useTranslate()

  return (
    <Container className="p-v-40">
      <Helmet title={translate("Login")} />
      <div className="row">
        <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
          <Panel className={styles.root}>
            <Auth />
          </Panel>
          <Outlet />
        </div>
      </div>
    </Container>
  )
}
export default AuthView
