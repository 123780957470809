import React from "react"
import PropTypes from "prop-types"

import styles from "./Empty.module.css"

import noData from "./empty.svg"

const Empty = ({ image, title, message, action }) => (
  <div className={styles.root}>
    <img src={image} alt="No data" className={styles.image} />
    {title && <h2 className="h1">{title}</h2>}
    {message && <p className="c-motive--dark">{message}</p>}
    {action && <div className={styles.action}>{action}</div>}
  </div>
)

Empty.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  action: PropTypes.node,
}
Empty.defaultProps = {
  image: noData,
  title: null,
  message: null,
  action: null,
}

export default Empty
