import { without } from "lodash"

import {
  ACCOUNT_GET_REQUEST,
  ACCOUNT_GET_SUCCESS,
  ACCOUNT_GET_FAILURE,
  ACCOUNT_CANCEL,
  ACCOUNT_ADD_FLOW_SUCCESS,
  ACCOUNT_UPDATE_FLOW_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

const defaultState = {
  data: {},
  state: "INITIAL",
  error: null,
}

function accountStore(state = defaultState, action) {
  switch (action.type) {
    case ACCOUNT_GET_REQUEST:
      return {
        ...state,
        data: {},
        error: null,
        state: "LOADING",
      }
    case ACCOUNT_GET_SUCCESS:
      return {
        ...state,
        data: action.data,
        state: "LOADED",
      }
    case ACCOUNT_GET_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        state: "FAILED",
      }
    case ACCOUNT_ADD_FLOW_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          listed_in: {
            ...state.data.listed_in,
            [action.data.dst_site]: [
              ...(state.data.listed_in[action.data.dst_site] || []),
              {
                id: action.req.id,
                dst_account_id: action.data.dst_account_id,
                dst_site: action.data.dst_site,
                from_site: action.data.source_site,
                list_all: action.data.list_all,
                via_account_id: action.data.source_account_id,
                via_account_name: action.data.source_account_name,
              },
            ],
          },
          not_listed: without(state.data.not_listed, action.data.dst_site),
        },
      }
    case ACCOUNT_UPDATE_FLOW_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          listed_in: {
            ...state.data.listed_in,
            [action.data.dst_site]: state.data.listed_in[
              action.data.dst_site
            ].map(flow => {
              if (flow.id === action.data.id) {
                return action.data
              }
              return flow
            }),
          },
        },
      }
    case ACCOUNT_CANCEL:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default accountStore
