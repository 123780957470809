import React from "react"
import { Routes, Route } from "react-router-dom"
import Loadable from "react-loadable"
import { HOC as PermissionsHOC } from "react-redux-permissions"

import ModuleDisabled from "shared/views/NotAllowed/ModuleDisabled"
import Loader from "shared/components/Loader/Local"

const Blacklist = Loadable({
  loader: () => import("./views/Blacklist"),
  loading: () => <Loader />,
})
const BulkRequest = Loadable({
  loader: () => import("./views/BulkRequest"),
  loading: () => <Loader />,
})
const ConversionCharts = Loadable({
  loader: () => import("./views/ConversionCharts"),
  loading: () => <Loader />,
})
const PriceAdjustment = Loadable({
  loader: () => import("./views/PriceAdjustment"),
  loading: () => <Loader />,
})
const Repricer = Loadable({
  loader: () => import("./views/Repricer"),
  loading: () => <Loader />,
})
const ShippingManager = Loadable({
  loader: () => import("./views/ShippingManager"),
  loading: () => <Loader />,
})
const TranslationManager = Loadable({
  loader: () => import("./views/TranslationManager"),
  loading: () => <Loader />,
})

const BlacklistView = PermissionsHOC(["blacklist"])(
  Blacklist,
  <ModuleDisabled className={["m-t-80", "m-b-30"].join(" ")} view="Blacklist" />
)

const RepricerView = PermissionsHOC(["amazon"])(
  Repricer,
  <ModuleDisabled className={["m-t-80", "m-b-30"].join(" ")} view="Repricer" />
)

const BulkRequestView = PermissionsHOC(["bulkItemRequest"])(
  BulkRequest,
  <ModuleDisabled
    className={["m-t-80", "m-b-30"].join(" ")}
    view="Bulk request"
  />
)

const Tools = () => (
  <Routes>
    <Route path="/">
      <Route path="blacklist/*" element={<BlacklistView />} />
      <Route path="bulk-request/*" element={<BulkRequestView />} />
      <Route path="conversion-charts/*" element={<ConversionCharts />} />
      <Route path="price-adjustment/*" element={<PriceAdjustment />} />
      <Route path="repricer/*" element={<RepricerView />} />
      <Route path="shipping-manager/*" element={<ShippingManager />} />
      <Route path="translation-manager/*" element={<TranslationManager />} />
    </Route>
  </Routes>
)

export default Tools
