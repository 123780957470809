import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import PropTypes from "prop-types"
import Cookie from "js-cookie"
import config from "core/config"

import ModalHeader from "shared/components/Modal/ModalHeader"
import ModalContent from "shared/components/Modal/ModalContent"
import IFrame from "shared/components/IFrame"

const GetFeedbackModal = props => {
  const {
    width,
    height,
    src,
    showAfterMS,
    show,
    postViewCookie,
    shouldShowOnce,
    userPercentage,
    cookieToShow,
    onClose,
    ...restProps
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isTimerExpired, setIsTimerExpired] = useState(false)
  useEffect(() => {
    if (config.env !== "production") {
      setIsOpen(false)
    }
  })

  useEffect(() => {
    setTimeout(() => {
      setIsTimerExpired(true)
    }, showAfterMS)
  }, [])

  useEffect(() => {
    const showAgain =
      Boolean(postViewCookie) && Cookie.get(postViewCookie)
        ? !shouldShowOnce
        : true

    const isUserInPercentage =
      Math.floor(Math.random() * 100) + 1 <= userPercentage
    const isModalOpen = Boolean(
      showAgain &&
        (show || (showAfterMS && isTimerExpired) || Cookie.get(cookieToShow)) &&
        isUserInPercentage
    )
    setIsOpen(isModalOpen)
  }, [isTimerExpired, show, shouldShowOnce])

  useEffect(() => {
    if (isOpen && postViewCookie) {
      Cookie.set(postViewCookie, true)
    }
  }, [isOpen, postViewCookie])

  const onRequestClose = () => {
    setIsOpen(false)
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      className="slideup"
      overlayClassName="popupOverlay"
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      {...restProps}
    >
      <ModalHeader
        canClose
        containerClassName="no-p no-m"
        onRequestClose={onRequestClose}
      />
      <ModalContent className="no-p">
        <IFrame source={src} width={width} height={height} />
      </ModalContent>
    </Modal>
  )
}

GetFeedbackModal.propTypes = {
  showAfterMS: PropTypes.number,
  userPercentage: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  show: PropTypes.bool,
  shouldShowOnce: PropTypes.bool,
  postViewCookie: PropTypes.string,
  cookieToShow: PropTypes.string,
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

GetFeedbackModal.defaultProps = {
  userPercentage: 100,
  showAfterMS: 0,
  onClose: () => {},
  show: false,
  shouldShowOnce: true,
  postViewCookie: "",
  cookieToShow: "",
  width: "400",
  height: "500",
}

export default GetFeedbackModal
