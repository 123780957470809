import { all, fork, take } from "redux-saga/effects"

import {
  ZENDESK_SUBMIT_TICKET_SUCCESS,
  ZENDESK_SUBMIT_TICKET_CANCEL,
} from "../../constants"

import history from "../../../core/history"

function* submitSuccess() {
  while (true) {
    yield take(ZENDESK_SUBMIT_TICKET_SUCCESS)
    history.push("/submit-request/success")
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(submitSuccess)])

    yield all(tasks)

    yield take(ZENDESK_SUBMIT_TICKET_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
