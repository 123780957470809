/*
 * To add to the config, add an object:
 * {
 *  type: 'link' | 'meta',
 *  sizes: 'widthxheight',
 *  rel: 'rel value'
 *  filename: <Name of your file'
 * }
 */

const configHelmet = {
  meta: [
    { charset: "utf-8" },
    // Setting IE=edge tells Internet Explorer to use the latest
    // engine to render the page and execute Javascript
    { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
    //  Meta descriptions are commonly used on search engine
    // result pages to display preview snippets for a given page.
    { name: "description", content: "Plug & Play International Ecommerce" },
    {
      name: "keywords",
      content:
        "international, ecommerce, ebay, amazon, online stores, translations",
    },
    { name: "application-name", content: "Webinterpret" },
    // Mobile Safari introduced this tag to let web developers control the viewport's size and scale
    // The width property controls the size of the viewport, the initial-scale property controls
    // the zoom level when the page is first loaded
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    // Add to homescreen for Chrome on Android
    { name: "mobile-web-app-capable", content: "yes" },
    // Add to homescreen for Safari on IOS
    { name: "apple-mobile-web-app-capable", content: "yes" },
    { name: "apple-mobile-web-app-status-bar-style", content: "black" },
    { name: "apple-mobile-web-app-title", content: "Webinterpret" },
    // Tile icon for Win8 (144x144 + tile color)
    // Add to homescreen for Windows App
    { name: "msapplication-config", content: "/assets/browserconfig.xml" },
    { name: "theme-color", content: "#ffffff" },
  ],
  link: [
    // Add to homescreen for Chrome on Android
    {
      rel: "icon",
      type: "image/png",
      href: "/assets/favicon-16x16.png",
      sizes: "16x16",
    },
    {
      rel: "icon",
      type: "image/png",
      href: "/assets/favicon-194x194.png",
      sizes: "194x194",
    },
    {
      rel: "icon",
      type: "image/png",
      href: "/assets/android-chrome-192x192.png",
      sizes: "192x192",
    },
    // Firefox doesnt support sizes so we declare 32x32 as last
    {
      rel: "icon",
      type: "image/png",
      href: "/assets/favicon-32x32.png",
      sizes: "32x32",
    },
    { rel: "shortcut icon", href: "/assets/favicon.ico" },
    // Add to homescreen for Safari
    {
      rel: "apple-touch-icon",
      href: "/assets/apple-touch-icon.png",
      sizes: "180x180",
    },
    { rel: "apple-touch-startup-image", href: "/assets/favicon-256x256.png" },
    {
      rel: "mask-icon",
      href: "/assets/safari-pinned-tab.svg",
      color: "#30bffb",
    },

    // Others
    { rel: "manifest", href: "/assets/manifest.json" },
  ],
}

export default configHelmet
