import { all, select, put, fork, call, take } from "redux-saga/effects"
import { find } from "lodash"

import {
  PRICE_RULES_GET,
  PRICE_RULES_GET_REQUEST,
  PRICE_RULES_GET_SUCCESS,
  PRICE_RULES_GET_FAILURE,
  PRICE_RULE_GET,
  PRICE_RULE_GET_REQUEST,
  PRICE_RULE_GET_SUCCESS,
  PRICE_RULE_GET_FAILURE,
  PRICE_RULES_CANCEL,
} from "../../constants"

import { get as getRules } from "../../fetch/priceAdjustment/rules"

function* get() {
  while (true) {
    const action = yield take([PRICE_RULES_GET])
    const { user } = yield select()
    yield put({ type: PRICE_RULES_GET_REQUEST })
    try {
      const params =
        action.platform === "ebay"
          ? {
            account_id: action.account,
          }
          : {}
      const { data } = yield call(() => getRules(user, action.platform, params))
      if (data) yield put({ type: PRICE_RULES_GET_SUCCESS, data })
    } catch (error) {
      yield put({ type: PRICE_RULES_GET_FAILURE, error })
    }
  }
}

function* getRule() {
  while (true) {
    const action = yield take(PRICE_RULE_GET)
    const { priceAdjustmentRules } = yield select()
    yield put({ type: PRICE_RULE_GET_REQUEST })
    const rule = find(priceAdjustmentRules.data, {
      site: action.site,
    })
    if (!rule) {
      yield put({ type: PRICE_RULE_GET_FAILURE, error: "RULE_NOT_FOUND" })
    }
    yield put({ type: PRICE_RULE_GET_SUCCESS, data: rule })
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(get), fork(getRule)])

    yield all(tasks)

    yield take(PRICE_RULES_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
