import React, { Component, cloneElement } from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"

import DropdownTrigger from "./DropdownTrigger"
import DropdownContent from "./DropdownContent"

import styles from "./Dropdown.module.css"

export class Dropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    top: PropTypes.bool,
    right: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    noRelative: PropTypes.bool,
    className: PropTypes.string,
  }
  static defaultProps = {
    disabled: false,
    top: false,
    right: false,
    noRelative: false,
    className: "",
    onShow: noop,
    onHide: noop,
  }
  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }

    this.onWindowClick = this.onWindowClick.bind(this)
  }

  componentDidMount() {
    window.addEventListener("click", this.onWindowClick)
    window.addEventListener("touchstart", this.onWindowClick)
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick)
    window.removeEventListener("touchstart", this.onWindowClick)
  }

  onWindowClick(event) {
    const { active } = this.state
    const dropdownElement = this.dropdown
    if (
      event.target !== dropdownElement &&
      !dropdownElement.contains(event.target) &&
      active
    ) {
      this.hide()
    }
  }

  onToggleClick(event) {
    const { active } = this.state
    event.preventDefault()
    if (active) {
      this.hide()
    } else {
      this.show()
    }
  }

  hide() {
    const { onHide } = this.props
    this.setState({
      active: false,
    })
    if (onHide) {
      onHide()
    }
  }

  show() {
    const { disabled, onShow } = this.props
    if (!disabled) {
      this.setState({
        active: true,
      })
      if (onShow) {
        onShow()
      }
    }
  }

  render() {
    const { children, disabled, top, right, noRelative, className } = this.props
    const { active } = this.state
    // create component classes
    const dropdownClasses = `${className} ${styles.root} ${
      noRelative ? "" : styles.relative
    } ${disabled ? styles.disabled : ""}`
    // stick callback on trigger element
    const boundChildren = React.Children.map(children, child => {
      if (child.type === DropdownTrigger) {
        const newChild = cloneElement(child, {
          ref: el => {
            this.trigger = el
          },
          onClick: event => {
            this.onToggleClick(event)
          },
          top,
          active,
        })
        return newChild
      }
      if (child.type === DropdownContent) {
        const newChild = cloneElement(child, {
          ref: el => {
            this.content = el
          },
          onClick: event => {
            function isDescendant(parent, target) {
              if (target.nodeName === parent) return true
              let node = target.parentNode
              while (node != null) {
                if (node.nodeName === parent) {
                  return true
                }
                node = node.parentNode
              }
              return false
            }
            if (
              isDescendant("BUTTON", event.target) ||
              isDescendant("A", event.target)
            ) {
              this.hide()
            }
          },
          top,
          right,
          active,
        })
        return newChild
      }
      return child
    })

    return (
      <div
        className={dropdownClasses}
        ref={el => {
          this.dropdown = el
        }}
      >
        {boundChildren}
      </div>
    )
  }
}

export { DropdownTrigger, DropdownContent }
export default Dropdown
