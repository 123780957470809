import { all, select, put, fork, take } from "redux-saga/effects"
import { find } from "lodash"

import {
  EPL_CAMPAIGN_GET,
  EPL_CAMPAIGN_GET_REQUEST,
  EPL_CAMPAIGN_GET_SUCCESS,
  EPL_CAMPAIGN_GET_FAILURE,
  EPL_CAMPAIGN_CANCEL,
} from "data/constants"

function* getCampaignFromList() {
  while (true) {
    const action = yield take(EPL_CAMPAIGN_GET)
    const { eplReport } = yield select()

    yield put({ type: EPL_CAMPAIGN_GET_REQUEST })

    const campaign = find(eplReport.campaigns, {
      campaign_id: action.campaignID,
    })

    if (campaign) {
      yield put({ type: EPL_CAMPAIGN_GET_SUCCESS, data: campaign })
    } else {
      yield put({
        type: EPL_CAMPAIGN_GET_FAILURE,
        error: "EPL_CAMPAIGN_NOT_FOUND",
      })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getCampaignFromList)])

    yield all(tasks)

    yield take([EPL_CAMPAIGN_CANCEL])
    yield all(tasks.map(task => task.cancel()))
  }
}
