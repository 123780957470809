export const getServiceEligibility = ({ serviceName, services = [] }) => {
  if (services.length === 0) return false
  const eligibleService = services.find(
    service => service.service_name === serviceName
  )
  if (eligibleService) {
    return eligibleService.eligible
  }

  return false
}

export const SERVICE_TYPE = {
  SHIPPING: "shipping",
  RETURNS: "returns-international-active",
}
