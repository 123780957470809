import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function postItems(userId, platform, data) {
  const controller = new AbortController()

  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/bulk-request/${platform}`,
    method: "POST",
    data,
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getIssues(userId, platform) {
  const controller = new AbortController()

  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/bulk-request/${platform}/issues`,
    method: "GET",
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  postItems,
  getIssues,
}
