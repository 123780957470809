import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function get(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/personal_data_management/contact_details`,
    method: "GET",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function edit(userId, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/personal_data_management/contact_details`,
    method: "PUT",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  get,
  edit,
}
