import {
  USER_LOGOUT_SUCCESS,
  EPL_PLAN_OPT_OUT_MODAL_SHOW,
  EPL_PLAN_OPT_OUT_MODAL_HIDE,
  EPL_PLAN_UPGRADE_MODAL_SHOW,
  EPL_PLAN_UPGRADE_MODAL_HIDE,
  EPL_RESUBSCRIBE_MODAL_SHOW,
  EPL_RESUBSCRIBE_MODAL_HIDE,
  EPL_MARKET_CLOSE_MODAL_SHOW,
  EPL_MARKET_CLOSE_MODAL_HIDE,
  EPL_MARKET_RESUME_MODAL_SHOW,
  EPL_MARKET_RESUME_MODAL_HIDE,
  EPL_MARKET_PAUSE_MODAL_SHOW,
  EPL_MARKET_PAUSE_MODAL_HIDE,
  EPL_MARKET_OPEN_MODAL_SHOW,
  EPL_MARKET_OPEN_MODAL_HIDE,
  EPL_EXCLUDE_ITEMS_BY_FILE_MODAL_SHOW,
  EPL_EXCLUDE_ITEMS_BY_FILE_MODAL_HIDE,
  EPL_ITEMS_EXCLUDED_RESET_ALL_MODAL_SHOW,
  EPL_ITEMS_EXCLUDED_RESET_ALL_MODAL_HIDE,
  EPL_UPSELL_MODAL_SHOW,
  EPL_UPSELL_MODAL_HIDE,
  EPL_TRIGGER_MODAL,
  EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_SHOW,
  EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_HIDE,
  EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_SHOW,
  EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_HIDE,
} from "data/constants"

const defaultState = {
  showOptOutModal: false,
  showUpgradeModal: false,
  showResubscribeModal: false,
  showMarketOpenModal: false,
  showMarketCloseModal: false,
  showMarketResumeModal: false,
  showMarketPauseModal: false,
  showItemsExcludeByFileModal: false,
  showItemsExcludeResetAllModal: false,
  showUpsellModal: false,
  modalSettings: null,
  showNotEligibleModalForAll: false,
  showNotEligibleModalForSome: false,
}

const eplModalStore = (state = defaultState, action) => {
  switch (action.type) {
    case EPL_TRIGGER_MODAL:
      return {
        ...state,
        ...triggerModal(action),
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

const triggerModal = action => {
  const modalSettings = { data: action.data.modalData }

  switch (action.data.modalActionType) {
    case EPL_RESUBSCRIBE_MODAL_SHOW:
      modalSettings.showResubscribeModal = true
      break
    case EPL_RESUBSCRIBE_MODAL_HIDE:
      modalSettings.showResubscribeModal = false
      break
    case EPL_PLAN_OPT_OUT_MODAL_SHOW:
      modalSettings.showOptOutModal = true
      break
    case EPL_PLAN_OPT_OUT_MODAL_HIDE:
      modalSettings.showOptOutModal = false
      break
    case EPL_PLAN_UPGRADE_MODAL_SHOW:
      modalSettings.showUpgradeModal = true
      break
    case EPL_PLAN_UPGRADE_MODAL_HIDE:
      modalSettings.showUpgradeModal = false
      break
    case EPL_MARKET_CLOSE_MODAL_SHOW:
      modalSettings.showMarketCloseModal = true
      break
    case EPL_MARKET_CLOSE_MODAL_HIDE:
      modalSettings.showMarketCloseModal = false
      break
    case EPL_MARKET_RESUME_MODAL_SHOW:
      modalSettings.showMarketResumeModal = true
      break
    case EPL_MARKET_RESUME_MODAL_HIDE:
      modalSettings.showMarketResumeModal = false
      break
    case EPL_MARKET_PAUSE_MODAL_SHOW:
      modalSettings.showMarketPauseModal = true
      break
    case EPL_MARKET_PAUSE_MODAL_HIDE:
      modalSettings.showMarketPauseModal = false
      break
    case EPL_MARKET_OPEN_MODAL_SHOW:
      modalSettings.showMarketOpenModal = true
      break
    case EPL_MARKET_OPEN_MODAL_HIDE:
      modalSettings.showMarketOpenModal = false
      break
    case EPL_EXCLUDE_ITEMS_BY_FILE_MODAL_SHOW:
      modalSettings.showItemsExcludeByFileModal = true
      break
    case EPL_EXCLUDE_ITEMS_BY_FILE_MODAL_HIDE:
      modalSettings.showItemsExcludeByFileModal = false
      break
    case EPL_ITEMS_EXCLUDED_RESET_ALL_MODAL_SHOW:
      modalSettings.showItemsExcludeResetAllModal = true
      break
    case EPL_ITEMS_EXCLUDED_RESET_ALL_MODAL_HIDE:
      modalSettings.showItemsExcludeResetAllModal = false
      break
    case EPL_UPSELL_MODAL_SHOW:
      modalSettings.showUpsellModal = true
      break
    case EPL_UPSELL_MODAL_HIDE:
      modalSettings.showUpsellModal = false
      break
    case EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_SHOW:
      modalSettings.showNotEligibleModalForAll = true
      break
    case EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_HIDE:
      modalSettings.showNotEligibleModalForAll = false
      break
    case EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_SHOW:
      modalSettings.showNotEligibleModalForSome = true
      break
    case EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_HIDE:
      modalSettings.showNotEligibleModalForSome = false
      break
    default:
      break
  }

  return {
    ...modalSettings,
  }
}

export default eplModalStore
