import fetch from "core/fetch"
import CONFIG from "core/config"

export function postZuoraFeedback(data) {
  const options = {
    url: `${CONFIG.gatewayHost}/checkout`,
    method: "post",
    data,
  }
  const promise = fetch(options)

  return promise
}

export function getPaymentStatus(paymentId) {
  const options = {
    url: `${CONFIG.gatewayHost}/checkout/${paymentId}`,
    method: "get",
  }
  const promise = fetch(options)

  return promise
}

export default {
  postZuoraFeedback,
  getPaymentStatus,
}
