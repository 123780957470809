import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function getBrands(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/seller/${userId}/conversion-charts/brands`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getCategories(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/seller/${userId}/conversion-charts/categories`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function getAll(userId, params) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/seller/${userId}/conversion-charts`,
    method: "get",
    params: {
      ...params,
    },
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  getBrands,
  getCategories,
  getAll,
}
