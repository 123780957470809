import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function getServicesEligibility(userId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/services_eligibility`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()
  return promise
}
