import {
  EPL_ADRATE_STRATEGY_POST_SUCCESS,
  EPL_AD_RATE_SUCCESS_NOTIFICATION_CLEAR,
  EPL_PLAN_INTERNATIONAL_UPGRADE_POST_SUCCESS,
  EPL_PLAN_DOMESTIC_UPGRADE_POST_SUCCESS,
  EPL_PLAN_UPGRADE_SUCCESS_NOTIFICATION_CLEAR,
  EPL_MARKET_CLOSE_SUCCESS_NOTIFICATION_CLEAR,
  EPL_MARKET_OPEN_SUCCESS_NOTIFICATION_CLEAR,
  EPL_MARKET_PAUSE_SUCCESS_NOTIFICATION_CLEAR,
  EPL_MARKET_RESUME_SUCCESS_NOTIFICATION_CLEAR,
  EPL_CREATE_ZENDESK_CHURN_TICKET_POST_SUCCESS_NOTIFICATION_CLEAR,
  EPL_OPT_OUT_POST_SUCCESS_NOTIFICATION_CLEAR,
  EPL_EXCLUDE_ITEMS_BY_FILE_SUCCESS_NOTIFICATION_CLEAR,
  EPL_MARKETS_CLOSE_POST_SUCCESS,
  EPL_MARKETS_OPEN_POST_SUCCESS,
  EPL_MARKETS_PAUSE_POST_SUCCESS,
  EPL_MARKETS_RESUME_POST_SUCCESS,
  EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS,
  EPL_CREATE_ZENDESK_CHURN_TICKET_POST_SUCCESS,
  EPL_OPT_OUT_POST_SUCCESS,
  EPL_ITEMS_EXCLUDE_BY_FILE_POST_SUCCESS,
  EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS,
  EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS,
  EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS,
  EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS_NOTIFICATION_CLEAR,
  EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS_NOTIFICATION_CLEAR,
  EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS_NOTIFICATION_CLEAR,
  EPL_ITEMS_STATUS_GET_WITH_RESULTS,
  EPL_ITEMS_STATUS_GET_WITH_RESULTS_NOTIFICATION_CLEAR,
  EPL_START_TRIAL_POST_SUCCESS,
  EPL_START_STANDARD_POST_SUCCESS,
  EPL_START_POST_SUCCESS_NOTIFICATION_CLEAR,
  EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS_NOTIFICATION_CLEAR,
  EPL_UNACCEPTED_TOS_ALERT,
  EPL_UNACCEPTED_TOS_WARNING,
  EPL_END_OWN_CAMPAIGNS_ALERT,
  EPL_LOCAL_DATA,
} from "data/constants"
import { DataStore } from "shared/helpers/localStorage"

const { EPL_LOCAL_STORAGE, SHOW_END_OWN_CAMPAIGNS_ALERT } = EPL_LOCAL_DATA
const eplLocalStorage = DataStore.getAll(EPL_LOCAL_STORAGE)
const defaultState = {
  lastUpdatedCampaign: "",
  showPlanUpgradeAlert: false,
  showMarketCloseSuccessAlert: false,
  showMarketOpenSuccessAlert: false,
  showMarketPauseSuccessAlert: false,
  showMarketResumeSuccessAlert: false,
  showMarketSetAdRateSuccessAlert: false,
  showCreateChurnTicketSuccessAlert: false,
  showOptOutSuccessAlert: false,
  showItemsExcludeByFileSuccessAlert: false,
  showItemsDeleteAllExclusionsSuccessAlert: false,
  showItemsDeleteSelectedExclusionsSuccessAlert: false,
  showItemsExcludeSelectedSuccessAlert: false,
  showItemsExcludeByFileResultAlert: false,
  showEplStartSuccessAlert: false,
  showUnacceptedToSAlert: false,
  showUnacceptedToSWarning: false,
  // eslint-disable-next-line no-nested-ternary
  showEndOwnCampaignsAlert: eplLocalStorage
    ? eplLocalStorage[SHOW_END_OWN_CAMPAIGNS_ALERT] !== undefined
      ? eplLocalStorage[SHOW_END_OWN_CAMPAIGNS_ALERT]
      : true
    : true,
}

const eplAlertsStore = (state = defaultState, action) => {
  switch (action.type) {
    case EPL_ADRATE_STRATEGY_POST_SUCCESS:
      return {
        ...state,
        lastUpdatedCampaign: action.campaignName,
      }
    case EPL_AD_RATE_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        lastUpdatedCampaign: "",
      }
    case EPL_PLAN_INTERNATIONAL_UPGRADE_POST_SUCCESS:
    case EPL_PLAN_DOMESTIC_UPGRADE_POST_SUCCESS:
      return {
        ...state,
        showPlanUpgradeAlert: true,
      }
    case EPL_PLAN_UPGRADE_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showPlanUpgradeAlert: false,
      }
    case EPL_MARKETS_PAUSE_POST_SUCCESS:
      return {
        ...state,
        showMarketPauseSuccessAlert: true,
      }
    case EPL_MARKET_PAUSE_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showMarketPauseSuccessAlert: false,
      }
    case EPL_MARKETS_CLOSE_POST_SUCCESS:
      return {
        ...state,
        showMarketCloseSuccessAlert: true,
      }

    case EPL_MARKET_CLOSE_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showMarketCloseSuccessAlert: false,
      }
    case EPL_MARKETS_RESUME_POST_SUCCESS:
      return {
        ...state,
        showMarketResumeSuccessAlert: true,
      }
    case EPL_MARKET_RESUME_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showMarketResumeSuccessAlert: false,
      }
    case EPL_MARKETS_OPEN_POST_SUCCESS:
      return {
        ...state,
        showMarketOpenSuccessAlert: true,
      }
    case EPL_MARKET_OPEN_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showMarketOpenSuccessAlert: false,
      }
    case EPL_CREATE_ZENDESK_CHURN_TICKET_POST_SUCCESS:
      return {
        ...state,
        showCreateChurnTicketSuccessAlert: true,
      }
    case EPL_CREATE_ZENDESK_CHURN_TICKET_POST_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showCreateChurnTicketSuccessAlert: false,
      }
    case EPL_OPT_OUT_POST_SUCCESS:
      return {
        ...state,
        showOptOutSuccessAlert: true,
      }
    case EPL_OPT_OUT_POST_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showOptOutSuccessAlert: false,
      }
    case EPL_ITEMS_EXCLUDE_BY_FILE_POST_SUCCESS:
      return {
        ...state,
        showItemsExcludeByFileSuccessAlert: true,
      }
    case EPL_EXCLUDE_ITEMS_BY_FILE_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showItemsExcludeByFileSuccessAlert: false,
      }
    case EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS:
      return {
        ...state,
        showItemsDeleteAllExclusionsSuccessAlert: true,
      }
    case EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showItemsDeleteAllExclusionsSuccessAlert: false,
      }
    case EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS:
      return {
        ...state,
        showItemsDeleteSelectedExclusionsSuccessAlert: true,
        lastDeletedExclusions: action.req.affected,
      }
    case EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showItemsDeleteSelectedExclusionsSuccessAlert: false,
        lastDeletedExclusions: 0,
      }
    case EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS:
      return {
        ...state,
        showItemsExcludeSelectedSuccessAlert: true,
        lastExcludedItems: action.req.affected,
      }
    case EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showItemsExcludeSelectedSuccessAlert: false,
        lastExcludedItems: 0,
      }
    case EPL_ITEMS_STATUS_GET_WITH_RESULTS:
      return {
        ...state,
        showItemsExcludeByFileResultAlert: true,
        lastExcludedItemsByFile: action.data.excluded,
        lastFailedItemsByFile: action.data.failed,
      }
    case EPL_ITEMS_STATUS_GET_WITH_RESULTS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showItemsExcludeByFileResultAlert: false,
        lastExcludedItemsByFile: 0,
        lastFailedItemsByFile: 0,
      }
    case EPL_START_TRIAL_POST_SUCCESS:
    case EPL_START_STANDARD_POST_SUCCESS:
      return {
        ...state,
        showEplStartSuccessAlert: true,
      }
    case EPL_START_POST_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showEplStartSuccessAlert: false,
      }
    case EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS:
      return {
        ...state,
        showMarketSetAdRateSuccessAlert: true,
      }
    case EPL_SET_AD_RATE_FOR_MARKET_PATCH_SUCCESS_NOTIFICATION_CLEAR:
      return {
        ...state,
        showMarketSetAdRateSuccessAlert: false,
      }
    case EPL_UNACCEPTED_TOS_ALERT:
      return {
        ...state,
        showUnacceptedToSAlert: true,
      }
    case EPL_END_OWN_CAMPAIGNS_ALERT:
      return {
        ...state,
        showEndOwnCampaignsAlert: false,
      }
    case EPL_UNACCEPTED_TOS_WARNING:
      return {
        ...state,
        showUnacceptedToSWarning: true,
      }
    default:
      return state
  }
}

export default eplAlertsStore
