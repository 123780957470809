import React, { Component } from "react"
import PropTypes from "prop-types"

import { wasEnterPressed } from "shared/helpers/keyCodes"

import Button from "shared/components/Button/Button"
import { countWords } from "shared/helpers/strings"

import { withTranslate } from "context/translateContext"

import Tag from "./components/Tag"

import styles from "./TagInput.module.css"

class TagInput extends Component {
  static propTypes = {
    reference: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    validationError: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    maxWordCount: PropTypes.number,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    values: [],
    validationError: "",
    label: "",
    placeholder: "",
    maxLength: null,
    maxWordCount: null,
  }

  constructor(props) {
    super(props)

    this.state = {
      hasAddingErrorOccured: false,
    }

    this.onKeyup = this.onKeyup.bind(this)
    this.preventSubmit = this.preventSubmit.bind(this)
    this.readInputValue = this.readInputValue.bind(this)
  }

  componentDidMount() {
    this.tagInput.focus()
  }

  onKeyup(e) {
    if (wasEnterPressed(e)) {
      e.preventDefault()

      if (!this.readInputValue()) return false
    }

    return false
  }

  onFailedAdd() {
    this.setState({ hasAddingErrorOccured: true })
    setTimeout(() => {
      this.setState({ hasAddingErrorOccured: false })
    }, 500)
  }

  isNewValueValid() {
    const { values, maxWordCount } = this.props
    const tagValue = this.tagInput.value.trim()

    if (
      !tagValue ||
      values.indexOf(tagValue) !== -1 ||
      (maxWordCount && countWords(tagValue) > maxWordCount)
    ) {
      return false
    }
    return true
  }

  readInputValue() {
    if (!this.isNewValueValid()) {
      this.onFailedAdd()
      return undefined
    }
    const tagValue = this.tagInput.value.trim()

    this.tagInput.value = ""
    this.addTag(tagValue)

    return tagValue
  }

  preventSubmit(e) {
    if (wasEnterPressed(e)) {
      e.preventDefault()
    }
  }

  removeTagAt(index) {
    const { onChange, values } = this.props
    const updatedValue = [...values]

    updatedValue.splice(index, 1)
    onChange(updatedValue)
  }

  addTag(newValue) {
    const { onChange, values } = this.props
    const updatedValue = [...values]

    updatedValue.push(newValue)
    onChange(updatedValue)
  }

  render() {
    const {
      reference,
      placeholder,
      label,
      validationError,
      values,
      maxLength,
    } = this.props
    const { t: translate } = this.props
    const { hasAddingErrorOccured } = this.state
    return (
      <div className={styles.root}>
        {label && <label htmlFor={reference}>{label}</label>}
        <div
          className={[
            styles.inputContainer,
            hasAddingErrorOccured ? "hasError" : "",
          ].join(" ")}
        >
          <input
            id={reference}
            type="text"
            onKeyUp={this.onKeyup}
            placeholder={placeholder}
            onKeyDown={this.preventSubmit}
            onKeyPress={this.preventSubmit}
            maxLength={maxLength}
            ref={input => {
              this.tagInput = input
            }}
          />
          <Button
            variation="primary"
            size="large"
            onClick={this.readInputValue}
          >
            {translate("Add")}
          </Button>
        </div>
        {validationError && (
          <span className="inputError">{validationError}</span>
        )}
        <div className={styles.tagContainer}>
          {!values.length && (
            <span className={styles.noTagsMessage}>
              {translate("No items entered")}
            </span>
          )}
          {values.map((value, index) => (
            <Tag
              key={value}
              onClose={() => this.removeTagAt(index)}
              text={value}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default withTranslate(TagInput)
