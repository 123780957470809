import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslate } from "context/translateContext"

import styles from "./CheckoutSteps.module.css"

class CheckoutSteps extends Component {
  static propTypes = {
    pathname: PropTypes.string.isRequired,
  }

  getClass() {
    const { pathname } = this.props
    let className = styles.first
    switch (pathname) {
      case "/checkout/pay-as-you-go/billing":
      case "/checkout/monthly/billing":
      case "/checkout/yearly/billing":
      case "/checkout/special/billing":
        className = styles.second
        break
      case "/checkout/pay-as-you-go/payment":
      case "/checkout/monthly/payment":
      case "/checkout/yearly/payment":
      case "/checkout/special/payment":
        className = styles.third
        break
      default:
        break
    }
    return className
  }

  renderStep(n, name) {
    return (
      <div className={styles.step}>
        <div className={styles.circle}>
          <div className={styles.check}>
            <span className="icon-check" />
          </div>
          <div className={styles.number}>{n}</div>
        </div>
        <span className="fs-12">{name}</span>
      </div>
    )
  }

  render() {
    const { t: translate } = this.props
    const activeStepClass = this.getClass()

    return (
      <div className={[styles.root, activeStepClass, "clearfix"].join(" ")}>
        {this.renderStep(1, translate("Plan selection"))}
        {this.renderStep(2, translate("Billing address"))}
        {this.renderStep(3, translate("Payment method"))}
      </div>
    )
  }
}

export default withTranslate(CheckoutSteps)
