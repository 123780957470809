import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { withTranslate } from "context/translateContext"
import Container from "../Container"

import styles from "./AppAlertBanner.module.css"

export class AppAlertBanner extends PureComponent {
  static propTypes = {
    alerts: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  getAlertMessage(issue) {
    const { t: translate } = this.props
    const mapper = {
      ACCOUNT_BROKEN: {
        key: `ACCOUNT_BROKEN_${issue.accountId}`,
        message: translate(
          "Connection with your account {username} has been lost! Please reconnect the token immediately! {link}",
          {
            username: (
              <Link to={`/user/accounts/${issue.accountId}`}>
                {issue.username}
              </Link>
            ),
            link: (
              <Link to={`/user/accounts/${issue.accountId}`}>
                {translate("Add token")}
              </Link>
            ),
          }
        ),
        className: styles.danger,
      },
      ACCOUNT_REQUIRES_ATTENTION: {
        key: `ACCOUNT_REQUIRES_ATTENTION_${issue.accountId}`,
        message: translate(
          "Add additional token for your account {username} for a better synchronisation. {link}",
          {
            username: (
              <Link to={`/user/accounts/${issue.accountId}`}>
                {issue.username}
              </Link>
            ),
            link: (
              <Link to={`/user/accounts/${issue.accountId}`}>
                {translate("Add token")}
              </Link>
            ),
          }
        ),
        className: styles.warning,
      },
      ACCOUNT_REQUIRES_ATTENTION_2: {
        key: `ACCOUNT_REQUIRES_ATTENTION_2_${issue.accountId}`,
        message: translate(
          "One token from your account {username} is broken. {link}",
          {
            username: (
              <Link to={`/user/accounts/${issue.accountId}`}>
                {issue.username}
              </Link>
            ),
            link: (
              <Link to={`/user/accounts/${issue.accountId}`}>
                {translate("Refresh token")}
              </Link>
            ),
          }
        ),
        className: styles.warning,
      },
      PAYMENT_FAILED_1: {
        key: `PAYMENT_FAILED_1_${issue.accountId}`,
        message: translate(
          "Your last payment has failed. Please check your {link} for more information.",
          {
            link: (
              <Link to="/user/profile/financial-details">
                {translate("Profile")}
              </Link>
            ),
          }
        ),
        className: styles.danger,
      },
      PAYMENT_FAILED_2: {
        key: `PAYMENT_FAILED_2_${issue.accountId}`,
        message: translate(
          "Your last payment has failed and your credit card will expire soon. Please check your {link} for more information.",
          {
            link: (
              <Link to="/user/profile/financial-details">
                {translate("Profile")}
              </Link>
            ),
          }
        ),
        className: styles.danger,
      },
      PAYMENT_FAILED_3: {
        key: `PAYMENT_FAILED_3_${issue.accountId}`,
        message: translate(
          "Your last payment has failed and your credit card has expired. Please check your {link} for more information.",
          {
            link: (
              <Link to="/user/profile/financial-details">
                {translate("Profile")}
              </Link>
            ),
          }
        ),
        className: styles.danger,
      },
      PAYMENT_EXPIRE_SOON: {
        key: `PAYMENT_EXPIRE_SOON_${issue.accountId}`,
        message: translate(
          "Your credit card will expire soon. Please check your {link} to update your payment method.",
          {
            link: (
              <Link to="/user/profile/financial-details">
                {translate("Profile")}
              </Link>
            ),
          }
        ),
        className: styles.warning,
      },
      PAYMENT_EXPIRED: {
        key: `PAYMENT_EXPIRED_${issue.accountId}`,
        message: translate(
          "Your credit card has expired. Please check your {link} to update your payment method.",
          {
            link: (
              <Link to="/user/profile/financial-details">
                {translate("Profile")}
              </Link>
            ),
          }
        ),
        className: styles.warning,
      },
      CURRENCY_UNSUPPORTED: {
        key: "CURRENCY_UNSUPPORTED",
        message: translate(
          "Your account currency {currency} is not supported. Please contact our {support} to update your currency.",
          {
            currency: issue.currency ? `(${issue.currency})` : "",
            support: <Link to="/submit-request">{translate("Support")}</Link>,
          }
        ),
        className: styles.danger,
      },
    }
    return mapper[issue.type]
  }

  toggle() {
    const { open } = this.state
    this.setState({
      open: !open,
    })
  }

  render() {
    const { alerts } = this.props
    const { open } = this.state
    const { t: translate } = this.props
    const list = alerts.map(alert => this.getAlertMessage(alert))

    if (list.length === 0) return null
    return (
      <div className={[styles.root, open ? styles.open : ""].join(" ")}>
        <ul className="l-unstyled">
          {list.map(alert => (
            <li key={alert.key} className={alert.className}>
              <Container>{alert.message}</Container>
            </li>
          ))}
        </ul>
        {list.length > 1 && (
          <button
            onClick={() => this.toggle()}
            className={styles.more}
            type="button"
          >
            {open ? translate("Hide") : translate("See all")}
          </button>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  alerts: state.alerts.list,
})

export default connect(mapStateToProps)(withTranslate(AppAlertBanner))
