import {
  BLACKLIST_GET_REQUEST,
  BLACKLIST_GET_SUCCESS,
  BLACKLIST_GET_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  data: {},
  state: "INITIAL",
  error: null,
}

function blacklistSingleStore(state = defaultState, action) {
  switch (action.type) {
    case BLACKLIST_GET_REQUEST:
      return {
        ...state,
        data: {},
        error: null,
        state: "LOADING",
      }
    case BLACKLIST_GET_SUCCESS:
      return {
        ...state,
        data: action.data,
        state: "LOADED",
      }
    case BLACKLIST_GET_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        state: "FAILED",
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default blacklistSingleStore
