import {
  CONVERSION_CHARTS_GET_BRAND_LIST_REQUEST,
  CONVERSION_CHARTS_GET_BRAND_LIST_SUCCESS,
  CONVERSION_CHARTS_GET_BRAND_LIST_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  state: "INITIAL",
  list: [],
  error: null,
}

function conversionChartsBrandsStore(state = defaultState, action) {
  switch (action.type) {
    case CONVERSION_CHARTS_GET_BRAND_LIST_REQUEST:
      return {
        ...state,
        state: "LOADING",
      }
    case CONVERSION_CHARTS_GET_BRAND_LIST_SUCCESS:
      return {
        ...state,
        state: "LOADED",
        list: action.data.brands,
      }
    case CONVERSION_CHARTS_GET_BRAND_LIST_FAILURE:
      return {
        ...state,
        state: "FAILED",
        list: [],
        error: action.error,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default conversionChartsBrandsStore
