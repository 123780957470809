import {
  DASHBOARD_GET_SALES_WEEKLY_REQUEST,
  DASHBOARD_GET_SALES_WEEKLY_SUCCESS,
  DASHBOARD_GET_SALES_WEEKLY_FAILURE,
  DASHBOARD_GET_SALES_MONTHLY_REQUEST,
  DASHBOARD_GET_SALES_MONTHLY_SUCCESS,
  DASHBOARD_GET_SALES_MONTHLY_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  weekly: null,
  monthly: null,
  loadingWeekly: false,
  loadingMonthly: false,
  errorWeekly: null,
  errorMonthly: null,
}

function dashboardSalesStore(state = defaultState, action) {
  switch (action.type) {
    case DASHBOARD_GET_SALES_WEEKLY_REQUEST:
      return {
        ...state,
        loadingWeekly: true,
        errorWeekly: null,
      }
    case DASHBOARD_GET_SALES_MONTHLY_REQUEST:
      return {
        ...state,
        loadingMonthly: true,
        errorMonthly: null,
      }
    case DASHBOARD_GET_SALES_WEEKLY_SUCCESS:
      return {
        ...state,
        weekly: action.data,
        loadingWeekly: false,
      }
    case DASHBOARD_GET_SALES_MONTHLY_SUCCESS:
      return {
        ...state,
        monthly: action.data,
        loadingMonthly: false,
      }
    case DASHBOARD_GET_SALES_WEEKLY_FAILURE:
      return {
        ...state,
        errorWeekly: action.error,
        loadingWeekly: false,
      }
    case DASHBOARD_GET_SALES_MONTHLY_FAILURE:
      return {
        ...state,
        errorMonthly: action.error,
        loadingMonthly: false,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default dashboardSalesStore
