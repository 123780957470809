export const RETURN_KEY_CODE = 13
export const ENTER = "Enter"

export const wasEnterPressed = e =>
  e.key === ENTER ||
  e.keyCode === RETURN_KEY_CODE ||
  e.which === RETURN_KEY_CODE

export default {
  RETURN_KEY_CODE,
  ENTER,
  wasEnterPressed,
}
