import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, NavLink } from "react-router-dom"
import { connect } from "react-redux"
import Permissions from "react-redux-permissions"
import { withTranslate } from "context/translateContext"

import CONFIG from "core/config"

import { logout } from "data/actions/user/auth"
import { validAccounts } from "data/selectors/user"

import Container from "../Container"
import Dropdown from "../Dropdown"
import DropdownTrigger from "../Dropdown/DropdownTrigger"
import DropdownContent from "../Dropdown/DropdownContent"
import HelpDropdown from "../HelpDropdown"

import Menu from "./components/Menu"

import styles from "./Header.module.css"
import logo from "../../images/wi-logo.svg"

class Header extends Component {
  static propTypes = {
    accounts: PropTypes.object.isRequired,
    activePlatform: PropTypes.string,
    activeAccount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    stateReady: PropTypes.bool.isRequired,
    name: PropTypes.string,
    logoutUser: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
  }

  static defaultProps = {
    activePlatform: null,
    activeAccount: null,
    name: null,
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.logout = this.logout.bind(this)
  }

  openMenu() {
    this.setState({
      open: true,
    })
  }

  closeMenu() {
    this.setState({
      open: false,
    })
  }

  logout() {
    const { logoutUser } = this.props
    logoutUser()
  }

  render() {
    const {
      accounts,
      activePlatform,
      activeAccount,
      stateReady,
      name,
      lang,
      t: translate,
    } = this.props
    const { open } = this.state

    return (
      <header className={styles.root}>
        <Container>
          <button
            className={`${styles.toggle} visible-xs visible-sm`}
            onClick={this.openMenu}
            type="button"
          >
            <span className="icon icon-menu" />
          </button>
          <div className="clearfix">
            <nav>
              <div className={styles.logo}>
                <Link to="/">
                  <img src={logo} alt="WebInterpret logo" />
                </Link>
              </div>
              <div className={`${styles.menu} ${open ? styles.open : ""}`}>
                {stateReady && (
                  <Menu
                    accounts={accounts}
                    activePlatform={activePlatform}
                    activeAccount={activeAccount}
                    lang={lang}
                  />
                )}
              </div>
            </nav>
            {stateReady && (
              <div className="right">
                <HelpDropdown className="visible-md-inline-block visible-lg-inline-block" />
                <Dropdown className={styles.dropdown} right>
                  <DropdownTrigger
                    className={[styles.link, styles.userLink].join(" ")}
                  >
                    <span className={`${styles.user} hidden-xs hidden-sm`}>
                      <span className={styles.name}>
                        <span className="block">{name}</span>
                        <span className="c-gray fs-12">
                          {translate("Settings")}
                        </span>
                      </span>
                      <span className="icon-arrow-down fs-12" />
                    </span>
                    <span className={`${styles.user} visible-xs visible-sm`}>
                      <span className="icon-user fs-20" />
                    </span>
                  </DropdownTrigger>
                  <DropdownContent className={styles.dropdownContent}>
                    <ul className="l-unstyled">
                      <li>
                        <NavLink
                          className={navData =>
                            navData.isActive ? styles.active : ""
                          }
                          to="/user/profile"
                        >
                          <strong className="block">{name}</strong>
                          <span className="fs-12">
                            {translate("View profile")}
                          </span>
                        </NavLink>
                      </li>
                      <li className="separator" />
                      <li>
                        <NavLink
                          className={navData =>
                            navData.isActive ? styles.active : ""
                          }
                          to="/user/profile/settings"
                        >
                          {translate("Settings")}
                        </NavLink>
                      </li>
                      <Permissions allowed={["ebay"]}>
                        <li>
                          <NavLink
                            className={navData =>
                              navData.isActive ? styles.active : ""
                            }
                            to="/user/accounts"
                          >
                            {translate("Accounts")}
                          </NavLink>
                        </li>
                      </Permissions>
                      <li className="separator" />
                      <li>
                        <NavLink
                          className={navData =>
                            navData.isActive ? styles.active : ""
                          }
                          to="/user/invoices"
                        >
                          {translate("Invoices")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={navData =>
                            navData.isActive ? styles.active : ""
                          }
                          to="/user/plans"
                        >
                          {translate("Plans")}
                        </NavLink>
                      </li>
                      <li className="separator" />
                      <li>
                        <button onClick={this.logout} type="button">
                          {translate("Logout")}
                        </button>
                      </li>
                      <Permissions allowed={["admin"]}>
                        <li className="separator" />
                        <li>
                          <NavLink
                            className={navData =>
                              navData.isActive ? styles.active : ""
                            }
                            to="/user/permissions"
                          >
                            {translate("Permissions")}
                          </NavLink>
                        </li>
                        <li>
                          <a
                            href={`${CONFIG.portalAdminEbay}/admin/become_admin_user_back_again`}
                          >
                            <span className="icon-logout" />{" "}
                            {translate("Admin Ebay")}
                          </a>
                        </li>
                        <li>
                          <a
                            href={`${CONFIG.portalAdminAmazon}/admin/become_admin_user_back_again`}
                          >
                            <span className="icon-logout" />{" "}
                            {translate("Admin Amazon")}
                          </a>
                        </li>
                      </Permissions>
                    </ul>
                  </DropdownContent>
                </Dropdown>
              </div>
            )}
          </div>
          <div
            role="button"
            tabIndex={-1}
            className={`${styles.backdrop} ${open ? styles.open : ""}`}
            onClick={this.closeMenu}
            onKeyPress={this.closeMenu}
          />
        </Container>
      </header>
    )
  }
}

const mapStateToProps = state => ({
  activePlatform: state.general.activePlatform,
  activeAccount: state.general.activeAccount,
  accounts: validAccounts(state),
  lang: state.i18nState.lang,
})

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(withTranslate(Header))
