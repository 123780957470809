import {
  LOADING_STATES,
  PRODUCTS_GET_SETTINGS_REQUEST,
  PRODUCTS_GET_SETTINGS_SUCCESS,
  PRODUCTS_GET_SETTINGS_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

const defaultState = {
  countryUsage: null,
  freeShipping: null,
  itemCondition: null,
  vatFree: null,
  state: LOADING_STATES.INITIAL,
  error: null,
}

function productSettingsStore(state = defaultState, action) {
  switch (action.type) {
    case PRODUCTS_GET_SETTINGS_REQUEST:
      return {
        ...state,
        error: null,
        state: LOADING_STATES.LOADING,
      }
    case PRODUCTS_GET_SETTINGS_SUCCESS:
      return {
        ...state,
        countryUsage: action.data.countryUsage,
        freeShipping: action.data.freeShipping,
        itemCondition: action.data.itemCondition,
        vatFree: action.data.vatFree,
        state: LOADING_STATES.LOADED,
      }
    case PRODUCTS_GET_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        state: LOADING_STATES.FAILED,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default productSettingsStore
