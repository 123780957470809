const AMAZON_SITES_LIST = [
  {
    country: "DE",
    region: "Europe",
    site: "DE",
  },
  {
    country: "ES",
    region: "Europe",
    site: "ES",
  },
  {
    country: "IT",
    region: "Europe",
    site: "IT",
  },
  {
    country: "UK",
    region: "Europe",
    site: "UK",
  },
  {
    country: "FR",
    region: "Europe",
    site: "FR",
  },
]

export function getSitesList() {
  return AMAZON_SITES_LIST
}

export default {
  getSitesList,
}
