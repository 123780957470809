import { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useSearchParams } from "react-router-dom"

import { dispatchTokenStateConnection } from "data/actions/user/multichannelOnboarding"

const TokenConnectionHandler = ({ dispatchRedirectReason }) => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const onboardingPlatform = searchParams.get("onboarding")
    const reason = searchParams.get("fail_type")
    if (["amazon", "ebay"].includes(onboardingPlatform)) {
      dispatchRedirectReason(onboardingPlatform, reason)
    }

    return undefined
  }, [])

  return null
}

TokenConnectionHandler.propTypes = {
  dispatchRedirectReason: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  dispatchRedirectReason: (platform, reason) =>
    dispatch(dispatchTokenStateConnection(platform, reason)),
})

export default connect(null, mapDispatchToProps)(TokenConnectionHandler)
