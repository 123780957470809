import React from "react"
import PropTypes from "prop-types"

import styles from "./ModalContent.module.css"

function ModalContent({ children, className }) {
  return <div className={[styles.root, className].join(" ")}>{children}</div>
}

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

ModalContent.defaultProps = {
  className: "",
}

export default ModalContent
