import { ZENDESK_CHANNELS_GET_SUCCESS } from "data/constants"
const defaultState = {
  channels: {
    chat: false,
    ticket_form: false,
    talk: false,
    help_center: false,
  },
}

function zendeskStore(state = defaultState, action) {
  switch (action.type) {
    case ZENDESK_CHANNELS_GET_SUCCESS:
      return {
        ...state,
        channels: action.req,
      }
    default:
      return state
  }
}

export default zendeskStore
