import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function get(userId, service) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/terms_of_service/${service}/latest`,
    method: "GET",
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function post(userId, service, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/terms_of_service/${service}`,
    method: "POST",
    data,
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function put(userId, service, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/terms_of_service/${service}/${data.version}`,
    method: "PUT",
    data,
  }

  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  get,
  post,
  put,
}
