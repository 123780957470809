import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { NavLink, Link } from "react-router-dom"
import Permissions from "react-redux-permissions"
import { withTranslate } from "context/translateContext"

import Badge from "shared/components/Badge"
import Container from "shared/components/Container"
import Dropdown from "shared/components/Dropdown"
import DropdownTrigger from "shared/components/Dropdown/DropdownTrigger"
import DropdownContent from "shared/components/Dropdown/DropdownContent"
import HelpDropdown from "shared/components/HelpDropdown"
import { DataStore } from "shared/helpers/localStorage"

import withRouter from "shared/helpers/withRouter"
import styles from "../../Header.module.css"

class Menu extends Component {
  static propTypes = {
    accounts: PropTypes.object.isRequired,
    activePlatform: PropTypes.string,
    activeAccount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    location: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    activePlatform: null,
    activeAccount: null,
  }

  constructor(props) {
    super(props)
    this.localStoreName = "VISITED_TABS"

    const visitedTabsStore = DataStore.getAll(this.localStoreName)

    this.state = {
      isActive: false,
      isShippingVisited: visitedTabsStore?.isShippingVisited,
      isReturnsVisited: visitedTabsStore?.isReturnsVisited,
    }

    this.setTabVisited = this.setTabVisited.bind(this)
  }

  static getDerivedStateFromProps(props) {
    return {
      isActive: props.location.pathname.indexOf("/tools/") > -1,
    }
  }

  shouldComponentUpdate(nextProps) {
    const { location, activeAccount, activePlatform, lang } = this.props
    if (
      nextProps.activePlatform !== activePlatform ||
      nextProps.activeAccount !== activeAccount ||
      nextProps.location.pathname !== location.pathname ||
      nextProps.lang !== lang
    ) {
      return true
    }
    return false
  }

  getAccount(platform) {
    const { accounts, activeAccount } = this.props
    return accounts[platform].indexOf(activeAccount) > -1
      ? activeAccount
      : accounts[platform][0]
  }

  setSpecialExceptPermissions(activePlatform) {
    return activePlatform === "amazon"
      ? ["preTrialESB", "isReturningAmazonUser"]
      : ["preTrialESB"]
  }

  setTabVisited(tab) {
    const { state } = this
    const name = tab === "shipping" ? "isShippingVisited" : "isReturnsVisited"

    DataStore.set(this.localStoreName, { [name]: true })

    if (!state[name]) {
      this.setState({
        [name]: true,
      })
    }
  }

  render() {
    const { activePlatform } = this.props
    const { isActive, isShippingVisited, isReturnsVisited } = this.state
    const { t: translate } = this.props
    const account = this.getAccount(activePlatform)
    const except = this.setSpecialExceptPermissions(activePlatform)

    return (
      <Permissions except={["noPlatform"]}>
        <ul className="l-unstyled">
          <li>
            <Permissions except={["preTrialESB"]}>
              <NavLink
                to={`/${activePlatform}/${account}/dashboard`}
                className={navData => (navData.isActive ? styles.active : "")}
              >
                {translate("Dashboard")}
              </NavLink>
            </Permissions>
          </li>

          {["ebay", "amazon"].includes(activePlatform) && (
            <Fragment>
              <li>
                <Permissions except={except}>
                  <NavLink
                    className={navData =>
                      navData.isActive ? styles.active : ""
                    }
                    to={`/${activePlatform}/${account}/products`}
                  >
                    {translate("Products")}
                  </NavLink>
                </Permissions>
              </li>
              <li>
                <Permissions except={except}>
                  <NavLink
                    className={navData =>
                      navData.isActive ? styles.active : ""
                    }
                    to={`/${activePlatform}/${account}/orders`}
                  >
                    {translate("Orders")}
                  </NavLink>
                </Permissions>
              </li>

              <li>
                <Link
                  to="/shipping"
                  className={styles.withLabel}
                  onClick={() => this.setTabVisited("shipping")}
                >
                  {translate("Shipping")}
                  {!isShippingVisited && (
                    <Badge type="motive" className={styles.menuLabel}>
                      {translate("NEW")}
                    </Badge>
                  )}
                </Link>
              </li>
              <li>
                <Link
                  to="/returns"
                  className={styles.withLabel}
                  onClick={() => this.setTabVisited("returns")}
                >
                  {translate("Returns")}
                  {!isReturnsVisited && (
                    <Badge type="motive" className={styles.menuLabel}>
                      {translate("NEW")}
                    </Badge>
                  )}
                </Link>
              </li>
              <li>
                {activePlatform === "ebay" && (
                  <NavLink
                    className={navData =>
                      navData.isActive
                        ? `${styles.active} ${styles.withLabel}`
                        : styles.withLabel
                    }
                    to={`/${activePlatform}/${account}/marketing`}
                  >
                    {translate("Marketing")}
                  </NavLink>
                )}
              </li>
              <li>
                <Permissions allowed={["ebay", "amazon"]} except={except}>
                  <Dropdown className={styles.menuDropdownRoot} noRelative>
                    <DropdownTrigger className={styles.link}>
                      <span>
                        {translate("More")}{" "}
                        <span className="icon-arrow-down fs-12" />
                      </span>
                    </DropdownTrigger>
                    <DropdownContent
                      className={[
                        styles.menuDropdown,
                        isActive ? styles.menuDropdownActive : "",
                      ].join(" ")}
                      activeClassName={styles.menuDropdownActive}
                    >
                      <Container>
                        <div className={styles.menuDropdownCol}>
                          <span className={styles.menuDropdownHeader}>
                            {translate("General")}
                          </span>
                          <ul className="l-unstyled">
                            <li>
                              <NavLink
                                className={navData =>
                                  navData.isActive ? styles.active : ""
                                }
                                to={`/all/${this.getAccount(
                                  "all"
                                )}/tools/conversion-charts`}
                              >
                                {translate("Conversion Charts")}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className={navData =>
                                  navData.isActive ? styles.active : ""
                                }
                                to={`/${activePlatform}/${account}/tools/blacklist`}
                              >
                                {translate("Blacklist")}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className={navData =>
                                  navData.isActive ? styles.active : ""
                                }
                                to={`/all/${this.getAccount(
                                  "all"
                                )}/tools/translation-manager`}
                              >
                                {translate("Translation Manager")}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                className={navData =>
                                  navData.isActive ? styles.active : ""
                                }
                                to={`/${activePlatform}/${account}/tools/price-adjustment`}
                              >
                                {translate("Price Adjustment")}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <Permissions allowed={["ebay"]}>
                          <div className={styles.menuDropdownCol}>
                            <span className={styles.menuDropdownHeader}>
                              {translate("eBay")}
                            </span>
                            <ul className="l-unstyled">
                              <li>
                                <NavLink
                                  className={navData =>
                                    navData.isActive ? styles.active : ""
                                  }
                                  to={`/ebay/${this.getAccount(
                                    "ebay"
                                  )}/tools/shipping-manager`}
                                >
                                  {translate("Shipping Manager")}
                                </NavLink>
                              </li>
                              <Permissions allowed={["bulkItemRequest"]}>
                                <li>
                                  <NavLink
                                    className={navData =>
                                      navData.isActive ? styles.active : ""
                                    }
                                    to={`/ebay/${this.getAccount(
                                      "ebay"
                                    )}/tools/bulk-request`}
                                  >
                                    {translate("Bulk Request")}
                                  </NavLink>
                                </li>
                              </Permissions>
                            </ul>
                          </div>
                        </Permissions>
                        <Permissions allowed={["amazon"]}>
                          <div className={styles.menuDropdownCol}>
                            <span className={styles.menuDropdownHeader}>
                              {translate("Amazon")}
                            </span>
                            <ul className="l-unstyled">
                              <li>
                                <NavLink
                                  className={navData =>
                                    navData.isActive ? styles.active : ""
                                  }
                                  to={`/amazon/${this.getAccount(
                                    "amazon"
                                  )}/tools/repricer`}
                                >
                                  {translate("Repricer")}
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </Permissions>
                      </Container>
                    </DropdownContent>
                  </Dropdown>
                </Permissions>
              </li>
            </Fragment>
          )}
          <li className="visible-xs visible-sm">
            <div className="separator" />
            <HelpDropdown className={styles.help} />
          </li>
        </ul>
      </Permissions>
    )
  }
}

export default withRouter(withTranslate(Menu))
