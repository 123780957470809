import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "print-js"
import { useTranslate } from "context/translateContext"

import { getService } from "shared/services/termsOfService"
import Loader from "shared/components/Loader/Local"

import styles from "./InlinePDF.module.css"

const InlinePDF = props => {
  const { lang, renderActions, termsType, termsVersion, width } = props
  const { t: translate } = useTranslate()

  const [file, setFile] = useState("")
  const [numPages, setNumPages] = useState(null)

  useEffect(() => {
    const getTOS = getService(termsType, termsVersion)
    setFile(getTOS.files[termsVersion][lang] || getTOS.files.current.en)
  }, [termsType, termsVersion])

  const onDocumentLoad = ({ numPages: pageNo }) => setNumPages(pageNo)

  const printDocument = () => {
    window.printJS({
      type: "pdf",
      printable: file,
    })
  }

  const renderError = () => (
    <div className="">
      <a href={file} target="_blank">
        {translate("Read Terms of Service")}
      </a>
    </div>
  )

  const renderActionButtons = () => {
    const fileName = file
      .split("/")
      .pop()
      .split("?")[0]

    return (
      <div className={`${styles.actions} flex-container f-d-col`}>
        <a
          type="pdf"
          title={translate("Download document")}
          className={`icon-cloud-download ${styles.icon}`}
          download={fileName}
          href={file}
        />
        <button
          title={translate("Print document")}
          type="button"
          onClick={printDocument}
          className={`icon-printer ${styles.icon}`}
        />
      </div>
    )
  }

  const renderPages = () => {
    const pages = []

    for (let i = 1; i < numPages + 1; i += 1) {
      pages.push(
        <div key={`${i}-page`} className={styles.page}>
          <Page
            pageNumber={i}
            loading={translate("Failed to load page.")}
            renderTextLayer={false}
            width={width - 40}
          />
        </div>
      )
    }
    return pages
  }

  return (
    <Document
      file={file}
      className={styles.root}
      onLoadSuccess={onDocumentLoad}
      width={width}
      loading={
        <div className={styles.load}>
          <Loader />
        </div>
      }
      error={renderError()}
    >
      <div className={styles.pages}>{renderPages()}</div>
      {renderActions && renderActionButtons()}
    </Document>
  )
}

InlinePDF.propTypes = {
  termsType: PropTypes.string,
  termsVersion: PropTypes.string,
  lang: PropTypes.string,
  width: PropTypes.number,
  renderActions: PropTypes.bool,
}

InlinePDF.contextTypes = {
  t: PropTypes.func.isRequired,
}

InlinePDF.defaultProps = {
  termsType: "wi",
  termsVersion: "current",
  lang: "en",
  width: 600,
  renderActions: false,
}

export default InlinePDF
