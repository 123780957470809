/* eslint-disable no-console */

import loggerAPI from "data/fetch/logger"
const { NODE_ENV } = process.env

const postLog = payload => loggerAPI.pushLog(payload)

const getLevel = level => {
  const levels = ["info", "warn", "error"]
  return levels.includes(level.toLowerCase()) ? level : "info"
}

function logger(
  message,
  userId,
  businessProcessName,
  level = "info",
  error = null
) {
  try {
    if (typeof message !== "string") {
      console.log('logger: "message" is required and must be a string')
      return Promise.resolve()
    }

    if (typeof userId !== "number") {
      console.log('logger: "userId" is required and must be a number')
      return Promise.resolve()
    }

    const payload = {
      message,
      userId,
      processName: businessProcessName
        ? businessProcessName.toString()
        : "common",
      level: getLevel(level),
    }

    if (error) {
      payload.error = error
      payload.level = "error"
    }

    if (NODE_ENV === "development") {
      console.log(payload)
      return Promise.resolve()
    }

    return postLog(payload)
  } catch (err) {
    if (NODE_ENV === "development") {
      console.log(err)
    }

    return Promise.resolve()
  }
}

export default logger
