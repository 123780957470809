import { find, cloneDeep } from "lodash"

const areas = [
  { name: "Europe", code: "EU" },
  { name: "North America", code: "NA" },
  { name: "Worldwide", code: "RW" },
]
const countries = [
  { name: "Afghanistan", code: "AF", requiresPostalCode: true },
  { name: "Åland Islands", code: "AX", requiresPostalCode: true },
  { name: "Albania", code: "AL", requiresPostalCode: true },
  { name: "Algeria", code: "DZ", requiresPostalCode: true },
  { name: "American Samoa", code: "AS", requiresPostalCode: true },
  { name: "Andorra", code: "AD", requiresPostalCode: true },
  { name: "Angola", code: "AO", requiresPostalCode: false },
  { name: "Anguilla", code: "AI", requiresPostalCode: false },
  { name: "Antarctica", code: "AQ", requiresPostalCode: true },
  { name: "Antigua and Barbuda", code: "AG", requiresPostalCode: false },
  { name: "Argentina", code: "AR", requiresPostalCode: true },
  { name: "Armenia", code: "AM", requiresPostalCode: true },
  { name: "Aruba", code: "AW", requiresPostalCode: false },
  { name: "Australia", code: "AU", requiresPostalCode: true },
  { name: "Austria", code: "AT", requiresPostalCode: true },
  { name: "Azerbaijan", code: "AZ", requiresPostalCode: true },
  { name: "Bahamas", code: "BS", requiresPostalCode: false },
  { name: "Bahrain", code: "BH", requiresPostalCode: true },
  { name: "Bangladesh", code: "BD", requiresPostalCode: true },
  { name: "Barbados", code: "BB", requiresPostalCode: true },
  { name: "Belarus", code: "BY", requiresPostalCode: true },
  { name: "Belgium", code: "BE", requiresPostalCode: true },
  { name: "Belgium (French)", code: "BEFR", requiresPostalCode: true },
  { name: "Belize", code: "BZ", requiresPostalCode: false },
  { name: "Benin", code: "BJ", requiresPostalCode: false },
  { name: "Bermuda", code: "BM", requiresPostalCode: true },
  { name: "Bhutan", code: "BT", requiresPostalCode: false },
  { name: "Bolivia", code: "BO", requiresPostalCode: false },
  { name: "Bonaire", code: "BQ", requiresPostalCode: true },
  { name: "Bosnia and Herzegovina", code: "BA", requiresPostalCode: true },
  { name: "Botswana", code: "BW", requiresPostalCode: false },
  { name: "Bouvet Island", code: "BV", requiresPostalCode: false },
  { name: "Brazil", code: "BR", requiresPostalCode: true },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    requiresPostalCode: true,
  },
  { name: "Brunei Darussalam", code: "BN", requiresPostalCode: true },
  { name: "Bulgaria", code: "BG", requiresPostalCode: true },
  { name: "Burkina Faso", code: "BF", requiresPostalCode: false },
  { name: "Burundi", code: "BI", requiresPostalCode: false },
  { name: "Cambodia", code: "KH", requiresPostalCode: true },
  { name: "Cameroon", code: "CM", requiresPostalCode: false },
  { name: "Canada", code: "CA", requiresPostalCode: true },
  { name: "Canada (French)", code: "CAFR", requiresPostalCode: true },
  { name: "Cape Verde", code: "CV", requiresPostalCode: true },
  { name: "Cayman Islands", code: "KY", requiresPostalCode: true },
  { name: "Central African Republic", code: "CF", requiresPostalCode: false },
  { name: "Chad", code: "TD", requiresPostalCode: false },
  { name: "Chile", code: "CL", requiresPostalCode: true },
  { name: "China", code: "CN", requiresPostalCode: true },
  { name: "Christmas Island", code: "CX", requiresPostalCode: true },
  { name: "Cocos (Keeling) Islands", code: "CC", requiresPostalCode: true },
  { name: "Colombia", code: "CO", requiresPostalCode: true },
  { name: "Comoros", code: "KM", requiresPostalCode: false },
  { name: "Congo", code: "CG", requiresPostalCode: false },
  {
    name: "Congo, The Democratic Republic of the",
    code: "CD",
    requiresPostalCode: false,
  },
  { name: "Cook Islands", code: "CK", requiresPostalCode: false },
  { name: "Costa Rica", code: "CR", requiresPostalCode: true },
  { name: "Ivory Coast", code: "CI", requiresPostalCode: false },
  { name: "Croatia", code: "HR", requiresPostalCode: true },
  { name: "Cuba", code: "CU", requiresPostalCode: true },
  { name: "Curaçao", code: "CW", requiresPostalCode: false },
  { name: "Cyprus", code: "CY", requiresPostalCode: true },
  { name: "Czech Republic", code: "CZ", requiresPostalCode: true },
  { name: "Denmark", code: "DK", requiresPostalCode: true },
  { name: "Djibouti", code: "DJ", requiresPostalCode: false },
  { name: "Dominica", code: "DM", requiresPostalCode: false },
  { name: "Dominican Republic", code: "DO", requiresPostalCode: true },
  { name: "Ecuador", code: "EC", requiresPostalCode: true },
  { name: "Egypt", code: "EG", requiresPostalCode: false },
  { name: "El Salvador", code: "SV", requiresPostalCode: false },
  { name: "Equatorial Guinea", code: "GQ", requiresPostalCode: false },
  { name: "Eritrea", code: "ER", requiresPostalCode: false },
  { name: "Estonia", code: "EE", requiresPostalCode: true },
  { name: "Ethiopia", code: "ET", requiresPostalCode: false },
  { name: "Falkland Islands (Malvinas)", code: "FK", requiresPostalCode: true },
  { name: "Faroe Islands", code: "FO", requiresPostalCode: true },
  { name: "Fiji", code: "FJ", requiresPostalCode: false },
  { name: "Finland", code: "FI", requiresPostalCode: true },
  { name: "France", code: "FR", requiresPostalCode: true },
  { name: "French Guiana", code: "GF", requiresPostalCode: true },
  { name: "French Polynesia", code: "PF", requiresPostalCode: true },
  { name: "French Southern Territories", code: "TF", requiresPostalCode: true },
  { name: "Gabon", code: "GA", requiresPostalCode: false },
  { name: "Gambia", code: "GM", requiresPostalCode: false },
  { name: "Georgia", code: "GE", requiresPostalCode: false },
  { name: "Germany", code: "DE", requiresPostalCode: true },
  { name: "Ghana", code: "GH", requiresPostalCode: false },
  { name: "Gibraltar", code: "GI", requiresPostalCode: true },
  { name: "Great Britain", code: "GB", requiresPostalCode: true },
  { name: "Greece", code: "GR", requiresPostalCode: false },
  { name: "Greenland", code: "GL", requiresPostalCode: true },
  { name: "Grenada", code: "GD", requiresPostalCode: false },
  { name: "Guadeloupe", code: "GP", requiresPostalCode: true },
  { name: "Guam", code: "GU", requiresPostalCode: true },
  { name: "Guatemala", code: "GT", requiresPostalCode: true },
  { name: "Guernsey", code: "GG", requiresPostalCode: true },
  { name: "Guinea", code: "GN", requiresPostalCode: false },
  { name: "Guinea-Bissau", code: "GW", requiresPostalCode: false },
  { name: "Guyana", code: "GY", requiresPostalCode: false },
  { name: "Haiti", code: "HT", requiresPostalCode: true },
  {
    name: "Heard Island and Mcdonald Islands",
    code: "HM",
    requiresPostalCode: true,
  },
  {
    name: "Holy See (Vatican City State)",
    code: "VA",
    requiresPostalCode: true,
  },
  { name: "Honduras", code: "HN", requiresPostalCode: false },
  { name: "Hong Kong", code: "HK", requiresPostalCode: false },
  { name: "Hungary", code: "HU", requiresPostalCode: true },
  { name: "Iceland", code: "IS", requiresPostalCode: true },
  { name: "India", code: "IN", requiresPostalCode: true },
  { name: "Indonesia", code: "ID", requiresPostalCode: true },
  { name: "Iran, Islamic Republic Of", code: "IR", requiresPostalCode: false },
  { name: "Iraq", code: "IQ", requiresPostalCode: false },
  { name: "Ireland", code: "IE", requiresPostalCode: false },
  { name: "Isle of Man", code: "IM", requiresPostalCode: true },
  { name: "Israel", code: "IL", requiresPostalCode: true },
  { name: "Italy", code: "IT", requiresPostalCode: true },
  { name: "Jamaica", code: "JM", requiresPostalCode: true },
  { name: "Japan", code: "JP", requiresPostalCode: true },
  { name: "Jersey", code: "JE", requiresPostalCode: true },
  { name: "Jordan", code: "JO", requiresPostalCode: false },
  { name: "Kazakhstan", code: "KZ", requiresPostalCode: true },
  { name: "Kenya", code: "KE", requiresPostalCode: false },
  { name: "Kiribati", code: "KI", requiresPostalCode: false },
  {
    name: 'Korea, Democratic People"s Republic of',
    code: "KP",
    requiresPostalCode: false,
  },
  { name: "Korea, Republic of", code: "KR", requiresPostalCode: true },
  { name: "Kuwait", code: "KW", requiresPostalCode: false },
  { name: "Kyrgyzstan", code: "KG", requiresPostalCode: true },
  {
    name: 'Lao People"s Democratic Republic',
    code: "LA",
    requiresPostalCode: false,
  },
  { name: "Latvia", code: "LV", requiresPostalCode: true },
  { name: "Lebanon", code: "LB", requiresPostalCode: false },
  { name: "Lesotho", code: "LS", requiresPostalCode: false },
  { name: "Liberia", code: "LR", requiresPostalCode: false },
  { name: "Libyan Arab Jamahiriya", code: "LY", requiresPostalCode: false },
  { name: "Liechtenstein", code: "LI", requiresPostalCode: true },
  { name: "Lithuania", code: "LT", requiresPostalCode: true },
  { name: "Luxembourg", code: "LU", requiresPostalCode: true },
  { name: "Macao", code: "MO", requiresPostalCode: false },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
    requiresPostalCode: true,
  },
  { name: "Madagascar", code: "MG", requiresPostalCode: true },
  { name: "Malawi", code: "MW", requiresPostalCode: false },
  { name: "Malaysia", code: "MY", requiresPostalCode: true },
  { name: "Maldives", code: "MV", requiresPostalCode: true },
  { name: "Mali", code: "ML", requiresPostalCode: true },
  { name: "Malta", code: "MT", requiresPostalCode: true },
  { name: "Marshall Islands", code: "MH", requiresPostalCode: true },
  { name: "Martinique", code: "MQ", requiresPostalCode: true },
  { name: "Mauritania", code: "MR", requiresPostalCode: false },
  { name: "Mauritius", code: "MU", requiresPostalCode: true },
  { name: "Mayotte", code: "YT", requiresPostalCode: true },
  { name: "Mexico", code: "MX", requiresPostalCode: true },
  {
    name: "Micronesia, Federated States of",
    code: "FM",
    requiresPostalCode: true,
  },
  { name: "Moldova, Republic of", code: "MD", requiresPostalCode: true },
  { name: "Monaco", code: "MC", requiresPostalCode: true },
  { name: "Mongolia", code: "MN", requiresPostalCode: true },
  { name: "Montenegro", code: "ME", requiresPostalCode: true },
  { name: "Montserrat", code: "MS", requiresPostalCode: false },
  { name: "Morocco", code: "MA", requiresPostalCode: true },
  { name: "Mozambique", code: "MZ", requiresPostalCode: true },
  { name: "Myanmar", code: "MM", requiresPostalCode: true },
  { name: "Namibia", code: "NA", requiresPostalCode: false },
  { name: "Nauru", code: "NR", requiresPostalCode: false },
  { name: "Nepal", code: "NP", requiresPostalCode: true },
  { name: "Netherlands", code: "NL", requiresPostalCode: true },
  { name: "Netherlands Antilles", code: "AN", requiresPostalCode: false },
  { name: "New Caledonia", code: "NC", requiresPostalCode: true },
  { name: "New Zealand", code: "NZ", requiresPostalCode: true },
  { name: "Nicaragua", code: "NI", requiresPostalCode: true },
  { name: "Niger", code: "NE", requiresPostalCode: false },
  { name: "Nigeria", code: "NG", requiresPostalCode: true },
  { name: "Niue", code: "NU", requiresPostalCode: false },
  { name: "Norfolk Island", code: "NF", requiresPostalCode: true },
  { name: "Northern Mariana Islands", code: "MP", requiresPostalCode: true },
  { name: "Norway", code: "NO", requiresPostalCode: true },
  { name: "Oman", code: "OM", requiresPostalCode: true },
  { name: "Pakistan", code: "PK", requiresPostalCode: true },
  { name: "Palau", code: "PW", requiresPostalCode: true },
  {
    name: "Palestinian Territory, Occupied",
    code: "PS",
    requiresPostalCode: true,
  },
  { name: "Panama", code: "PA", requiresPostalCode: false },
  { name: "Papua New Guinea", code: "PG", requiresPostalCode: true },
  { name: "Paraguay", code: "PY", requiresPostalCode: true },
  { name: "Peru", code: "PE", requiresPostalCode: true },
  { name: "Philippines", code: "PH", requiresPostalCode: true },
  { name: "Pitcairn", code: "PN", requiresPostalCode: true },
  { name: "Poland", code: "PL", requiresPostalCode: true },
  { name: "Portugal", code: "PT", requiresPostalCode: true },
  { name: "Puerto Rico", code: "PR", requiresPostalCode: true },
  { name: "Qatar", code: "QA", requiresPostalCode: false },
  { name: "Reunion", code: "RE", requiresPostalCode: true },
  { name: "Romania", code: "RO", requiresPostalCode: true },
  { name: "Russian Federation", code: "RU", requiresPostalCode: true },
  { name: "Rwanda", code: "RW", requiresPostalCode: false },
  { name: "Saint Helena", code: "SH", requiresPostalCode: true },
  { name: "Saint Kitts and Nevis", code: "KN", requiresPostalCode: false },
  { name: "Saint Lucia", code: "LC", requiresPostalCode: false },
  { name: "Saint Pierre and Miquelon", code: "PM", requiresPostalCode: true },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    requiresPostalCode: false,
  },
  { name: "Samoa", code: "WS", requiresPostalCode: false },
  { name: "San Marino", code: "SM", requiresPostalCode: true },
  { name: "Sao Tome and Principe", code: "ST", requiresPostalCode: false },
  { name: "Saudi Arabia", code: "SA", requiresPostalCode: false },
  { name: "Senegal", code: "SN", requiresPostalCode: false },
  { name: "Serbia", code: "RS", requiresPostalCode: true },
  { name: "Serbia and Montenegro", code: "CS", requiresPostalCode: true },
  { name: "Seychelles", code: "SC", requiresPostalCode: false },
  { name: "Sierra Leone", code: "SL", requiresPostalCode: false },
  { name: "Singapore", code: "SG", requiresPostalCode: true },
  { name: "Slovakia", code: "SK", requiresPostalCode: true },
  { name: "Slovenia", code: "SI", requiresPostalCode: true },
  { name: "Solomon Islands", code: "SB", requiresPostalCode: false },
  { name: "Somalia", code: "SO", requiresPostalCode: false },
  { name: "South Africa", code: "ZA", requiresPostalCode: true },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    requiresPostalCode: true,
  },
  { name: "Spain", code: "ES", requiresPostalCode: true },
  { name: "Sri Lanka", code: "LK", requiresPostalCode: true },
  { name: "Sudan", code: "SD", requiresPostalCode: false },
  { name: "Suriname", code: "SR", requiresPostalCode: false },
  { name: "Svalbard and Jan Mayen", code: "SJ", requiresPostalCode: true },
  { name: "Swaziland", code: "SZ", requiresPostalCode: true },
  { name: "Sweden", code: "SE", requiresPostalCode: true },
  { name: "Switzerland", code: "CH", requiresPostalCode: true },
  { name: "Syrian Arab Republic", code: "SY", requiresPostalCode: false },
  { name: "Taiwan, Province of China", code: "TW", requiresPostalCode: true },
  { name: "Tajikistan", code: "TJ", requiresPostalCode: false },
  {
    name: "Tanzania, United Republic of",
    code: "TZ",
    requiresPostalCode: true,
  },
  { name: "Thailand", code: "TH", requiresPostalCode: true },
  { name: "Timor-Leste", code: "TL", requiresPostalCode: false },
  { name: "Togo", code: "TG", requiresPostalCode: false },
  { name: "Tokelau", code: "TK", requiresPostalCode: false },
  { name: "Tonga", code: "TO", requiresPostalCode: false },
  { name: "Trinidad and Tobago", code: "TT", requiresPostalCode: false },
  { name: "Tunisia", code: "TN", requiresPostalCode: true },
  { name: "Turkey", code: "TR", requiresPostalCode: true },
  { name: "Turkmenistan", code: "TM", requiresPostalCode: true },
  { name: "Turks and Caicos Islands", code: "TC", requiresPostalCode: true },
  { name: "Tuvalu", code: "TV", requiresPostalCode: false },
  { name: "Uganda", code: "UG", requiresPostalCode: false },
  { name: "Ukraine", code: "UA", requiresPostalCode: true },
  { name: "United Arab Emirates", code: "AE", requiresPostalCode: false },
  { name: "United Kingdom", code: "UK", requiresPostalCode: true },
  { name: "United States", code: "US", requiresPostalCode: false },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    requiresPostalCode: true,
  },
  { name: "Uruguay", code: "UY", requiresPostalCode: true },
  { name: "Uzbekistan", code: "UZ", requiresPostalCode: true },
  { name: "Vanuatu", code: "VU", requiresPostalCode: false },
  { name: "Venezuela", code: "VE", requiresPostalCode: true },
  { name: "Vietnam", code: "VN", requiresPostalCode: true },
  { name: "Virgin Islands, British", code: "VG", requiresPostalCode: false },
  { name: "Virgin Islands, U.S.", code: "VI", requiresPostalCode: true },
  { name: "Wallis and Futuna", code: "WF", requiresPostalCode: true },
  { name: "Western Sahara", code: "EH", requiresPostalCode: false },
  { name: "Yemen", code: "YE", requiresPostalCode: false },
  { name: "Zambia", code: "ZM", requiresPostalCode: false },
  { name: "Zimbabwe", code: "ZW", requiresPostalCode: false },
]

export const getCountries = () => countries

export const getCountriesWithUkSwitch = () => {
  const list = cloneDeep(countries)
  find(list, { code: "UK" }).code = "GB"
  return list
}

export const getCountriesWithEU = () => {
  const list = cloneDeep(countries)
  list.push({ code: "EU", name: "European Union" })
  return list
}

export const getISOCountryCode = countryCode =>
  countryCode
    .toString()
    .substring(0, 2)
    .toUpperCase()

export const getAreaNameByCode = code => {
  const area = find(areas, { code })
  return area ? area.name : null
}

export const getCountryNameByCode = code => {
  const country = find(getCountriesWithEU(), { code })
  return country ? country.name : null
}

export const getAreaFlagByCode = code => {
  let flag = null
  try {
    flag = require(`../images/flags/areas/${code}.svg`).default
  } catch (e) {
    console.log(`No flag for ${getAreaNameByCode(code)}`) // eslint-disable-line
  }
  return flag
}

export const getCountryFlagByCode = code => {
  let flag = null
  try {
    flag = require(`../images/flags/${code}.svg`).default
  } catch (e) {
    console.log(`No flag for ${getCountryNameByCode(code)}`) // eslint-disable-line
  }
  return flag
}

export const checkIfCountryRequiresPostalCode = code => {
  const country = find(countries, { code })
  return country ? country.requiresPostalCode : false
}

export const isNorthAmericaCountry = countryCode =>
  countryCode === "US" || countryCode === "CA" || countryCode === "CAFR"

export default {
  getCountries,
  getCountriesWithUkSwitch,
  getCountriesWithEU,
  getISOCountryCode,
  getCountryNameByCode,
  getCountryFlagByCode,
  checkIfCountryRequiresPostalCode,
  isNorthAmericaCountry,
}
