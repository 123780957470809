import React from "react"
import PropTypes from "prop-types"

import styles from "../Loader.module.css"

const LocalLoader = ({ message, floating }) => {
  const classes = [styles.root, floating ? styles.floating : ""].join(" ")
  return (
    <div className={classes}>
      <div className={[styles.loader, styles.local].join(" ")} />
      {message && (
        <div
          className={[styles.message, "p-t-10", "fs-12", "c-motive--dark"].join(
            " "
          )}
        >
          {message}
        </div>
      )}
    </div>
  )
}

LocalLoader.propTypes = {
  message: PropTypes.string,
  floating: PropTypes.bool,
}

LocalLoader.defaultProps = {
  message: null,
  floating: true,
}

export default LocalLoader
