import {
  USER_LOGOUT_SUCCESS,
  PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_REQUEST,
  PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_SUCCESS,
  PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_FAILURE,
} from "data/constants"

const defaultState = {
  data: null,
  loading: false,
  error: null,
}

function creditCardConfigDetailsStore(state = defaultState, action) {
  switch (action.type) {
    case PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...action.data,
        },
        loading: false,
      }
    case PROFILE_GET_CREDIT_CARD_CONFIG_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error.error,
        loading: false,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default creditCardConfigDetailsStore
