import { sortBy } from "lodash"
import dayjs from "shared/services/date"
import {
  INVOICES_GET_REQUEST,
  INVOICES_GET_SUCCESS,
  INVOICES_GET_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "../constants"

const defaultState = {
  data: [],
  total_pages: 0,
  total: 0,
  loading: false,
  error: null,
}

function invoicesStore(state = defaultState, action) {
  switch (action.type) {
    case INVOICES_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case INVOICES_GET_SUCCESS:
      return {
        ...state,
        data: sortBy(action.data.data, o => dayjs(o.invoice_date)).reverse(),
        total_pages: action.data.total_pages,
        total: action.data.total,
        loading: false,
        error: null,
      }
    case INVOICES_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        total_pages: 0,
        total: 0,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default invoicesStore
