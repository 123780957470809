import {
  LOADING_STATES,
  PROFILE_GET_TOOLS_REQUEST,
  PROFILE_GET_TOOLS_SUCCESS,
  PROFILE_GET_TOOLS_FAILURE,
  PROFILE_GET_TOOLS_ACTIVE_SUCCESS,
  PROFILE_GET_TOOLS_ACTIVE_FAILURE,
  PROFILE_UPDATE_TOOLS_ACTIVE_SUCCESS,
  EBAY_ONBOARDING_SUBMIT_3PTOOL_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

const defaultState = {
  state: LOADING_STATES.INITIAL,
  list: null,
  listOptions: null,
  current: null,
  error: null,
}

function profileThirdPartyToolsStore(state = defaultState, action) {
  switch (action.type) {
    case PROFILE_GET_TOOLS_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      }
    case PROFILE_GET_TOOLS_SUCCESS:
      return {
        ...state,
        list: action.data.tools,
        listOptions: action.data.toolOptions,
      }
    case PROFILE_GET_TOOLS_ACTIVE_SUCCESS:
    case PROFILE_UPDATE_TOOLS_ACTIVE_SUCCESS:
      return {
        ...state,
        current: action.req,
        state: LOADING_STATES.LOADED,
      }
    case EBAY_ONBOARDING_SUBMIT_3PTOOL_SUCCESS:
      return {
        ...state,
        current: action.toolData,
      }
    case PROFILE_GET_TOOLS_FAILURE:
    case PROFILE_GET_TOOLS_ACTIVE_FAILURE:
      return {
        ...state,
        error: action.error.error,
        state: LOADING_STATES.FAILED,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default profileThirdPartyToolsStore
