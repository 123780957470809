import { map, groupBy, findIndex } from "lodash"
import {
  PRICE_RULES_GET_REQUEST,
  PRICE_RULES_GET_SUCCESS,
  PRICE_RULES_GET_FAILURE,
  PRICE_RULE_EDIT_SUCCESS,
  PRICE_RULES_CANCEL,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

import { arePricesEqual, areAllEnabled } from "../../utils"

const defaultState = {
  data: [],
  state: "INITIAL",
  error: null,
}

const getSiteRules = (site, rules) => ({
  ...site,
  rules,
  pricesEqual: arePricesEqual(rules),
  areAllEnabled: areAllEnabled(rules),
})

const updateRule = (newRule, existingRules) => {
  const rules = [...existingRules]
  const idx = findIndex(rules, { site: newRule.sites[0].site })
  rules[idx] = getSiteRules(newRule.sites[0], newRule.rules)
  return rules
}

const mapRulesToState = data => {
  const rules = groupBy(data.rules, "site")
  return map(data.sites, site => getSiteRules(site, rules[site.site]))
}

function priceAdjustmentStore(state = defaultState, action) {
  switch (action.type) {
    case PRICE_RULES_GET_REQUEST:
      return {
        ...state,
        data: [],
        error: null,
        state: "LOADING",
      }
    case PRICE_RULES_GET_SUCCESS:
      return {
        ...state,
        data: mapRulesToState(action.data),
        state: "LOADED",
      }
    case PRICE_RULES_GET_FAILURE:
      return {
        ...state,
        data: [],
        error: action.error,
        state: "FAILED",
      }
    case PRICE_RULE_EDIT_SUCCESS:
      return {
        ...state,
        data: updateRule(action.data, state.data),
      }
    case PRICE_RULES_CANCEL:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default priceAdjustmentStore
