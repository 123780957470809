import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isNil, take, sortBy } from "lodash"
import { withTranslate } from "context/translateContext"

import CONFIG from "core/config"
import {
  ZENDESK_ARTICLES_GET_LIST,
  ZENDESK_ARTICLES_CANCEL,
} from "data/constants"
import {
  getLocale,
  isLimitedSection,
  isEmptyLanguageSection,
  getSearchParams,
} from "shared/services/zendesk/articles"

import Loader from "shared/components/Loader/Local"
import Empty from "shared/components/Empty"

import Article from "./components/Article"

import styles from "./ZendeskArticles.module.css"

import empty from "./posts.svg"

export class ZendeskArticles extends PureComponent {
  static propTypes = {
    query: PropTypes.string,
    topic: PropTypes.number,
    limit: PropTypes.number,
    lang: PropTypes.string.isRequired,
    viewMore: PropTypes.bool,
    get: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    recommended: PropTypes.bool,
    articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  static defaultProps = {
    query: null,
    topic: null,
    limit: 25,
    viewMore: false,
    recommended: false,
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    const { query, topic, lang, cancel } = this.props
    if (
      query !== prevProps.query ||
      topic !== prevProps.topic ||
      lang !== prevProps.lang
    ) {
      cancel()
      this.fetch()
    }
  }

  componentWillUnmount() {
    const { cancel } = this.props
    cancel()
  }

  fetch() {
    const { get, query, topic, lang: locale } = this.props
    let params = {
      query,
      locale,
    }
    if (topic) {
      params = {
        ...params,
        ...getSearchParams(topic),
      }
    }
    get(params)
  }

  filterList() {
    const { articles, limit, recommended } = this.props
    let list = take(articles, limit)
    if (recommended) {
      list = sortBy(list, article => !article.promoted)
    }

    return list
  }

  renderNotice() {
    const { topic, lang } = this.props
    const { t: translate } = this.props

    if (isNil(topic)) return null

    if (isLimitedSection(topic, lang)) {
      return (
        <p className="m-t-10 fs-11 c-motive--dark">
          {translate(
            "There is currently a limited number of articles available in your language."
          )}
          <br />
          {translate(
            "If you wish to view the articles in English, please visit English {helpcenter}.",
            {
              helpcenter: (
                <a
                  href={`${CONFIG.zendeskHost}/hc/en-us/sections/${topic}`}
                  target="_blank"
                >
                  {translate("Help Center")}
                </a>
              ),
            }
          )}
        </p>
      )
    }
    if (isEmptyLanguageSection(topic, lang)) {
      return (
        <p className="m-t-10 fs-11 c-motive--dark">
          {translate(
            "Please note that articles for this topic are currently available only in English & German."
          )}
        </p>
      )
    }
    return null
  }

  renderEmpty() {
    const { t: translate } = this.props
    return (
      <div className={[styles.empty, "relative", "textCenter"].join(" ")}>
        <Empty
          image={empty}
          title={translate("No articles found!")}
          message={translate("We found no articles matching your selection.")}
        />
      </div>
    )
  }

  renderList() {
    const { viewMore, lang, topic } = this.props
    const { t: translate } = this.props

    const list = this.filterList()

    if (list.length === 0) return this.renderEmpty()

    return (
      <Fragment>
        <ul className={[styles.list, "l-unstyled"].join(" ")}>
          {list.map(article => (
            <li key={article.id}>
              <Article article={article} />
            </li>
          ))}
        </ul>
        {this.renderNotice()}
        {viewMore && (
          <a
            href={`${CONFIG.zendeskHost}/hc/${lang}/${
              [200604456].indexOf(topic) > -1 ? "sections" : "categories"
            }/${topic}`}
            target="_blank"
          >
            {translate("View all articles")}
          </a>
        )}
      </Fragment>
    )
  }

  render() {
    const { loading } = this.props

    return (
      <div className={styles.root}>
        {loading ? <Loader /> : this.renderList()}
      </div>
    )
  }
}

const mapStateToProps = (state, { topic }) => ({
  lang: getLocale(state.i18nState.lang, topic),
  loading: state.zendeskArticles.loading,
  articles: state.zendeskArticles.list,
})

const mapDispatchToProps = dispatch => ({
  get: params =>
    dispatch({
      type: ZENDESK_ARTICLES_GET_LIST,
      params,
    }),
  cancel: () =>
    dispatch({
      type: ZENDESK_ARTICLES_CANCEL,
    }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslate(ZendeskArticles))
