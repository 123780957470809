import { all, fork, put, race, take } from "redux-saga/effects"
import { gt, get, some } from "lodash"

import {
  GENERAL_INITIAL_DATA_READY,
  USER_GET_SUCCESS,
  USER_GET_SETTINGS_SUCCESS,
  USER_SET_PERMISSIONS_SUCCESS,
  ACCOUNTS_GET_SUCCESS,
  USER_GET_PLANS_RESOLVED,
  GENERAL_SET_ACTIVE_ACCOUNT,
  ENTITLEMENTS_CUSTOM_GET_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS,
  GENERAL_INITIAL_DATA_FAILED,
  USER_GET_FAILURE,
  USER_GET_SETTINGS_FAILURE,
  ENTITLEMENTS_CUSTOM_GET_FAILURE,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE,
  ACCOUNTS_GET_FAILURE,
} from "data/constants"

function* waitForCriticalDataLoadingSuccess() {
  while (true) {
    yield all([
      take(USER_GET_SUCCESS),
      take(USER_GET_SETTINGS_SUCCESS),
      take(ENTITLEMENTS_CUSTOM_GET_SUCCESS),
      take(USER_SET_PERMISSIONS_SUCCESS),
      take(ACCOUNTS_GET_SUCCESS),
      take(USER_GET_PLANS_RESOLVED),
      take(GENERAL_SET_ACTIVE_ACCOUNT),
      take(USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS),
    ])
    yield put({
      type: GENERAL_INITIAL_DATA_READY,
      value: true,
    })
  }
}

function* huntForServerError() {
  while (true) {
    const failedTasks = yield race([
      take(USER_GET_FAILURE),
      take(USER_GET_SETTINGS_FAILURE),
      take(ENTITLEMENTS_CUSTOM_GET_FAILURE),
      take(ACCOUNTS_GET_FAILURE),
      take(USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE),
    ])

    const shouldCrashApp = some(failedTasks, item => {
      if (typeof item === "undefined") return false
      const status =
        get(item, "httpStatus") ||
        get(item, "error.response.status") ||
        get(item, "error.request.status")

      return gt(parseInt(status, 10), 499)
    })

    if (shouldCrashApp) {
      yield put({ type: GENERAL_INITIAL_DATA_FAILED })
    }
  }
}

export default function* main() {
  yield all([fork(waitForCriticalDataLoadingSuccess), fork(huntForServerError)])
}
