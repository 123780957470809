import {
  BULK_ITEM_REQUEST_ISSUES_GET_REQUEST,
  BULK_ITEM_REQUEST_ISSUES_GET_SUCCESS,
  BULK_ITEM_REQUEST_ISSUES_GET_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

import { mapBulkItemResultIssues } from "../../../shared/services/products"

const defaultState = {
  loading: false,
  data: null,
  error: null,
}

function productBulkStore(state = defaultState, action) {
  switch (action.type) {
    case BULK_ITEM_REQUEST_ISSUES_GET_REQUEST:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      }
    case BULK_ITEM_REQUEST_ISSUES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: mapBulkItemResultIssues(action.data),
      }
    case BULK_ITEM_REQUEST_ISSUES_GET_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.data,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default productBulkStore
