import { head, find, get, has } from "lodash"

import {
  USER_LOGOUT_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_START_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE,
  USER_MULTICHANNEL_ONBOARDING_START_FAILURE,
  USER_MULTICHANNEL_ONBOARDING_SET_STEP_NAME,
  USER_MULTICHANNEL_ONBOARDING_ALERT_TOKEN_ALREADY_REGISTERED,
  USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_REQUEST,
  USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_FAILURE,
  USER_MULTICHANNEL_ONBOARDING_COMMON_ERROR_NOTIFICATION,
  USER_MULTICHANNEL_ONBOARDING_COMPLETED,
  USER_MULTICHANNEL_ONBOARDING_CANCELED,
  USER_MULTICHANNEL_ONBOARDING_RECALCULATE_ONGOING_ONBOARDINGS,
  EBAY_ONBOARDING_BACK_TO_CHECKOUT,
} from "data/constants"

const defaultState = {
  ongoingOnboardings: [],
  current: {
    currentStepName: "",
  },
  error: {},
  showConnectTokenAlert: "",
  tokenAlertPlatform: "",
  isLoading: false,
}

function multichannelOnboardingStore(state = defaultState, action) {
  switch (action.type) {
    case USER_MULTICHANNEL_ONBOARDING_START_SUCCESS:
      return {
        ...state,
        ongoingOnboardings: [...state.ongoingOnboardings, action.req],
        current: {
          ...action.req,
          currentStepName: getCurrentStepName(state.current, action.req.step),
          showConnectTokenAlert: "",
          tokenAlertPlatform: "",
        },
      }
    case USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS:
      return {
        ...state,
        ongoingOnboardings: action.data,
        current: {
          ...getCurrentOnboarding(state.current, action.data),
          currentStepName: getCurrentStepName(state.current, action.data),
        },
        showConnectTokenAlert: "",
        tokenAlertPlatform: "",
      }
    case USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        current: {
          ...defaultState.current,
        },
      }
    case USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case USER_MULTICHANNEL_ONBOARDING_SET_STEP_NAME:
      return {
        ...state,
        current: { ...state.current, currentStepName: action.stepName },
      }
    case USER_MULTICHANNEL_ONBOARDING_RECALCULATE_ONGOING_ONBOARDINGS:
      return {
        ...state,
        current: calculateCurrentOnboarding(
          state.ongoingOnboardings,
          action.platform
        ),
      }
    case USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE:
    case USER_MULTICHANNEL_ONBOARDING_START_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    case USER_MULTICHANNEL_ONBOARDING_ALERT_TOKEN_ALREADY_REGISTERED:
      return {
        ...state,
        showConnectTokenAlert: "duplicated",
        tokenAlertPlatform: action.payload.platform,
      }
    case USER_MULTICHANNEL_ONBOARDING_COMMON_ERROR_NOTIFICATION:
      return {
        ...state,
        showConnectTokenAlert: "other",
        tokenAlertPlatform: action.payload.platform,
      }
    case EBAY_ONBOARDING_BACK_TO_CHECKOUT:
      return {
        ...state,
        current: {
          ...state.current,
          isSubscriptionFailed: true,
          currentStepName: "Checkout",
        },
      }
    case USER_MULTICHANNEL_ONBOARDING_COMPLETED:
    case USER_MULTICHANNEL_ONBOARDING_CANCELED:
      return {
        ...state,
        current: {
          ...defaultState.current,
        },
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

const getCurrentOnboarding = (currentOnboarding, data = []) => {
  if (!data.length) return currentOnboarding

  const currentOnboardingId = currentOnboarding.id

  if (currentOnboardingId) {
    return {
      ...currentOnboarding,
      ...(find(data, ["id", currentOnboardingId]) || {}),
    }
  }

  return {
    ...currentOnboarding,
    ...(head(data) || {}),
  }
}

const getCurrentStepName = (currentOnboarding, data) => {
  if (Array.isArray(data)) {
    return data.length
      ? get(data, ["0", "step", "type"])
      : get(currentOnboarding.step, "type", "")
  }
  return has(data, "type") ? data.type : get(currentOnboarding.step, "type", "")
}

const calculateCurrentOnboarding = (ongoingOnboardings = [], platform) => {
  const reducedList = ongoingOnboardings.filter(
    onboarding => onboarding.channel === platform
  )
  const current = reducedList[0]

  return {
    ...current,
    currentStepName: getCurrentStepName(current, reducedList),
  }
}

export default multichannelOnboardingStore
