import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"

import styles from "./DropdownTrigger.module.css"

export class DropdownTrigger extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    top: PropTypes.bool,
    ref: PropTypes.func,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
  }
  static defaultProps = {
    ref: undefined,
    active: false,
    top: false,
    className: styles.root,
    onClick: noop,
  }

  render() {
    const { children, ref, className, onClick } = this.props
    const props = {
      ref,
      onClick,
      className: [styles.root, className || ""].join(" "),
    }

    return (
      <div type="button" {...props}>
        {children}
      </div>
    )
  }
}

export default DropdownTrigger
