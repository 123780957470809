import {
  SHIPPING_PROFILE_GET_REQUEST,
  SHIPPING_PROFILE_GET_SUCCESS,
  SHIPPING_PROFILE_GET_FAILURE,
  SHIPPING_PROFILE_EDIT_SUCCESS,
  SHIPPING_DEFAULT_PROFILE_DELETE_SUCCESS,
  SHIPPING_PROFILE_DELETE_SUCCESS,
  PRODUCTS_GET_SHIPPING_SUCCESS,
  SHIPPING_PROFILE_RATES_UPDATE,
  SHIPPING_PROFILE_CANCEL,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  data: null,
  loading: false,
  error: null,
}

function shippingProfileStore(state = defaultState, action) {
  switch (action.type) {
    case SHIPPING_PROFILE_GET_REQUEST:
      return {
        ...state,
        data: {},
        error: null,
        loading: true,
      }
    case SHIPPING_PROFILE_GET_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      }
    case SHIPPING_PROFILE_GET_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        loading: false,
      }
    case PRODUCTS_GET_SHIPPING_SUCCESS:
      return state.data
        ? {
          ...state,
          data: {
            ...state.data,
            items: action.data[state.data.profile.profile_id] || [],
          },
        }
        : state
    case SHIPPING_PROFILE_EDIT_SUCCESS:
    case SHIPPING_PROFILE_RATES_UPDATE:
      return {
        ...state,
        data: action.data,
      }
    case SHIPPING_DEFAULT_PROFILE_DELETE_SUCCESS:
    case SHIPPING_PROFILE_DELETE_SUCCESS:
    case SHIPPING_PROFILE_CANCEL:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default shippingProfileStore
