import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import dayjs from "shared/services/date"

import { useTranslate } from "context/translateContext"
import CONFIG from "core/config"

import Container from "../Container"
import LanguagePicker from "../LanguagePicker"
import HelpDropdown from "../HelpDropdown"

import styles from "./Footer.module.css"

const Footer = () => {
  const { t: translate } = useTranslate()
  return (
    <footer className={styles.root}>
      <Container>
        <div className="row">
          <div className="col-md-4">
            <a className={styles.link} href={CONFIG.mainhost}>
              {translate("Home")}
            </a>
            <a className={styles.link} href={`${CONFIG.mainhost}/careers`}>
              {translate("Careers")}
            </a>
            <Link className={styles.link} to="/terms-and-conditions">
              {translate("Terms & Conditions")}
            </Link>
          </div>
          <div className="col-md-8">
            <div className={styles.right}>
              <div className={styles.language}>
                <LanguagePicker />
              </div>
              <div className={styles.help}>
                <HelpDropdown top right />
              </div>
              <div className={styles.copyright}>
                {translate(
                  "Copyright © 2007 - {curr} Webinterpret",
                  {
                    curr: dayjs().year(),
                  },
                  "Do not translate {curr}"
                )}
                {CONFIG.legal && (
                  <Fragment>
                    <span className="m-l-10 m-r-10 cr-pointer hidden-xs">
                      |
                    </span>
                    <div className="visible-xs" />
                    {CONFIG.legal}
                  </Fragment>
                )}
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
