import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"

import ModalHeader from "../../components/Modal/ModalHeader"
import ModalContent from "../../components/Modal/ModalContent"
import Auth from "../../components/Auth"

const AuthModal = ({ onRequestClose }) => (
  <Modal
    isOpen
    onRequestClose={() => onRequestClose()}
    contentLabel="Auth"
    className="popup"
    overlayClassName="popupOverlay"
    ariaHideApp={false}
  >
    <ModalHeader onRequestClose={() => onRequestClose()} />
    <ModalContent>
      <Auth />
    </ModalContent>
  </Modal>
)

AuthModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
}

export default AuthModal
