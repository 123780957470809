import { all, select, call, put, take, fork } from "redux-saga/effects"
import { each } from "lodash"
import {
  USER_GET_SUCCESS,
  USER_GET_SETTINGS,
  USER_GET_SETTINGS_REQUEST,
  USER_GET_SETTINGS_SUCCESS,
  USER_GET_SETTINGS_FAILURE,
} from "../../constants"
import { get as getSettings } from "../../fetch/user/settings"

function* get() {
  while (true) {
    yield take([USER_GET_SUCCESS, USER_GET_SETTINGS])
    const { user } = yield select()
    yield put({ type: USER_GET_SETTINGS_REQUEST })
    try {
      const { data } = yield call(() => getSettings(user.id))
      if (data) {
        const settings = {}
        each(data.items, obj => {
          settings[obj.name] = obj.value
        })
        yield put({ type: USER_GET_SETTINGS_SUCCESS, data: settings })
      }
    } catch (error) {
      yield put({ type: USER_GET_SETTINGS_FAILURE, error })
    }
  }
}

export default function* main() {
  const tasks = yield all([fork(get)])

  yield all(tasks)
}
