import React from "react"
import PropTypes from "prop-types"
import TextareaAutosize from "react-autosize-textarea"
import { noop } from "lodash"

import { useTranslate } from "context/translateContext"
import defaultIcon from "shared/images/icons/info.svg"
import defaultIconRed from "shared/images/icons/info_red.svg"

import TagInput from "./TagInput"
import DatePicker from "./DatePicker"
import Select from "./Select"
import Dropzone from "./Dropzone"

import { normalizeBooleanValue } from "./normalizers"

import styles from "./Forms.module.css"

const propTypes = {
  reference: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.node,
  addon: PropTypes.node,
  translateLabel: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  error: PropTypes.bool,
  isrequired: PropTypes.bool,
  tooltiptext: PropTypes.string,
  tooltipicon: PropTypes.string,
  tooltipiconred: PropTypes.string,
}
const defaultProps = {
  reference: "field",
  className: "",
  label: null,
  addon: null,
  translateLabel: false,
  error: null,
  isrequired: null,
  tooltiptext: "",
  tooltipicon: "",
  tooltipiconred: "",
}

export const renderField = ({
  reference,
  isrequired,
  label,
  addon,
  meta,
  input,
  error,
  translateLabel,
  className,
  tooltiptext,
  tooltipicon,
  tooltipiconred,
  ...props
}) => {
  const { t: translate } = useTranslate()
  let icon = tooltipicon || defaultIcon
  if (meta.submitFailed && (error || meta.error)) {
    icon = tooltipiconred || defaultIconRed
  }
  return (
    <div
      className={[
        "inputContainer",
        className,
        meta.submitFailed && (error || meta.error) ? "hasError" : "",
      ].join(" ")}
    >
      {label && (
        <label htmlFor={reference}>
          {translateLabel ? translate(label) : label}
          {tooltiptext && (
            <span className={`${styles.helper} fs-10`}>
              <img
                alt="help icon"
                data-tip={translate(tooltiptext)}
                data-multiline
                src={icon}
              />
            </span>
          )}
        </label>
      )}
      <div className="inputGroup">
        {addon && <span className="inputAddon">{addon}</span>}
        <input id={reference} {...props} {...input} />
      </div>
      {meta.submitFailed && meta.error && (
        <span className="inputError">{translate(meta.error)}</span>
      )}
    </div>
  )
}
renderField.propTypes = propTypes
renderField.defaultProps = defaultProps

export const renderRadio = ({
  reference,
  label,
  meta,
  input,
  translateLabel,
  ...props
}) => {
  const { t: translate } = useTranslate()
  return (
    <div className="radio">
      <input id={reference} {...props} {...input} />
      <label htmlFor={reference}>
        {translateLabel ? translate(label) : label}
      </label>
    </div>
  )
}
renderRadio.propTypes = propTypes
renderRadio.defaultProps = defaultProps

export const renderCheckbox = ({
  reference,
  label,
  meta,
  input,
  translateLabel,
  ...props
}) => {
  const { t: translate } = useTranslate()
  return (
    <div className="checkbox">
      <input
        id={reference}
        type="checkbox"
        {...props}
        {...input}
        checked={normalizeBooleanValue(input.value)}
      />
      <label htmlFor={reference}>
        {translateLabel ? translate(label) : label}
      </label>
    </div>
  )
}
renderCheckbox.propTypes = propTypes
renderCheckbox.defaultProps = defaultProps

export const renderToggle = ({
  reference,
  label,
  meta,
  input,
  translateLabel,
  ...props
}) => {
  const { t: translate } = useTranslate()
  return (
    <div className={styles.toggle}>
      {label && (
        <div className="left">{translateLabel ? translate(label) : label}</div>
      )}
      <div className="right">
        <div className="switch">
          <input id={reference} {...props} {...input} />
          <label htmlFor={reference} className="slider" />
        </div>
      </div>
      <div className="clearfix" />
    </div>
  )
}
renderToggle.propTypes = propTypes
renderToggle.defaultProps = defaultProps

export const renderTextArea = ({
  reference,
  label,
  meta,
  input,
  error,
  translateLabel,
  onResize,
  ...props
}) => {
  const { t: translate } = useTranslate()
  return (
    <div
      className={`inputContainer ${
        meta.submitFailed && (error || meta.error) ? "hasError" : ""
      }`}
    >
      {label && (
        <label htmlFor={reference}>
          {translateLabel ? translate(label) : label}
        </label>
      )}
      <TextareaAutosize
        id={reference}
        onResize={onResize}
        {...props}
        {...input}
      />
      {meta.submitFailed && meta.error && (
        <span className="inputError">{translate(meta.error)}</span>
      )}
    </div>
  )
}
renderTextArea.propTypes = {
  ...propTypes,
  onResize: PropTypes.func,
}
renderTextArea.defaultProps = {
  ...defaultProps,
  onResize: noop,
}

export const renderTagInput = ({ input, meta, reference, ...props }) => (
  <TagInput
    validationError={meta.submitFailed ? meta.error : ""}
    reference={reference}
    values={input.value}
    onChange={newValue => input.onChange(newValue)}
    {...props}
  />
)

renderTagInput.propTypes = propTypes
renderTagInput.defaultProps = defaultProps

export const renderDropzone = Dropzone
export const renderDatePicker = DatePicker
export const renderSelect = Select

export default {
  renderField,
  renderRadio,
  renderCheckbox,
  renderTextArea,
  renderDatePicker,
  renderSelect,
  renderDropzone,
  renderTagInput,
}
