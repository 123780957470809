import logger from "shared/services/logger"
import {
  TERMS_OF_SERVICE_GET,
  TERMS_OF_SERVICE_POST,
  TERMS_OF_SERVICE_PUT,
} from "../../constants"
import {
  get as getTOS,
  post as postTOS,
  put as putTOS,
} from "../../fetch/user/termsOfService"

export function get(service) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = getTOS(user.id, service)

    return dispatch({
      type: TERMS_OF_SERVICE_GET,
      promise,
    })
  }
}

export function post(service, data) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = postTOS(user.id, service, data).catch(err => {
      logger(
        "TOS creating failed",
        user.id,
        "TOS:acceptance:POST failed",
        "error",
        {
          errorResponse: err.response?.data,
          userData: { ...user },
          envData: {
            userAgent: navigator?.userAgent,
          },
        }
      )

      dispatch(put(service, data))
    })

    return dispatch({
      type: TERMS_OF_SERVICE_POST,
      promise,
    })
  }
}

export function put(service, data) {
  return (dispatch, getState) => {
    const { user } = getState()
    const promise = putTOS(user.id, service, data)

    return dispatch({
      type: TERMS_OF_SERVICE_PUT,
      promise,
    })
  }
}

export default {
  get,
  put,
  post,
}
