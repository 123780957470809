import {
  CONVERSION_CHART_GET_REQUEST,
  CONVERSION_CHART_GET_SUCCESS,
  CONVERSION_CHART_GET_FAILURE,
  CONVERSION_CHART_DELETE_SUCCESS,
  CONVERSION_CHART_EDIT_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  data: {},
  state: "INITIAL",
  error: null,
}

function conversionChartStore(state = defaultState, action) {
  switch (action.type) {
    case CONVERSION_CHART_GET_REQUEST:
      return {
        ...state,
        data: {},
        error: null,
        state: "LOADING",
      }
    case CONVERSION_CHART_GET_SUCCESS:
      return {
        ...state,
        data: action.data.chart,
        state: "LOADED",
      }
    case CONVERSION_CHART_GET_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        state: "FAILED",
      }
    case CONVERSION_CHART_DELETE_SUCCESS:
      return {
        ...state,
        data: {},
      }
    case CONVERSION_CHART_EDIT_SUCCESS:
      return {
        ...state,
        data: action.data.chart,
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default conversionChartStore
