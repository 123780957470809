import React from "react"
import PropTypes from "prop-types"
import styles from "./Badge.module.css"

function Badge({ children, type, className, ...props }) {
  return (
    <span
      className={[styles.root, styles[type], className].join(" ")}
      {...props}
    >
      {children}
    </span>
  )
}

Badge.propTypes = {
  /** Alert content. Text or HTML. */
  children: PropTypes.node.isRequired,
  /** Type of badge. Changes color. */
  type: PropTypes.string,
  /** CSS classes. */
  className: PropTypes.string,
}

Badge.defaultProps = {
  type: "default",
  className: "",
}

export default Badge
