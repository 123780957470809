import { all, select, put, fork, call, take } from "redux-saga/effects"
import { determineCurrentPlan, reducePlanList, addSitesListToPlans, isUserEarlyBird, mergePROPlans } from "shared/services/plans"
import { get } from "lodash"

import {
  PLANS_GET,
  PLANS_GET_REQUEST,
  PLANS_GET_SUCCESS,
  PLANS_GET_FAILURE,
  PLANS_CANCEL,
} from "../../constants"

import { getAll, getIds } from "../../fetch/plans/list"

function* getList() {
  while (true) {
    yield take([PLANS_GET])
    const { user } = yield select()
    yield put({ type: PLANS_GET_REQUEST })
    try {
      const { data } = yield call(() => getAll(user))
      const ids = yield call(() => getIds(user))
      if (data && ids) {
        const rowData = data.data || data
        const plansListWithCurrentMarked = determineCurrentPlan(
          rowData.packages,
          ids
        )
        const isEarlyBird = isUserEarlyBird(user)
        const reducedPlansList = reducePlanList(plansListWithCurrentMarked)
        const plansWithSites = addSitesListToPlans(reducedPlansList, rowData.currency)
        const mergedPlans = mergePROPlans(plansWithSites, isEarlyBird)

        yield put({
          type: PLANS_GET_SUCCESS,
          data: {
            currentPlanID: get(rowData, "packages.CURRENT.id", null),
            ...rowData,
            packages: {
              ...mergedPlans,
            },
            ids,
          },
        })
      }
    } catch (error) {
      yield put({ type: PLANS_GET_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getList)])

    yield all(tasks)

    yield take(PLANS_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
