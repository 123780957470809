import React from "react"
import openZendeskPlugin from "shared/services/zendesk/plugin"

import { useTranslate } from "context/translateContext"
import Container from "../Container"

import styles from "./Header.module.css"
import logo from "../../images/wi-logo.svg"

const handleClick = event => {
  event.preventDefault()
  openZendeskPlugin()
  return false
}

const SignUpHeader = () => {
  const { t: translate } = useTranslate()
  return (
    <header className={styles.root}>
      <Container>
        <div className="row">
          <div className="col-md-3">
            <div className={styles.logo}>
              <img src={logo} alt="WebInterpret logo" />
            </div>
          </div>
          <div className="col-md-offset-6 col-md-3 hidden-xs textRight m-t-20 p-t-5">
            {translate("Need help?")}{" "}
            <button
              className={styles.zendeskLink}
              onClick={handleClick}
              type="button"
            >
              {translate("Contact us")}
            </button>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default SignUpHeader
