import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  INVOICES_GET,
  INVOICES_GET_REQUEST,
  INVOICES_GET_SUCCESS,
  INVOICES_GET_FAILURE,
  INVOICES_CANCEL,
} from "../../constants"

import { get as getInvoices } from "../../fetch/invoices/list"

function* getList() {
  while (true) {
    const action = yield take(INVOICES_GET)
    const { user } = yield select()
    yield put({ type: INVOICES_GET_REQUEST })
    try {
      const { data } = yield call(() => getInvoices(user.id, action.params))
      if (data) yield put({ type: INVOICES_GET_SUCCESS, data })
    } catch (error) {
      if (error.response.status === 404) {
        yield put({ type: INVOICES_GET_SUCCESS, data: { data: [] } })
      } else {
        yield put({ type: INVOICES_GET_FAILURE, error })
      }
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getList)])

    yield all(tasks)

    yield take([INVOICES_CANCEL])
    yield all(tasks.map(task => task.cancel()))
  }
}
