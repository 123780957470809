import {
  all,
  select,
  put,
  fork,
  call,
  take,
  takeLatest,
} from "redux-saga/effects"

import {
  TRIAL_START_GET,
  DASHBOARD_CANCEL,
  PRODUCTS_GET_SETTINGS,
  PRODUCTS_GET_SETTINGS_REQUEST,
  PRODUCTS_GET_SETTINGS_SUCCESS,
  PRODUCTS_GET_SETTINGS_FAILURE,
  PRODUCTS_UPDATE_SETTINGS_SUCCESS,
  PRODUCTS_UPDATE_COUNTRY_USAGE_SUCCESS,
  DASHBOARD_GET_SALES_WEEKLY,
  DASHBOARD_GET_SALES_MONTHLY,
} from "../../constants"

import { get } from "../../fetch/products/settings"

function* updatedCountries() {
  const { general } = yield select()
  yield put({
    type: DASHBOARD_GET_SALES_WEEKLY,
    platform: general.activePlatform,
  })
  yield put({
    type: DASHBOARD_GET_SALES_MONTHLY,
    platform: general.activePlatform,
  })
}

function* getSettings() {
  while (true) {
    const action = yield take([
      PRODUCTS_GET_SETTINGS,
      TRIAL_START_GET,
      PRODUCTS_UPDATE_SETTINGS_SUCCESS,
    ])
    const { user } = yield select()
    yield put({ type: PRODUCTS_GET_SETTINGS_REQUEST })
    try {
      const { data } = yield call(() => get(user, action.platform))
      if (data) { yield put({ type: PRODUCTS_GET_SETTINGS_SUCCESS, data: data.data }) }
    } catch (error) {
      yield put({ type: PRODUCTS_GET_SETTINGS_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    yield takeLatest(PRODUCTS_UPDATE_COUNTRY_USAGE_SUCCESS, updatedCountries)
    const tasks = yield all([fork(getSettings)])

    yield all(tasks)

    yield take(DASHBOARD_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
