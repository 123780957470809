import React from "react"
import PropTypes from "prop-types"

import styles from "./Panel.module.css"

function Panel({ className, children, innerClassName, ...props }) {
  return (
    <div className={[styles.root, className].join(" ")}>
      <div className={innerClassName} {...props}>
        {children}
      </div>
    </div>
  )
}

Panel.propTypes = {
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.node,
}
Panel.defaultProps = {
  className: "",
  innerClassName: "",
  children: null,
}

export default Panel
