import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import * as Sentry from "@sentry/react"

import { withTranslate } from "context/translateContext"
import Container from "shared/components/Container"

import styles from "./ErrorBoundary.module.css"

import image from "./missionimpossible.svg"

class ErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(err) {
    this.setState({
      hasError: true,
    })
    const client = Sentry.getCurrentHub().getClient()

    if (!!client && client.getOptions().enabled) {
      Sentry.captureException(err)
      Sentry.showReportDialog()
    }
  }

  renderError() {
    const { t: translate } = this.props

    return (
      <Container>
        <div className={styles.root}>
          <div className="row">
            <div className="col-sm-6">
              <h1 className={styles.title}>{translate("Shoot!")}</h1>
              <h2>{translate("Well, this is unexpected...")}</h2>
              <p>
                {translate(
                  "An error as occured and we're working to fix the problem!"
                )}
              </p>
              <p>
                {translate(
                  "If you need immediate help from our customer service team, please visit our {helpcenter} for additional information.",
                  {
                    helpcenter: (
                      <a
                        href="https://webinterpret.zendesk.com/"
                        target="_blank"
                      >
                        {translate("Help Center")}
                      </a>
                    ),
                  }
                )}{" "}
                {translate("Thank you for your patience!")}
              </p>
            </div>
            <div className="col-sm-6">
              <img src={image} alt="Mission impossible" />
            </div>
          </div>
        </div>
      </Container>
    )
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    return hasError ? this.renderError() : children
  }
}

export default withTranslate(ErrorBoundary)
