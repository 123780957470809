import {
  EPL_ITEMS_LOAD,
  EPL_ITEMS_LOAD_SUCCESS,
  EPL_ITEMS_LOAD_FAILURE,
  EPL_ITEMS_STATUS_GET_SUCCESS,
  EPL_ITEMS_STATUS_GET_FAILURE,
  EPL_ITEMS_LIST_GET_REQUEST,
  EPL_ITEMS_LIST_GET_SUCCESS,
  EPL_ITEMS_LIST_GET_FAILURE,
  EPL_ITEMS_EXCLUDE_BY_FILE_POST_REQUEST,
  EPL_ITEMS_EXCLUDE_BY_FILE_POST_FAILURE,
  EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS,
  EPL_ITEMS_EXCLUDED_RESET_SELECTED_REQUEST,
  EPL_ITEMS_EXCLUDE_SELECTED_REQUEST,
  EPL_ITEMS_STATUS_GET_REQUEST,
  LOADING_STATES,
} from "data/constants"

const defaultState = {
  loadingState: LOADING_STATES.INITIAL,
  error: null,
}

const eplExcludingItemsStore = (state = defaultState, action) => {
  switch (action.type) {
    case EPL_ITEMS_LOAD:
    case EPL_ITEMS_EXCLUDED_RESET_SELECTED_REQUEST:
    case EPL_ITEMS_STATUS_GET_REQUEST:
    case EPL_ITEMS_EXCLUDE_SELECTED_REQUEST:
      return {
        ...state,
        queryParams: action.params ? action.params : state.queryParams,
        loadingState: LOADING_STATES.LOADING,
      }
    case EPL_ITEMS_LOAD_SUCCESS:
      return {
        ...state,
        loadingState: LOADING_STATES.LOADED,
      }
    case EPL_ITEMS_LOAD_FAILURE:
      return {
        ...state,
        loadingState: LOADING_STATES.FAILED,
      }
    case EPL_ITEMS_STATUS_GET_SUCCESS:
      return {
        ...state,
        status: action.req,
      }
    case EPL_ITEMS_LIST_GET_REQUEST:
      return {
        ...state,
        list: { ...state.list, state: LOADING_STATES.LOADING },
      }
    case EPL_ITEMS_LIST_GET_SUCCESS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: state?.list?.page || 1,
        },
        list: { ...action.req, state: LOADING_STATES.LOADED },
      }
    case EPL_ITEMS_STATUS_GET_FAILURE:
      return {
        ...state,
        error: action.error.error,
      }
    case EPL_ITEMS_LIST_GET_FAILURE:
      return {
        ...state,
        error: action.error.error,
        list: {
          state: LOADING_STATES.FAILED,
        },
      }
    case EPL_ITEMS_EXCLUDE_BY_FILE_POST_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          processing: true,
        },
      }
    case EPL_ITEMS_EXCLUDE_BY_FILE_POST_FAILURE:
      return {
        ...state,
        error: action.error,
        status: {
          ...state.status,
          processing: false,
        },
      }
    case EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS:
      return {
        loadingState: LOADING_STATES.INITIAL,
        error: null,
      }
    default:
      return state
  }
}

export default eplExcludingItemsStore
