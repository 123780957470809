import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import { useTranslate } from "context/translateContext"
import Container from "../../components/Container"
import Panel from "../../components/Panel"
import Button from "../../components/Button"

const ModuleDisabled = ({ view, className }) => {
  const { t: translate } = useTranslate()
  return (
    <Container className="clearfix">
      <Helmet
        title={translate("{module} disabled", {
          module: translate(view),
        })}
      />
      <Panel className={["textCenter", className].join(" ")}>
        <div className="m-t-50 m-b-20">
          <h1 className="w300 m-b-30">
            {translate("{module} disabled", {
              module: translate(view),
            })}
          </h1>
          <div className={["fs-24", "m-b-30", "c-gray"].join(" ")}>
            <span className="icon-lock" />
          </div>
          <p className="m-b-30">
            {translate("This feature is available on our paid plan.")}
            <br />
            {translate(
              "Upgrade your plan to get access to this and more features."
            )}
          </p>
          <Button
            className="m-b-10"
            variation="primary"
            size="wide"
            to="/user/plans"
            category="Upsell"
            action="upgrade"
            actionlabel={view}
          >
            {translate("Upgrade")}
          </Button>
          <br />
          <Button variation="link" to="/plans">
            {translate("View plans")}
          </Button>
        </div>
      </Panel>
    </Container>
  )
}

ModuleDisabled.propTypes = {
  view: PropTypes.string,
  className: PropTypes.string,
}
ModuleDisabled.defaultProps = {
  view: "Module",
  className: "",
}

export default ModuleDisabled
