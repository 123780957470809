import { find } from "lodash"
import { getUnformattedValue } from "data/utils/formaters"

export const normalizeCheckboxValues = (value, previousValue) => {
  // only the onChange event returns a string
  if (typeof value !== "string") return previousValue
  if (!previousValue || !previousValue.length) return [value]
  if (!find(previousValue, val => val === value)) {
    return [...previousValue, value]
  }
  return previousValue.filter(val => val !== value)
}

export const normalizeBooleanValue = value => value === "true" || value === true

export const normalizeNumberValue = value =>
  value ? parseFloat(value, 10) : ""

export const normalizeFloats = obj => {
  const format = (acc, curr) => ({
    ...acc,
    [curr]: obj[curr] === "" ? obj[curr] : parseFloat(obj[curr], 10),
  })
  return Object.keys(obj).reduce(format, {})
}

export const normalizeInternationalFloats = (obj, decimalSeparator) => {
  const format = (acc, curr) => ({
    ...acc,
    [curr]:
      obj[curr] === ""
        ? obj[curr]
        : unifyFloatValue(obj[curr], decimalSeparator),
  })
  return Object.keys(obj).reduce(format, {})
}

export const unifyFloatValue = (value, decimalSeparator) => {
  const unformattedValue = `${getUnformattedValue(value, decimalSeparator)}`
  return parseFloat(unformattedValue.replace(",", "."))
}

export default {
  normalizeCheckboxValues,
  normalizeBooleanValue,
  normalizeNumberValue,
  normalizeFloats,
  normalizeInternationalFloats,
  unifyFloatValue,
}
