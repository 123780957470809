import {
  TRIAL_START_GET_REQUEST,
  TRIAL_START_GET_SUCCESS,
  TRIAL_START_GET_FAILURE,
  TRIAL_START_REQUEST,
  TRIAL_START_SUCCESS,
  TRIAL_START_FAILURE,
  TRIAL_PROLONG_REQUEST,
  TRIAL_PROLONG_SUCCESS,
  TRIAL_PROLONG_FAILURE,
  TRIAL_START_CANCEL,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  loading: false,
  extending: false,
  starting: false,
  data: {
    items: [],
    postponeCount: 0,
    validationTime: null,
  },
  error: null,
}
function trialStartStore(state = defaultState, action) {
  switch (action.type) {
    case TRIAL_START_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case TRIAL_START_GET_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      }
    case TRIAL_START_REQUEST:
      return {
        ...state,
        starting: true,
      }
    case TRIAL_START_SUCCESS:
      return {
        ...state,
        starting: false,
      }
    case TRIAL_PROLONG_REQUEST:
      return {
        ...state,
        extending: true,
      }
    case TRIAL_PROLONG_SUCCESS:
      return {
        ...state,
        extending: false,
        data: {
          ...state.data,
          postponeCount: state.data.postponeCount + 1,
          validationTime: action.data.validationTime,
        },
      }
    case TRIAL_START_GET_FAILURE:
    case TRIAL_START_FAILURE:
    case TRIAL_PROLONG_FAILURE:
      return {
        ...state,
        error: action.error.error,
        loading: false,
        starting: false,
        extending: false,
      }
    case TRIAL_START_CANCEL:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default trialStartStore
