import { all, select, call, put, take, fork } from "redux-saga/effects"

import {
  USER_GET_SUCCESS,
  USER_GET_PLANS,
  USER_GET_PLANS_REQUEST,
  USER_GET_PLANS_SUCCESS,
  USER_GET_PLANS_FAILURE,
  USER_GET_PLANS_RESOLVED,
} from "data/constants"
import UserPlanAPI from "data/fetch/user/plans"

function* getPlans() {
  while (true) {
    yield take([USER_GET_SUCCESS, USER_GET_PLANS])

    const { user } = yield select()

    yield put({ type: USER_GET_PLANS_REQUEST })

    try {
      const { data } = yield call(() => UserPlanAPI.getPlans(user.id))
      yield put({ type: USER_GET_PLANS_SUCCESS, payload: data })
    } catch (error) {
      yield put({ type: USER_GET_PLANS_FAILURE, error })
    }

    yield put({ type: USER_GET_PLANS_RESOLVED })
  }
}

export default function* main() {
  const tasks = yield all([fork(getPlans)])

  yield all(tasks)
}
