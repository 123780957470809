import { isEmpty, get } from "lodash"

import {
  LOADING_STATES,
  USER_ONBOARDING_OPT_OUT_REQUEST,
  USER_ONBOARDING_OPT_OUT_SUCCESS,
  USER_ONBOARDING_OPT_OUT_FAILURE,
  USER_ONBOARDING_VISITED,
  USER_GET_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "data/constants"

const defaultState = {
  error: null,
  enableOptOut: false,
  isOptedOut: false,
  selfOptOutEnabled: true,
  enableLeaving: false,
  shouldBeOnboarded: false,
  state: LOADING_STATES.INITIAL,
}

function ebayOnboardingStore(state = defaultState, action) {
  switch (action.type) {
    case USER_ONBOARDING_OPT_OUT_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      }
    case USER_ONBOARDING_OPT_OUT_SUCCESS:
      return {
        ...state,
        selfOptOutEnabled: action.req.self_opt_out_enabled,
        isOptedOut: true,
        state: LOADING_STATES.LOADED,
      }
    case USER_ONBOARDING_OPT_OUT_FAILURE:
      return {
        ...state,
        error: action.error,
        state: LOADING_STATES.FAILED,
      }
    case USER_ONBOARDING_VISITED:
      return {
        ...state,
        shouldBeOnboarded: false,
      }
    case USER_GET_SUCCESS:
      return {
        ...state,
        shouldBeOnboarded: shouldBeOnboarded(action.req.data),
        enableOptOut: enableOptOut(action.req.data),
        enableLeaving: enableLeaving(action.req.data),
      }
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export function enableOptOut(user) {
  return get(user, "welcome_page.immediate_opt_out", false)
}

export function enableLeaving(user) {
  return get(user, "welcome_page.show_for_active", false)
}

export function shouldBeOnboarded(user) {
  return (
    !isEmpty(get(user, "platforms.ebay.accounts", [])) &&
    get(user, "platforms.ebay.state", "") !== "D" &&
    (get(user, "welcome_page.show_for_active", false) ||
      get(user, "welcome_page.show_for_inactive", false)) &&
    // User can't be from an Auto Opt-in program
    // He will be onboarded in a different way
    get(user, "platforms.ebay.programme") !== "AUTO_OPT_IN"
  )
}

export default ebayOnboardingStore
