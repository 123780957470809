import { each } from "lodash"
import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function get(user, item, platform) {
  const controller = new AbortController()
  const promises = []

  each(item.translations, (ti, key) => {
    if (!ti.manualPriceEnabled) return
    const params = {
      userId: user.id,
      sku: item.sku,
      site: key,
    }
    const options = {
      signal: controller.signal,
      url: `${CONFIG.gatewayHost}/v1/users/${user.id}/pricing/manual/items/${platform}`,
      method: "get",
      params,
    }
    promises.push(fetch(options))
  })

  const promise = Promise.all(promises).then(values =>
    values.map(val => val.data.items[0])
  )
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function post(userId, platform, payload) {
  const controller = new AbortController()

  const options = {
    url: `${CONFIG.gatewayHost}/v1/users/${userId}/pricing/manual/items/${platform}`,
    method: "POST",
    data: payload,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  get,
  post,
}
