import { put, take, takeEvery, race, select } from "redux-saga/effects"
import { setLanguage, setTranslations } from "redux-i18n"
import { has, noop } from "lodash"

// import { setTranslations } from "data/actions/user/translations"

import {
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  ACCOUNTS_GET_SUCCESS,
  GENERAL_SET_LANGUAGE,
  GENERAL_SET_ACTIVE_PLATFORM,
  GENERAL_SET_ACTIVE_ACCOUNT,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE,
} from "data/constants"
import { getUserLanguages, changeDayjsLocale } from "shared/services/languages"
import { extendTrackingData, injectLiveChat } from "shared/services/analytics"

function* setupLanguage(action) {
  let additionalLanguages = []

  switch (action.type) {
    case USER_GET_SUCCESS:
      additionalLanguages = [...action.req.data.langs]
      break
    case USER_GET_FAILURE:
    default:
      break
  }

  const language = getUserLanguages(additionalLanguages)
  // Change dayjs locale
  changeDayjsLocale(language)
  // Set application language
  yield put({
    type: GENERAL_SET_LANGUAGE,
    language,
  })
}

function* initAnalytics(action) {
  const { user, i18nState } = yield select()
  switch (action.type) {
    case USER_GET_SUCCESS:
      if (user.adminUserId) {
        break
      }

      try {
        extendTrackingData(user, i18nState)
        injectLiveChat(user)
      } catch (e) {
        noop("Analytics init failed", e)
      }
      break
    case USER_GET_FAILURE:
      injectLiveChat()
      break
    default:
      injectLiveChat()
      break
  }
}

function* setInitialPlatform() {
  yield race([
    take([USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS]),
    take([USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE]),
  ])

  const { user, multichannelOnboarding } = yield select()
  let platform = "ebay"
  const activeOnboarding = multichannelOnboarding.current
  const isEbay =
    has(user.platforms, "ebay") && user.platforms.ebay.state !== "D"
  const isAmazon =
    has(user.platforms, "amazon") && user.platforms.amazon.state !== "D"
  const isCdiscount =
    has(user.platforms, "cdiscount") && user.platforms.cdiscount.state !== "D"
  const hasOngoingOnboarding = activeOnboarding.is_ongoing

  if (isCdiscount) {
    platform = "cdiscount"
  }
  if (isAmazon) {
    platform = "amazon"
  }
  if (isEbay) {
    platform = "ebay"
  }
  if (hasOngoingOnboarding) {
    platform = activeOnboarding.channel
  }
  yield put({
    type: GENERAL_SET_ACTIVE_PLATFORM,
    platform,
  })
}

function* setLang({ language }) {
  if (language.toUpperCase() !== "EN") {
    const { translations } = yield import("translations/translations")
    yield put(setTranslations(translations))
  }

  yield put(setLanguage(language))
}

function* setAccount() {
  const { user, general } = yield select()
  const platform = general.activePlatform
  let account = user.id

  if (platform === "ebay") {
    account =
      (user.platforms.ebay &&
        user.platforms.ebay.accounts &&
        user.platforms.ebay.accounts[0].id) ||
      user.id
  }
  yield put({
    type: GENERAL_SET_ACTIVE_ACCOUNT,
    account,
  })
}

export default function* main() {
  yield takeEvery([USER_GET_SUCCESS, USER_GET_FAILURE], setupLanguage)
  yield takeEvery([USER_GET_SUCCESS, USER_GET_FAILURE], initAnalytics)
  yield takeEvery(GENERAL_SET_LANGUAGE, setLang)
  // We initialize right after getting account instead of all data ready,
  // because this needs to be done before main component starts rendering routes
  yield takeEvery(USER_GET_SUCCESS, setInitialPlatform)
  yield takeEvery(ACCOUNTS_GET_SUCCESS, setAccount)
}
