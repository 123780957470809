import React, { Component } from "react"
import PropTypes from "prop-types"

import styles from "./IFrame.module.css"

class IFrame extends Component {
  static propTypes = {
    /** Iframe content. Text or HTML. */
    children: PropTypes.node,
    source: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  static defaultProps = {
    children: null,
    source: "",
    height: "",
  }

  componentDidMount() {
    const { children, height, source } = this.props

    this.iframe = document.createElement("iframe")
    this.iframe.setAttribute("width", "100%")
    this.iframe.setAttribute("frameborder", "0")
    this.iframe.setAttribute("src", source)
    this.iframe.setAttribute("height", height)
    this.container.appendChild(this.iframe)
    this.iframe.contentWindow.document.body.style.margin = 0

    if (children) this.setContent()
  }

  setContent() {
    const { children } = this.props
    this.iframe.contentDocument.body.innerHTML = children
    setTimeout(() => {
      if (this.iframe) {
        this.iframe.height = `${this.iframe.contentWindow.document.body
          .scrollHeight + 15}px`
      }
    }, 500)
  }

  render() {
    return (
      <div
        className={styles.root}
        ref={el => {
          this.container = el
        }}
      />
    )
  }
}

export default IFrame
