import {
  LOADING_STATES,
  ORDERS_GET_LIST_REQUEST,
  ORDERS_GET_LIST_SUCCESS,
  ORDERS_GET_LIST_FAILURE,
  ORDERS_CLEAR,
  ORDERS_CANCEL,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

const defaultState = {
  count: 0,
  list: [],
  state: LOADING_STATES.INITIAL,
  error: null,
}

function ordersStore(state = defaultState, action) {
  switch (action.type) {
    case ORDERS_GET_LIST_REQUEST:
      return {
        ...state,
        count: 0,
        list: [],
        error: null,
        state: LOADING_STATES.LOADING,
      }
    case ORDERS_GET_LIST_SUCCESS:
      return {
        ...state,
        count: action.data.count,
        list: action.data.sales,
        state: LOADING_STATES.LOADED,
      }
    case ORDERS_GET_LIST_FAILURE:
      return {
        ...state,
        count: 0,
        list: [],
        error: action.error,
        state: LOADING_STATES.FAILED,
      }
    case ORDERS_CLEAR:
    case ORDERS_CANCEL:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default ordersStore
