import dayjs from "dayjs"
import weekOfYear from "dayjs/plugin/weekOfYear"
import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import isBetween from "dayjs/plugin/isBetween"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

dayjs.extend(weekOfYear)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)

export default dayjs
