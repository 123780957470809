/* eslint-disable import/prefer-default-export */

import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function getAll(user, platform, params) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${user.id}/blacklisting/keywords/${platform}`,
    method: "get",
    params,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function get(user, platform, keyword) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${
      user.id
    }/blacklisting/keywords/${encodeURIComponent(keyword)}/${platform}`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function add(user, platform, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${user.id}/blacklisting/keywords/${platform}`,
    method: "post",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function update(user, platform, data) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${
      user.id
    }/blacklisting/keywords/${encodeURIComponent(data.keyword)}/${platform}`,
    method: "put",
    data,
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function remove(user, platform, keyword) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${
      user.id
    }/blacklisting/keywords/${encodeURIComponent(keyword)}/${platform}`,
    method: "delete",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  getAll,
  get,
  update,
  remove,
}
