import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./Container.module.css"

function Container({ children, fullWidth, ...rest }) {
  const classnames = classNames(styles.root, { [styles.fullWidth]: fullWidth })
  return (
    <div className={classnames}>
      <div {...rest}>{children}</div>
    </div>
  )
}

Container.propTypes = {
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Container.defaultProps = {
  fullWidth: false,
}

export default Container
