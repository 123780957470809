import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Field, reduxForm, SubmissionError } from "redux-form"
import { isNil } from "lodash"

import { withTranslate } from "context/translateContext"
import { login } from "data/actions/user/auth"

import { renderField, renderCheckbox } from "shared/components/Forms"
import PasswordInput from "shared/components/Forms/PasswordInput"
import Button from "shared/components/Button"
import { isEmail } from "shared/helpers/validate"

import styles from "./LoginForm.module.css"

export class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }

  static defaultProps = {
    error: null,
  }

  constructor(props) {
    super(props)

    this.login = this.login.bind(this)
  }

  login(values) {
    const { loginUser } = this.props
    return loginUser(values).catch(data => {
      throw new SubmissionError({
        _error: data?.error?.description || "Login error",
      })
    })
  }

  render() {
    const { handleSubmit, submitting, error } = this.props
    const { t: translate } = this.props
    return (
      <form onSubmit={handleSubmit(this.login)}>
        <div className="m-b-10">
          <Field
            reference="emailAuth"
            name="username"
            type="text"
            component={renderField}
            label={translate("Email address")}
          />
          <Field
            reference="passwordAuth"
            name="password"
            type="password"
            component={PasswordInput}
            label={translate("Password")}
          />
          {error && (
            <span className={`${styles.error} fs-13 m-b-15 block textCenter`}>
              {translate(error)}
            </span>
          )}
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Field
              reference="keepMe"
              name="keepMe"
              component={renderCheckbox}
              label={translate("Keep me signed in")}
            />
          </div>
          <div className={`col-sm-6 ${styles.forgot}`}>
            <Link className="fs-12" to="password-reset/submit-email">
              {translate("Forgotten password?")}
            </Link>
          </div>
        </div>
        <div className={styles.submitButton}>
          <Button
            type="submit"
            disabled={submitting}
            loading={submitting}
            variation="primary"
            size="landing-large"
            block
          >
            {translate("Sign in")}
          </Button>
        </div>
      </form>
    )
  }
}

const validate = values => {
  const errors = {}
  if (isNil(values.username)) {
    errors.username = "Email is required"
  } else if (!isEmail(values.username)) {
    errors.username = "Invalid email address"
  }
  if (isNil(values.password)) {
    errors.password = "Password is required"
  }
  return errors
}

const mapDispatchToProps = dispatch => ({
  loginUser: data => dispatch(login(data)),
})

LoginForm = connect(null, mapDispatchToProps)(LoginForm)

export default reduxForm({
  form: "login",
  validate,
})(withTranslate(LoginForm))
