import { all, select, call, put, take, fork } from "redux-saga/effects"

import {
  TRIAL_START_GET,
  TRIAL_START_GET_REQUEST,
  TRIAL_START_GET_SUCCESS,
  TRIAL_START_GET_FAILURE,
  TRIAL_PROLONG,
  TRIAL_PROLONG_REQUEST,
  TRIAL_PROLONG_SUCCESS,
  TRIAL_PROLONG_FAILURE,
  TRIAL_START,
  TRIAL_START_REQUEST,
  TRIAL_START_SUCCESS,
  TRIAL_START_FAILURE,
  TRIAL_START_CANCEL,
} from "../../constants"
import {
  get as getTrialData,
  prolong as prolongTrial,
  start as startTrial,
} from "../../fetch/user/trial"

function* get() {
  while (true) {
    yield take([TRIAL_START_GET])
    const { user } = yield select()
    yield put({ type: TRIAL_START_GET_REQUEST })
    try {
      const { data } = yield call(() => getTrialData(user.id))
      if (data) yield put({ type: TRIAL_START_GET_SUCCESS, data: data.data })
    } catch (error) {
      yield put({ type: TRIAL_START_GET_FAILURE, error })
    }
  }
}

function* prolong() {
  while (true) {
    yield take([TRIAL_PROLONG])
    const { user } = yield select()
    yield put({ type: TRIAL_PROLONG_REQUEST })
    try {
      const { data } = yield call(() => prolongTrial(user.id))
      if (data) yield put({ type: TRIAL_PROLONG_SUCCESS, data: data.data })
    } catch (error) {
      yield put({ type: TRIAL_PROLONG_FAILURE, error })
    }
  }
}

function* start() {
  while (true) {
    yield take([TRIAL_START])
    const { user } = yield select()
    yield put({ type: TRIAL_START_REQUEST })
    try {
      const { data } = yield call(() => startTrial(user.id))
      if (data) yield put({ type: TRIAL_START_SUCCESS, data: data.data })
    } catch (error) {
      yield put({ type: TRIAL_START_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(get), fork(prolong), fork(start)])

    yield all(tasks)

    yield take(TRIAL_START_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
