import React from "react"
import PropTypes from "prop-types"

import { useTranslate } from "context/translateContext"
import GetFeedbackModal from "shared/components/GetFeedbackModal"

const SURVEY_DELAY_MS = 1000
const PostEplPlanUpgradeCancelSurvey = ({ onClose }) => {
  const { t: translate } = useTranslate()
  return (
    <GetFeedbackModal
      src={translate("https://www.getfeedback.com/e/0lNdzEeW")}
      showAfterMS={SURVEY_DELAY_MS}
      data-gtm="shown-ui"
      category="ePL"
      action="post_epl_plan_upgrade_cancel_survey_shown"
      actionlabel="survey"
      onClose={onClose}
    />
  )
}

PostEplPlanUpgradeCancelSurvey.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default PostEplPlanUpgradeCancelSurvey
