import React, { Component } from "react"
import PropTypes from "prop-types"

import { withTranslate } from "context/translateContext"

import eyeOpen from "./assets/eye-open.svg"
import eyeClosed from "./assets/eye-closed.svg"

import styles from "./PasswordInput.module.css"

class PasswordInput extends Component {
  static propTypes = {
    reference: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.node,
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    error: PropTypes.bool,
    className: PropTypes.string,
    isrequired: PropTypes.bool,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    reference: "field",
    type: "password",
    label: null,
    error: null,
    className: "",
    isrequired: true,
  }

  constructor(props) {
    super(props)

    this.state = {
      hidden: true,
    }

    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow(event) {
    const { hidden } = this.state
    event.preventDefault()
    this.setState({ hidden: !hidden })
  }

  render() {
    const {
      reference,
      isrequired,
      label,
      meta,
      input,
      error,
      className,
      type,
      t: translate,
      ...props
    } = this.props
    const { hidden } = this.state
    return (
      <div
        className={[
          "inputContainer",
          className,
          meta.submitFailed && (error || meta.error) ? "hasError" : "",
        ].join(" ")}
      >
        {label && <label htmlFor={reference}>{translate(label)}</label>}

        <div className="inputGroup relative">
          <input
            id={reference}
            type={hidden ? type : "text"}
            className={styles.passwordInput}
            {...props}
            {...input}
          />
          <button
            type="button"
            className={styles.eyeButton}
            onClick={event => this.toggleShow(event)}
          >
            <img
              alt="password-img"
              className={[
                styles.passwordIcon,
                meta.submitFailed && (error || meta.error)
                  ? styles.hasError
                  : "",
              ].join(" ")}
              src={hidden ? eyeOpen : eyeClosed}
            />
          </button>
        </div>
        {meta.submitFailed && meta.error && (
          <span className="inputError">{translate(meta.error)}</span>
        )}
      </div>
    )
  }
}

export default withTranslate(PasswordInput)
