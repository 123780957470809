import { find } from "lodash"

export const areAllEnabled = rules => {
  const notEnabledRule = find(rules, { is_pricing_enabled: false })
  return !notEnabledRule
}

export const arePricesEqual = rules => {
  const prices = rules.map(rule => rule.price_increase)
  const types = rules.map(rule => rule.increase_type)
  return (
    prices.every(val => val === prices[0]) &&
    types.every(val => val === types[0])
  )
}

export default {
  areAllEnabled,
  arePricesEqual,
}
