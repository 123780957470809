import { all, call, fork, take, race, select, put } from "redux-saga/effects"
import { get, isEmpty } from "lodash"

import {
  USER_GET_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_REQUEST,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE,
  GENERAL_INITIAL_DATA_READY,
  USER_MULTICHANNEL_ONBOARDING_CATCH_REDIRECT_BACK,
  USER_MULTICHANNEL_ONBOARDING_TOKEN_CONNECTED_SUCCESS_NOTIFICATION,
  USER_MULTICHANNEL_ONBOARDING_ALERT_TOKEN_ALREADY_REGISTERED,
  USER_MULTICHANNEL_ONBOARDING_COMMON_ERROR_NOTIFICATION,
  AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_SUCCESS,
  AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_SUCCESS,
  AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_SUCCESS,
  EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_SUCCESS,
  EBAY_ONBOARDING_SUBMIT_3PTOOL_SUCCESS,
  EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_SUCCESS,
  EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_SET_CHOSEN_PLATFORM,
  USER_MULTICHANNEL_ONBOARDING_COMPLETED,
  USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_FAILURE,
  USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_SUCCESS,
  EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_SUCCESS,
  EBAY_ONBOARDING_POST_CHECKOUT_SUCCESS,
} from "data/constants"

import { get as getUser } from "data/actions/user/auth"
import MultichannelOnboardingAPI from "data/fetch/user/multichannelOnboarding"

import history from "core/history"

function* listenForRedirectResults() {
  const { platform, reason } = yield take(
    USER_MULTICHANNEL_ONBOARDING_CATCH_REDIRECT_BACK
  )

  yield take(GENERAL_INITIAL_DATA_READY)
  const { general, multichannelOnboarding } = yield select()
  const isPlatformEligible =
    reason || platform === get(multichannelOnboarding, "current.channel", "")

  let leadTo = "/start"
  let callbackReason

  if (reason) {
    callbackReason = reason.toLowerCase()
  } else {
    callbackReason = isPlatformEligible ? "success" : ""
  }

  switch (callbackReason) {
    case "success":
      yield put({
        type: USER_MULTICHANNEL_ONBOARDING_TOKEN_CONNECTED_SUCCESS_NOTIFICATION,
        payload: { platform },
      })
      leadTo = `/${platform}/${general.activeAccount}/onboarding`
      break
    case "already_registered":
      yield put({
        type: USER_MULTICHANNEL_ONBOARDING_ALERT_TOKEN_ALREADY_REGISTERED,
        payload: { platform },
      })
      leadTo = `/${platform}/${general.activeAccount}/dashboard`
      break
    case "other_error":
      yield put({
        type: USER_MULTICHANNEL_ONBOARDING_COMMON_ERROR_NOTIFICATION,
        payload: { platform },
      })
      leadTo = `/${platform}/${general.activeAccount}/dashboard`
      break
    default:
      break
  }

  if (isPlatformEligible) {
    yield put({
      type: USER_MULTICHANNEL_ONBOARDING_SET_CHOSEN_PLATFORM,
      platform,
    })
  }

  history.push(leadTo)
}

function* onLoad() {
  while (true) {
    yield take([USER_GET_SUCCESS])

    yield getOnboardingList()
  }
}

function* onStepUpdate() {
  while (true) {
    yield race([
      take([USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_SUCCESS]),
      take([USER_MULTICHANNEL_ONBOARDING_CANCEL_POST_FAILURE]),
      take([AMAZON_ONBOARDING_SUBMIT_SOURCE_SITE_SUCCESS]),
      take([AMAZON_ONBOARDING_SUBMIT_TARGET_SITES_SUCCESS]),
      take([AMAZON_ONBOARDING_SUBMIT_PRODUCTS_TYPE_SUCCESS]),
      take([EBAY_ONBOARDING_SUBMIT_SHIPPING_OPTIONS_SUCCESS]),
      take([EBAY_ONBOARDING_SUBMIT_3PTOOL_SUCCESS]),
      take([EBAY_ONBOARDING_CONFIRM_PAYPAL_MULTICURRENCY_SUCCESS]),
      take([EBAY_ONBOARDING_SUBMIT_ACCOUNT_INFO_SUCCESS]),
      take([EBAY_ONBOARDING_POST_CHECKOUT_SUCCESS]),
      take([EBAY_ONBOARDING_SUBMIT_CONFIGURE_SITES_SUCCESS]),
    ])

    yield getOnboardingList()
  }
}

function* getOnboardingList() {
  const { user, multichannelOnboarding } = yield select()

  yield put({ type: USER_MULTICHANNEL_ONBOARDING_LIST_GET_REQUEST })
  try {
    const { data } = yield call(() =>
      MultichannelOnboardingAPI.getList(user.id)
    )
    if (
      isEmpty(data.result) &&
      get(multichannelOnboarding, "current.is_ongoing", false)
    ) {
      yield put({ type: USER_MULTICHANNEL_ONBOARDING_COMPLETED })

      yield put(getUser())
      history.replace("/")
    }

    yield put({
      type: USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS,
      data: data.result,
    })
  } catch (error) {
    yield put({ type: USER_MULTICHANNEL_ONBOARDING_LIST_GET_FAILURE, error })
  }
}

export default function* main() {
  yield all([fork(onLoad), fork(onStepUpdate), fork(listenForRedirectResults)])
}
