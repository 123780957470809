import {
  PRODUCT_GET_REQUEST,
  PRODUCT_GET_SUCCESS,
  PRODUCT_GET_FAILURE,
  PRODUCTS_UPDATE_BLOCK_SUCCESS,
  PRODUCTS_UPDATE_UNBLOCK_SUCCESS,
  PRODUCTS_UPDATE_BLOCK_REPRICER_SUCCESS,
  PRODUCTS_UPDATE_UNBLOCK_REPRICER_SUCCESS,
  PRODUCT_CLEAR,
  USER_LOGOUT_SUCCESS,
} from "../../constants"

import { block, unblock, blockRepricer, unblockRepricer } from "../../utils"

const defaultState = {
  data: {},
  state: "INITIAL",
  error: null,
}

function productSummaryStore(state = defaultState, action) {
  switch (action.type) {
    case PRODUCT_GET_REQUEST:
      return {
        ...state,
        data: {},
        error: null,
        state: "LOADING",
      }
    case PRODUCT_GET_SUCCESS:
      return {
        ...state,
        data: action.data,
        state: "LOADED",
      }
    case PRODUCT_GET_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        state: "FAILED",
      }
    case PRODUCTS_UPDATE_BLOCK_SUCCESS:
      if (state.data.id !== action.data.items[0].id) return state
      return {
        ...state,
        data: {
          ...state.data,
          translations: block(
            state.data.translations,
            action.data.items[0].sites
          ),
        },
      }
    case PRODUCTS_UPDATE_UNBLOCK_SUCCESS:
      if (state.data.id !== action.data.items[0].id) return state
      return {
        ...state,
        data: {
          ...state.data,
          translations: unblock(
            state.data.translations,
            action.data.items[0].sites
          ),
        },
      }
    case PRODUCTS_UPDATE_BLOCK_REPRICER_SUCCESS:
      if (state.data.id !== action.data.items[0].id) return state
      return {
        ...state,
        data: {
          ...state.data,
          translations: blockRepricer(
            state.data.translations,
            action.data.items[0].sites
          ),
        },
      }
    case PRODUCTS_UPDATE_UNBLOCK_REPRICER_SUCCESS:
      if (state.data.id !== action.data.items[0].id) return state
      return {
        ...state,
        data: {
          ...state.data,
          translations: unblockRepricer(
            state.data.translations,
            action.data.items[0].sites
          ),
        },
      }
    case PRODUCT_CLEAR:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default productSummaryStore
