import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  BLACKLIST_GET_LIST,
  BLACKLIST_GET_LIST_REQUEST,
  BLACKLIST_GET_LIST_SUCCESS,
  BLACKLIST_GET_LIST_FAILURE,
  BLACKLIST_CANCEL,
} from "../../constants"

import { getAll } from "../../fetch/blacklist"

function* getList() {
  while (true) {
    const action = yield take([BLACKLIST_GET_LIST])
    const { user } = yield select()
    yield put({ type: BLACKLIST_GET_LIST_REQUEST })
    try {
      const { data } = yield call(() =>
        getAll(user, action.platform, action.params)
      )
      if (data) yield put({ type: BLACKLIST_GET_LIST_SUCCESS, data: data.data })
    } catch (error) {
      yield put({ type: BLACKLIST_GET_LIST_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getList)])

    yield all(tasks)

    yield take(BLACKLIST_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
