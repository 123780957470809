import { fork } from "redux-saga/effects"

import general from "./general"
import userPlans from "./user/plans"
import auth from "./user/auth"
import signUp from "./user/signUp"
import multichannelOnboarding from "./user/multichannelOnboarding"
import cdiscountOnboarding from "./user/cdiscountOnboarding"
import user from "./user/user"
import termsOfService from "./user/termsOfService"
import trial from "./user/trial"
import tokens from "./accounts/tokens"
import account from "./accounts/single"
import accounts from "./accounts/list"
import settings from "./user/settings"
import permissions from "./user/permissions"
import dashboard from "./dashboard"
import productsList from "./products/list"
import productsSingle from "./products/single"
import productsSettings from "./products/settings"
import bulkItemRequest from "./products/bulk"
import ordersList from "./orders/list"
import conversionChartsList from "./conversionCharts/list"
import repricerSettings from "./repricer/settings"
import blacklistList from "./blacklist/list"
import blacklistSingle from "./blacklist/single"
import translationManager from "./translationManager/list"
import sellerTerm from "./translationManager/sellerTerm"
import bulkActions from "./translationManager/bulkActions"
import shippingProfiles from "./shippingProfiles/list"
import shippingProfilesSingle from "./shippingProfiles/single"
import shippingProfilesItems from "./shippingProfiles/items"
import shippingProfilesServices from "./shippingProfiles/services"
import shippingProfilesRates from "./shippingProfiles/rates"
import priceAdjustment from "./priceAdjustment/rules"
import contactDetails from "./profile/contactDetails"
import financialDetails from "./profile/financialDetails"
import creditCardConfigDetails from "./profile/creditCardConfigDetails"
import thirdPartyTools from "./profile/thirdPartyTools"
import invoicesList from "./invoices/list"
import plans from "./plans/plans"
import payment from "./plans/payment"
import zendeskArticles from "./zendesk/articles"
import zendeskTicket from "./zendesk/ticket"
import epl from "./epl/epl"
import eplCampaign from "./epl/campaign"
import eplOnboarding from "./epl/onboarding"
import eplExcludedItems from "./epl/excludedItems"
import eplEligibility from "./epl/eligibility"
import platformStates from "./user/platformStates"
import entitlements from "./entitlements/entitlements"

export default function* main() {
  yield fork(general)
  yield fork(auth)
  yield fork(signUp)
  yield fork(multichannelOnboarding)
  yield fork(cdiscountOnboarding)
  yield fork(user)
  yield fork(termsOfService)
  yield fork(trial)
  yield fork(tokens)
  yield fork(account)
  yield fork(accounts)
  yield fork(settings)
  yield fork(permissions)
  yield fork(dashboard)
  yield fork(productsList)
  yield fork(productsSingle)
  yield fork(productsSettings)
  yield fork(ordersList)
  yield fork(conversionChartsList)
  yield fork(repricerSettings)
  yield fork(blacklistList)
  yield fork(blacklistSingle)
  yield fork(translationManager)
  yield fork(sellerTerm)
  yield fork(bulkActions)
  yield fork(shippingProfiles)
  yield fork(shippingProfilesSingle)
  yield fork(shippingProfilesItems)
  yield fork(shippingProfilesServices)
  yield fork(shippingProfilesRates)
  yield fork(priceAdjustment)
  yield fork(bulkItemRequest)
  yield fork(userPlans)
  // profile
  yield fork(contactDetails)
  yield fork(financialDetails)
  yield fork(thirdPartyTools)
  yield fork(creditCardConfigDetails)
  // invoices
  yield fork(invoicesList)
  // plans & payments
  yield fork(plans)
  yield fork(payment)
  // zendesk
  yield fork(zendeskArticles)
  yield fork(zendeskTicket)
  // epl
  yield fork(epl)
  yield fork(eplCampaign)
  yield fork(eplOnboarding)
  yield fork(eplExcludedItems)
  yield fork(eplEligibility)
  // user states
  yield fork(platformStates)
  // entitlements
  yield fork(entitlements)
}
