/**
 * converts dash to camelCase
 */
export const camelToDash = string =>
  string.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()
/**
 * converts camelCase to underscore
 */
export const camelToUnderscore = string =>
  string.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
/**
 * converts dash to camelCase
 */
export const dashToCamel = string =>
  string.replace(/-([a-z])/g, g => g[1].toUpperCase())
/**
 * converts space to camelCase
 */
export const spaceToCamel = string => {
  if (!string) {
    return ""
  }
  return string.toLowerCase().replace(/\s([a-z])/g, g => g[1].toUpperCase())
}
/**
 * converts strings with spaces to words with dashes
 */
export const spaceToDash = string => {
  if (!string) {
    return ""
  }
  return string.replace(/\s+/g, "-").toLowerCase()
}
/**
 * converts strings with dashes to words with spaces
 */
export const dashToSpace = string => {
  if (!string) {
    return ""
  }
  return string.replace(/-/g, " ")
}
/**
 * converts strings with dashes to words with underscores
 */
export const dashToUnderscore = string => {
  if (!string) {
    return ""
  }
  return string.replace(/-/g, "_")
}
/**
 * converts camelCase word to words with spaces
 */
export const camelToSpace = string =>
  string.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase()
/**
 * converts strings with underscores to words with spaces
 */
export const underscoreToSpace = string => {
  if (!string) {
    return ""
  }
  return string.replace(/_/g, " ")
}
/**
 * converts strings with underscores to words with dashes
 */
export const underscoreToDash = string => {
  if (!string) {
    return ""
  }
  return string.replace(/_/g, "-")
}
/**
 * converts strings with spaces to words with underscores
 */
export const spaceToUnderscore = string => {
  if (!string) {
    return ""
  }
  return string.replace(/ /g, "_")
}
/**
 * converts dots to dashes
 */
export const dotToDash = string => {
  if (!string) {
    return ""
  }
  return string.replace(/\./g, "-")
}
/**
 * capitalize first letter
 */
export const capitalizeFirstLetter = string => {
  if (!string) {
    return ""
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}
/**
 * count words
 */
export const countWords = string => {
  let s
  if (!string) {
    return 0
  }
  s = string.replace(/(^\s*)|(\s*$)/gi, "")
  s = s.replace(/[ ]{2,}/gi, " ")
  s = s.replace(/\n /, "\n")
  return s.split(" ").length
}
/**
 * Remove spaces from string
 */
export const removeSpaces = string => string.replace(/ /g, "")

export default {
  camelToDash,
  camelToUnderscore,
  dashToCamel,
  dashToUnderscore,
  spaceToCamel,
  spaceToDash,
  dashToSpace,
  camelToSpace,
  underscoreToSpace,
  underscoreToDash,
  spaceToUnderscore,
  dotToDash,
  capitalizeFirstLetter,
  countWords,
  removeSpaces,
}
