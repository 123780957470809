/* eslint-disable */
/*
 * Redux middleware to handle promises
 * As seen in: https://github.com/caljrimmer/isomorphic-redux-app
 */

export default function promiseMiddleware(getState) {
  return next => action => {
    if (typeof action === "function") {
      return action(next, getState)
    }

    const { promise, type, ...rest } = action

    if (!promise) return next(action)

    const SUCCESS = `${type}_SUCCESS`
    const REQUEST = `${type}_REQUEST`
    const FAILURE = `${type}_FAILURE`
    next({ ...rest, type: REQUEST })
    return promise
      .then(req => {
        next({
          ...rest,
          req: req.data,
          httpStatus: req.status,
          type: SUCCESS,
        })
        return Promise.resolve({ ...req })
      })
      .catch(req => {
        next({
          ...rest,
          httpStatus: (req.response && req.response.status) || 418,
          error: (req.response && req.response.data) || req,
          type: FAILURE,
        })
        return Promise.reject({
          ...((req.response && req.response.data) || req),
        })
      })
  }
}
