import { PureComponent } from "react"
import PropTypes from "prop-types"

class Throw extends PureComponent {
  static propTypes = {
    shouldThrow: PropTypes.bool,
    errorMessage: PropTypes.string,
  }

  static defaultProps = {
    shouldThrow: false,
    errorMessage: "Error thrown",
  }

  componentDidMount() {
    this.checkErrorTrigger()
  }

  componentDidUpdate() {
    this.checkErrorTrigger()
  }

  checkErrorTrigger() {
    const { shouldThrow, errorMessage } = this.props
    if (shouldThrow) {
      throw new Error(errorMessage)
    }
  }

  render() {
    return null
  }
}

export default Throw
