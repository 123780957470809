import React from "react"
import PropTypes from "prop-types"
import Select from "react-select-plus"
import { useTranslate } from "context/translateContext"

import "react-select-plus/dist/react-select-plus.css"
import "./Select.css"

import {
  transformValue,
  multiChangeHandler,
  singleChangeHandler,
} from "./helpers"

RFReactSelect.defaultProps = {
  multi: false,
  className: "",
  label: null,
  reference: null,
  placeholder: "Select...",
  noresultstext: "No results were found...",
  translateLabel: true,
  options: [],
}

RFReactSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
    ]).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array,
  meta: PropTypes.object.isRequired,
  multi: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  reference: PropTypes.string,
  placeholder: PropTypes.string,
  noresultstext: PropTypes.string,
  translateLabel: PropTypes.bool,
}

export default function RFReactSelect({
  input,
  options,
  multi,
  className,
  placeholder,
  noresultstext,
  label,
  reference,
  meta,
  translateLabel,
  ...props
}) {
  const { t: translate } = useTranslate()
  const { name, value, onBlur, onChange, onFocus } = input
  const transformedValue = transformValue(value, options, multi)
  return (
    <div
      className={`inputContainer ${className} ${
        meta.submitFailed && meta.error ? "hasError" : null
      }`}
    >
      {label ? (
        <label htmlFor={reference}>
          {translateLabel ? translate(label) : label}
        </label>
      ) : null}
      <Select
        valueKey="value"
        name={name}
        value={transformedValue}
        multi={multi}
        options={options}
        onChange={
          multi ? multiChangeHandler(onChange) : singleChangeHandler(onChange)
        }
        onBlur={() => onBlur(value)}
        onFocus={onFocus}
        placeholder={placeholder}
        noresultstext={noresultstext}
        instanceId={name}
        {...props}
      />
      {meta.submitFailed && meta.error && (
        <span className="inputError">{translate(meta.error)}</span>
      )}
    </div>
  )
}
