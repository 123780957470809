import { all, put, fork, select, take } from "redux-saga/effects"

import {
  SHIPPING_PROFILE_RATE_EDIT_SUCCESS,
  SHIPPING_PROFILE_RATES_UPDATE,
  SHIPPING_PROFILE_RATES_SET_DEFAULT,
  SHIPPING_PROFILE_RATES_SET_CUSTOM,
  SHIPPING_PROFILE_RATES_SET_EXCLUDED,
  SHIPPING_PROFILES_CANCEL,
} from "../../constants"

const updateRegions = (regions, country) =>
  regions.map(r => {
    const countryList = r.countries.map(c => c.code)

    if (countryList.indexOf(country.code) > -1) {
      const region = {
        ...r,
        countries_using_default:
          country.type === "DEFAULT"
            ? [...r.countries_using_default, country.code]
            : r.countries_using_default,
        custom_countries:
          country.type === "CUSTOM"
            ? [...r.custom_countries, country.code]
            : r.custom_countries.filter(c => c !== country.code),
        excluded_countries:
          country.type === "EXCLUDED"
            ? [...r.excluded_countries, country.code]
            : r.excluded_countries.filter(c => c !== country.code),
      }
      region.countries = r.countries.map(c => {
        if (c.code === country.code) {
          return country
        }
        return c
      })
      return region
    }

    return r
  })

function* rateEdit() {
  while (true) {
    const action = yield take(SHIPPING_PROFILE_RATE_EDIT_SUCCESS)
    const { shippingProfile } = yield select()
    const data = {
      ...shippingProfile.data,
      regions: updateRegions(shippingProfile.data.regions, action.req),
    }

    yield put({ type: SHIPPING_PROFILE_RATES_UPDATE, data })

    switch (action.data.type) {
      case "DEFAULT":
        yield put({ type: SHIPPING_PROFILE_RATES_SET_DEFAULT })
        break
      case "CUSTOM":
        yield put({ type: SHIPPING_PROFILE_RATES_SET_CUSTOM })
        break
      case "EXCLUDED":
        yield put({ type: SHIPPING_PROFILE_RATES_SET_EXCLUDED })
        break
      default:
        break
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(rateEdit)])

    yield all(tasks)

    yield take(SHIPPING_PROFILES_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
