import { all, select, put, take, fork, takeLatest } from "redux-saga/effects"
import { add, clear, remove } from "react-redux-permissions"
import { map, get, find } from "lodash"

import {
  USER_GET_SUCCESS,
  USER_GET_SETTINGS_SUCCESS,
  USER_GET_PLANS_SUCCESS,
  USER_SET_PERMISSIONS_SUCCESS,
  USER_UPDATE_SETTING_SUCCESS,
  USER_LOGOUT_SUCCESS,
  ENTITLEMENTS_CUSTOM_GET_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_START_SUCCESS,
  CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_SUCCESS,
  USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS,
} from "data/constants"
import CONFIG from "core/config"
import { dashToCamel } from "shared/helpers/strings"

function* setPermission(role, enabled) {
  if (enabled) yield put(add(role))
}

function* setUserPermissions() {
  const { user, settings } = yield select()
  const permissions = {
    // Active platforms
    noPlatform: user.isPlatformless,
    ebay: !!user.platforms.ebay,
    amazon: !!user.platforms.amazon && !user.isReturningAmazonUser,
    cdiscount: !!user.platforms.cdiscount,
    // User state
    admin: user.isAdmin,
    paying: user.isPaying,
    passive: user.isPassive,
    preTrialESB: user.isPreTrialESB,
    shippingEnabled: user.isShippingEnabled,
    trial: user.isTrial,
    trialESB: user.isESB && !user.isPaying && !user.isDeleted,
    isReturningAmazonUser: user.isReturningAmazonUser,
    deleted: user.isDeleted,
    ebayUK: user.isEbayUK,
    // User program
    usp: !!user.platforms.ebay && user.platforms.ebay.programme === "USPILOT",
    // Active views
    cdiscountEnabled: settings.data["cdiscount-enabled"] || user.isPlatformless,
    shipments: settings.data["shipments-enabled"],
    blacklist: settings.data["blacklisting-enabled"],
    conversionCharts: settings.data["sizes-api-enabled"],
    shippingProfiles: settings.data["shipping-profiles-enabled"],
    blocking: settings.data["blocking-enabled"],
    orderManagement: settings.data["order-management-enabled"],
    bulkItemRequest: settings.data["bulk-requesting-enabled"],
    tokenManagement: settings.data["token-management-enabled"],
    priceRules: settings.data["price-rules-enabled"],
    repricer: settings.data["repricer-enabled"],
    listingFlows: settings.data["listing-flows-enabled"],
    editPaymentMethod: settings.data["edit-payment-method-enabled"],
    manualPrice: settings.data["manual-price-management-enabled"],
    manualTitle: settings.data["manual-title-management-enabled"],
    checkoutPage: settings.data["checkout-page-enabled"],
    earlyBird: settings.data["discount-early-bird-enabled"],
    wiShippingEnabled: settings.data["wi-shipping-enabled"],
  }

  const roles = []
  map(permissions, (value, key) => {
    if (value) {
      roles.push(key)
    }
  })

  yield put(add(roles))

  yield put({
    type: USER_SET_PERMISSIONS_SUCCESS,
  })
}

function* setSubscriber() {
  const { user } = yield select()
  yield setPermission("subscribed", user.isSubscriber)
}

function* updatePermission(action) {
  yield setPermission(dashToCamel(action.data.name), action.data.value)
}

function* setEPLPermissions(action) {
  const isSellerEligible =
    get(action, "data.can_onboard_to_epl") ||
    get(action, "data.can_onboard_to_epl_domestic") ||
    get(action, "data.can_use_epl_trial") ||
    get(action, "data.can_use_epl_trial_domestic")
  const canManageAdRates = get(action, "data.can_manage_epl_ad_rate_strategies")
  const canManageDomesticAdRates = get(
    action,
    "data.can_manage_epl_domestic_ad_rate_strategies"
  )

  yield setPermission("eplEligible", isSellerEligible)
  yield setPermission("eplCanManageAdRates", canManageAdRates)
  yield setPermission("eplCanManageDomesticAdRates", canManageDomesticAdRates)
}

function* setCdiscountPermissions() {
  yield setPermission("cdiscount", true)
}

function* updateLoginStatus() {
  while (true) {
    const action = yield take([USER_GET_SUCCESS, USER_LOGOUT_SUCCESS])
    switch (action.type) {
      case USER_GET_SUCCESS:
        yield setPermission("logged", true)
        break
      case USER_LOGOUT_SUCCESS:
        yield put(clear())
        break
      default:
        break
    }
  }
}

function* setDomainPermission() {
  if (CONFIG.location === "cn") {
    yield put(add(["china"]))
  }
}

function* updateOnboardingsPermissions() {
  const { multichannelOnboarding } = yield select()
  const { ongoingOnboardings } = multichannelOnboarding
  const platforms = ["ebay", "amazon", "cdiscount"]
  const addPermissions = []
  const removePermissions = []

  platforms.forEach(element =>
    find(ongoingOnboardings, { channel: element })
      ? addPermissions.push(`${element}Onboarding`)
      : removePermissions.push(`${element}Onboarding`)
  )

  // TODO: get rid of this
  // AUTO OPT-IN HACK added to deal with different onboarding format for Ebay Auto Opt-in sellers
  const ebayAutoOptInOnboarding = find(ongoingOnboardings, {
    campaign: "auto-opt-in",
  })

  if (ebayAutoOptInOnboarding) {
    const ebayEntryIndex = addPermissions.indexOf("ebay")
    addPermissions.splice(ebayEntryIndex, 1)
    addPermissions.push("ebayAOIOnboarding")
    removePermissions.push("ebayOnboarding")
  }
  // END OF AUTO OPT-IN HACK

  if (addPermissions.length > 0) {
    yield put(add(addPermissions))
  }
  if (removePermissions.length > 0) {
    yield all(removePermissions.map(permission => put(remove(permission))))
  }
}

export default function* main() {
  yield all([
    fork(updateLoginStatus),
    fork(setDomainPermission),
    takeLatest(USER_GET_SETTINGS_SUCCESS, setUserPermissions),
    takeLatest(
      CDISCOUNT_ONBOARDING_SUBMIT_API_CREDENTIALS_SUCCESS,
      setCdiscountPermissions
    ),
    takeLatest(USER_GET_PLANS_SUCCESS, setSubscriber),
    takeLatest(USER_UPDATE_SETTING_SUCCESS, updatePermission),
    takeLatest(ENTITLEMENTS_CUSTOM_GET_SUCCESS, setEPLPermissions),
    takeLatest(
      [
        USER_MULTICHANNEL_ONBOARDING_LIST_GET_SUCCESS,
        USER_MULTICHANNEL_ONBOARDING_START_SUCCESS,
      ],
      updateOnboardingsPermissions
    ),
  ])
}
