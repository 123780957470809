import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEqual, keys, pickBy, identity } from "lodash"

import AuthModal from "./Auth"
import TermsOfServiceModal from "./TermsOfService"
import HelpCenterModal from "./HelpCenter"

import { GENERAL_HIDE_MODAL } from "../../data/constants"

class GlobalModal extends Component {
  static propTypes = {
    modals: PropTypes.shape({
      auth: PropTypes.bool.isRequired,
      tos: PropTypes.bool.isRequired,
      help: PropTypes.bool.isRequired,
    }).isRequired,
    closeModal: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      modal: null,
    }
    this.closeModal = this.closeModal.bind(this)
    this.modals = {
      auth: <AuthModal onRequestClose={this.closeModal} />,
      tos: <TermsOfServiceModal />,
      help: <HelpCenterModal onRequestClose={this.closeModal} />,
    }
  }

  componentDidMount() {
    this.setVisibility()
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { modals } = this.props
    const { modal } = this.state
    return (
      !isEqual(modals, nextProps.modals) || !isEqual(modal, nextState.modal)
    )
  }

  componentDidUpdate() {
    this.setVisibility()
  }

  setVisibility() {
    const { modals } = this.props
    const modal = keys(pickBy(modals, identity))
    this.setState({
      modal: modal[0] || null,
    })
  }

  closeModal() {
    const { closeModal } = this.props
    const { modal } = this.state
    closeModal(modal)
  }

  render() {
    const { modal } = this.state
    return this.modals[modal] || null
  }
}
const mapStateToProps = state => ({
  modals: state.general.modals,
})

const mapDispatchToProps = dispatch => ({
  closeModal: name =>
    dispatch({
      type: GENERAL_HIDE_MODAL,
      name,
    }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalModal)
