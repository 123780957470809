import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  REPRICER_GET_SETTINGS,
  REPRICER_GET_SETTINGS_REQUEST,
  REPRICER_GET_SETTINGS_SUCCESS,
  REPRICER_GET_SETTINGS_FAILURE,
  REPRICER_CANCEL,
} from "../../constants"

import { get as getDefaults } from "../../fetch/repricer/settings"

function* get() {
  while (true) {
    yield take([REPRICER_GET_SETTINGS])
    const { user } = yield select()
    yield put({ type: REPRICER_GET_SETTINGS_REQUEST })
    try {
      const { data } = yield call(() => getDefaults(user.id))
      if (data) { yield put({ type: REPRICER_GET_SETTINGS_SUCCESS, data: data.data }) }
    } catch (error) {
      yield put({ type: REPRICER_GET_SETTINGS_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(get)])

    yield all(tasks)

    yield take(REPRICER_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
