import { all, select, put, fork, call, take } from "redux-saga/effects"
import { find, keys } from "lodash"

import {
  USER_GET_SUCCESS,
  ACCOUNTS_GET,
  ACCOUNTS_GET_REQUEST,
  ACCOUNTS_GET_SUCCESS,
  ACCOUNTS_GET_FAILURE,
  ACCOUNTS_CANCEL,
} from "data/constants"

import { getAll as getFlows } from "data/fetch/accounts/flows"
import { getEbay } from "data/fetch/accounts/list"
import { getTokenWithStatus } from "data/utils"

const mapFlowsToAccounts = (accounts, flows) =>
  accounts.data.map(account => {
    const flow = find(flows.source_accounts, { account_id: account.account_id })
    const sites = keys(flow.listed_in)
    sites.forEach(site => {
      flow.listed_in[site] = flow.listed_in[site].map(f => ({
        ...f,
        dst_account_id: account.account_id,
        dst_site: site,
      }))
    })
    const tokens = account.tokens.map(token => ({
      ...getTokenWithStatus(token),
      site: flow.site,
    }))
    return {
      ...account,
      ...flow,
      tokens,
    }
  })

function* getList() {
  while (true) {
    yield take([ACCOUNTS_GET, USER_GET_SUCCESS])
    const { user } = yield select()
    const availablePlatforms = keys(user.platforms)
    const data = {
      amazon: [],
      ebay: [],
    }

    yield put({ type: ACCOUNTS_GET_REQUEST })
    try {
      if (availablePlatforms.indexOf("ebay") > -1) {
        // Fetch list of ebay accounts
        const { data: ebayAccounts } = yield call(() => getEbay(user))
        // Fetch list of flows
        const { data: flows } = yield call(() => getFlows(user.id))
        // Assign flows to their accounts account and update data
        data.ebay = mapFlowsToAccounts(ebayAccounts, flows)
      }

      yield put({
        type: ACCOUNTS_GET_SUCCESS,
        data,
      })
    } catch (error) {
      yield put({ type: ACCOUNTS_GET_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getList)])

    yield all(tasks)

    yield take(ACCOUNTS_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
