import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function get(userId, chartId) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/seller/${userId}/conversion-charts/single/${chartId}`,
    method: "get",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function add(userId, chart) {
  const controller = new AbortController()
  const options = {
    url: `${CONFIG.gatewayHost}/v1/seller/${userId}/conversion-charts`,
    method: "POST",
    data: {
      chart,
    },
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function edit(userId, chart) {
  const controller = new AbortController()
  const options = {
    url: `${CONFIG.gatewayHost}/v1/seller/${userId}/conversion-charts/single/${chart.id}`,
    method: "PUT",
    data: {
      chart,
    },
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function remove(userId, chart) {
  const controller = new AbortController()
  const options = {
    url: `${CONFIG.gatewayHost}/v1/seller/${userId}/conversion-charts/single/${chart.id}`,
    method: "DELETE",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  get,
  add,
  edit,
  remove,
}
