import { put, call, take, select } from "redux-saga/effects"

import {
  CONVERSION_CHART_GET,
  CONVERSION_CHART_GET_REQUEST,
  CONVERSION_CHART_GET_SUCCESS,
  CONVERSION_CHART_GET_FAILURE,
} from "../../constants"

import { get as getChart } from "../../fetch/conversionCharts/single"

function* get() {
  while (true) {
    const action = yield take([CONVERSION_CHART_GET])
    const { user } = yield select()
    yield put({ type: CONVERSION_CHART_GET_REQUEST })
    try {
      const { data } = yield call(() => getChart(user.id, action.id))
      if (data) yield put({ type: CONVERSION_CHART_GET_SUCCESS, data })
    } catch (error) {
      yield put({ type: CONVERSION_CHART_GET_FAILURE, error })
    }
  }
}

export default get
