import {
  EPL_CAMPAIGN_GET_REQUEST,
  EPL_CAMPAIGN_GET_SUCCESS,
  EPL_CAMPAIGN_GET_FAILURE,
  EPL_ADRATE_STRATEGY_GET_REQUEST,
  EPL_ADRATE_STRATEGY_GET_SUCCESS,
  EPL_ADRATE_STRATEGY_GET_FAILURE,
  EPL_CAMPAIGN_CLEAR,
  USER_LOGOUT_SUCCESS,
  LOADING_STATES,
} from "data/constants"

const defaultState = {
  data: {},
  adRateStrategies: null,
  state: LOADING_STATES.INITIAL,
  error: null,
}

function campaignStore(state = defaultState, action) {
  switch (action.type) {
    case EPL_CAMPAIGN_GET_REQUEST:
      return {
        ...state,
        data: {},
        error: null,
        state: LOADING_STATES.LOADING,
      }
    case EPL_CAMPAIGN_GET_SUCCESS:
      return {
        ...state,
        data: action.data,
        state: LOADING_STATES.LOADED,
      }
    case EPL_CAMPAIGN_GET_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        state: LOADING_STATES.FAILED,
      }
    case EPL_ADRATE_STRATEGY_GET_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      }
    case EPL_ADRATE_STRATEGY_GET_SUCCESS:
      return {
        ...state,
        adRateStrategies: action.req,
        state: LOADING_STATES.LOADED,
      }
    case EPL_ADRATE_STRATEGY_GET_FAILURE:
      return {
        ...state,
        adRateStrategies: null,
        state: LOADING_STATES.FAILED,
      }
    case EPL_CAMPAIGN_CLEAR:
    case USER_LOGOUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}

export default campaignStore
