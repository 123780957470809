import { TRIGGER_SURVEY, HIDE_SURVEY } from "data/constants"

export function triggerSurvey({ surveyActionType, justClosedMarkets }) {
  return dispatch =>
    dispatch({
      type: TRIGGER_SURVEY,
      data: {
        surveyActionType,
        justClosedMarkets,
      },
    })
}

export function hideSurvey() {
  return dispatch =>
    dispatch({
      type: HIDE_SURVEY,
    })
}

export default {
  triggerSurvey,
  hideSurvey,
}
