// EPL
const STATE_DEACTIVATED = "Deactivated"
const STATE_PAYING = "Paying"
const STATE_NON_SPONSORED = "Non sponsored"
const STATE_TRIAL = "Trial"
const UNKNOWN = "Unknown"

export const EPL_USER_STATE_NAMES = {
  D: STATE_DEACTIVATED,
  P: STATE_PAYING,
  N: STATE_NON_SPONSORED,
  T: STATE_TRIAL,
}
export const EPL_USER_STATES = {
  STATE_DEACTIVATED: "D",
  STATE_PAYING: "P",
  STATE_NON_SPONSORED: "N",
  STATE_TRIAL: "T",
}

export const EPL_CUSTOMER_STATUS = {
  NOT_STARTED: "not_started",
  TRIAL: "trial",
  REGULAR: "regular",
  PAYING: "paying",
  OPTED_OUT: "opted_out",
}

export const getEPLUserStateName = state =>
  EPL_USER_STATE_NAMES[state] || UNKNOWN

export const createEPLUserState = (currentState, platform) => ({
  start: new Date().toISOString(),
  state: currentState,
  user_parameters: {
    current_state: currentState,
    platform,
    program: "default",
  },
})

export default {
  getEPLUserStateName,
  createEPLUserState,
  EPL_USER_STATES,
  EPL_CUSTOMER_STATUS,
}
