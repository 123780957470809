import { all, put, fork, call, take } from "redux-saga/effects"

import {
  TRIAL_START_GET,
  SHIPPING_PROFILES_GET_SERVICES,
  SHIPPING_PROFILES_GET_SERVICES_REQUEST,
  SHIPPING_PROFILES_GET_SERVICES_SUCCESS,
  SHIPPING_PROFILES_GET_SERVICES_FAILURE,
  SHIPPING_PROFILES_GET_LIST,
  SHIPPING_PROFILE_GET,
  SHIPPING_PROFILES_CANCEL,
} from "../../constants"

import { get as getServices } from "../../fetch/shippingProfiles/services"

function* getList() {
  while (true) {
    yield take([
      TRIAL_START_GET,
      SHIPPING_PROFILES_GET_SERVICES,
      SHIPPING_PROFILES_GET_LIST,
      SHIPPING_PROFILE_GET,
    ])
    yield put({ type: SHIPPING_PROFILES_GET_SERVICES_REQUEST })
    try {
      const { data } = yield call(() => getServices())

      if (data) {
        yield put({
          type: SHIPPING_PROFILES_GET_SERVICES_SUCCESS,
          data,
        })
      }
    } catch (error) {
      yield put({ type: SHIPPING_PROFILES_GET_SERVICES_FAILURE, error })
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([fork(getList)])

    yield all(tasks)

    yield take(SHIPPING_PROFILES_CANCEL)
    yield all(tasks.map(task => task.cancel()))
  }
}
