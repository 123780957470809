import { takeLatest, put, putResolve, select } from "redux-saga/effects"

import { triggerModal, getAccounts, getAllMarkets } from "data/actions/epl/epl"

import {
  EPL_ACCOUNTS_GET_SUCCESS,
  EPL_ALL_MARKETS_GET_SUCCESS,
  EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_SHOW,
  EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_SHOW,
  ELIGIBILITY_STATUS,
  EPL_LOCAL_DATA,
  EPL_DATA_FOR_ACCOUNTS_VIEW,
  EPL_DATA_FOR_ACCOUNTS_VIEW_SUCCESS,
  EPL_DATA_FOR_ACCOUNTS_VIEW_FAILURE,
} from "data/constants"

import { DataStore } from "shared/helpers/localStorage"

import { getActiveAccount } from "../../../shared/helpers/eligibility"

const {
  EPL_LOCAL_STORAGE,
  SHOW_MODAL_ALL_BLOCKED,
  SHOW_MODAL_SOME_BLOCKED,
  DONT_SHOW,
  ALL,
  SOME,
  ELIGIBILITY_CHECK,
} = EPL_LOCAL_DATA

const PLA = "PLA"
const SHOW_NOT_ELIGIBLE_MODAL = "SHOW_NOT_ELIGIBLE_MODAL"

function setShowNotEligibleModal({ type, modal }) {
  const userStoredData = DataStore.getAll(EPL_LOCAL_STORAGE)
  if (userStoredData !== null) {
    const typeValue =
      userStoredData?.[ELIGIBILITY_CHECK]?.[type] !== DONT_SHOW
        ? modal
        : DONT_SHOW
    DataStore.set(EPL_LOCAL_STORAGE, {
      [ELIGIBILITY_CHECK]: {
        ...userStoredData?.[ELIGIBILITY_CHECK],
        [type]: typeValue,
      },
    })
  } else {
    DataStore.set(EPL_LOCAL_STORAGE, { [ELIGIBILITY_CHECK]: { [type]: modal } })
  }
}

function* showNotEligibleModal(action) {
  const { type } = action.data
  const modalStatus = DataStore.getAll(EPL_LOCAL_STORAGE)?.[
    ELIGIBILITY_CHECK
  ]?.[type]

  const modalType =
    type === ALL ? SHOW_MODAL_ALL_BLOCKED : SHOW_MODAL_SOME_BLOCKED

  const modalTypeAction =
    type === ALL
      ? EPL_NOT_ELIGIBLE_MODAL_FOR_ALL_SHOW
      : EPL_NOT_ELIGIBLE_MODAL_FOR_SOME_SHOW

  switch (modalStatus) {
    case undefined:
    case null:
      setShowNotEligibleModal({ type, modal: modalType })
      yield put(triggerModal(modalTypeAction))
      break
    case SHOW_MODAL_ALL_BLOCKED:
    case SHOW_MODAL_SOME_BLOCKED:
      yield put(triggerModal(modalTypeAction))
      break
    default:
      break
  }
}

function* isNotEligibleForAll(action) {
  const { general } = yield select()
  const activeUserAccount = getActiveAccount({
    activeAccount: general.activeAccount,
    accounts: action.req?.accounts,
  })
  return (
    action.type === EPL_ACCOUNTS_GET_SUCCESS &&
    activeUserAccount?.eligibility_status === ELIGIBILITY_STATUS.NOT_ELIGIBLE
  )
}

function isNotEligibleForSome(action) {
  return (
    action.type === EPL_ALL_MARKETS_GET_SUCCESS &&
    checkMarketsEligibility(action)
  )
}

function* eligibilityCheck(action) {
  if (isNotEligibleForAll(action)) {
    yield put({ type: SHOW_NOT_ELIGIBLE_MODAL, data: { type: ALL } })
  }
  if (isNotEligibleForSome(action)) {
    yield put({ type: SHOW_NOT_ELIGIBLE_MODAL, data: { type: SOME } })
  }
}

function checkMarketsEligibility(action) {
  const isSomeMarketsNotEligible =
    action.req.markets
      .map(market =>
        market.eligibility.filter(program => program.program_type === PLA)
      )
      .filter(el => el.is_eligible === false).length > 0
  return isSomeMarketsNotEligible
}

function* getEPLMarketsData() {
  try {
    yield putResolve(getAccounts())
    yield putResolve(getAllMarkets())
    yield put({ type: EPL_DATA_FOR_ACCOUNTS_VIEW_SUCCESS })
  } catch (error) {
    if ([424, 500, 502, 504].includes(error.status)) {
      yield put({ type: EPL_DATA_FOR_ACCOUNTS_VIEW_FAILURE })
    } else {
      yield put({ type: EPL_DATA_FOR_ACCOUNTS_VIEW_SUCCESS })
    }
  }
}

export default function* main() {
  yield takeLatest(
    [EPL_ACCOUNTS_GET_SUCCESS, EPL_ALL_MARKETS_GET_SUCCESS],
    eligibilityCheck
  )
  yield takeLatest(SHOW_NOT_ELIGIBLE_MODAL, showNotEligibleModal)
  yield takeLatest(EPL_DATA_FOR_ACCOUNTS_VIEW, getEPLMarketsData)
}
