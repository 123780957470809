import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function get(id) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${id}/settings`,
    method: "GET",
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export function edit(id, name, value) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/v1/users/${id}/settings/${name}`,
    method: "PUT",
    data: {
      value,
    },
  }
  const promise = fetch(options)
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  get,
  edit,
}
