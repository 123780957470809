import { includes } from "lodash"
import CONFIG from "../../../core/config"
import { replaceUrlHost } from "../../helpers/misc"

export const UILANG_TO_HCLANG = {
  en: "en-us",
  de: "de",
  fr: "fr",
  es: "es",
  it: "it",
  zh: "zh-cn",
}
export const HC_SECTION_IDS = {
  ebay: 200193953,
  amazon: 200197446,
  "shipping-program": 202577248,
  "invoices-and-payments": 202117647,
}

// Sections with small amount of articles
export const LIMITED_SECTIONS = [200197446]
// Sections without FR, IT, ES or ZH
export const EMPTY_LANGUAGE_SECTIONS = [202577248]

export const getIdBySlug = slug => HC_SECTION_IDS[slug]

export const isLimitedSection = (section, lang) =>
  includes(LIMITED_SECTIONS, section) && includes(["it", "es", "zh-cn"], lang)

export const isEmptyLanguageSection = (section, lang) =>
  includes(EMPTY_LANGUAGE_SECTIONS, section) && !includes(["en", "de"], lang)

// we need to pass diferrent params to search (category or section queryParam) basing on Issue Type
// for Invoices and Payments we need to pass category queryParam: http://api.webinterpret.com/v1/articles?category=202117647&locale=de
// for the rest of Issue Types we need to pass section queryParam: i.e. http://api.webinterpret.com/v1/articles?locale=de&section=200604456
export const getSearchParams = id =>
  [200604456].indexOf(id) > -1 ? { section: id } : { category: id }

export const getLocale = (lang, section) => {
  let locale = UILANG_TO_HCLANG[lang]
  // for sections with limited languages with locale different than en-us or de, use en-us locale, because
  // there are no articles in fr/ es/ it/ zh
  if (section && isEmptyLanguageSection(section, lang)) {
    locale = "en-us"
  }
  return locale
}

export const replaceUrls = articles =>
  articles.map(article => {
    // Expression to find every href in text
    const regex = /href="(.*?)"/g
    const body = article.body.replace(regex, url => {
      // Replace relative url with baseURL from help center
      const href = replaceUrlHost(CONFIG.zendeskHost, url)
      return `href="${href}"`
    })
    return {
      ...article,
      body,
    }
  })

export default {
  getLocale,
  isLimitedSection,
  isEmptyLanguageSection,
  getSearchParams,
  getIdBySlug,
}
