import { CANCEL } from "redux-saga"
import { AbortController } from "node-abort-controller"
import fetch from "core/fetch"
import CONFIG from "core/config"

export function pushLog(payload) {
  const controller = new AbortController()
  const options = {
    signal: controller.signal,
    url: `${CONFIG.gatewayHost}/diary`,
    method: "POST",
    data: payload,
  }
  const promise = fetch(options).catch(() => {})
  promise[CANCEL] = () => controller.abort()

  return promise
}

export default {
  pushLog,
}
