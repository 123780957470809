import { transform, isEqual, isObject } from "lodash/fp"

export const cleanObject = obj => {
  /* eslint-disable */
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName]
    }
  }
  return obj
  /* eslint-enable */
}

export const replaceUrlHost = (host, url) => {
  // Pattern to test is it's relative url or not
  const regex = /^https?:\/\//i
  // Get content inside href quotes
  const uri = url.match(/"(.*?)"/)[1]
  if (regex.test(uri)) return uri

  return `${host}${uri}`
}

// Get difference between objects
const _transform = transform.convert({
  cap: false,
})
const iteratee = baseObj => (result, value, key) => {
  if (!isEqual(value, baseObj[key])) {
    const valIsObj = isObject(value) && isObject(baseObj[key])
    result[key] = // eslint-disable-line
      valIsObj === true ? differenceObject(value, baseObj[key]) : value
  }
}
export function differenceObject(targetObj, baseObj) {
  return _transform(iteratee(baseObj), null, targetObj)
}

export default {
  cleanObject,
  differenceObject,
}
