import React from "react"
import PropTypes from "prop-types"

import { useTranslate } from "context/translateContext"
import logo from "shared/images/wi-logo.svg"

import styles from "./WiMotto.module.css"

const WiMotto = ({ addUnderline }) => {
  const { t: translate } = useTranslate()
  return (
    <div>
      <div className={styles.logo}>
        <img src={logo} alt="WebInterpret logo" />
      </div>
      <div
        className={[styles.motto, addUnderline ? styles.underline : ""].join(
          " "
        )}
      >
        {translate("Opening worldwide commerce")}
      </div>
    </div>
  )
}

WiMotto.propTypes = {
  addUnderline: PropTypes.bool,
}

WiMotto.defaultProps = {
  addUnderline: false,
}

export default WiMotto
