import { all, takeLatest, put, putResolve } from "redux-saga/effects"
import {
  EPL_ITEMS_STATUS_GET_SUCCESS,
  EPL_ITEMS_STATUS_GET_WITH_RESULTS,
  EPL_ITEMS_EXCLUDE_BY_FILE_POST_REQUEST,
  EPL_ITEMS_STATUS_GET_WITH_RESULTS_NOTIFICATION_CLEAR,
  EPL_ITEMS_EXCLUDE_BY_FILE_POST_FAILURE,
  EPL_ITEMS_LOAD,
  EPL_ITEMS_LOAD_SUCCESS,
  EPL_ITEMS_LOAD_FAILURE,
  EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS,
  EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS,
  EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS,
  EPL_LOCAL_DATA,
} from "data/constants"

import { DataStore } from "shared/helpers/localStorage"

import { loadItemsStatus, loadItemsList } from "data/actions/epl/epl"

const { EPL_LOCAL_STORAGE, SHOULD_SEE_EPL_RESULTS } = EPL_LOCAL_DATA

function* loadItemsViewData(action) {
  try {
    const { params } = action
    yield all([
      putResolve(loadItemsStatus()),
      putResolve(loadItemsList(params)),
    ])
    yield put({ type: EPL_ITEMS_LOAD_SUCCESS })
  } catch (error) {
    yield put({ type: EPL_ITEMS_LOAD_FAILURE })
  }
}

function* showExcludeByFileResults(action) {
  const showEPLResult =
    DataStore.getAll(EPL_LOCAL_STORAGE)?.[SHOULD_SEE_EPL_RESULTS] === "true"
  if (
    showEPLResult &&
    !action.req.processing &&
    (action.req.excluded > 0 || action.req.failed > 0)
  ) {
    yield put({
      type: EPL_ITEMS_STATUS_GET_WITH_RESULTS,
      data: { excluded: action.req.excluded, failed: action.req.failed },
    })
  }
}

function setShouldSeeEPLResult() {
  DataStore.set(EPL_LOCAL_STORAGE, { [SHOULD_SEE_EPL_RESULTS]: true })
}
function unsetShouldSeeEPLResult() {
  DataStore.remove(EPL_LOCAL_STORAGE, SHOULD_SEE_EPL_RESULTS)
}

export default function* main() {
  yield takeLatest(EPL_ITEMS_STATUS_GET_SUCCESS, showExcludeByFileResults)
  yield takeLatest(
    EPL_ITEMS_EXCLUDE_BY_FILE_POST_REQUEST,
    setShouldSeeEPLResult
  )
  yield takeLatest(
    [
      EPL_ITEMS_STATUS_GET_WITH_RESULTS_NOTIFICATION_CLEAR,
      EPL_ITEMS_EXCLUDE_BY_FILE_POST_FAILURE,
    ],
    unsetShouldSeeEPLResult
  )

  yield takeLatest(
    [
      EPL_ITEMS_LOAD,
      EPL_ITEMS_EXCLUDED_RESET_ALL_SUCCESS,
      EPL_ITEMS_EXCLUDED_RESET_SELECTED_SUCCESS,
      EPL_ITEMS_EXCLUDE_SELECTED_SUCCESS,
    ],
    loadItemsViewData
  )
}
