import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDropzone } from "react-dropzone"

import { useTranslate } from "context/translateContext"

import styles from "./Dropzone.module.css"

const isImage = type => /(^image\/)\w+/.test(type)

const renderDropzone = props => {
  const { t: translate } = useTranslate()
  const [myFiles, setMyFiles] = useState([])
  const { maxFiles, maxSize, multiple, accept, icon, dropzoneText } = props

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: acceptedFiles => {
      setMyFiles([...myFiles, ...acceptedFiles])
      props.input.onChange([...myFiles, ...acceptedFiles])
    },
    maxFiles,
    maxSize,
    multiple,
    accept,
    disabled: maxFiles === myFiles.length,
  })

  const disableInput = maxFiles === myFiles.length

  const removeFile = file => () => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
    props.input.onChange(newFiles)
  }

  const files = myFiles.map(file =>
    isImage(file.type)
      ? Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      : file
  )

  const thumbs = files.map(file => (
    <div className={styles.filesContainer} key={file.name}>
      <div className={styles.thumb}>
        <div className={styles.thumbInner}>
          {file.preview ? (
            <img src={file.preview} className={styles.thumbImg} alt="" />
          ) : (
            <span className="fs-62 icon-doc m-b-10"></span>
          )}
        </div>

        <button type="button" onClick={removeFile(file)}>
          <span className="icon-close m-l-5" />
        </button>
      </div>
      <span className="fs-10 breakWord m-t-5">{file.name}</span>
    </div>
  ))

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  return (
    <section className="container">
      <div
        {...getRootProps()}
        className={[styles.dropzone, disableInput ? styles.disabled : ""].join(
          " "
        )}
      >
        <input {...getInputProps()} disabled={disableInput} />
        {!!icon && <div className={[icon, styles.uploadIcon].join(" ")} />}
        <span className="fs-11">
          {dropzoneText || translate("Drop files or click here to upload")}
        </span>
      </div>
      {!!props.description && (
        <div className={styles.description}>
          <div>
            <span className="icon-exclamation v-b m-r-5 fs-20" />
            <span className="fs-13">{translate(props.description)}</span>
          </div>
          {!!props.sampleFile && (
            <div className="fs-11 textUppercase m-t-15">
              <a href={props.sampleFile}>{translate("Example file")}</a>
            </div>
          )}
        </div>
      )}

      <aside className={styles.thumbsContainer}>{thumbs}</aside>
      {!!fileRejectionItems.length && (
        <ul className="c-red fs-11">{fileRejectionItems}</ul>
      )}
    </section>
  )
}

renderDropzone.propTypes = {
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
}
renderDropzone.defaultProps = {
  multiple: false,
  accept: "image/*",
  maxFiles: 5,
  maxSize: 5242880, // 5MB
}
export default renderDropzone
