import { all, select, put, fork, call, take } from "redux-saga/effects"

import {
  USER_GET_SUCCESS,
  ENTITLEMENTS_CUSTOM_GET,
  ENTITLEMENTS_CUSTOM_GET_SUCCESS,
  ENTITLEMENTS_CUSTOM_GET_FAILURE,
} from "data/constants"

import API from "data/fetch/entitlements/entitlements"

function* getUserEntitlements() {
  while (true) {
    yield take([USER_GET_SUCCESS])
    const { user } = yield select()
    yield put({ type: ENTITLEMENTS_CUSTOM_GET })
    try {
      const { data } = yield call(() => API.getUserEntitlements(user.id))
      if (data) yield put({ type: ENTITLEMENTS_CUSTOM_GET_SUCCESS, data })
    } catch (error) {
      yield put({ type: ENTITLEMENTS_CUSTOM_GET_FAILURE, error })
    }
  }
}

export default function* main() {
  const tasks = yield all([fork(getUserEntitlements)])

  yield all(tasks)
}
